import React, { useEffect, useState } from "react";
import style from "./EventsImagesStyle";
import { Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import { getallcampaignAPI } from "../../../apis/auth";
import {
  setmediatabswitch,
  storeInLocalStore,
} from "../../../redux/reducers/mainSlice";
import { useNavigate } from "react-router-dom";

const CreatedButton = () => {
  const profile_id = useSelector(
    (state) => state.main.store.profile_data?.profile_id
  );
  const storeCampaignlist = useSelector(
    (state) => state.main.store.campaignsList
  );
  const dispatch = useDispatch();
  const history = useNavigate();
  const [campaignDataList, setCampaignDataList] = useState([]);
  const getallcampaign = () => {
    let data = {
      profile_id: profile_id,
      unit_id: 0,
    };
    getallcampaignAPI(data).then((res) => {
      let tempData = res.campaign.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setCampaignDataList(tempData);
      dispatch(storeInLocalStore({ campaignsList: tempData }));
    });
  };
  const movetomedia = (data) => {
    dispatch(setmediatabswitch(data));
    dispatch(storeInLocalStore({ selectedcampaign: data }));
    history("/media");
  };
  useEffect(() => {
    if (profile_id) {
      if (!storeCampaignlist) {
        getallcampaign();
      } else {
        setCampaignDataList(storeCampaignlist);
      }
    }
  }, [storeCampaignlist]);
  return (
    <>
      <Typography sx={style.createText}>Created Campaigns</Typography>
      <CustomButton
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          flexShrink: 0,
          borderRadius: 0,
          marginTop: "20px",
          maxWidth: "300px",
        }}
        onClick={(e) => {
          movetomedia("Evergreen");
        }}
        label="Evergreen"
      />
      {campaignDataList &&
        campaignDataList.length > 0 &&
        campaignDataList.map((item, index) => {
          return (
            <React.Fragment>
              <CustomButton
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  borderRadius: 0,
                  marginTop: "20px",
                  maxWidth: "300px",
                }}
                onClick={(e) => {
                  movetomedia(item.name);
                }}
                label={item.name}
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default CreatedButton;
