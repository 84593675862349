const style = {
    boldText: {
        color: "#FCFCFC",
        fontSize: "80px",
        fontFamily: "WhyteInktrap-Black",
        fontWeight: 400,
        lineHeight: "105px",
        textTransform: "uppercase",
    },
    text: {
        color: "#FCFCFC",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "SFProText-Regular",
    },
    errMsgBox: {
        color: "red",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "SFProText-Regular",
    },
    inputStyles: {
        backgroundColor: "black",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        padding: "34px 20px 20px 20px",
        borderRadius: "20px",
        width: "300px",
        color: "#FCFCFC",
        textAlign: "justify",
        fontSize: "16px",
        fontFamily: "SFProText-Regular",
    },
    forgetPasswordBtn: {
        textTransform: "none",
        color: "#FCFCFC",
        fontSize: "16px",
        fontFamily: "SFProText-Regular",
        marginRight: "10px",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-center"
    },
    signInButton: {
        backgroundColor: "#eb2426",
        width: "350px",
        borderRadius: "50px",
        display: "flex",
        padding: "16px 50px",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            background: "#eb2426",
        },
    },
    iconsBox: {
        paddingLeft: "120px",
    },
    buttonBox: {
        paddingLeft: "50px",
    },
    signupBtn: {
        textTransform: "none",
        color: "#FCFCFC",
        fontSize: "13px",
        fontFamily: "SFProText-Regular",
        width:'300px',
        padding: '0px 3px',

    },
    agrrecontent: {
        textTransform: "none",
        color: "#FCFCFC",
        fontSize: "13px",
        fontFamily: "SFProText-Regular",
        padding: '0px 3px',

    },
    agreeTitle : {
        textTransform: "none",
        color: "#FCFCFC",
        fontSize: "22px",
        fontWeight:'900',
        fontFamily: "helvetica-font",
        padding: '0px 3px',
    },
    morebtn: {
        textTransform: "none",
        color: "#FCFCFC",
        fontSize: "13px",
        fontFamily: "SFProText-Regular",
        width:'300px',
        padding: '0px 3px',
        textDecoration:'none',
        fontWeight: '700'
    },
    inputLabel: {
        position: "absolute",
        top: "0px",
        paddingTop: "10px",
        paddingLeft: "20px",
        color: "#FCFCFC",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "WhyteInktrap-Black",
    },

};

export default style;
