import { Box, Typography } from '@mui/material'
import React from 'react'
import style from './PaymentStyle'
import payment from '../../assets/images/Payment/payment.png'
import check from '../../assets/images/Payment/check.png'
import facebook from '../../assets/images/Payment/paymentIcon-4.png'
import instagram from '../../assets/images/Payment/paymentIcon-3.png'
import google from '../../assets/images/Payment/paymentIcon-1.png'
import gmail from '../../assets/images/Payment/paymentIcon-2.png'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/Common/CustomButton/CustomButton'
function AddPaymentMethodPage() {
    const navigate = useNavigate()
    return (
        <Box style={{...style.container,maxWidth:"80vw"}}>
            <Typography style={style.addPaymentHeading}>Payment Method</Typography>
            <Typography style={style.addPaymentSmallHeading}>You haven’t set up any payment method</Typography>
            <CustomButton onClick={()=>navigate('/payment-form')} label={'Add Payment Method'} sx={{padding:'8px 30px',margin:'20px 0px',width:"max-content",fontFamily: 'SF Pro display', fontSize: '15px'}}/>
            <Typography style={style.addPaymentHeading}>Payment History</Typography>
            <Typography style={style.addPaymentSmallHeading}>Your billing history is empty. Start adding your card information to boost your engagement and statistics.</Typography>
        </Box>
    )
}

export default AddPaymentMethodPage