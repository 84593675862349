const style = {
  container: {
    maxWidth: "100%",
    padding: "6% 8% 0% 8%",
    backgroundColor: "#000000",
    color: "#FCFCFC",
  },
};

export default style;
