import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { handleAddressglobal } from "../../CustomHook/addressUtills";
import crossicon from "./../../assets/images/cross.png";

const Addform = ({
  open,
  handleclose,
  setLocations,
  locations,
  editabledata,
  setEditabledata,
  currentIndex,
  setCurrentindex,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [formdata, setFormdata] = useState({
    address: "",
    authorize: "",
    email: "",
  });
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"],
  };
  const handlevalue = (val) => {
    setFormdata(val);
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      if (place) {
        let confirmAddress = handleAddressglobal(place);
        handlevalue({
          ...formdata,
          address:
            `${confirmAddress?.addressLine1}  ${confirmAddress?.city} ${confirmAddress?.zipCode}, ${confirmAddress?.country} ` ||
            inputRef.current.value,
        });
      }
    });
  }, [inputRef.current]);

  const closepopup = () => {
    setFormdata({
      address: "",
      authorize: "",
      email: "",
    });
    handleclose();
    setEditabledata();
  };

  const handleSubmit = () => {
    if (currentIndex != null) {
      let newarray = [...locations];
      newarray[currentIndex] = formdata;
      setLocations(newarray);
    } else {
      setLocations([...locations, { ...formdata }]);
    }
    setCurrentindex(null);
    closepopup();
  };
  useEffect(() => {
    setFormdata(editabledata);
  }, [editabledata]);
  return (
    <Modal
      show={open}
      onClose={() => {
        closepopup();
      }}
      centered
      className="description-body"
    >
      <Modal.Body>
        <div
          style={{
            margin: "30px",
            position: "relative",
          }}
        >
          <img
            src={crossicon}
            style={{
              width: "20px",
              position: "absolute",
              top: "-20px",
              right: "-20px",
              cursor: "pointer",
            }}
            alt="cross"
            onClick={() => {
              closepopup();
            }}
          />
          <h3 className="text-center h3-title-while-whynk">Add New Address</h3>
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            <div className="form-group mb-4">
              <label>Address</label>
              <input
                ref={inputRef}
                type={"text"}
                value={formdata?.address}
                className="custom-input"
                onChange={(e) => {
                  handlevalue({
                    ...formdata,
                    address: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group mb-4">
              <label>Authorize</label>
              <input
                type={"text"}
                value={formdata?.authorize}
                className="custom-input"
                onChange={(e) => {
                  handlevalue({
                    ...formdata,
                    authorize: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group mb-4">
              <label>Email</label>
              <input
                type={"email"}
                value={formdata?.email}
                className="custom-input"
                onChange={(e) => {
                  handlevalue({
                    ...formdata,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <button
              className={`byteready-btn byteready-primary ${
                formdata?.address && formdata?.authorize && formdata?.email
                  ? ""
                  : "disabled"
              }`}
              style={{
                width: "100%",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Addform;
