import React, { useRef, useState } from "react";

const CustomVideoPlayer = ({ videoSrc, posterImage }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingPending, setIsPlayingPending] = useState(false);

  const handleClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        // Prevent multiple play requests
        if (isPlayingPending) return;

        setIsPlayingPending(true);
        videoRef.current
          .play()
          .then(() => {
            setIsPlaying(true);
            setIsPlayingPending(false);
          })
          .catch((error) => {
            console.error("Error playing video:", error);
            setIsPlayingPending(false);
          });
      }
    }
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        className="video-player"
        controls
        poster={posterImage}
        onClick={handleClick}
        style={{
          width: "100%",
        }}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default CustomVideoPlayer;
