const style = {
    boldText: {
      color: "#FCFCFC",
      fontSize: "80px",
      textAlign: "center",
      fontFamily: "WhyteInktrap-Black",
      fontWeight: 400,
      lineHeight: "105px",
      textTransform: "uppercase",
    },
    text: {
      color: "#FCFCFC",
      textAlign: "center",
      justifyContent: "center",
      fontSize: "18px",
      fontFamily: "SFProText-Regular",
    },
    loginButton: {
      marginTop: "20px",
      backgroundColor: "#eb2426",
      width: "350px",
      borderRadius: "50px",
      display: "flex",
      padding: "16px 50px",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        background: "#eb2426",
      },
    },
  };
  
  export default style;