import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./../state";

const setInitialState = () => {
  const store = JSON.parse(localStorage.getItem("store"));
  if (store) {
    return { ...initialState, store };
  } else {
    return initialState;
  }
};
export const mainSlice = createSlice({
  name: "main",
  initialState: setInitialState(),
  reducers: {
    storeInLocalStore: (state, action) => {
      const storeData = state.store ?? {};
      const payloadData = action.payload ?? {};

      state.store = Object.assign(storeData, payloadData);
      localStorage.setItem(
        "store",
        JSON.stringify({ ...storeData, ...payloadData })
      );
    },
    settogodownposition: (state, action) => {
      state.settogodownposition = action.payload;
    },
    setoriginscroller: (state, action) => {
      state.setoriginscroller = action.payload;
    },
    setmediatabswitch: (state, action) => {
      state.setmediatabswitch = action.payload;
    },
    selectedPlatform: (state, action) => {
      state.selectedPlatform = action.payload;
    },
    setsavepostloader: (state, action) => {
      state.savepostloader = action.payload;
    },
    setsavepostsecondloader: (state, action) => {
      state.savepostsecondloader = action.payload;
    },
    setreviewmainloader: (state, action) => {
      state.reviewloader = action.payload;
    },
    setreviewsecondloader: (state, action) => {
      state.reviewsecondloader = action.payload;
    },
  },
});

export const {
  storeInLocalStore,
  settogodownposition,
  setoriginscroller,
  setmediatabswitch,
  selectedPlatform,
  setsavepostloader,
  setsavepostsecondloader,
  setreviewmainloader,
  setreviewsecondloader,
} = mainSlice.actions;

export default mainSlice.reducer;
