import React, { useEffect, useState } from "react";
import style from "./Style";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import "./Imagestyle.css";
// import PHONEIMG from "../../../assets/SVG/phoneImg.svg";
import PhoneImage from "../../../assets/images/postview/homephone.png";
import SwitchButton from "../../../components/Common/SwitchButton/SwitchButton.jsx";
import VerticalContainer from "../../../assets/images/vertical_container.png";
import ResetBtn from "../../../assets/SVG/ic_round-refresh.svg";
import FrontCamera from "../../../assets/SVG/Front_Camera.svg";
import deleteIconImg from "../../../assets/SVG/delete.svg";
import EditInstagram from "./posts/EditInstagram";
import EditFacebook from "./posts/EditFacebook";
import { useSelector } from "react-redux";

const ImageSlider = ({
  image,
  images,
  description,
  iconurl,
  mobileLoader,
  resturantNameValue,
  loader,
  resetImage,
  handleImageUpload,
  handleTextChange,
  editableText,
  customStyle,
  deleteIcon,
  handleDeleteOpen,
}) => {
  const website_url = useSelector(
    (state) => state?.main?.store?.profile_data?.website
  );
  const [uploadImage, setUploadImage] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [editable, setEditable] = useState(false);
  const [currentDesign, setCurrentDesign] = useState("insta");
  const [editData, setEditData] = useState("");

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleEdit = (e) => {
    setEditData(e.target.value);
    handleTextChange(e.target.value);
  };

  const handleUploadImage = (e) => {
    // setUploadImage(URL.createObjectURL(e.target.files[0]));
    handleImageUpload(e);
  };

  const handleEditClose = () => {
    setEditable(false);
  };

  useEffect(() => {
    console.log(website_url);
    setUploadImage(image);

    setEditData(description);
    //setEditData(resturantData?.promt_text);
  }, [image, description]);
  const designSwitch = (data) => {
    setCurrentDesign(data);
  };
  return (
    <>
      <div
        className="phoneMainwrapper"
        style={{
          background: mobileLoader ? "#f1f1f1" : `url(${uploadImage})`,
        }}
      >
        <>
          {mobileLoader ? (
            <>
              <div className="innerMobilewrapper">
                <CircularProgress
                  style={{
                    color: "#eb2426",
                    margin: "auto",
                    textAlign: "center",
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="innerMobilewrapper">
                <div
                  className="phone"
                  style={{
                    backgroundImage: `url(${PhoneImage})`,
                  }}
                >
                  <div className="swicth">
                    <SwitchButton
                      designSwitch={designSwitch}
                      mobilePhone={true}
                    />
                  </div>
                  {currentDesign == "insta" ? (
                    <>
                      <EditInstagram
                        iconurl={`https://s2.googleusercontent.com/s2/favicons?domain=${website_url}`}
                        resturantNameValue={resturantNameValue}
                        loader={loader}
                        uploadImage={uploadImage}
                        resetImage={resetImage}
                        ResetBtn={ResetBtn}
                        handleUploadImage={handleUploadImage}
                        editData={editData}
                        weburl={website_url}
                      />
                    </>
                  ) : (
                    <>
                      <EditFacebook
                        iconurl={`https://s2.googleusercontent.com/s2/favicons?domain=${website_url}`}
                        resturantNameValue={resturantNameValue}
                        loader={loader}
                        uploadImage={uploadImage}
                        resetImage={resetImage}
                        ResetBtn={ResetBtn}
                        handleUploadImage={handleUploadImage}
                        editData={editData}
                        weburl={website_url}
                      />
                    </>
                  )}
                </div>
                {deleteIcon && (
                  <Box onClick={handleDeleteOpen} sx={style.deleteBox}>
                    <img src={deleteIconImg} alt="deleteError" />
                  </Box>
                )}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default ImageSlider;
