const style = {
  mobViewContainer: {
    width: "70%",
    padding: "5% 0% 0% 0%",
    display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
    justifyContent: "center",
  },
  mobViewStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  behaviorBox: {
    display: "flex",
    width: "90%",
    padding: "2% 0% 8% 0%",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    borderRadius: "40px",
    background: "#090909",
  },
  text: {
    color: "#FCFCFC",
    textAlign: "left",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "26px",
    lineHeight: "38px",
    fontStyle: "normal",
  },
  text2: {
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 100,
    textTransform: "uppercase",
    fontSize: "11px",
    fontStyle: "normal",
    lineHeight: "120%",
    marginTop: "15%",
  },
  text3: {
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "SFProText-Regular",
    fontWeight: 900,
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  iconBox: { width: "80%", height: "13vh" },
  imgBox: {
    width: "37px",
    height: "37px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  whiteBox: {
    display: "flex",
    width: "25px",
    height: "25px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "50px",
    background: "#FFF",
    backdropFilter: "blur(12.5px)",
  },
  addPointBox: {
    height: "15vh",
    display: "flex",
    padding: "24px 16px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    borderRadius: "20px",
    border: "1px solid #262626",
    background: "#000",
  },
  label: {
    margin: "5% 0% 2% 15%",
    color: "#9E9E9E",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  mobHeaderText: {
    color: "#9E9E9E",
    fontFamily: "SFProText-Regular",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "5%",
  },

  fabIconStyle: {
    position: "fixed",
    right: "2%",
    bottom: "15%",
    background: "#eb2426",
    "&:hover": {
      background: "#eb2426",
    },
  },
  container: {
    maxWidth: "100%",
    // height: "100vh",
    backgroundColor: "#111111",
    color: "#fffff",
    padding: "88px 0px 50px 100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cards: {
    marginTop: "10%",
    width: "200px",
    height: "136px",
    borderRadius: "0px 0px 20px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  addListsBox: {
    display: "flex",
    flexDirection: "row",
    height: "6vh",
    padding: "16px 24px 16px 4px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "50px",
    background: "#eb2426",
  },
  behaviorCircle: {
    width: "45px",
    height: "45px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default style;
