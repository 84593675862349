import React, { useState } from "react";
import { Slider, Typography, Box, Stack } from "@mui/material";

const style = {
  text: {
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "20px",
    lineHeight: "1.2",
  },
  text2: {
    color: "rgba(255, 255, 255, 0.40)",
    textAlign: "right",
    fontFamily: "SFProText-Regular",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "1.2",
  },
  text3: {
    color: "#FCFCFC",
    textAlign: "right",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "20px",
    lineHeight: "1.2",
  },
  box: {
    width: "22px",
    height: "22px",
    flexShrink: 0,
    borderRadius: "15px",
    background: "#eb2426",
    boxShadow: "0px 0px 2px 0px rgba(14, 123, 224, 0.10)",
    position: "absolute",
    top: "10%",
  },
  msgBox: {
    position: "absolute",
    top: -22,
    transform: "translateX(-50%)",
    width: "auto",
    backgroundColor: "#000",
    color: "#fff",
    padding: "4px 8px",
    borderRadius: 4,
    textAlign: "center",
  },
};

const CustomSlider = ({
  maxviewers,
  baseprice,
  targetprice,
  handleSliderChange,
}) => {
  return (
    <>
      <Box>
        <Typography sx={style.text3}>{maxviewers}</Typography>
        <Typography sx={style.text2}>Max Viewers</Typography>
      </Box>
      <Stack sx={{ position: "relative" }} direction="row" spacing={2}>
        <Box
          sx={{
            ...style.box,
            left: "1%",
          }}
        ></Box>
        <Slider
          sx={{
            color: "#eb2426",
            "& .MuiSlider-valueLabel": {
              borderRadius: "20px",
              background: "#eb2426",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "4px 8px",
            },
          }}
          aria-label="Custom slider"
          valueLabelDisplay="on"
          valueLabelFormat={(value) => {
            return (
              <>
                    <div className="media-slider-top-data">
                      <div className="media-slider-number">+{value}</div>
                      <div className="media-slider-text">New Viewer</div>
                    </div>
                    <div className="media-slider-down-data">${targetprice}</div>
                  </>
            );
          }}
          onChange={handleSliderChange}
          max={maxviewers}
          min={0}
        />
        <Box
          sx={{
            ...style.box,
            right: "-2.2%",
            background: "#262626",
          }}
        ></Box>
      </Stack>
      <Typography sx={style.text3}>${maxviewers * baseprice}</Typography>
    </>
  );
};

export default CustomSlider;
