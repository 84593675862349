import React, { useState } from "react";
import { debounce } from "../CustomHook/addressUtills";
import { useDispatch } from "react-redux";
import { storeInLocalStore } from "../redux/reducers/mainSlice";
import Thoughtspotcomponent from "./../components/thoughtspot/Thoughtspotcomponent";

const Customerdata = () => {
  const dispatch = useDispatch();
  const [thoughtspotData, setThoughtspot] = useState([]);

  const liveboardId = "f3777095-89a1-4d7c-b70b-c2a55149f6bc";
  const handleevent = (type, data) => {
    //console.log("Type", type, "Data", data);
  };
  const saveToLocalStorage = debounce((data) => {
    dispatch(storeInLocalStore({ thoughtspotData: data }));
  }, 1000);
  return (
    <div className="full-width">
      <div className="content-padding-both">
        <Thoughtspotcomponent
          thoughtspotData={thoughtspotData}
          setThoughtspot={setThoughtspot}
          liveboardId={liveboardId}
          handleevent={handleevent}
          saveToLocalStorage={saveToLocalStorage}
        />
      </div>
    </div>
  );
};

export default Customerdata;
