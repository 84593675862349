import { Stack } from "@mui/material";
import React from "react";
import point from "../../assets/SVG/point.svg";

const OptionIcon = ({ style }) => {
  return (
    <>
      <Stack sx={{ width: "20%" , height: "20%", ...style }} direction="row" spacing={.5} >
        <img src={point} alt="error" />
        <img src={point} alt="error" />
        <img src={point} alt="error" />
      </Stack>
    </>
  );
};

export default OptionIcon;
