const style = {
  mainContainer: {
    backgroundColor: "#000000",
    color: "#FCFCFC",
    padding: "100px 0px 50px 0px",
    position: "relative",
  },
  title: {
    color: "#FCFCFC",
    fontSize: "128px",
    fontWeight: 400,
    fontFamily: "WhyteInktrap-Black",
    textTransform: "uppercase",
    lineHeight: "1.2",
    paddingLeft: "80px",
    "@media (max-width: 700px)": {
      paddingLeft: "40px",
      fontSize: "60px",
    },
  },
  titleShadowStyle: {
    color: "#FCFCFC",
    fontSize: "128px",
    fontWeight: 400,
    fontFamily: "WhyteInktrap-Black",
    textTransform: "uppercase",
    opacity: "0.1000",
    position: "absolute",
    top: "25px",
    left: "-225px",
  },
  button: {
    width: "5%",
    height: "12vh",
    flexShrink: 0,
    color: "#FCFCFC",
    fontSize: "12px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    backgroundColor: "#eb2426",
    position: "absolute",
    right: "3%",
    top: "36%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "0px 0px 40px 30px rgba(205, 76, 29, 0.80)",
    "@media (max-width: 400px)": {
      right: "20px",
      marginTop: "-20px",
    },
  },
};

export default style;
