import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

const style = {
  label: {
    color: "#FFF",
    fontFamily: "SFProText-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  text: {
    fontStyle: "normal",
    color: "#FCFCFC",
    textAlign: "left",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "normal",
  },
};

const StartDate = () => {
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const [startDate, setStartDate] = useState(
    moment(currDate).format("YYYY-MM-DD")
  );
  const [time, setTime] = useState(
    moment(currTime, "HH:mm").format("HH:mm:ss")
  );

  return (
    <>
      <Typography sx={style.text}>Start Date</Typography>
      <Stack sx={{ margin: "2% 0%" }} direction="row" spacing={5}>
        <Box sx={{ width: "45%" }}>
          <label htmlFor="startDatePicker" style={style.label}>
            Start Date
          </label>
          <input
            style={{ marginTop: "6%" }}
            defaultValue={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            type="date"
            id="startDatePicker"
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <label htmlFor="timePicker" style={style.label}>
            Time
          </label>
          <input
            style={{ marginTop: "6%" }}
            defaultValue={time}
            placeholder="HH:MM:SS"
            id="timePicker"
            type="time"
            onChange={(e) => setTime(e.target.value)}
          />
        </Box>
      </Stack>
    </>
  );
};

export default StartDate;
