import { Box, Typography } from '@mui/material'
import React from 'react'
import style from './PaymentStyle'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import eye from '../../assets/images/Payment/eye.png'
import trash from '../../assets/images/Payment/iconoir_trash.png'
import CustomButton from '../../components/Common/CustomButton'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function PaymentHistoryPage() {
    const rows = [
        {
            date: "15 October, 2022",
            plan: 'Boost Posts',
            card: "VISA **** **** **** 8132",
            status: 'Paid',
            price: '$199'
        },
        {
            date: "15 October, 2022",
            plan: 'Boost Posts',
            card: "VISA **** **** **** 8132",
            status: 'Paid',
            price: '$199'
        },
        {
            date: "15 October, 2022",
            plan: 'Boost Posts',
            card: "VISA **** **** **** 8132",
            status: 'Paid',
            price: '$199'
        }
    ]
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: '#717171',
            fontWeight:'400'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: '#151515',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#151515',
        },
        'td': {
            color:"#fff",
            fontSize:"13px !important",
            fontFamily:"SF Pro Display",
            fontWeight:400
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (
        <Box style={{ ...style.container, maxWidth: "80vw" }}>
            <Typography style={style.addPaymentHeading}>Payment Method</Typography>
            <Box className='d-flex justify-content-between mb-5'>
                <Box sx={{ ...style.smallContainer, top: '0', marginTop: '20px', borderRadius: '3px', padding: "20px", marginRight: "20px", width: "23%" }}>
                    <Typography style={{ width: "max-content", display: "inline-block" }}>**** **** **** 8132</Typography>
                    <img src={eye} style={{ marginLeft: '10px' }} />
                    <Box className='d-flex align-items-center justify-content-between'>
                        <CustomButton text={'Make default'} spacing={'5px 20px'} custommargin={'10px 0px'} />
                        <img src={trash} />
                    </Box>
                </Box>
                <Box sx={{ ...style.smallContainer, top: '0', marginTop: '20px', borderRadius: '3px', padding: "20px", marginRight: "20px", width: "23%" }}>
                    <Typography style={{ width: "max-content", display: "inline-block" }}>**** **** **** 8132</Typography>
                    <img src={eye} style={{ marginLeft: '10px' }} />
                    <Box className='d-flex align-items-center justify-content-between'>
                        <CustomButton text={'Make default'} spacing={'5px 20px'} custommargin={'10px 0px'} />
                        <img src={trash} />
                    </Box>
                </Box>
                <Box sx={{ ...style.smallContainer, top: '0', marginTop: '20px', borderRadius: '3px', padding: "20px", marginRight: "20px", width: "23%" }}>
                    <Typography style={{ width: "max-content", display: "inline-block" }}>**** **** **** 8132</Typography>
                    <img src={eye} style={{ marginLeft: '10px' }} />
                    <Box className='d-flex align-items-center justify-content-between'>
                        <CustomButton text={'Make default'} spacing={'5px 20px'} custommargin={'10px 0px'} />
                        <img src={trash} />
                    </Box>
                </Box>
                <Box sx={{ ...style.smallContainer, top: '0', marginTop: '20px', borderRadius: '3px', padding: "20px", marginRight: "20px", width: "23%" }}>
                    <Typography style={{ width: "max-content", display: "inline-block" }}>**** **** **** 8132</Typography>
                    <img src={eye} style={{ marginLeft: '10px' }} />
                    <Box className='d-flex align-items-center justify-content-between'>
                        <CustomButton text={'Make default'} spacing={'5px 20px'} custommargin={'10px 0px'} />
                        <img src={trash} />
                    </Box>
                </Box>
            </Box>
            <Typography style={style.addPaymentHeading}>Payment History</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Plan</StyledTableCell>
                            <StyledTableCell>Card</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell>
                                    {row.date}
                                </StyledTableCell>
                                <StyledTableCell>{row.plan}</StyledTableCell>
                                <StyledTableCell>{row.card}</StyledTableCell>
                                <StyledTableCell>{row.status}</StyledTableCell>
                                <StyledTableCell>{row.price}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default PaymentHistoryPage