import React, { useEffect, useState } from "react";
import instaContainer from "../../../assets/images/postview/insta.png";
import { Box, Typography } from "@mui/material";
import style from "./InstaMobileViewStyle";
import profileImg from "../../../assets/images/circleInstaImg.png";
import more from "../../../assets/images/more.png";
import post from "../../../assets/images/events-3.png";
import heart from "../../../assets/images/postview/heart.png";
import chat from "../../../assets/images/postview/chat.png";
import share from "../../../assets/images/postview/send.png";
import bookmark from "../../../assets/images/postview/bookmark.png";
import NextBtn from "../../Common/NextBtn";
import PreviousBtn from "../../Common/PreviousBtn";
import EventPopUp from "../../NewEvents/EventPopOver/EventPopUp";
import UpdateEventPopUp from "../../NewEvents/EventPopOver/UpdateEventPopUp";
import { useSelector } from "react-redux";
function InstaMobileView({
  data,
  profile,
  commingfromgmail,
  showCustomHandleButton,
  functionOnModalClose,
}) {
  const itemsPerPage = 3;
  const [startIndex, setStartIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState();
  const [popUpData, setPopUpData] = useState();
  const [campaignData, setCampaignData] = useState([]);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const visibleItems = commingfromgmail
    ? ""
    : data?.slice(startIndex, startIndex + itemsPerPage);
  const getCaption = (data) => {
    let caption;
    if (data && Object.keys(data).length > 0) {
      caption = data?.filter((x) => x.platform == "instagram");
    }
    return caption?.[0]?.caption;
  };
  const handleNext = (items) => {
    if (startIndex + itemsPerPage < data.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const handlePrevious = (items) => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    if (showCustomHandleButton) {
      setCampaignData(visibleItems);
    } else {
      setCampaignData(data);
    }
  }, [data, showCustomHandleButton, visibleItems]);
  return (
    <>
      {showCustomHandleButton && data?.length > 3 && (
        <PreviousBtn width={"50px"} handlePrevious={() => handlePrevious()} />
      )}
      {!commingfromgmail &&
        campaignData?.map((campaign, index) => {
          return (
            <React.Fragment key={index}>
              <Box
                onClick={() => {
                  !campaign?.is_posted && setOpen(true);
                  setTab(campaign?.type);
                  setPopUpData({
                    ...campaign,
                    desc:
                      campaign?.other_platforms?.[0]?.caption ||
                      campaign?.description,
                  });
                }}
                sx={{
                  ...style.mainWrapper,
                  backgroundImage: `url(${instaContainer})`,
                  display: "inline-block",
                  cursor: !campaign?.is_posted ? "pointer" : "",
                }}
              >
                <Box
                  className="d-flex justify-content-between align-items-center"
                  style={style.heading}
                >
                  <Box className="d-flex align-items-center">
                    <img src={profileImg} width={"30px"} />
                    <Typography
                      color="#000"
                      fontWeight={"700"}
                      fontSize={"15px"}
                      ml={1}
                    >
                      {profile?.resturant_name}
                    </Typography>
                  </Box>
                  <img src={more} width={"13px"} />
                </Box>
                <Box
                  style={{
                    ...style.post,
                    backgroundImage: `url(${campaign?.image_url})`,
                  }}
                >
                  {/* <Typography style={style.postHeadingText}>
                    {campaign?.title}
                  </Typography> */}
                </Box>
                <Box
                  className="d-flex justify-content-between"
                  style={style.commentBox}
                >
                  <Box>
                    <img
                      src={heart}
                      width={"18px"}
                      style={style.commentImage}
                    />
                    <img src={chat} width={"22px"} style={style.commentImage} />
                    <img
                      src={share}
                      width={"22px"}
                      style={style.commentImage}
                    />
                  </Box>
                  <img
                    src={bookmark}
                    width={"18px"}
                    style={style.commentImage}
                  />
                </Box>
                <Box
                  style={style.comments}
                  className="d-flex align-items-start"
                >
                  <img src={profileImg} width={"10px"} />
                  <Typography color="#000" fontSize={"12px"} ml={0.5}>
                    Liked by{" "}
                    <span style={{ fontWeight: "600" }}>craig_love</span> and{" "}
                    <span style={{ fontWeight: "600" }}>44,686 others</span>
                  </Typography>
                </Box>
                <Box
                  style={style.postCaption}
                  className="d-flex align-items-center"
                >
                  <Typography
                    sx={style.postCaptionText}
                    color="#000"
                    fontSize={"12px"}
                    ml={0.5}
                  >
                    <span style={{ fontWeight: "600" }}>
                      {profile?.resturant_name}
                    </span>{" "}
                    {getCaption(campaign?.other_platforms) ||
                      campaign?.description}
                  </Typography>
                </Box>
              </Box>
              {open && (
                <UpdateEventPopUp
                  platform={"instagram"}
                  functionOnModalClose={functionOnModalClose}
                  profile_id={profile_id}
                  data={popUpData}
                  tab={tab}
                  open={open}
                  handleClose={() => setOpen(false)}
                />
              )}
            </React.Fragment>
          );
        })}
      {commingfromgmail && (
        <Box
          sx={{
            ...style.mainWrapper,
            backgroundImage: `url(${instaContainer})`,
            display: "inline-block",
          }}
        >
          <Box
            className="d-flex justify-content-between align-items-center"
            style={style.heading}
          >
            <Box className="d-flex align-items-center">
              <img src={profileImg} width={"30px"} />
              <Typography
                color="#000"
                fontWeight={"700"}
                fontSize={"15px"}
                ml={1}
              >
                {profile?.resturant_name}
              </Typography>
            </Box>
            <img src={more} width={"13px"} />
          </Box>
          <Box
            style={{
              ...style.post,
              backgroundImage: `url(${data?.image_url})`,
            }}
          >
            {/* <Typography style={style.postHeadingText}>{data?.title}</Typography> */}
          </Box>
          <Box
            className="d-flex justify-content-between"
            style={style.commentBox}
          >
            <Box>
              <img src={heart} width={"18px"} style={style.commentImage} />
              <img src={chat} width={"22px"} style={style.commentImage} />
              <img src={share} width={"22px"} style={style.commentImage} />
            </Box>
            <img src={bookmark} width={"18px"} style={style.commentImage} />
          </Box>
          <Box style={style.comments} className="d-flex align-items-start">
            <img src={profileImg} width={"10px"} />
            <Typography color="#000" fontSize={"12px"} ml={0.5}>
              Liked by <span style={{ fontWeight: "600" }}>craig_love</span> and{" "}
              <span style={{ fontWeight: "600" }}>44,686 others</span>
            </Typography>
          </Box>
          <Box style={style.postCaption} className="d-flex align-items-center">
            <Typography
              sx={style.postCaptionText}
              color="#000"
              fontSize={"12px"}
              ml={0.5}
            >
              <span style={{ fontWeight: "600" }}>
                {profile?.resturant_name}
              </span>{" "}
              {getCaption(data?.other_platforms)}
            </Typography>
          </Box>
        </Box>
      )}
      {showCustomHandleButton && data?.length > 3 && (
        <NextBtn handleNext={() => handleNext()} width={"50px"} />
      )}
    </>
  );
}

export default InstaMobileView;
