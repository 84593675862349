import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./PopOverStyle";
import commonStyle from "../../../assets/style/commonStyle";
import close from "../../../assets/SVG/close.svg";
import CustomDatePicker from "../../Common/CustomDatePicker";
import CustomTime from "../../Common/CustomTime";
import CustomTimePicker from "../../Common/CustomTimePicker.jsx";
import CustomButton from "../../Common/CustomButton/CustomButton.jsx";
import moment from "moment/moment";
import { useCompaignData } from "../../../Context/CampaignContext";
import ResetBtn from "../../Common/ResetBtn";
import UploadBtn from "../../Common/UploadBtn";
import Inputs from "./Collectiveinput";
import { useDispatch, useSelector } from "react-redux";
import { saveSinglecompainAPI, unitImageuploadAPI } from "../../../apis/auth";
import {
  storeInLocalStore,
  setoriginscroller,
} from "../../../redux/reducers/mainSlice";
import ProfileAlert from "./ProfileAlert";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const PopOver = ({ data, open, handleClose, tab, changeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const profile_id = useSelector(
    (state) => state?.main?.store?.profile_data?.profile_id
  );
  const restaurant_name = useSelector(
    (state) => state.main.store?.profile_data?.resturant_name
  );
  console.log(profile_id, restaurant_name, "RESTURANT");
  const getCaompainData = useSelector(
    (state) => state?.main?.store?.campaignUnit
  );
  const [uploadImage, setUploadImage] = useState();
  const [title, setTitle] = useState("");
  const [descData, setDescData] = useState(
    "Celebrate Taco Day with delicious, savory tacos filled with mouthwatering flavors and toppings galore!"
  );
  const [eventPrice, setEventPrice] = useState();
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const [date, setDate] = useState(moment(currDate).format("YYYY-MM-DD"));
  const [fieldData, setFieldData] = useState();
  const [imageFile, setImageFile] = useState();
  const [inputValues, setInputValues] = useState({});
  const [profileAlert, setProfileAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [time, setTime] = useState(
    moment(currTime, "HH:mm").format("HH:mm:ss")
  );
  const [compaignData, setCompaignData] = useCompaignData();

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const handleTime = (e) => {
    setTime(e.target.value);
  };

  const handleCampaign = () => {
    let inputField = checkAllKeysHaveValues(inputValues);
    if (inputField?.length > 0) {
      setAlertMessage(`${inputField[0]} is required.`);
    } else {
      if (!profile_id || !restaurant_name) {
        setProfileAlert(true);
      } else {
        let check =
          compaignData.length > 0
            ? compaignData?.some((compaign) => {
                if (compaign.text === title) {
                  return true;
                } else {
                  return false;
                }
              })
            : false;
        if (!check) {
          let payload = {
            profile_id: profile_id,
            child_temp_id: data.id,
            input_text: JSON.stringify(inputValues),
            title: title,
            description: descData,
            image_url: uploadImage,
          };
          setLoading(true);
          saveSinglecompainAPI(payload)
            .then((res) => {
              if (res.status == "success") {
                setLoading(false);
                setInputValues("");
                const _data = {
                  id: res.addunit.id,
                  imgUrl: res.addunit.image_url,
                  text: title,
                  description: res.addunit.description.trim(),
                  date: date,
                  time: time,
                  event_price: eventPrice,
                };

                if (imageFile) {
                  data = {
                    profileid: profile_id,
                    unitid: _data.id,
                    image: imageFile,
                  };
                  unitImageuploadAPI(data).then((res) => {
                    if (res.status == "success") {
                      let _tempdata = {
                        ..._data,
                        imgUrl: res.image_url,
                      };
                      setImageFile("");
                      setCompaignData([...compaignData, _tempdata]);
                      handleClose();
                    }
                  });
                } else {
                  setImageFile("");
                  setCompaignData([...compaignData, _data]);
                  handleClose();
                }

                let tempval = [];
                if (getCaompainData?.length > 0) {
                  tempval = [...getCaompainData, _data];
                } else {
                  tempval = [_data];
                }
                dispatch(storeInLocalStore({ campaignUnit: tempval }));
                if (tab == "events") {
                  changeTab("offers");
                } else if (tab == "offers") {
                  changeTab("rewards");
                }
              } else {
                alert(res.message);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          setAlertMessage("Already Exists.");
        }
      }
    }
    setTimeout(() => {
      setAlertMessage("");
    }, 2000);
  };
  function checkAllKeysHaveValues(obj) {
    const blankKeys = [];

    for (const key in obj) {
      if (!obj[key]) {
        blankKeys.push(key);
      }
    }

    return blankKeys;
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
    const modifiedFile = new File([file], newFileName, { type: file.type });
    setImageFile(modifiedFile);
    setUploadImage(URL.createObjectURL(modifiedFile));
  };

  useEffect(() => {
    setFieldData(data?.contentData?.fields);
    setDescData(data?.contentData?.description);
    setTitle(data?.text || "");
    setUploadImage(data?.contentData?.image_url);
    const transformedObject = data?.contentData?.fields.reduce(
      (result, item) => {
        if (item.field_type == "date") {
          result[item.field_name] = moment(date).format("DD/MM/YYYY");
        } else {
          result[item.field_name] = "";
        }

        return result;
      },
      {}
    );
    setInputValues(transformedObject);
  }, [data]);
  const handleValueData = (fieldName, value) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [fieldName]: value,
    }));
  };
  const handleprofileAlert = () => {
    setProfileAlert(false);
    dispatch(setoriginscroller(true));
    navigate("/");
  };
  return (
    <>
      <Modal show={open} size="lg" centered>
        <Modal.Body
          style={{
            background: "#151515",
          }}
        >
          <Box align="right" sx={{ marginTop: "0px" }}>
            <img
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              alt="imgError"
              src={close}
            />
          </Box>
          <div className="row align-items-center">
            <div className="col-md-6">
              <Box
                sx={{
                  ...style.imgContainer,
                  backgroundImage: `url(${uploadImage})`,
                }}
              >
                <ResetBtn />
                <UploadBtn
                  handleChange={handleImageUpload}
                  style={style.editBtn}
                />
              </Box>
            </div>
            <div className="col-md-6">
              <>
                {fieldData &&
                  fieldData.length > 0 &&
                  fieldData.map((obj, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Inputs
                          obj={obj}
                          handleValue={handleValueData}
                          inputValue={inputValues}
                          currentDate={date}
                          time={time}
                        />
                      </React.Fragment>
                    );
                  })}
              </>
            </div>
          </div>
          <div className="row px-5 py-2">
            <div className="col-md-12">
              <Stack direction="row" spacing={10}>
                <Typography
                  sx={{
                    ...style.title,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Title :
                </Typography>
                <input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="events"
                  style={{ width: "70%" }}
                />
              </Stack>
            </div>
            <div className="col-md-12 mt-2">
              <Typography sx={style.title}>Description</Typography>
              <textarea
                cols="70"
                rows="4"
                charswidth="23"
                value={descData}
                onChange={(e) => setDescData(e.target.value)}
                className="text_area"
                style={{ ...style.title, fontWeight: 400 }}
              />
              {alertMessage && (
                <div>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {alertMessage}
                  </span>
                </div>
              )}
            </div>
            <Box align="center">
              {/* <CustomButton
              sx={{  }}
              label="AI Generate"
              // onClick={() => {
              //   handleCampaign();
              // }}
            /> */}
              {loading ? (
                <Button
                  style={{
                    background: "#eb2426",
                    height: "51px",
                    padding: "16px 32px 16px 32px",
                    borderRadius: "50px",
                    fontSize: "16px",
                    border: "none",
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                  disabled
                >
                  <span>Saving</span>
                  <span>
                    <CircularProgress
                      className="savingLoader"
                      sx={{ color: "#fff", height: "15px", width: "15px" }}
                    />
                  </span>
                </Button>
              ) : (
                <CustomButton
                  sx={{ width: "20%" }}
                  label="Save"
                  onClick={() => {
                    handleCampaign();
                  }}
                  disabling={loading}
                />
              )}
            </Box>
          </div>
        </Modal.Body>
      </Modal>
      <ProfileAlert open={profileAlert} handleClose={handleprofileAlert} />
    </>
  );
};

export default PopOver;
