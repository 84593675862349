import React from "react";
import "./Futurerich.css";
import Cards from "./Cards";
import image1 from "../../../assets/images/home/card-i.png";
import image2 from "../../../assets/images/home/card-2.png";
import image3 from "../../../assets/images/home/card-3.png";
const Futurerich = () => {
  return (
    <>
      <div className="full-width py-5 futurerich">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="inner-content text-center">
              <h1 className="title">
                Feature-Rich <br />
                <span className="bold-inner">Content Calendar</span>
              </h1>
              <p>
                Our platform auto-generates a full content calendar, complete
                with meticulously crafted posts for Facebook and Instagram. And
                it’s not just fluff—we dive deep. Our posts explore:
              </p>
            </div>
          </div>
          <div className="roundcircle">
            <div className="inner-circle">
              <p className="circle-content">Never miss a posting date again!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width pb-5 futurerich-card">
        <div className="row content-padding">
          <div className="col-md-8 offset-md-2">
            <div className="row align-items-stretch">
              <div className="col-md-4">
                <div className="card-conatiner">
                  <Cards
                    img={image1}
                    title={"Your Scrumptious Menu"}
                    content={
                      "Highlight daily specials, signature dishes, and what makes your food uniquely irresistible."
                    }
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-conatiner">
                  <Cards
                    img={image2}
                    title={"Your Unique Origin Story"}
                    content={
                      "Share the story of how your restaurant came to life, your inspirations, and what sets you apart."
                    }
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-conatiner">
                  <Cards
                    img={image3}
                    title={"Your Locations"}
                    content={
                      "Whether it's your cozy physical spot downtown or your bustling online delivery platform, we make sure your customers know where to find you."
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Futurerich;
