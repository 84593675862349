import React from "react";
import { CircularProgress } from "@mui/material";

const DashboardLoader = () => {
  return (
    <div className="dashboard-loading">
      <div className="loading-data">
        <CircularProgress sx={{ color: "#eb2426" }} />
      </div>
    </div>
  );
};

export default DashboardLoader;
