import React, { useState } from "react";

const Adddishes = ({ fields, handleAddDishes, handleDeleteDish }) => {
  const [dishes, setDishes] = useState({
    dish_name: "",
    description: "",
  });
  const [alertmessage, setalertmessage] = useState();
  const handleInputChange = (e, text) => {
    if (text === "dish_name") {
      setDishes({ ...dishes, dish_name: e.target.value });
    } else {
      setDishes({ ...dishes, description: e.target.value });
    }
  };
  const addfinalDishes = () => {
    if (dishes.dish_name.trim() == "" || dishes.description.trim() == "") {
      setalertmessage("All fields are required.");
    } else {
      handleAddDishes(dishes);
      setDishes({
        dish_name: "",
        description: "",
      });
    }
    setTimeout(() => {
      setalertmessage();
    }, 2000);
  };
  return (
    <>
      <div className="row ">
        <div className="col-md-12">
          <p className="special-dish-add-titl">Add Special</p>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-3">
          <div className="form-group">
            <input
              type={"text"}
              className="form-control custom-input"
              placeholder="Name"
              value={dishes.dish_name}
              onChange={(e) => handleInputChange(e, "dish_name")}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type={"text"}
              className="form-control custom-input"
              placeholder="Description"
              value={dishes.description}
              onChange={(e) => handleInputChange(e, "description")}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <button
              onClick={() => {
                addfinalDishes();
              }}
              className="byteready-btn byteready-outlinne"
            >
              Add Specials
            </button>
          </div>
        </div>
      </div>
      <div className="row py-3">
        {fields.map((field, index) => (
          <div key={index} className="col-md-3">
            <div className="filename-container">
              <span className="file_name">{field.dish_name}</span>{" "}
              <span
                className="cross-btn"
                onClick={() => handleDeleteDish(field)}
              >
                X
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Adddishes;
