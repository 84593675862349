import React from "react";

const Calenderdatepickermain = ({
  currentdate,
  handledatechange,
  maxDates,
  mindates,
}) => {
  return (
    <>
      <div className="calender-datepicker">
        <p className="chhoose-platform-text">Date</p>
        <input
          type={"date"}
          value={currentdate}
          onChange={handledatechange}
          className="custom-input"
          min={mindates}
          max={maxDates}
        />
      </div>
    </>
  );
};

export default Calenderdatepickermain;
