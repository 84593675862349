const style = {
  container: {
    maxWidth: "100%",
    padding: "6%",
    backgroundColor: "#000000",
    color: "#FCFCFC",
  },
  headerContainer: {
    padding: "0% 3% 0% 3%",
  },
  title: {
    padding: "0px",
    color: "#9E9E9E",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  titleBox: {
    margin: "35px 0 10px 0",
  },
  headText: {
    color: "#FCFCFC",
    fontSize: "25px",
    fontFamily: "WhyteInktrap-Black",
    padding: "0px 3px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  mobContainer: {
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boldText: {
    color: "#FCFCFC",
    fontSize: "40px",
    textAlign: "center",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 600,
    lineHeight: "1.2",
    marginTop: "50px",
    textTransform: "uppercase",
  },
};

export default style;
