import React from "react";
import "./historystyle.css";
import dummpost from "../../../assets/images/create-calender/dummy-image.png";
import moment from "moment";

const Allhistory = ({ cuisine, data, handleDatareturn }) => {
  return (
    <>
      <div className="history-container">
        <h4>History</h4>
        {cuisine ? (
          <>
            <div className="text-container scroll-design-horizontal pt-3">
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="text-item"
                    onClick={() => {
                      handleDatareturn(item);
                    }}
                  >
                    <div className="item-main-container text-center">
                      <div className="item-container-image-menu">
                        <img
                          src={item?.prompt_output_img_url || dummpost}
                          className="w-100"
                        />
                      </div>
                      {/* <span className="menu-name">Pizza</span> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="text-container scroll-design-horizontal">
              {data.map((item, index) => {
                let date_new = moment(item.date).format("MM/DD/YYYY");
                return (
                  <div
                    key={index}
                    className="text-item"
                    onClick={() => {
                      handleDatareturn(item);
                    }}
                  >
                    <div className="item-main-container">
                      <span className="date">{date_new}</span>
                      <div className="item-container">
                        <p>
                          {item?.prompt_output_text
                            ?.replace(/\n/g, "")
                            .slice(0, 30) +
                            (item?.prompt_output_text?.length > 30
                              ? "..."
                              : "") || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="text-container scroll-design-horizontal pt-3">
              {data.map((item, index) => {
                let date_new = moment(item.date).format("MM/DD/YYYY");
                return (
                  <div
                    key={index}
                    className="text-item"
                    onClick={() => {
                      handleDatareturn(item);
                    }}
                  >
                    <div className="item-main-container">
                      <span className="date">{date_new}</span>
                      <div className="item-container-image">
                        <img
                          src={item?.prompt_output_img_url || dummpost}
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Allhistory;
