import React from "react";
import { Modal } from "react-bootstrap";

const Deletepopup = ({open, handlecanel, handledelete}) => {
  return (
    <Modal show={open} size="sm" centered onHide={handlecanel}>
      <Modal.Body
        style={{
          background: "#151515",
          textAlign: "center",
          padding: "30px",
        }}
      >
        <p>Do you want to delete this post?</p>
        <div className="model-delete-btn-conatiner">
          <button className="byteready-btn byteready-primary" onClick={handledelete}>Delete</button>
          <button className="byteready-btn byteready-outlinne" onClick={handlecanel}>Cancel</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Deletepopup;
