import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Stack,
  Button,
  Slider,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
// import "./Total.css";

import firstTimeIcon from "../../../assets/SVG/ph_eye-fill.svg";
import engagedIcon from "../../../assets/SVG/ph_eye-fill2.svg";
import firstConsumersIcon from "../../../assets/SVG/solar_user-bold.svg";
import dinersIcon from "../../../assets/SVG/solar_dollar-bold.svg";
import rightArrowIcon from "../../../assets/SVG/material-symbols_keyboard-arrow-down-rounded.svg";
import dropdownIcon from "../../../assets/SVG/iconamoon_arrow-up-2-duotone.svg";
import upArrow from "../../../assets/SVG/material-symbols_arrow-right-rounded_green.svg";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import CircularProgress from "@mui/material/CircularProgress";

import Totalcard from "./Tabcards/Totalcard";

const TotalBox = ({
  loading,
  totalData,
  firstTimeViewer,
  engagedViewer,
  firsttimeconsumer,
  freqdiner,
  total,
}) => {
  const percentage = 40;
  const data = [1, 2, 3, 4, 5];

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100px",
            position: "relative",
          }}
        >
          <CircularProgress
            sx={{
              color: "#eb2426",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      ) : (
        <div>
          <div className="row header-text">
            <span className="text-head text-gray">
              Your Financial Update{" "}
              <img src={dropdownIcon} className="downarrow" alt="error" />
            </span>
          </div>
          <div className="line"></div>

          <div className="totalbox-container">
            <Totalcard
              total={true}
              data={totalData}
              name={"Total Spend"}
              bgspecialclass={" "}
              totalviewer={total}
            />
            <Totalcard
              total={false}
              bgspecialclass={"box-shadow-violet"}
              colorCode={"#4e0aa5"}
              data={firstTimeViewer}
              name={"First Time Viewers"}
              imgurl={firstTimeIcon}
            />
            <Totalcard
              total={false}
              bgspecialclass={"box-shadow-blue"}
              colorCode={"#1255b9"}
              data={engagedViewer}
              name={"Engaged Viewers"}
              imgurl={engagedIcon}
            />
            <Totalcard
              total={false}
              bgspecialclass={"box-shadow-navyblue"}
              colorCode={"#19b7e9"}
              data={firsttimeconsumer}
              name={"First Time Consumers"}
              imgurl={firstConsumersIcon}
            />
            <Totalcard
              total={false}
              bgspecialclass={"box-shadow-lightgrey"}
              colorCode={"#1de8db"}
              data={freqdiner}
              name={"Freq Diners"}
              imgurl={dinersIcon}
            />
          </div>
          {/* <ConcentricCircleChart data={data} /> */}
        </div>
      )}
    </>
  );
};

export default TotalBox;
