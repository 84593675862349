import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./MediaStyle";
import MediaSwitch from "../../components/Common/MediaSwitch";
import MySocialMedia from "../../components/MediaComp/MySocialMedia/MySocialMedia";
import MyMedia from "../../components/MediaComp/MyMedia/MyMedia";
import Footer from "../../components/Home/Footer/Footer";
import { setmediatabswitch } from "../../redux/reducers/mainSlice";
import { useDispatch, useSelector } from "react-redux";

const Media = () => {
  const dispatch = useDispatch();
  const tabData = useSelector((state) => state.main.setmediatabswitch);
  const profile_id = useSelector(
    (state) => state.main.store.profile_data.profile_id
  );
  const [mediaSwitch, setMediaSwitch] = useState(1);

  const toggleSwitch = (params) => {
    setMediaSwitch(params);
  };
  useEffect(() => {
    if (tabData) {
      if (tabData == "Evergreen") {
        toggleSwitch(1);
      } else {
        toggleSwitch(2);
      }
    }
  }, []);
  return (
    <>
      <Box sx={style.container}>
        <Box className="customcontainerglobal">
          <MediaSwitch toggleSwitch={toggleSwitch} mediaSwitch={mediaSwitch} />
          {mediaSwitch === 1 && <MySocialMedia />}
          {mediaSwitch === 2 && <MyMedia />}
        </Box>
      </Box>
     
    </>
  );
};

export default Media;
