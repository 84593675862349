import React from "react";
import logo from "./../../../../assets/images/vertical_container.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RightArrow from "../../../../assets/SVG/RightArrow.svg";
import LikeButton from "../../../../assets/SVG/white_like.svg";
import CommentButton from "../../../../assets/SVG/white_comment.svg";
import ShareButton from "../../../../assets/SVG/white_share.svg";
import EditButton from "../../../../assets/SVG/tabler_edit.svg";
import fbIcon from "../../../../assets/images/fbLike.png";
import fbLikeIcon from "../../../../assets/images/instaLike.png";
import { Button, CircularProgress } from "@mui/material";
import style from "../Style";

const EditFacebook = ({
  iconurl,
  resturantNameValue,
  loader,
  uploadImage,
  resetImage,
  ResetBtn,
  handleUploadImage,
  editData,
  weburl,
}) => {
  return (
    <>
      <div className="post-inner">
        <div className="postheader">
          <div className="left-header">
            <div className="left-wrapper">
              <div className="logo">
                <img className="logo-img" src={iconurl || logo} />
              </div>
              <div className="name-area">
                <p className="name">{resturantNameValue}</p>
                <p className={` sponsored`}>Sponsored</p>
              </div>
            </div>
          </div>
          <div className="right-header">
            <MoreHorizIcon />
          </div>
        </div>
        <div className={`shop-now `}>
          <span className="description mb-0">{editData}</span>
        </div>
        <div className="instapostcontainer">
          <div
            className="post-image"
            style={{
              background: loader ? `#f1f1f1` : `url(${uploadImage})`,
            }}
          >
            {loader ? (
              <div className="loader-area">
                <CircularProgress
                  style={{
                    color: "#eb2426",
                    margin: "auto",
                    textAlign: "center",
                  }}
                />
              </div>
            ) : (
              <>
                <img
                  onClick={resetImage}
                  src={ResetBtn}
                  className="imagereload"
                  alt="ResetBtn"
                />
                <div className="loader-area">
                  <Button
                    sx={style.uploadBtn}
                    variant="contained"
                    component="label"
                  >
                    Upload Image
                    <input
                      onChange={handleUploadImage}
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      hidden
                    />
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="learn-more">
          <div className="left-learnmore">
            <p className="website">{weburl || "Websitename.com"}</p>
            <p className="headline">{resturantNameValue}</p>
          </div>
          <div className="right-learnmore">
            <button className="learnbtn">Learn More</button>
          </div>
        </div>
        <div className="likearea">
          <div className="left-likearea">
            <img src={fbLikeIcon} className="love" alt="likeerror" />
            <img src={fbIcon} className="like" alt="fberror" />{" "}
            <span>3,4K</span>
          </div>
          <div className="right-likearea">
            <span>70 comments</span> <span> 104 shares</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFacebook;
