import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import styles from "./../Calendar.js";
import Button from "../../../components/Common/CustomButton.jsx";
import closeicon from "../../../assets/SVG/close.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#151515",
  margin: "auto",
  border: "2px solid #151515",
  boxShadow: "0px 8px 25.596452713012695px 0px rgba(0, 0, 0, 0.10)",
  p: 4,
  padding: "0px",
  textAlign: "center",
};
const PostConfirm = ({ open, handleClose }) => {
 
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            onClick={handleClose}
            sx={{
              textAlign: "right",
              padding: "10px 16px",
            }}
          >
            <img src={closeicon} style={{ cursor: "pointer" }} />
          </Box>
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                fontSize: "16px",
                color: "#FCFCFC;",
                textAlign: "center",
                paddingBottom: '40px'
              }}
            >
              Post is Created!!
            </Typography>
            <Box>
            <Button
              text={"Ok"}
              spacing={"12px 40px"}
              custommargin={"auto"}
              handleChange={handleClose}
            />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PostConfirm;
