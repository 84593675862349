import React from "react";
import "./Imageview.css";
import Withoutvalue from "./InnerImage/Withoutvalue";
import MobileWrapper from "../MobileView/MobileWrapper";

const Imageview = ({
  image,
  left,
  disable,
  handleImageupload,
  generatecaption,
  alldata,
  outputdata,
  staticIphone,
}) => {
  return (
    <>
      <div className="imageview-container">
        <img
          src={outputdata?.image || alldata?.output_image || image}
          className="w-100 bg-height"
        />
        <div className="image-inner">
          <div className="mobile-container-main">
            {outputdata?.image || alldata?.output_image ? (
              <>
                <div className="with-data">
                  <MobileWrapper
                    alldata={alldata}
                    outputImage={outputdata?.image}
                    outputtext={outputdata?.text}
                    handleImageupload={handleImageupload}
                    generatecaption={generatecaption}
                  />
                </div>
              </>
            ) : (
              <>
                <Withoutvalue
                  staticIphone={staticIphone}
                  left={left}
                  disable={disable}
                  handleImageupload={handleImageupload}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Imageview;
