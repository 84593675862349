import { CircularProgress } from "@mui/material";
import React from "react";

const Loadericon = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        zIndex:'9999'
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          background: "#0000008a",
          zIndex: "1",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress sx={{ color: "#eb2426" }} />
        </div>
      </div>
    </div>
  );
};

export default Loadericon;
