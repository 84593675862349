import { Typography } from "@mui/material";
import "./FirstTimeViewers.css";
import searchIcon from "../../../assets/SVG/tabler_search.svg";
import profileImg from "../../../assets/images/Ellipse 225.png";

const InnerTable = ({ data }) => {
  return (
    <>
      <div className="table-container">
        <div className="table-head">
          <div className="input-field">
            <input
              className="input-field-table"
              type="text"
              placeholder="Find a user"
            />
            <img
              width="24px"
              height="24px"
              className="search-icon"
              src={searchIcon}
              alt="error"
            />
          </div>
          <Typography variant="p" className="text2-ftv text-white cell-head2">
            First Order
          </Typography>
          <Typography variant="p" className="text2-ftv text-white cell-head2">
            Total Orders
          </Typography>
          <Typography variant="p" className="text2-ftv text-white cell-head2">
            Ave Orders
          </Typography>
          <Typography variant="p" className="text2-ftv text-white cell">
            LTV
          </Typography>
        </div>
        <div className="dyn-height">
          {data?.map((item, index) => (
            <div
              key={index}
              className="accordin-container accordin-container-align my-2 tr"
            >
              <div
                // style={{ width: "25%" }}
                className="text4 text-white align-img cell1"
              >
                <img className="pr-4" src={profileImg} alt="error" />
                <Typography
                  variant="p"
                  className="text2-ftv text-white pad-left"
                >
                  {item?.user}
                </Typography>
              </div>
              <div className="cell">
                <Typography variant="p" className="text2-ftv text-white">
                  {item?.first_order}
                </Typography>
              </div>
              <div className="cell">
                <Typography variant="p" className="text2-ftv text-white">
                  {item?.total_orders}
                </Typography>
              </div>
              <div className="cell">
                <Typography variant="p" className="text2-ftv text-white">
                  {item?.ave_order}
                </Typography>
              </div>
              <div className="cell">
                <Typography variant="p" className="text2-ftv text-white">
                  {item?.liv}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InnerTable;
