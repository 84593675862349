import { Box, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import style from './PaidMediaStyle'
import displayImage from '../../assets/images/IpadDisplayImage.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';
import InstaMobileView from '../../components/commoncomponents/InstaMobileView/InstaMobileView';
import FacebookMobileView from '../../components/commoncomponents/FacebookMobileView/FacebookMobileView';
import SmsView from '../../components/commoncomponents/SMSView/SmsView';
import GmailMobileView from '../../components/commoncomponents/GmailMobileView/GmailMobileView';
import GoogleView from '../../components/commoncomponents/GoogleView/GoogleView';
const tabStyles = {
    labelStyle: {
        color: 'black',
    },
    selectedLabelStyle: {
        color: 'blue', // Change label color when active
    },
    iconStyle: {
        fill: 'black',
    },
    selectedIconStyle: {
        fill: 'green', // Change icon color when active
    },
    indicator: {
        display: 'none', // Remove the indicator (border)
    },
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function PaidMedia() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ ...style.container, background: `url(${displayImage})`, backgroundRepeat: 'no-repeat', backgroundSize: "cover" }}>
            <Box sx={{ width: "85vw", margin: 'auto' }}>
                <Typography style={style.orangeHeading}>
                    My Paid Media
                </Typography>
                <Box sx={{ position: "relative",marginTop:"20px" }}>
                    <Box>
                        <Typography style={style.whiteHeading}>
                            Summer season
                        </Typography>
                    </Box>
                    <Box className='d-flex flex-column' style={{ marginLeft: '40px' }}>
                        <Typography style={style.smallHeading}>Choose The Platform</Typography>
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs style={{ position: "absolute", right: "0", top: '7%' }} TabIndicatorProps={{ style: tabStyles.indicator }} className='socialmediatabs' value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab style={{ color: "#fff" }} icon={<InstagramIcon />} {...a11yProps(0)} />
                                    <Tab style={{ color: "#fff" }} label="f"  {...a11yProps(1)} />
                                    <Tab style={{ color: "#fff" }} icon={<GoogleIcon />} {...a11yProps(2)} />
                                    <Tab style={{ color: "#fff" }} label='@'{...a11yProps(3)} />
                                    <Tab style={{ color: "#fff" }} label='SMS'{...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <InstaMobileView />
                                <InstaMobileView />
                                <InstaMobileView />
                                <InstaMobileView />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div className='d-flex'>
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <div className='d-flex'>
                                    <GoogleView />
                                    <GoogleView />
                                    <GoogleView />
                                    <GoogleView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <div className='d-flex'>
                                    <SmsView />
                                    <SmsView />
                                    <SmsView />
                                    <SmsView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <GmailMobileView />
                                <GmailMobileView />
                                <GmailMobileView />
                                <GmailMobileView />
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ position: "relative",marginTop:"20px" }}>
                    <Box>
                        <Typography style={style.whiteHeading}>
                            our chef
                        </Typography>
                    </Box>
                    <Box className='d-flex flex-column' style={{ marginLeft: '40px' }}>
                        <Typography style={style.smallHeading}>Choose The Platform</Typography>
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs style={{ position: "absolute", right: "0", top: '7%' }} TabIndicatorProps={{ style: tabStyles.indicator }} className='socialmediatabs' value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab style={{ color: "#fff" }} icon={<InstagramIcon />} {...a11yProps(0)} />
                                    <Tab style={{ color: "#fff" }} label="f"  {...a11yProps(1)} />
                                    <Tab style={{ color: "#fff" }} icon={<GoogleIcon />} {...a11yProps(2)} />
                                    <Tab style={{ color: "#fff" }} label='@'{...a11yProps(3)} />
                                    <Tab style={{ color: "#fff" }} label='SMS'{...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <InstaMobileView />
                                <InstaMobileView />
                                <InstaMobileView />
                                <InstaMobileView />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div className='d-flex'>
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <div className='d-flex'>
                                    <GoogleView />
                                    <GoogleView />
                                    <GoogleView />
                                    <GoogleView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <div className='d-flex'>
                                    <SmsView />
                                    <SmsView />
                                    <SmsView />
                                    <SmsView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <GmailMobileView />
                                <GmailMobileView />
                                <GmailMobileView />
                                <GmailMobileView />
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ position: "relative",marginTop:"20px" }}>
                    <Box>
                        <Typography style={style.whiteHeading}>
                            Responsive Campaign
                        </Typography>
                    </Box>
                    <Box className='d-flex flex-column' style={{ marginLeft: '40px' }}>
                        <Typography style={style.smallHeading}>Choose The Platform</Typography>
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs style={{ position: "absolute", right: "0", top: '7%' }} TabIndicatorProps={{ style: tabStyles.indicator }} className='socialmediatabs' value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab style={{ color: "#fff" }} icon={<InstagramIcon />} {...a11yProps(0)} />
                                    <Tab style={{ color: "#fff" }} label="f"  {...a11yProps(1)} />
                                    <Tab style={{ color: "#fff" }} icon={<GoogleIcon />} {...a11yProps(2)} />
                                    <Tab style={{ color: "#fff" }} label='@'{...a11yProps(3)} />
                                    <Tab style={{ color: "#fff" }} label='SMS'{...a11yProps(4)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <InstaMobileView />
                                <InstaMobileView />
                                <InstaMobileView />
                                <InstaMobileView />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div className='d-flex'>
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                    <FacebookMobileView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <div className='d-flex'>
                                    <GoogleView />
                                    <GoogleView />
                                    <GoogleView />
                                    <GoogleView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                                <div className='d-flex'>
                                    <SmsView />
                                    <SmsView />
                                    <SmsView />
                                    <SmsView />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                                <GmailMobileView />
                                <GmailMobileView />
                                <GmailMobileView />
                                <GmailMobileView />
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PaidMedia