import React, { useRef } from "react";
import "./Socialmediadesign.css";
import reloadimg from "../../../../assets/images/create-calender/reload-icon.png";
import shopnow from "../../../../assets/images/create-calender/shopnow.png";
import loveicon from "../../../../assets/images/create-calender/love-icon.png";
import chaticon from "../../../../assets/images/create-calender/chat-icon.png";
import sendicon from "../../../../assets/images/create-calender/send-icon.png";
import editicon from "../../../../assets/images/create-calender/edit-icon.png";

const Instagram = ({
  alldata,
  outputImage,
  outputtext,
  handleImageupload,
  generatecaption,
}) => {
  const imageuploadRef = useRef();
  return (
    <>
      <div className="instagram-main-container">
        <div className="post-image">
          <img
            src={outputImage || alldata.output_image}
            className="w-100"
            style={{ minHeight: "150px" }}
          />
          <img
            onClick={() => {
              generatecaption("image");
            }}
            src={reloadimg}
            className="reloadicon"
          />
          <button
            onClick={() => {
              imageuploadRef.current.click();
            }}
            className="upload-image"
          >
            Upload Image
          </button>
          <input
            ref={imageuploadRef}
            type="file"
            accept=".jpg, .jpeg, .png"
            hidden
            onChange={(e) => {
              handleImageupload(e);
            }}
          />
        </div>
        <img src={shopnow} className="w-100" />
        <div className="icon-area">
          <div className="left-icon">
            <img src={loveicon} />
            <img src={chaticon} />
            <img src={sendicon} />
          </div>
          <div className="right-icn">
            <div>
              <img src={editicon} />
              Edit
            </div>
          </div>
        </div>
        <div className="description">{outputtext || alldata.cuisine_story}</div>
      </div>
    </>
  );
};

export default Instagram;
