import React, { useState } from "react";
import moment from "moment";
import Facebook from "../../../commoncomponents/socialview/Facebook";
import Instagram from "../../../commoncomponents/socialview/Instagram";
import editimg from "../../../../assets/images/create-calender/post-edit.png";
import Loadericon from "../../../Common/Loadericon";

const Calenderdata = ({
  cardData,
  currentdesign,
  currentDate,
  handlePopup,
  loader,
  starttime,
}) => {
  let startDate = moment(currentDate);
  const weekdays = moment.weekdaysShort();
  const currentDayOfWeek = moment(currentDate).day();
  const rotatedWeekdays = [
    ...weekdays.slice(currentDayOfWeek),
    ...weekdays.slice(0, currentDayOfWeek),
  ];
  return (
    <>
      <div className="row monthly-data position-relation">
        {loader && <Loadericon />}
        <div className="col-md-12">
          <div className="content-padding-both">
            <div className="calender-area">
              <div>
                <table className="table table-borderless custom-table">
                  <thead>
                    <tr>
                      {rotatedWeekdays.map((day, index) => (
                        <th key={index}>{day.charAt(0)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {cardData &&
                      cardData
                        .reduce((weeks, day, index) => {
                          startDate =
                            index === 0
                              ? startDate.add(0, "days")
                              : startDate.add(1, "days");
                          const weekIndex = Math.floor(index / 7);
                          if (!weeks[weekIndex]) {
                            weeks[weekIndex] = [];
                          }
                          weeks[weekIndex].push(
                            <td key={index} align="center">
                              <div className="post-card">
                                {currentdesign == "insta" ? (
                                  <>
                                    <Instagram data={day} />
                                  </>
                                ) : (
                                  <>
                                    <Facebook data={day} />
                                  </>
                                )}
                                {!starttime && (
                                  <div className="editpost">
                                    <img
                                      onClick={() => handlePopup(day)}
                                      src={editimg}
                                      className="edit-btn"
                                    />
                                  </div>
                                )}
                              </div>

                              <p className="post-date">{`${startDate.format(
                                "MM/DD"
                              )}`}</p>
                            </td>
                          );
                          return weeks;
                        }, [])
                        .map((week, index) => <tr key={index}>{week}</tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calenderdata;
