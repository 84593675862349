import { Box, Grid, Stack, Typography, Fab } from "@mui/material";
import React, { useState } from "react";
import style from "../viewersStyle";
import MediaTextSwitch from "../../../components/Common/MediaTextSwitch";
import IncreaseViewers from "../../../components/Viewers/IncreaseViewers/IncreaseViewers";
import Reach from "../../../components/Viewers/Reach/Reach";
import MobView from "../MobView/MobView";
import TabView from "../TabView/TabView";
import img2 from "../../../assets/images/mediaInstaimg2.png";
import arrowIcon from "../../../assets/SVG/white-left-arrow.svg";
import CheckoutPanel from "../../../components/Common/CheckoutPanel";
import AddPoint from "../AddPoint/AddPoint";
import circleIcon from "../../../assets/SVG/circle-Icon.svg";
import cutCircleIcon from "../../../assets/SVG/3-4-circle.svg";
import dollarIcon from "../../../assets/SVG/solar_dollar-bold-bg.svg";
import eyeIcon from "../../../assets/SVG/ph_eye-fill-3.svg";
import AddLists from "../AddLists/AddLists";
import antiClockwiseIcon from "../../../assets/SVG/anti-clock-wise-symbol.svg";

const instaData = {
  imgUrl: img2,
  event: "Summer Season",
  offers: "$50 for 2",
};

const checkoutData = [
  {
    campaign: "Summer Season",
    price: 2,
  },
  {
    campaign: "Outdoor Dining",
    price: 10,
  },
  {
    campaign: "Farm to Table",
    price: 10,
  },
];

const FirstTimeConsumers = ({ loader }) => {
  const [checkout, setCheckout] = useState(false);

  return (
    <>
      <Grid sx={style.box} container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography sx={style.label}>Choose From Your Campaigns</Typography>
          <MediaTextSwitch
            customStyle={{ width: { xs: "70%", sm: "50%", md: "80%" } }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              ...style.behaviorBox,
              position: "relative",
            }}
          >
            <Typography sx={style.text}>Behavioral</Typography>
            <Stack direction="row" spacing={2}>
              <Box align="center" sx={{ width: "150px" }}>
                <Box sx={{ ...style.iconBox, position: "relative" }}>
                  <Box
                    sx={{
                      backgroundImage: `url(${antiClockwiseIcon})`,
                      ...style.behaviorCircle,
                      width: "56px",
                      height: "56px",
                    }}
                  >
                    <img
                      width="25px"
                      height="28px"
                      src={cutCircleIcon}
                      alt="error"
                    />
                  </Box>
                  <img
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "25%",
                    }}
                    width="25px"
                    height="25px"
                    src={dollarIcon}
                    alt="error"
                  />
                </Box>
                <Typography sx={{ ...style.text2, marginTop: "0%" }}>
                  Time of Previous <br /> / Regular Order
                </Typography>
              </Box>
              <Box align="center" sx={{ width: "150px" }}>
                <Box sx={{ ...style.iconBox, position: "relative" }}>
                  <Box
                    sx={{
                      backgroundImage: `url(${antiClockwiseIcon})`,
                      ...style.behaviorCircle,
                      width: "56px",
                      height: "56px",
                    }}
                  >
                    <img
                      width="25px"
                      height="28px"
                      src={cutCircleIcon}
                      alt="error"
                    />
                  </Box>
                  <img
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "20%",
                    }}
                    width="25px"
                    height="25px"
                    src={eyeIcon}
                    alt="error"
                  />
                </Box>
                <Typography sx={{ ...style.text2, marginTop: "0%" }}>
                  Last or Regular Time <br /> of Visit
                </Typography>
              </Box>
              <AddPoint
                customStyle={{
                  width: "130px",
                  padding: "2%",
                }}
              />
            </Stack>
            <AddLists
              customStyle={{
                position: "absolute",
                bottom: "-13%",
                left: "40%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <MobView />
      <TabView />
      <Grid sx={{ marginTop: "5%" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <IncreaseViewers />
        </Grid>
        <Grid item xs={12} md={6}>
          <Reach />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          width: "1.10%",
          height: "40vh",
          padding: "24px 16px 24px 32px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
          borderRadius: "20px 0px 0px 20px",
          background: "#181818",
          position: "fixed",
          right: "0%",
          bottom: "4%",
        }}
      ></Box>
      {!checkout && (
        <Fab sx={style.fabIconStyle} onClick={() => setCheckout(true)}>
          <img src={arrowIcon} alt="error" />
        </Fab>
      )}
      <CheckoutPanel
        setCheckout={setCheckout}
        checkout={checkout}
        data={checkoutData}
      />
    </>
  );
};

export default FirstTimeConsumers;
