// menu.js
const menus = [
  {
    name: "Creative",
    link: "/create-calender",
    submenu: [
      {
        name: "Brand Story",
        link: "/create-calender",
        key: "createmysmcalender",
      },
      {
        name: "SM",
        link: "/create-calender",
        key: "smcalender",
      },
      {
        name: "Campaigns",
        link: "/create-calender",
        key: "createmypaidads",
      },
    ],
  },
  {
    name: "Media",
    link: "/my-social-media",
    submenu: [
      {
        name: "SM",
        link: "/my-social-media",
        key: "mysocialmedia",
      },
      {
        name: "Behavioral",
        link: "/my-social-media",
        key: "customerjourney",
      },
      {
        name: "Campaigns",
        link: "/my-social-media",
        key: "mypaidaids",
      },
    ],
  },
  {
    name: "Customer Segments",
    link: "/my-customers",
    submenu: [
      {
        name: "Behavioral",
        link: "/my-customers",
        key: "firsttimeviewercus",
      },
      {
        name: "Personal Attributes",
        link: "/my-customers",
        key: "engagedviewercus",
      },
      {
        name: "Campaign",
        link: "/my-customers",
        key: "firsttimeconsumercus",
      },
    ],
  },
  {
    name: "Data",
    link: "/insight-360",
    submenu: [
      {
        name: "Corporate",
        link: "/insight-360",
        key: "overview",
      },
      {
        name: "Operations",
        link: "/insight-360",
        key: "firsttimeviewer",
      },
      {
        name: "Location",
        link: "/insight-360",
        key: "engagedviewer",
      },
    ],
  },
];

export default menus;
