import { Box, Slider, Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearGraph from "../../../components/Common/LinearGraph";
import moment from "moment";
import CustomButton from "../../../components/Common/CustomButton/CustomButton";
import CustomSlider from "../../../components/Common/CustomSlider";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  container: {
    padding: "10px",
  },
  header: {
    // width: "80%",
    // height: "10vh",
  },
  headerText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  dateContainer: {
    margin: "5% 0%",
    display: "flex",
    width: "100%",
  },
  item: {
    width: "50%",
    padding: "0px 10px",
  },
  label: {
    color: "#FFF",
    fontFamily: "SFProText-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  slider: {
    color: "#eb2426", // Customize the slider color here
    "& .MuiSlider-valueLabel": {
      // Customize the value label here
      width: "132px",
      height: "67px",
      flexShrink: 0,
      borderRadius: "20px",
      background: "#eb2426",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "bold",
      padding: "4px 8px",
    },
  },
};

const IncreaseViewers = ({
  maxviewersData,
  publishcampaign,
  gettargetvalue,
  publishloader,
}) => {
  const [targetprice, setTargetprice] = useState(0);
  const currDate = new Date().toLocaleDateString();
  const currentdate = moment(currDate).format("YYYY-MM-DD");
  const [alertmessage, setAlertmessage] = useState("");
  const [startDate, setStartDate] = useState(
    moment(currDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment(currDate).format("YYYY-MM-DD"));
  const [maxenddata, setMaxenddate] = useState(
    moment(currDate).add(90, "days").format("YYYY-MM-DD")
  );

  const handleSliderChange = (event, newValue) => {
    let baseprice = maxviewersData.base_price_in_usd;
    let crntval = (newValue * parseFloat(baseprice)).toFixed(2);
    setTargetprice(crntval);
    gettargetvalue({ newValue, crntval });
  };
  const handlestartdate = (e) => {
    setStartDate(e.target.value);
    setMaxenddate(moment(e.target.value).add(90, "days").format("YYYY-MM-DD"));
  };
  const handleenddate = (e) => {
    setEndDate(e.target.value);
  };
  const handlepublish = () => {
    if (endDate <= startDate) {
      setAlertmessage("The end date will be in future.");
    } else if (targetprice <= 0) {
      setAlertmessage("Please Select Viewers");
    } else {
      let data = {
        startdate: startDate,
        enddate: endDate,
        targetamount: targetprice,
      };
      console.log(startDate, endDate);
      publishcampaign(data);
    }
    setTimeout(() => {
      setAlertmessage("");
    }, 2000);
  };
  return (
    <>
      <div style={style.container}>
        <div style={style.header}>
          <p style={style.headerText}>Increase Number of New Viewers</p>
        </div>
        <div style={style.dateContainer}>
          <div style={style.item}>
            <label htmlFor="startDatePicker" style={style.label}>
              Start Date
            </label>
            <input
              defaultValue={startDate}
              onChange={handlestartdate}
              type="date"
              id="startDatePicker"
              min={currentdate}
            />
          </div>
          <div style={style.item}>
            <label htmlFor="endDatePicker" style={style.label}>
              End Date
            </label>
            <input
              defaultValue={endDate}
              onChange={handleenddate}
              type="date"
              id="endDatePicker"
              min={currentdate}
              max={maxenddata}
            />
            <div
              style={{
                color: "#ED1414",
                fontFamily: "SF Pro Display",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "1.2",
                textAlign: "end",
                padding: "5px 0px",
              }}
            >
              Max 90 days
            </div>
          </div>
        </div>

        <Box sx={{ padding: "10% 0%" }}>
          <CustomSlider
            maxviewers={maxviewersData.max_viewers}
            baseprice={maxviewersData.base_price_in_usd}
            targetprice={targetprice}
            handleSliderChange={handleSliderChange}
          />
        </Box>
        {alertmessage && (
          <Box
            sx={{
              color: "#ED1414",
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "1.2",
              textAlign: "end",
              padding: "5px 0px",
              position: "absolute",
            }}
          >
            {alertmessage}
          </Box>
        )}

        <Box align="right" sx={{ margin: "5% 0%" }}>
          {publishloader ? (
            <>
              <Button
                sx={{
                  width: "fit-content",
                  height: "51px",
                  padding: "16px 32px 16px 32px",
                  borderRadius: "50px",
                  gap: "8px",
                  backgroundColor: "#eb2426",
                  color: "#FCFCFC",
                  textAlign: "center",
                  fontFamily: "SF Pro Display",
                  textTransform: "none",
                  cursor: "pointer",
                  zIndex: "9",
                  "&:hover": {
                    backgroundColor: "#eb2426",
                    boxShadow: "none",
                    cursor: "pointer",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "#eb2426",
                    cursor: "pointer",
                  },
                  "&:hover": {
                    backgroundColor: "#eb2426",
                    boxShadow: "none",
                    cursor: "pointer",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "#eb2426",
                    cursor: "pointer",
                  },
                }}
                disabled={true}
              >
                <Typography
                  sx={{
                    color: "white",
                  }}
                >
                  Publishing
                </Typography>{" "}
                <CircularProgress
                  size={30}
                  sx={{
                    color: "white",
                  }}
                />
              </Button>
            </>
          ) : (
            <>
              <CustomButton
                onClick={handlepublish}
                sx={{ width: "fit-content" }}
                label="Publish"
              />
            </>
          )}
        </Box>
      </div>
    </>
  );
};

export default IncreaseViewers;
