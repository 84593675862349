import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
const Themesliders = ({
  templateAll,
  handleClick,
  selectedtheme,
  selectedthemeid,
  users,
}) => {
  return (
    <div>
      <div className="theme-container">
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          loop={true}
          // autoplay={{ delay: 3000 }}
          pagination
          navigation={true}
          modules={[Navigation]}
        >
          {templateAll &&
            templateAll.length > 0 &&
            templateAll.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className={`theme-item ${
                      item?.id === selectedtheme?.id ||
                      item?.id === selectedthemeid ||
                      item?.id === users?.theme
                        ? "active"
                        : ""
                    }`}
                  >
                    <img src={item.imgUrl} className="w-100" />
                    <p className="name">{item.text} </p>
                    <div className="hover-btn">
                      <button
                        onClick={() => {
                          handleClick(item);
                        }}
                        className="byteready-btn byteready-primary"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default Themesliders;
