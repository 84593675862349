import { Box, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton/CustomButton";
import OutlinedButton from "./OutlineButton";

const style = {
  container: {
    margin: "10% 30%",
    width: "40%",
    height: "40vh",
    flexShrink: 0,
    backgroundColor: "#000000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#000",
  },
  text: {
    color: "#FCFCFC",
    fontFamily: "SFProText-Regular",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
  },
};

const DeletePopover = ({ open, handleClose, handleDelete }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.container}>
          <Typography sx={style.text}>
            Do you want to delete this post?
          </Typography>
          <Stack
            sx={{ margin: "40px 0px 10px 0px", width: "50%" }}
            direction="row"
            justifyContent="space-between"
          >
            <CustomButton onClick={handleDelete} sx={{ width: "40%" }} label="Delete" />
            <OutlinedButton
              handleChange={handleClose}
              style={{ width: "40%", padding: "10px 20px" }}
              text="Cancel"
            />
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DeletePopover;
