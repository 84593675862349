import React from "react";
import logo from "./../../assets/img/logo.png";
import businessman from "./../../assets/img/businessman-ceo.png";
import circleimg from "../../assets/img/round-circle.png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="content">
                <img src={logo} alt="logo" className="banner-logo" />
                <h1 className="heading-main text-white">
                  Empower Your <br></br> Marketing <br></br> Communication
                  <br></br> with AI Precision
                </h1>
                <Link to={"/create-calender"} className="btn-one">
                  START NOW
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-image-container">
                <img src={businessman} alt="man" className="right-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={circleimg} alt="circle-image" className="circle-image" />
    </div>
  );
};

export default Banner;
