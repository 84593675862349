import React from "react";

const UploadMenu = ({
  selectedFile,
  setSelectedFile,
  handleFileChange,
  uploadicon,
}) => {
  return (
    <>
      <div className=" py-3">
        <p>
          <strong>Menu</strong>
        </p>
        <div className="file-main">
          {selectedFile && (
            <div className="filename-container">
              <span className="file_name">{selectedFile.name}</span>{" "}
              <span className="cross-btn" onClick={() => setSelectedFile(null)}>
                X
              </span>
            </div>
          )}
          <label className="byteready-btn byteready-outlinne upload-button">
            {/* <input
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            /> */}
            <img src={uploadicon} alt="Upload Icon" />
            Upload Menu
          </label>
        </div>
      </div>
    </>
  );
};

export default UploadMenu;
