const style = {
  container: {
    padding: "50px 0 50px 0",
    backgroundColor: "#000000",
    width: "90vw",
    margin: "auto",
  },
  postpreviewheading: {
    fontFamily: "helvetica-font",
    fontSize: "32px",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    margin: "40px 0px",
  },
  smallHeading: {
    fontFamily: "helvetica-font",
    fontSize: "16px",
    lineHeight: "1.2",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#9E9E9E",
    textWrap: "nowrap",
  },
};
export default style;
