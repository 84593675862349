import React from "react";
import { Switch } from "@mui/material";
import { styled } from "@mui/system";

const SwitchButtonComponent = styled(Switch)(({ theme, checked }) => ({
  padding: 7,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    position: "relative",
    backgroundColor: checked ? "#0CAB5E !important" : "red !important",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "100%",
      height: "100%",
      fontSize: 12,
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "&::after": {
      content: checked ? '"ON"' : '""',
      right: 8,
      fontSize: "10px !important",
      color: "var(--white-color)",
      opacity: checked ? 1 : 0,
      transition: "opacity 0.3s",
      fontFamily: "helvetica-font",
      fontWeight: "400",
    },
    "&::before": {
      content: !checked ? '"OFF"' : '""',
      left: 9,
      fontSize: "10px !important",
      color: "var(--white-color)",
      opacity: !checked ? 1 : 0,
      transition: "opacity 0.3s",
      fontFamily: "helvetica-font",
      fontWeight: "400",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: "#D9D9D9 !important",
  },
}));

const Switchbutton = ({ checked, onChange }) => {
  return <SwitchButtonComponent checked={checked} onChange={onChange} />;
};

export default Switchbutton;
