import React, { useEffect, useState } from "react";
import "./Themestyle.css";
import Loadericon from "../../Common/Loadericon";
import scrossicon from "../../../assets/images/create-calender/cross-theme.png";
import edittheme from "../../../assets/images/create-calender/edit-theme.png";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const Savedtheme = ({
  loader,
  templateAll,
  selectedtheme,
  selectedthemeid,
  users,
  handleClick,
  handlleedit,
  closesavedtheme,
}) => {
  return (
    <>
      <div className="theme-container save-theme">
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          loop={true}
          autoplay={{ delay: 3000 }}
          pagination
          navigation={true}
          modules={[Navigation, Autoplay]}
        >
          {templateAll &&
            templateAll.length > 0 &&
            templateAll.map((item, index) => {
              const created_date = moment(item?.created_at).format(
                "DD/MM/YYYY"
              );
              return (
                <SwiperSlide key={index}>
                  <div
                    className={`theme-item ${
                      item?.id === selectedtheme?.id ||
                      item?.id === selectedthemeid ||
                      item?.id === users?.theme
                        ? "active"
                        : ""
                    }`}
                  >
                    <p className="date">{created_date}</p>
                    <div className="theme-image postion-relative">
                      <img src={item.image_url} className="w-100 min-height-100" />
                      <img
                        onClick={() => {
                          handlleedit(item);
                        }}
                        src={edittheme}
                        alt="edit-theme"
                        className="edit-theme-btn"
                      />
                    </div>

                    <div className="event-bottom">
                      <p className="event-name">{item?.title || ""} </p>
                      <p className="event-descriptio">{item?.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default Savedtheme;
