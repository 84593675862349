import React from "react";
import cardtrial from "../../../assets/images/info/image1.png";

const Cardsone = ({ handleFocus }) => {
  return (
    <>
      <div className="suggestion-card-container">
        <div className="suggetion-inner-container">
          <div className="image-container">
            <img src={cardtrial} className="w-100" />
          </div>
          <div className="content-container">
            <h6 className="sugesstion-class">
              Start with High-Quality Visuals
            </h6>
            <ul className="colored-ul">
              <li>
                <div>
                  <h6>Photos:</h6>
                  <p className="font-small">Share high-resolution images or </p>
                </div>
              </li>
              <li>
                <div>
                  <h6>Videos:</h6>
                  <p className="font-small">
                    Create short clips or walkthroughs that give viewers a
                    virtual experience{" "}
                  </p>
                </div>
              </li>
            </ul>
            <div className="btn-container">
              <span
                onClick={() => {
                  handleFocus("skip");
                }}
                className="skip-btn"
              >
                Skip
              </span>
              <button
                onClick={() => {
                  handleFocus(2);
                }}
                className="byteready-btn byteready-primary"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cardsone;
