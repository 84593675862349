import React, { useEffect, useState } from "react";
import "./CardBottom.css";
import { Button, Slider, Stack, Typography } from "@mui/material";
import { setTargetAPI } from "../../../apis/auth";

const CardBottom = ({ data, tag, fetchData }) => {
  const [maxViewer, setMaxViewer] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [targetViewer, setTargetViewer] = useState(0);
  const [targetPrice, setTargetPrice] = useState(0);
  const [currentViewer, setCurrentViewer] = useState();
  const [currentPrice, setCurrentPrice] = useState();

  const handleSliderChange = (event, newValue) => {
    let baseprice = parseFloat(data?.base_price_in_usd);
    let trgprice = (parseFloat(newValue) * baseprice).toFixed(2);
    setTargetViewer(newValue);
    setTargetPrice(trgprice);
  };
  const settargetprice = () => {
    let payload = {
      profile_id: "6516ef35a0eb18748f5ae975",
      campagin_id: "650c1177303f9ccf6a352537",
      viewer_type: tag,
      viewer_count: targetViewer,
    };
    setTargetAPI(payload)
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          fetchData();
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  useEffect(() => {
    if (data) {
      let baseprice = parseFloat(data.base_price_in_usd);
      let mxprice = (parseFloat(data.max_viewers) * baseprice).toFixed(2);
      let trgprice = data["first-time-viewers"]
        ? (parseFloat(data["first-time-viewers"]) * baseprice).toFixed(2)
        : 0.0;
      setMaxViewer(data.max_viewers);
      setCurrentViewer(data["first-time-viewers"]);
      setCurrentPrice(trgprice);
      setTargetViewer(data.current_viewers);
      setTargetPrice(data.current_spend.toFixed(2));
      setMaxPrice(mxprice);
    }
  }, [data]);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className="d-flex flex-column">
          <Typography variant="p" className="text-cb">
            Total No.
          </Typography>
          <Typography variant="p" className="text-cb text2-cb">
            {currentViewer || "00"}
          </Typography>
          <Typography variant="p" className="text-cb text-green text2-cb">
            {targetViewer || "00"}
          </Typography>
        </div>
        <div className="d-flex flex-column">
          <Typography variant="p" className="text-cb">
            Spend
          </Typography>
          <Typography variant="p" className="text-cb text2-cb">
            ${currentPrice || "0.00"}
          </Typography>
          <Typography variant="p" className="text-cb text-green text2-cb">
            ${targetPrice || "0.00"}
          </Typography>
        </div>
      </Stack>
      <div className="d-flex flex-column my-1">
        <Typography variant="p" className="text4-cb">
          Max
        </Typography>
        <Typography variant="p" className="text4-cb text-white">
          {maxViewer || "00"}
        </Typography>
        <div className="slider-box px-2">
          <Slider
            sx={{ color: "#eb2426" }}
            value={targetViewer}
            max={maxViewer}
            min={0}
            onChange={handleSliderChange}
          />
          <div className="slider-circle"></div>
        </div>
        <Typography variant="p" className="text4-cb text-white">
          ${maxPrice || "0.00"}
        </Typography>
      </div>
      <Button
        sx={{
          width: "100%",
          margin: "2px 0",
          borderRadius: "50px",
          opacity: 0.3,
          background: "#eb2426",
          "&:hover": {
            background: "#eb2426",
          },
        }}
        variant="contained"
      >
        Boost
      </Button>
    </>
  );
};

export default CardBottom;
