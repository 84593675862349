import React from "react";
import "../Dashboard.css";
import {
  Card,
  Grid,
  Typography,
  Stack,
  Button,
  Slider,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import totalIcon from "../../../assets/SVG/total_icon.svg";
import firstTimeIcon from "../../../assets/SVG/ph_eye-fill.svg";
import engagedIcon from "../../../assets/SVG/ph_eye-fill2.svg";
import firstConsumersIcon from "../../../assets/SVG/solar_user-bold.svg";
import dinersIcon from "../../../assets/SVG/solar_dollar-bold.svg";
import rightArrowIcon from "../../../assets/SVG/material-symbols_keyboard-arrow-down-rounded.svg";
import dropdownIcon from "../../../assets/SVG/iconamoon_arrow-up-2-duotone.svg";
import ApexCharts from "apexcharts";
import CardBottom from "./../components/CardBottom";
import CustomCharts from "./../components/CustomCharts";

const Years = [
  2000, 2001, 2002, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
];

const Total = () => {
  return (
    <>
      <div className="chart-container">
        <Stack
          className="w-100"
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="p" className="text5-total">
            Chart
          </Typography>
          <div>
            <Form.Select
              className="select-total"
              aria-label="Default select example"
            >
              {/* <option>--select--</option> */}
              {Years?.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </div>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div className="chart-card">
              <Stack
                className="py-4 justify-content-end"
                direction="row"
                spacing={2}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="point bg-violet"></div>
                  <Typography variant="p" className="text">
                    First Time Viewers
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="point bg-blue"></div>
                  <Typography variant="p" className="text">
                    Engaged Viewers
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="point bg-navyblue"></div>
                  <Typography variant="p" className="text">
                    First Time Consumers
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="point bg-lightgrey"></div>
                  <Typography variant="p" className="text">
                    Freq Diners
                  </Typography>
                </Stack>
              </Stack>
              <CustomCharts />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="chart-card">
              <Stack
                className="py-4 justify-content-end"
                direction="row"
                spacing={2}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="point bg-orange"></div>
                  <Typography variant="p" className="text">
                    Spend
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="point bg-green"></div>
                  <Typography variant="p" className="text">
                    Revenues
                  </Typography>
                </Stack>
              </Stack>
              <CustomCharts />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Total;
