import React, { useState } from "react";
import TodaysPosts from "../../TodaysPosts/TodaysPosts";
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import style from "./MySocialMediaStyle";
import SwitchButton from "../../Common/SwitchButton/SwitchButton.jsx";
import MediaTextSwitch from "../../Common/MediaTextSwitch";
import CustomButton from "../../Common/CustomButton/CustomButton";
import SocialMediaCards from "../../Common/SocialMediaCards/SocialMediaCards.jsx";
import SocialMediafbCards from "../../Common/SocialMediaFbCards/SocialMediaFbCards";
import EditSocialMedia from "./EditSocialMedia";
import { withEditFeature } from "./EditSocialMedia";
import MediaHeader from "../MediaHeader";
import PopupShow from "../../../pages/Calendar/Signup/PopupShow";

const cardData = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const MySocialMedia = ({ operation }) => {
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const [date, setDate] = useState(moment(currDate).format("YYYY-MM-DD"));
  const [time, setTime] = useState(
    moment(currTime, "HH:mm").format("HH:mm:ss")
  );
  const tempCurrentDate = new Date();
  const [currentDate, setCurrentDate] = useState(tempCurrentDate);
  const [monthalydata, setMonthalydata] = useState(cardData);
  const [modelData, setModelData] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  //Date Change
  let startDate = moment(currentDate);
  const weekdays = moment.weekdaysShort();
  const currentDayOfWeek = moment(currentDate).day();
  const rotatedWeekdays = [
    ...weekdays.slice(currentDayOfWeek),
    ...weekdays.slice(0, currentDayOfWeek),
  ];
  // console.log("rotatedWeekdays ", rotatedWeekdays);
  const [designSwicher, setDesingswitcher] = useState("insta");

  const handlePopup = (data) => {
    // console.log("Check " , data);
    setModelData(data);
  };
  const clodeModel = (data) => {
    if ((data = "reload")) {
      setModelData("");
      // fetchData();
    } else {
      setModelData("");
    }
  };

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const handleTime = (e) => {
    setTime(e.target.value);
  };

  // Social Media Switcher
  const designSwitch = (data) => {
    setDesingswitcher(data);
  };
  const EditSocialMedia =
    operation === "edit"
      ? withEditFeature(
          designSwicher === "insta" ? SocialMediaCards : SocialMediafbCards
        )
      : designSwicher === "insta"
      ? SocialMediaCards
      : SocialMediafbCards;

  return (
    <>
      <Box
        sx={{
          ...style.mainContainer,
          background: operation === "edit" ? "#000000" : "#111",
        }}
      >
        <MediaHeader designSwitch={designSwitch} operation={operation} />
      </Box>
      <Box
        sx={{
          ...style.tableContainer,
          background: operation === "edit" ? "#000000" : "#111",
        }}
      >
        <TableContainer>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "0px",
                }}
              >
                {rotatedWeekdays.map((day) => (
                  <TableCell key={day} align="left">
                    <Typography sx={style.dayText} variant="h5">
                      {day.charAt(0)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {monthalydata &&
                monthalydata
                  .reduce((weeks, day, index) => {
                    startDate =
                      index === 0
                        ? startDate.add(0, "days")
                        : startDate.add(1, "days");
                    const weekIndex = Math.floor(index / 7);
                    if (!weeks[weekIndex]) {
                      weeks[weekIndex] = [];
                    }
                    weeks[weekIndex].push(
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          padding: "2px",
                          margin: "auto",
                          cursor: "pointer",
                        }}
                        onMouseEnter={()=> setShowEdit(true)}
                        onMouseLeave={()=> setShowEdit(false)}
                        onClick={() => operation === "edit" && handlePopup(day)}
                      >
                        <EditSocialMedia
                          dataperday={day}
                          // url={wesiturl}
                          // name={resName}
                        />
                        <Typography
                          sx={style.dateText}
                          variant="h5"
                        >{`${startDate.format("MM/DD")}`}</Typography>
                      </TableCell>
                    );
                    return weeks;
                  }, [])
                  .map((week, index) => (
                    <TableRow key={index}>{week}</TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <PopupShow
          open={modelData}
          // wesiturl={wesiturl}
          resName={"Demo"}
          handleClose={clodeModel}
          // resData={profileData}
        />
      </Box>
      {operation === "view" && (
        <Box sx={{ width: "100%", height: "150vh" }}>
          <TodaysPosts />
        </Box>
      )}
    </>
  );
};

export default MySocialMedia;
