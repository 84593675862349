import React, { useState } from "react";
import eyeopen from "../../assets/images/header/eye-open.png";
import eyeclose from "../../assets/images/header/eye-close.png";
import { useNavigate } from "react-router-dom";

const Changepassword = ({
  alertMessage,
  profileData,
  passwordChange,
  setPasswordChange,
  forgetpassword
}) => {
  const navigate = useNavigate();
  const [showpassword, setShowpassword] = useState(false);
  const [showconfirmpassword, setShowconfirmpassword] = useState(false);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="content-padding-both">
            <div className="basic-card change-password-container">
              <h3 className="basic-title">Change Password</h3>
              <div className="row align-items-top py-2">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Current Password</label>
                    <div className="position-relative password">
                      <input
                        type={"password"}
                        className="custom-input"
                        defaultValue={passwordChange?.current_password || ""}
                        onChange={(e) => {
                          setPasswordChange({
                            ...passwordChange,
                            current_password: e.target.value,
                          });
                        }}
                      />
                      <div className="d-flex justify-content-end">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            forgetpassword();
                          }}
                        >
                          Forgot Password?
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>New Password</label>
                    <div className="position-relative password">
                      <input
                        type={showpassword ? "text" : "password"}
                        className="custom-input"
                        defaultValue={passwordChange?.new_password || ""}
                        onChange={(e) => {
                          setPasswordChange({
                            ...passwordChange,
                            new_password: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={showpassword ? eyeopen : eyeclose}
                        onClick={() => {
                          setShowpassword(!showpassword);
                        }}
                        className="password-eye"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Re-enter New Password</label>
                    <div className="position-relative password">
                      <input
                        type={showconfirmpassword ? "text" : "password"}
                        className="custom-input"
                        defaultValue={passwordChange?.confirm_password || ""}
                        onChange={(e) => {
                          setPasswordChange({
                            ...passwordChange,
                            confirm_password: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={showconfirmpassword ? eyeopen : eyeclose}
                        onClick={() => {
                          setShowconfirmpassword(!showconfirmpassword);
                        }}
                        className="password-eye"
                      />
                    </div>
                  </div>
                </div>
                {alertMessage && (
                  <>
                    <div className="col-md-12">
                      <span className="alert-message">{alertMessage}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
