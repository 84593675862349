const style = {
  userName: {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
  },
  headerTextBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  viewsTextStyle: {
    paddingTop: "3px",
    color: "#FFF",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  likeBtnText: {
    paddingTop: "6px",
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  cardStyle: {
    display: "flex",
    width: "30%",
    padding: "8px 16px",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "10px",
    border: "1px solid #eb2426",
  },
};

export default style;
