const style = {
  container: {
    maxWidth: "100%",
    // height: "100vh",
    backgroundColor: "#000000",
    color: "#fffff",
    marginTop: "20px",
    padding: "88px 0px 50px 0px",
  },
  rightContainer: {
    padding: "10px 0px 20px 135px",
  },
  headerText: {
    color: "#FCFCFC",
    textAlign: "left",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
    fontSize: "64px",
    lineHeight: "100%",
  },

  descStyle: {
    margin: "0px 0px",
    color: "#FFF",
    textAlign: "left",
    fontFamily: "SFProText-Regular",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  descBox: {
    width: "93%",
    display: "flex",
    marginTop: "20px",
    padding: "16px",
    alignItems: "center",
    gap: "114px",
    alignSelf: "stretch",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.30)",
  },
  descText: {
    color: "#FFF",
    fontFamily: "SFProText-Regular",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal",
  },
  saveBtn: {
    marginTop: "34px",
    width: "20%",
  },
  textArea: {
    marginTop: "0px",
    color: "#FCFCFC",
    fontSize: "15px",
    fontFamily: "SFProText-Regular",
    background: "transparent",
    borderColor: "transparent",
    outline: "none",
    width: "100%",
  },
  btnContainer: {
    display: "flex",
    flexdirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  btnBox: {
    width: "30px",
    height: "30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    margin: "10px 10px 10px 0px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};

export default style;
