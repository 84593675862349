import React, { useEffect, useState } from "react";
import Main from "../components/CreateCalender/Main";
import Tabview from "../components/CreateCalender/Tabview";
import NewEvents from "../components/NewEvents/NewEvents";
import { useDispatch, useSelector } from "react-redux";
import { storeInLocalStore } from "../redux/reducers/mainSlice";

const Createcalender = () => {
  const [activetab, setActivetab] = useState();
  const dispatch = useDispatch();
  const eventTitle = useSelector((state) => state?.main?.store?.eventTitle);
  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  useEffect(() => {
    // console.log(currentsubmenu);

    if (currentsubmenu) {
      if (currentsubmenu == "createmypaidads") {
        if (eventTitle?.id) {
          setActivetab(currentsubmenu);
        } else {
          setActivetab("smcalender");
          dispatch(storeInLocalStore({ currentsubmenu: "smcalender" }));
        }
      }
      setActivetab(currentsubmenu);
    }
  }, [currentsubmenu]);
  return (
    <div className="full-width headerpadding">
      {activetab == "createmysmcalender" && <Main />}
      {activetab == "smcalender" && <Tabview />}
      {activetab == "createmypaidads" && <NewEvents />}
    </div>
  );
};

export default Createcalender;
