import React, { useState } from "react";
import "./FirstTimeViewers.css";
import trendUp from "../../../assets/SVG/Group_1.svg";
import { Stack, Typography } from "@mui/material";
import arrowUp from "../../../assets/SVG/material-symbols_keyboard-arrow-down-rounded_up_blue.svg";
import arrowDown from "../../../assets/SVG/material-symbols_keyboard-arrow-down-rounded_down_blue.svg";
import InnerTable from "./InnerTable";
import ProgressBar from "react-bootstrap/ProgressBar";

const tableData = [
  {
    user: "M. Hitchcock",
    first_order: 44989,
    total_orders: 16,
    ave_order: 4.24399e-314,
    liv: 6.36599e-314,
  },
  {
    user: "M. Hitchcock",
    first_order: 44989,
    total_orders: 16,
    ave_order: 4.24399e-314,
    liv: 6.36599e-314,
  },
  {
    user: "M. Hitchcock",
    first_order: 44989,
    total_orders: 16,
    ave_order: 4.24399e-314,
    liv: 6.36599e-314,
  },
  {
    user: "M. Hitchcock",
    first_order: 44989,
    total_orders: 16,
    ave_order: 4.24399e-314,
    liv: 6.36599e-314,
  },
  {
    user: "M. Hitchcock",
    first_order: 44989,
    total_orders: 16,
    ave_order: 4.24399e-314,
    liv: 6.36599e-314,
  },
];

const Accordin = ({ data }) => {
  const [show, setShow] = useState("");

  const handleIndex = (params) => {
    if (params === show) {
      setShow("");
    } else {
      setShow(params);
    }
  };
  return (
    <>
      {data?.map((item, index) => {
        const now = item?.now;
        return (
          <React.Fragment key={index}>
            <tr
              className="accordin-container accordin-container-align alp"
              onClick={(e) => handleIndex(index)}
            >
              <th className="d-flex justify-content-between">
                <img src={show === index ? arrowUp : arrowDown} alt="error" />
                <Typography variant="p" className="text5-ftv align">
                  {item?.segment}
                </Typography>
              </th>
              <th>
                <Typography variant="p" className="text5-ftv align">
                  {item?.spend}
                </Typography>
              </th>
              <div>
                <Stack direction="row" spacing={2}>
                  <Typography variant="p" className="text5-ftv align">
                    {item?.viewers}
                  </Typography>
                  <div className="progress-bar-container">
                    <ProgressBar
                      style={{ width: `${now}%` }}
                      className="progress-bar"
                      now={now}
                      // label={`${now}%`}
                    />
                    <span className="progress-bar-level">{`${now}%`}</span>
                  </div>
                  <img src={trendUp} alt="error" />
                </Stack>
              </div>
              <td>
                <Typography variant="p" className="text5-ftv align">
                  {item?.cost_per}
                </Typography>
              </td>
            </tr>
            {show === index && <InnerTable data={tableData} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Accordin;
