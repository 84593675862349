const style = {
  imageSlider: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  mobileStyle: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "250px",
    height: "520px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  secondBox: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    maxWidth: "100%",
    height: "100%",
    paddingLeft: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    color: "red",
    "@media (max-width: 600px)": {
      maxWidth: "100vw",
    },
  },
  dotLineStyle: {
    margin: "2px",
    width: "75px",
    height: "10px",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    marginTop: "3px",
    cursor: "pointer",
  },
  instagramHeader: {
    display: "flex",
    // padding: "14.379px 19.172px",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: "215.688px",
    // alignSelf: "stretch",
    width: "100%",
    height: "200px",
    position: "absolute",
    color: "#000000",
    padding: "0px 20px 20px 50px",
  },
  instaHeaderText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "2.397px",
    paddingLeft: "6px",
  },
  firstInstaCont: {
    display: "flex",
    flexDirection: "row",
  },
  settingContainer: {
    padding: "30px",
  },
  instaPicBox: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginTop: "35px",
    width: "230px",
    height: "200px",
  },
  shopBox: {
    display: "flex",
    height: "30px",
    margin: "-2px 10px 0px 10px",
    padding: "0px 5px 5px 10px",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    alignSelf: "stretch",
    // border: "1px solid #FCFCFC",
  },
  shoptext: {
    color: "#1EA1F7",
    textAlign: "center",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  instaFooter: {
    width: "100%",
    height: "50px",
    paddingTop: "-20px",
    paddingLeft: "40px",
    display: "flex",
    flexDirection: "row",
  },
  instaBtn: {
    marginLeft: "-20px",
    // padding: "0px 1px 0px 1px"
  },
  editBox: {
    paddingLeft: "35px",
    cursor: "pointer",
  },
  editText: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "12px",
    marginLeft: "-12px",
    cursor: "pointer",
  },
  mobBotttomBox: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    marginTop: "-30px",
    padding: "0px 10px 5px 20px",
  },
  mobBotttomText: {
    color: "#000000",
    fontSize: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  btnContainer: {
    display: "flex",
    flexdirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  btnBox: {
    width: "30px",
    height: "30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    margin: "10px 10px 10px 0px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  uploadBtn: {
    marginTop: "40px",
    display: "inline-flex",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "50px",
    background: "rgba(255, 255, 255, 0.70)",
    backdropFilter: "blur(7.5px)",
    color: "#eb2426",
    textAlign: "center",
    fontSize: "12px",
    fontFamily: "SFProText-Regular",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "normal",
    textTransform: "none",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.70)",
      color: "#eb2426",
    },
  },
  textArea: {
    marginTop: "5px",
    color: "#000000",
    fontSize: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    background: "transparent",
    borderColor: "transparent",
    outline: "none",
  },
  deleteBox: {
    margin: "26px auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: "50%",
    cursor: "pointer",
  },
};

export default style;
