import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Auth = ({ children }) => {
  const token = localStorage.getItem("token");

  const renderChild = () => {
    //return children;
    return token ? children : <Navigate to="/login" />;
  };
  return renderChild();
};

export default Auth;
