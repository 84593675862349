import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./../../assets/css/ComponentCSS/ImageCard.css";
import PreviousBtn from "./PreviousBtn";
import NextBtn from "./NextBtn";

const style = {
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    height: "300px",
  },
  imgContainer: {
    width: { sm: "100px", md: "100px", lg: "165px", xl: "210px" },
    height: { sm: "118px", md: "120px", lg: "205px", xl: "250px" },
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    position: "relative",
    margin: "auto",
  },
  text: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: { sm: "14px", md: "18px", lg: "20px", xl: "25px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textTransform: "uppercase",
    position: "absolute",
    bottom: "11px",
    left: "0px",
    margin: "0px",
    padding: "0px",
  },
};

const ImageCards = ({ data, handlePrevious, handleNext }) => {
  return (
    <Box sx={style.mainContainer} className={"ImageContainer"}>
      {data?.length > 0 && <PreviousBtn handlePrevious={handlePrevious} />}
      <Grid container spacing={2}>
        {data?.map((element, index) => {
          return (
            <Grid key={index + 1} item xs={12} md={4}>
              <Box
                sx={{
                  backgroundImage: `url(${element?.imgUrl})`,
                }}
                className={`eventImagecard ${
                  index % 2 !== 0 ? "margintop" : ""
                }`}
              >
                <Typography className="text">{element?.text}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {data?.length > 0 && <NextBtn handleNext={handleNext} />}
    </Box>
  );
};

export default ImageCards;
