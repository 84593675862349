import React, { useState } from "react";
import { debounce } from "../../../CustomHook/addressUtills";
import { useDispatch } from "react-redux";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import Thoughtspotcomponent from "./../../../components/thoughtspot/Thoughtspotcomponent";

const Corporate = () => {
  const dispatch = useDispatch();
  const [thoughtspotData, setThoughtspot] = useState([]);

  const liveboardId = "f4e1dbc2-2467-4163-8ed0-e3b7e5c6cf31";
  const handleevent = (type, data) => {
    if (type == "Data") {
      const value =
        data.data.embedAnswerData.data[0].columnDataLite[0].dataValue[0];
      const name = data.data.name;
      const isFloat = !isNaN(parseFloat(value)) && isFinite(value);

      const newData = {
        name: name,
        value: isFloat ? value : null,
      };

      setThoughtspot((prevData) => {
        const exists = prevData.some(
          (item) => item.name === newData.name && item.value === newData.value
        );
        const updatedData = exists ? prevData : [...prevData, newData];
        saveToLocalStorage(updatedData);

        return updatedData;
      });
    }
    //console.log("Type", type, "Data", data);
  };
  const saveToLocalStorage = debounce((data) => {
    dispatch(storeInLocalStore({ thoughtspotData: data }));
  }, 1000);
  return (
    <div className="content-padding-both">
      <Thoughtspotcomponent
        thoughtspotData={thoughtspotData}
        setThoughtspot={setThoughtspot}
        liveboardId={liveboardId}
        handleevent={handleevent}
        saveToLocalStorage={saveToLocalStorage}
      />
    </div>
  );
};

export default Corporate;
