import React from 'react'
import Homesections from '../Common/Homesections';
import aiimage from "./../../assets/img/creative.png";

const Sectionfour = () => {
  return (
    <Homesections
      bgclass={"bg-two"}
      aiimage={aiimage}
      title={`<span>PERFORMANCE</span>`}
      subtitle={`Operating and investing based on real data allows businesses to optimize marketing strategies and resource allocation`}
      content={``}
      right={true}
      videourl={
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
      }
    />
  )
}

export default Sectionfour
