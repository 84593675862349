import { Box, Typography } from '@mui/material'
import React from 'react'
import style from './PaymentStyle'
import CustomButton from '../../components/Common/CustomButton/CustomButton'
import { useNavigate } from 'react-router-dom'

function PaymentForm() {
    const navigate = useNavigate()
    return (
        <Box style={style.container}>
            <Box style={{ ...style.smallContainer, top: '0', borderRadius: "5px" ,padding:"30px"}}>
                <Typography style={{ ...style.styleHeading, fontSize: '16px', textAlign: 'left' }}>PAYMENT</Typography>
                <label style={{ ...style.addPaymentSmallHeading, marginLeft: "5px" }}>Credit Card Number</label>
                <input placeholder='0123' />
                <label style={{ ...style.addPaymentSmallHeading, marginLeft: "5px" }}>Card Holder’s Name</label>
                <input placeholder='Name Surname' />
                <Box className='d-flex justify-content-between'>
                    <Box ml={1}>
                        <label style={{ ...style.addPaymentSmallHeading, marginLeft: "5px" }}>Credit Card Number</label>
                        <input placeholder='0123'/>
                    </Box>
                    <Box ml={1}>
                        <label style={{ ...style.addPaymentSmallHeading, marginLeft: "5px" }}>Credit Card Number</label>
                        <input placeholder='0123' />
                    </Box>
                    <Box ml={1}>
                        <label style={{ ...style.addPaymentSmallHeading, marginLeft: "5px" }}>Credit Card Number</label>
                        <input placeholder='***' />
                    </Box>
                </Box>
                <CustomButton label={'Pay'} sx={{ marginTop:'20px',display:"block",marginLeft:"auto", cursor: "pointer", fontFamily: 'Whyte Inktrap', fontSize: '13px' }} onClick={() => navigate('/payment-history')} />
            </Box>
        </Box>
    )
}

export default PaymentForm