import { Box, Typography } from "@mui/material";
import style from "../viewersStyle";
import plusIcon from "../../../assets/SVG/plusMinus.svg";

const AddLists = ({ customStyle }) => {
  return (
    <>
      <Box sx={{ ...customStyle, ...style.addListsBox, }}>
        <Box sx={style.whiteBox}>
          <img width="24px" height="24px" src={plusIcon} alt="error" />
        </Box>
        <Typography sx={style.text3}>Add Lists</Typography>
      </Box>
    </>
  );
};

export default AddLists;
