const style = {
  container: {
    padding: "150px 0px 50px 0px",
    maxWidth: "500px",
    margin: "auto",
    minHeight: "80vh",
  },
  styleHeading: {
    fontFamily: "helvetica-font",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "1.2",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#fff",
    textTransform: "uppercase",
  },
  styleSmallHeading: {
    fontFamily: "SF UI Display",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#fff",
    marginTop: "20px",
  },
  smallContainer: {
    background: "#151515",
    padding: "70px 20px 20px 20px",
    position: "relative",
    top: "-50px",
    zIndex: "9",
    borderRadius: "24px",
  },
  price: {
    fontFamily: "helvetica-font",
    fontSize: "35px",
    fontWeight: "900",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#eb2426",
  },
  addPaymentHeading: {
    fontFamily: "helvetica-font",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "8px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#fff",
    margin: "10px 0px",
  },
  addPaymentSmallHeading: {
    fontFamily: "SF UI Display",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#fff",
    margin: "10px 0px",
  },
};
export default style;
