import React from "react";
import cardtrial from "../../../assets/images/info/image4.png";

const Cardfour = ({ handleFocus }) => {
  return (
    <>
      <div className="suggestion-card-container">
        <div className="suggetion-inner-container">
          <div className="image-container">
            <img src={cardtrial} className="w-100" />
          </div>
          <div className="content-container">
            <h6 className="sugesstion-class">Consistency is Key</h6>
            <p className="font-small">
              Remember, the goal is to create a vivid mental image for potential
              diners, enticing them not just with the promise of delicious food,
              but with an unforgettable experience rooted in the charm and
              ambiance of your location.
            </p>
            <div className="btn-container">
              <span
                onClick={() => {
                  handleFocus("skip");
                }}
                className="skip-btn"
              >
                Skip
              </span>
              <button
                onClick={() => {
                  handleFocus("skip");
                }}
                className="byteready-btn byteready-primary"
              >
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cardfour;
