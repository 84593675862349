import { Box, Grid } from "@mui/material";
import React from "react";
import style from "./index.js";

const WrapperComp = ({ firstComp, secondComp, SingleCompnents }) => {
  return (
    <div>
      <div className="container">
        {SingleCompnents ? (
          <Box sx={style.singleContainer}>{SingleCompnents}</Box>
        ) : (
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div style={style.firstContainer}>{firstComp}</div>
            </div>
            <div className="col-md-6">
              <Box sx={style.loginForm}>{secondComp}</Box>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WrapperComp;
