import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import style from "./SocialMediaFbCardsStyle";
import ProfileImage from "../../../assets/images/pizza-hut.png";
import CardImage from "../../../assets/images/Image_1.png";
import fbIcon from "../../../assets/images/fbLike.png";
import fbLikeIcon from "../../../assets/images/instaLike.png";

const SocialMediaFbCards = ({
  dataperday,
  url,
  name,
  handleHover,
  handleRemoveHover,
}) => {
  let cleanurl = "";
  if (url) {
    cleanurl = url
      .replace(/^https?:\/\//, "")
      // .replace(/^www\./, "")
      .replace(/\/$/, "");
  }
  return (
    <>
      <Card
        onMouseEnter={handleHover}
        onMouseLeave={handleRemoveHover}
        sx={{ maxWidth: 120, maxHeight: 180, margin: "auto", zIndex: -1 }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "2px 4px",
            }}
          >
            <Box>
              <Avatar
                sx={{ width: 12, height: 12, marginRight: "3px" }}
                alt="Remy Sharp"
                src={
                  cleanurl
                    ? `https://s2.googleusercontent.com/s2/favicons?domain=${cleanurl}`
                    : ProfileImage
                }
              />
            </Box>
            <Stack direction="column" justifyContent="space-around">
              <Typography sx={style.headerText}>
                {name || "Pizza Hut"}
              </Typography>
              <Typography sx={{ ...style.headerText, fontWeight: 400 }}>
                {name || "5h"}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "2px 4px",
            }}
          >
            <Typography sx={{ ...style.headerText, fontWeight: 400 }}>
              Generating random paragraphs can be a excellent way for writers
            </Typography>
          </Box>
          <CardMedia
            component="img"
            height="94"
            image={dataperday?.prompt_output_img_url || CardImage}
            alt="CardImage"
          />
          <Box sx={{ padding: "0px 3px" }}>
            <CardActions sx={style.cardActions} disableSpacing>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px 2px 5px 0px",
                  margin: "0px",
                  lineHeight: "0px",
                  position: "relative"
                }}
                disableSpacing
              >
                <img
                  style={{ zIndex: 2  }}
                  width="10px"
                  height="10px"
                  src={fbIcon}
                  alt="LikeButton"
                />
                <img
                  style={{ zIndex: 1, position: "absolute", top: "25%", left: "25%" }}
                  width="10px"
                  height="10px"
                  src={fbLikeIcon}
                  alt="CommentButton"
                />
                <Typography
                  sx={{
                    ...style.headerText,
                    fontWeight: 400,
                    padding: "15% 40%",
                    fontSize: "6px"
                  }}
                >
                  3,4K
                </Typography>
              </Box>
              <Stack direction="row">
                <Typography
                  sx={{
                    ...style.headerText,
                    fontWeight: 400,
                    fontSize: "6px"
                  }}
                >
                  70 comments
                </Typography>
                <Typography
                  sx={{
                    ...style.headerText,
                    fontWeight: 400,
                    fontSize: "6px"
                  }}
                >
                  104 shares
                </Typography>
              </Stack>
            </CardActions>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default SocialMediaFbCards;
