import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./FacebookMobileViewStyle";
import fbcontainer from "../../../assets/images/postview/fb.png";
import profileImage from "../../../assets/images/circleInstaImg.png";
import earth from "../../../assets/images/earth.png";
import more from "../../../assets/images/more.png";
import cross from "../../../assets/images/close.png";
import post from "../../../assets/images/events-3.png";
import NextBtn from "../../Common/NextBtn";
import PreviousBtn from "../../Common/PreviousBtn";
import UpdateEventPopUp from "../../NewEvents/EventPopOver/UpdateEventPopUp";
import { useSelector } from "react-redux";

function FacebookMobileView({
  data,
  profile,
  showCustomHandleButton,
  functionOnModalClose,
}) {
  const itemsPerPage = 3;
  const [startIndex, setStartIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState();
  const [popUpData, setPopUpData] = useState();
  const [campaignData, setCampaignData] = useState([]);
  const visibleItems = data.slice(startIndex, startIndex + itemsPerPage);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const getCaption = (data) => {
    let caption = data?.filter((x) => x.platform == "facebook");
    console.log(caption?.[0]?.caption, "CAPTION");
    return caption?.[0]?.caption;
  };
  const handleNext = (items) => {
    console.log("CAMPAIGN DATAAAAAAAAA");
    if (startIndex + itemsPerPage < data.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  const handlePrevious = (items) => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    if (showCustomHandleButton) {
      setCampaignData(visibleItems);
    } else {
      setCampaignData(data);
    }
  }, [data, showCustomHandleButton, visibleItems]);
  return (
    <>
      {showCustomHandleButton && data?.length > 3 && (
        <PreviousBtn width={"50px"} handlePrevious={() => handlePrevious()} />
      )}
      {campaignData?.map((campaign, index) => {
        return (
          <React.Fragment key={index}>
            {open && (
              <UpdateEventPopUp
                platform={"facebook"}
                functionOnModalClose={functionOnModalClose}
                profile_id={profile_id}
                data={popUpData}
                tab={tab}
                open={open}
                handleClose={() => setOpen(false)}
              />
            )}
            <Box
              onClick={() => {
                !campaign?.is_posted && setOpen(true);
                setTab(campaign?.type);
                setPopUpData({
                  ...campaign,
                  desc:
                    campaign?.other_platforms?.[0]?.caption ||
                    campaign?.description,
                });
              }}
              sx={{
                ...style.mainWrapper,
                backgroundImage: `url(${fbcontainer})`,
                cursor: "pointer",
              }}
            >
              <div
                className="d-flex align-items-start"
                style={style.postHeading}
              >
                <img src={profileImage} style={style.postHeadingImage} />
                <div
                  className="d-flex align-items-start justify-content-between"
                  style={{ width: "87%" }}
                >
                  <div className="d-flex flex-column">
                    <Typography color={"#000"} fontWeight={"600"}>
                      {profile?.resturant_name}
                    </Typography>
                    <div className="d-flex align-items-center">
                      <span style={{ color: "grey", fontSize: "13px" }}>
                        1h .{" "}
                      </span>
                      <img src={earth} width={"13px"} />
                    </div>
                  </div>
                  <div className="d-flex mt-1">
                    <img
                      src={more}
                      width={"15px"}
                      style={{ marginRight: "20px" }}
                    />
                    <img
                      src={cross}
                      width={"15px"}
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                </div>
              </div>
              <Box
                style={{
                  ...style.post,
                  backgroundImage: `url(${campaign?.image_url})`,
                }}
              >
                {/* <Typography style={style.postHeadingText}>
                  {campaign?.title}
                </Typography> */}
              </Box>
            </Box>
          </React.Fragment>
        );
      })}
      {showCustomHandleButton && data?.length > 3 && (
        <NextBtn width={"50px"} handleNext={() => handleNext()} />
      )}
    </>
  );
}

export default FacebookMobileView;
