import React, { useEffect, useState } from "react";
import "./newdashboard.css";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import Tabdesign from "./Tabdesign/Tabdesign";
import TotalCardBox from "./Total/TotalBox";
import FirstTimeBox from "./FirstTimeViewers/FirstTimeBox";
import Total from "./Total/Total";
import FirstTimeViewers from "./FirstTimeViewers/FirstTimeViewers";
import { getallviewerAPI } from "../../apis/auth";
import firstTimeIcon from "../../assets/SVG/ph_eye-fill.svg";
import engagedIcon from "../../assets/SVG/ph_eye-fill2.svg";
import firstConsumersIcon from "../../assets/SVG/solar_user-bold.svg";
import dinersIcon from "../../assets/SVG/solar_dollar-bold.svg";
import Footer from "./Footer/Footer";

const Dashboard = () => {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [firstTimeViewer, setFirstTimeViewer] = useState();
  const [engagedViewer, setEngagedViewer] = useState();
  const [firsttimeconsumer, setFirsttimeconsumer] = useState();
  const [freqdiner, setFreqdiner] = useState();
  const [totalData, setTotalData] = useState();
  const [allinone, setAllinone] = useState({
    total: 0,
    firstviewer: 0,
    engagedviewer: 0,
    firsttimeconsumer: 0,
    freqdiner: 0,
  });
  const handleTab = (data) => {
    setIndex(data);
  };
  const initialRender = () => {
    setLoading(true);
    let payload = {
      profile_id: "6539fe4fa0eb1873290f9f15",
      campaign_id: "6539fee89933281b631a6ebd",
    };
    getallviewerAPI(payload)
      .then((res) => {
        if (res.status == "success") {
          setLoading(false);
          setTotalData(res.data.all);
          setEngagedViewer(res.data["engaged-viewers"]);
          setFirstTimeViewer(res.data["first-time-viewers"]);
          setFirsttimeconsumer(res.data["first-time-customers"]);
          setFreqdiner(res.data["freq-diners"]);
          setAllinone({
            total: res.data.all.all,
            firstviewer: res.data["first-time-viewers"]["first-time-viewers"],
            engagedviewer: res.data["engaged-viewers"]["engaged-viewers"],
            firsttimeconsumer:
              res.data["first-time-customers"]["first-time-customers"],
            freqdiner: res.data["freq-diners"]["freq-diners"],
          });
        }
      })
      .catch((rej) => {
        setLoading(false);
        console.log(rej);
      });
  };
  useEffect(() => {
    initialRender();
  }, []);
  return (
    <>
      <div className="container headerpadding">
        <Breadcrumb />
        <div className="row">
          <div className="col-md-12">
            <div className="card dashboard-card">
              <div className="card-container">
                <Tabdesign index={index} handleTab={handleTab} />
                {index == 0 && (
                  <TotalCardBox
                    total={allinone}
                    loading={loading}
                    totalData={totalData}
                    firstTimeViewer={firstTimeViewer}
                    engagedViewer={engagedViewer}
                    firsttimeconsumer={firsttimeconsumer}
                    freqdiner={freqdiner}
                  />
                )}
                {index == 1 && (
                  <FirstTimeBox
                    name={"First Time Viewers"}
                    loader={loading}
                    tag={"first-time-viewers"}
                    data={firstTimeViewer}
                    initialRender={initialRender}
                    icon={firstTimeIcon}
                    total={allinone}
                  />
                )}
                {index == 2 && (
                  <FirstTimeBox
                    name={"Engaged Viewers"}
                    loader={loading}
                    tag={"engaged-viewers"}
                    data={engagedViewer}
                    initialRender={initialRender}
                    icon={engagedIcon}
                    total={allinone}
                  />
                )}
                {index == 3 && (
                  <FirstTimeBox
                    name={"First Time Consumers"}
                    loader={loading}
                    tag={"first-time-customers"}
                    data={firsttimeconsumer}
                    initialRender={initialRender}
                    icon={firstConsumersIcon}
                    total={allinone}
                  />
                )}
                {index == 4 && (
                  <FirstTimeBox
                    name={"Freq Diners"}
                    loader={loading}
                    tag={"freq-diners"}
                    data={freqdiner}
                    initialRender={initialRender}
                    icon={dinersIcon}
                    total={allinone}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {index === 0 && <Total />}
            {(index === 1 || index === 2 || index === 3 || index === 4) && (
              <FirstTimeViewers />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
