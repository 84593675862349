import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import style from "./ViewMyMediaStyle";
import eyeIcon from "../../../assets/SVG/ph_eye-fill.svg";
import reloadIcon from "../../../assets/SVG/ph_eye-fill2.svg";
import consumersIcon from "../../../assets/SVG/solar_user-bold.svg";
import frequencyIcon from "../../../assets/SVG/solar_dollar-bold.svg";
import FirstTimeViewCard from "../../../components/Common/FirstTimeViewCard";
import img2 from "../../../assets/images/mediaInstaimg2.png";
import FirstTimeView from "../../../components/Viewers/FirstTimeView/FirstTimeView";
import EngagedView from "../../../components/Viewers/EngagedView/EngagedView";
import FirstTimeConsumers from "../../Viewers/FirstTimeConsumers/FirstTimeConsumers";
import FreqDiners from "../../Viewers/FreqDiners/FreqDiners"

const cardData = [
  {
    imgUrl: eyeIcon,
    title: "First Time Viewers",
    arrow: true,
  },
  {
    imgUrl: reloadIcon,
    title: "Engaged Viewers",
    arrow: true,
  },
  {
    imgUrl: consumersIcon,
    title: "First Time Consumers",
    arrow: true,
  },
  {
    imgUrl: frequencyIcon,
    title: "Freq Diners",
    arrow: false,
  },
];

const instaData = {
  imgUrl: img2,
  event: "Summer Season",
  offers: "$50 for 2",
};

const checkoutData = [
  {
    campaign: "Summer Season",
    price: 2,
  },
  {
    campaign: "Outdoor Dining",
    price: 10,
  },
  {
    campaign: "Farm to Table",
    price: 10,
  },
];

const ViewMyMedia = () => {
  const [loader, setLoader] = useState(false);
  const [viewers, setViewers] = useState("First Time Viewers");

  const handleViewers = (params) => {
    setViewers(params);
  };

  return (
    <>
      <Box sx={style.container}>
        <Grid sx={{ width: "50%" }} container spacing={2}>
          {cardData.map((data, index) => {
            return (
              <Grid
                key={index + 1}
                sx={{
                  ...style.cards,
                  backgroundColor: viewers === data.title ? "#111" : "#000",
                }}
                item
                xs={12}
                md={3}
              >
                <FirstTimeViewCard handleViewers={handleViewers} data={data} />
              </Grid>
            );
          })}
        </Grid>
        {viewers === "First Time Viewers" && <FirstTimeView />}
        {viewers === "Engaged Viewers" && <EngagedView />}
        {viewers === "First Time Consumers" && <FirstTimeConsumers />}
        {viewers === "Freq Diners" && <FreqDiners />}
      </Box>
    </>
  );
};

export default ViewMyMedia;
