import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Normal.css";
import profile from "../../../assets/images/circleInstaImg.png";
import style from "./NormalViewStyle";
import timelineImg from "../../../assets/images/fbTimeLine.png";
import dummypost from "../../../assets/images/create-calender/dummy-image.png";
import fbLike from "../../../assets/images/fbLike.png";
import instaLike from "../../../assets/images/instaLike.png";
import passwordEye from "../../../assets/SVG/Password_eye.svg";
import likeBtn from "../../../assets/SVG/like_fb_design.svg";
import commentBtn from "../../../assets/SVG/coment_fb_design.svg";
import avatar from "../../../assets/SVG/avatar.svg";
import SocialMediaHeader from "../../Common/SocialMediaHeader";
import Comments from "../components/Comments";
import { useSelector } from "react-redux";
import moment from "moment";
import rightArrow from "../../../assets/SVG/dropdown_arrow3.svg";
import leftArrow from "../../../assets/SVG/dropdown_arrow4.svg";
import loveicon from "../../../assets/images/create-calender/single-post-love.png";
import chaticon from "../../../assets/images/create-calender/single-post-chat.png";
import sendicon from "../../../assets/images/create-calender/single-post-send.png";
import bookmark from "../../../assets/images/create-calender/bookmark.png";

const commentData = [
  {
    user_name: "John Doe",
    avatar: avatar,
    multiComment: true,
  },
  {
    user_name: "Darrell Steward",
    avatar: avatar,
    multiComment: false,
  },
];

const text =
  "Generating random paragraphs can be a excellent way for writers Generating random paragraphs can be a excellent way for writers Generating rando";

const FacebookView = ({
  isActive,
  postdetails,
  addreply,
  handleprevious,
  handlenext,
  currentIndex,
  maxlenght,
  likeanddislikefunc,
  reviewpostComment,
}) => {
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const currentdate = moment(postdetails?.date).format("DD MMM");
  return (
    <div className="post-data">
      <div className="header-design">
        <div className="image">
          <img
            src={
              profile_info?.website
                ? `https://s2.googleusercontent.com/s2/favicons?domain=${profile_info?.website}`
                : profile
            }
            alt="profileImg"
            className="profile-icon"
          />
        </div>
        <div className="post-owner-name">
          <p className="name">
            {profile_info?.resturant_name || "Restaurant Name"}
          </p>
          <p className="time"> {currentdate}</p>
        </div>
      </div>
      <div className="post-description">
        {postdetails?.prompt_output_text
          ? postdetails?.prompt_output_text.replace(/\n/g, "")
          : text}
      </div>
      <div className="post-image">
        <img
          src={postdetails?.prompt_output_img_url || dummypost}
          className="w-100"
        />
        {currentIndex > 0 && (
          <img
            src={rightArrow}
            onClick={handleprevious}
            className="rightarrow"
          />
        )}
        {currentIndex < maxlenght && (
          <img src={leftArrow} onClick={handlenext} className="leftarrow" />
        )}
      </div>
      {postdetails ? (
        <>
          {postdetails?.insta_post_id ? (
            <>
              <div className="instagram-container d-flex justify-content-between">
                <div className="like-area">
                  <img src={loveicon} />
                  <img src={chaticon} />
                  <img src={sendicon} />
                </div>
                <div className="bookmark-area">
                  <img src={bookmark} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                className="py-2"
              >
                <Stack direction="row" sx={{ position: "relative", zIndex: 1 }}>
                  <img src={fbLike} alt="Error" />
                  <img
                    style={{ position: "absolute", left: "30%", zIndex: -1 }}
                    src={instaLike}
                    alt="Error"
                  />
                  <Typography sx={{ ...style.likeBtnText, paddingLeft: "50%" }}>
                    {postdetails?.matrics?.post_total_reactions}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <img src={passwordEye} alt="Error" />
                  <Typography sx={style.viewsTextStyle}>
                    {postdetails?.matrics?.post_unique_impressions}
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </>
      ) : (
        <>
          {isActive == "insta" ? (
            <>
              <div className="instagram-container d-flex justify-content-between">
                <div className="like-area">
                  <img src={loveicon} />
                  <img src={chaticon} />
                  <img src={sendicon} />
                </div>
                <div className="bookmark-area">
                  <img src={bookmark} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                className="py-2"
              >
                <Stack direction="row" sx={{ position: "relative", zIndex: 1 }}>
                  <img src={fbLike} alt="Error" />
                  <img
                    style={{ position: "absolute", left: "30%", zIndex: -1 }}
                    src={instaLike}
                    alt="Error"
                  />
                  <Typography sx={{ ...style.likeBtnText, paddingLeft: "50%" }}>
                    {postdetails?.matrics?.post_total_reactions}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <img src={passwordEye} alt="Error" />
                  <Typography sx={style.viewsTextStyle}>
                    {postdetails?.matrics?.post_unique_impressions}
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </>
      )}

      <Divider sx={{ backgroundColor: "#FCFCFC" }} />

      <Stack direction="row" justifyContent="space-evenly" className="py-2">
        <Stack direction="row" spacing={2}>
          <img width="24px" height="24px" src={likeBtn} alt="likeBtn" />
          <Typography sx={style.likeBtnText}>Like</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <img width="24px" height="24px" src={commentBtn} alt="likeBtn" />
          <Typography sx={style.likeBtnText}>Comment</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ backgroundColor: "#FCFCFC" }} />
      <Box
        className="scroll-design"
        sx={{
          maxHeight: `${200}px`,
          overflow: "scroll",
        }}
      >
        {reviewpostComment.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <Comments
                data={data}
                addreply={addreply}
                likeanddislikefunc={likeanddislikefunc}
              />
            </React.Fragment>
          );
        })}
      </Box>
    </div>
  );
};

export default FacebookView;
