import React, { useEffect, useState } from "react";
import "./../signupstyle.css";
import { SignIn } from "@clerk/clerk-react";

const Newlogin = () => {
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("?after_sign_up_url")) {
      const updatedUrl = currentUrl.split("#/")[0].split("?")[0];
      window.history.replaceState(null, null, updatedUrl);
    }
  }, []);
  return (
    <>
      <div className="full-width headerpadding loging signup-page">
        <div className="content-padding-both ">
          <div className="py-5">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title-wrap">
                  <div className="">
                    <h1 className="title extra-font">
                      Welcome <br />
                      back
                    </h1>
                    <p>The next chapter starts here</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                      <SignIn
                        path="/login"
                        signUpUrl="/signup"
                        afterSignInUrl={"/redirecting"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newlogin;
