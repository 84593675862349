import moment from "moment";
import { fetchProfileAPI } from "../apis/auth";

export function handleAddressglobal(place) {
  const { address_components, formatted_address } = place;
  let country = "";
  let state = "";
  let city = "";
  let zipCode = "";
  let addressLine1 = "";
  let addressLine2 = "";
  for (const component of address_components) {
    const componentType = component.types[0];

    if (componentType === "country") {
      country = component.short_name || component.long_name;
    } else if (componentType === "administrative_area_level_1") {
      state = component.long_name;
    } else if (
      componentType === "administrative_area_level_3" ||
      componentType === "locality"
    ) {
      city = component.long_name;
    } else if (componentType === "postal_code") {
      zipCode = component.long_name;
    } else if (componentType === "street_number") {
      addressLine1 = `${component.long_name} ${addressLine1}`;
    } else if (componentType === "route") {
      addressLine1 += component.long_name;
    } else if (componentType === "sublocality_level_1") {
      addressLine2 = component.long_name;
    }
  }

  const addressObj = {
    country,
    state,
    city,
    zipCode,
    addressLine1,
    addressLine2,
  };
  return addressObj;
}

// export function checkObjectMatch(obj1, obj2) {
// 	for (const key in obj1) {
// 		if (obj1.hasOwnProperty(key)) {
// 			if (obj1[key] !== obj2[key]) {
// 				return false;
// 			}
// 		}
// 	}
// 	return true

// }

export function convertData(data, uvKey) {
  return data.map((entry) => {
    const [month, day] = Object.entries(entry)[0];

    const monthMapping = {
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dec",
    };

    const abbreviatedMonth = monthMapping[month];
    const uvValue = entry[month];
    return { name: abbreviatedMonth, [uvKey]: uvValue };
  });
}
export function mergeData(propertyDefaults, ...dataSets) {
  const mergedData = {};

  dataSets.forEach((dataSet) => {
    dataSet.forEach((item) => {
      const { name, ...rest } = item;

      if (!mergedData[name]) {
        mergedData[name] = {
          name,
          ...propertyDefaults,
        };
      }
      Object.entries(rest).forEach(([key, value]) => {
        mergedData[name][key] = value !== undefined ? value : "";
      });
    });
  });

  return Object.values(mergedData);
}
export function generateYTicks(mergedData, maxTicks = 8) {
  const values = mergedData.reduce((acc, item) => {
    Object.keys(item).forEach((key) => {
      if (key !== "name") {
        acc.push(item[key]);
      }
    });
    return acc;
  }, []);

  const maxValue = Math.max(...values);
  const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)));

  const scales = [1, 2, 5, 10];
  let tickInterval;

  for (let scale of scales) {
    tickInterval = magnitude * scale;
    const tickCount = maxValue / tickInterval + 1;

    if (tickCount <= maxTicks) {
      break;
    }
  }

  return Array.from({ length: maxTicks }, (_, index) => index * tickInterval);
}
export async function fetchprofile(profile_id) {
  const currentdate = moment().format("YYYY-MM-DD");
  let profileData = "";
  let dispachabledata = "";

  try {
    const res = await fetchProfileAPI(profile_id);
    if (res.status) {
      const subscribe = res?.user_profile?.is_trial
        ? "free"
        : res?.user_profile?.subscib_end_date
        ? moment(res?.user_profile?.subscib_end_date).format("YYYY-MM-DD") >
          currentdate
          ? "paid"
          : "expire"
        : "start";

      const profile_data = {
        profile_id: res?.user_profile?.profile_id,
        resturant_name: res?.user_profile?.resturant_name,
        website: res?.user_profile?.resturant_website,
      };

      const payload = {
        id: res?.user_profile?.user_id,
        subscribe_status: subscribe,
        ...res?.user_profile,
      };
      profileData = res?.user_profile;
      dispachabledata = {
        profile_data: profile_data,
        users: payload,
      };
    }
  } catch (error) {
    console.error(error);
  }

  return { profileData, dispachabledata };
}

export async function eventnonefunc(status) {
  if (status) {
    document.getElementById("root").classList.add("event-none");
  } else {
    document.getElementById("root").classList.remove("event-none");
  }
}

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
