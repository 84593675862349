import React, { useEffect } from "react";
import "./paymentstyle.css";
import firsttimeicon from "../../assets/images/insight/firsttimeviewer.png";
import engageditem from "../../assets/images/insight/engagedviewer.png";
import firsttimecunsumer from "../../assets/images/insight/new-customer.png";
import freqdinner from "../../assets/images/insight/freq-dinner.png";
import { boostspaymentAPI } from "../../apis/auth";
import { useSelector } from "react-redux";
import { eventnonefunc } from "../../CustomHook/addressUtills";

const PaymentSummary = () => {
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const user_id = useSelector((state) => state.main.store?.users?.id);
  const payment_data = useSelector(
    (state) => state.main.store?.payment_summary
  );
  const paymentfunc = () => {
    eventnonefunc(true);
    let payload = {
      user_id: user_id,
      profile_id: profile_id,
      campagin_id: payment_data?.camp_id,
      first_time_viewers: payment_data?.firsttimeviewer,
      engaged_viewers: payment_data?.engagedViewer,
      first_time_customers: payment_data?.firsttimeconsumer,
      freq_diners: payment_data?.freqdiner,
      is_boost: payment_data?.type == "publish" ? false : true,
    };
    boostspaymentAPI(payload)
      .then((res) => {
        eventnonefunc(false);
        if (res.url) {
          window.location.href = res.url;
        }
      })
      .catch((rej) => {
        eventnonefunc(false);
        console.log(rej);
      });
  };
  return (
    <div className="full-with headerpadding payment-summary">
      <div className="row py-5">
        <div className="col-md-12 py-5">
          <div className="content-padding-both">
            <div className="d-flex justify-content-center">
              <div className="custom-card-design">
                <h1 className="white-subtitle payment-summary-title">
                  Payment Summary
                </h1>
                {payment_data?.firsttimevieweramount && (
                  <div className="payment-list-container">
                    <div className="name">
                      <img src={firsttimeicon} alt="" /> First Time Viewers
                    </div>
                    <div className="amount">
                      <p>Price</p>
                      <span>${payment_data?.firsttimevieweramount}</span>
                    </div>
                  </div>
                )}
                {payment_data?.engagedVieweramount && (
                  <div className="payment-list-container">
                    <div className="name">
                      <img src={engageditem} alt="" /> Engaged Viewers
                    </div>
                    <div className="amount">
                      <p>Price</p>
                      <span>${payment_data?.engagedVieweramount}</span>
                    </div>
                  </div>
                )}
                {payment_data?.firsttimeconsumeramount && (
                  <div className="payment-list-container">
                    <div className="name">
                      <img src={firsttimecunsumer} alt="" /> First Time
                      Consumers
                    </div>
                    <div className="amount">
                      <p>Price</p>
                      <span>${payment_data?.firsttimeconsumeramount}</span>
                    </div>
                  </div>
                )}
                {payment_data?.freqdineramount && (
                  <div className="payment-list-container">
                    <div className="name">
                      <img src={freqdinner} alt="" /> Freq Diners
                    </div>
                    <div className="amount">
                      <p>Price</p>
                      <span>${payment_data?.freqdineramount}</span>
                    </div>
                  </div>
                )}
                <div className="payment-list-container">
                  <div className="amount">
                    <span>Total</span>
                  </div>
                  <div className="amount">
                    <span>${payment_data?.totalamount}</span>
                  </div>
                </div>
                <div className="payment-list-container">
                  <button
                    onClick={paymentfunc}
                    className="byteready-btn  byteready-primary"
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
