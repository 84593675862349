import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./EventsImagesStyle";
import displayImage from "../../../assets/images/IpadDisplayImage.png";
import ipadFrame from "../../../assets/images/IpadImage.png";
import eventImage from "../../../assets/images/events-3.png";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { useCompaignData } from "../../../Context/CampaignContext";
import Loadericon from "../../Common/Loadericon";
import { useDispatch, useSelector } from "react-redux";
import { createCampaignAPI, updateCampaignAPI } from "../../../apis/auth";
import ImageCards from "../../Common/ImageCards";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import DashboardLoader from "../../Common/DashboardLoader";
import { useNavigate } from "react-router-dom";

const EventsImageNew = ({ eventpage, tab, pageTitle, handletabchange }) => {
  const navigate = useNavigate();
  const [compaignData, setCompaignData] = useCompaignData();
  const campaignUnit = useSelector((state) => state?.main?.store?.campaignUnit);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [hasEvents, setHasEvents] = useState(false);
  const [hasOffers, setHasOffers] = useState(false);
  const [hasRewards, setHasRewards] = useState(false);

  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const eventTitleID = useSelector(
    (state) => state.main.store?.eventTitle?.custom_themeid
  );
  useEffect(() => {
    setCompaignData(campaignUnit);
  }, [campaignUnit]);
  const campaignId = useSelector((state) => state?.main?.store?.campaignId);
  // console.log(campaignId, "CAMPAIGN ID");
  const campaignstoreData = useSelector(
    (state) => state.main.store?.campaignsList
  );
  const campaignStoreUnit = useSelector(
    (state) => state.main.store.campaignUnit
  );
  const firstName = eventpage ? eventpage.firstName.split(" ") : "";
  useEffect(() => {
    let _data = campaignUnit?.filter((element, i) => i < 3);
    setData(_data);
    if (campaignUnit) {
      for (const unit of campaignUnit) {
        if (unit.tab === "offers") {
          setHasOffers(true);
        } else if (unit.tab === "events") {
          setHasEvents(true);
        } else if (unit.tab === "rewards") {
          setHasRewards(true);
        }
      }
    }
  }, [campaignUnit]);
  const handleCampaignSave = () => {
    setLoading(true);
    let unitId = campaignUnit.map((item) => item.id);
    let data = {
      profile_id: profile_id,
      name: pageTitle,
      add_unit_ids: unitId,
      custom_theme_id: eventTitleID,
    };
    createCampaignAPI(data)
      .then((res) => {
        if (res.status == "success") {
          let data = {
            id: res.campaign.id,
            name: res.campaign.name,
          };
          let ss = [];
          if (campaignstoreData?.length > 0) {
            ss = [...campaignstoreData, data];
          } else {
            ss = [data];
          }

          setLoading(false);
          dispatch(storeInLocalStore({ campaignId: res.campaign.id }));
          handletabchange(3);
          dispatch(storeInLocalStore({ campaignsList: ss }));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
        setLoading(false);
        if (err?.response.status == 422) {
          navigate("/create-calender");
          dispatch(storeInLocalStore({ currentsubmenu: "createmysmcalender" }));
          dispatch(storeInLocalStore({ currentcomponentid: 3 }));
        }
      });
  };
  const handlePrevious = () => {
    if (index > 0 && campaignUnit.length > 3) {
      let _index = index - 1;
      let filterData = campaignUnit?.filter(
        (element, i) => i >= _index && i <= _index + 2
      );
      setData(filterData);
      setIndex(_index);
    }
  };
  const handleNext = () => {
    if (index < campaignUnit.length - 3 && campaignUnit.length > 3) {
      let newIndex = index + 1;
      let filterData = campaignUnit?.filter(
        (element, i) => i >= newIndex && i < newIndex + 3
      );
      setData(filterData);
      setIndex(newIndex);
    }
  };

  return (
    <>
      <div className="customcontainerglobal">
        <Box sx={style.mainContainer}>
          {loading && <Loadericon />}
          <Box
            sx={{
              ...style.circleContainer,
              backgroundImage: `url(${eventpage?.img}) !important`,
            }}
          ></Box>
          <Box sx={style.ipadBox}>
            <Box
              sx={{ ...style.ipadFrame, backgroundImage: `url(${ipadFrame})` }}
            >
              <Box
                sx={{
                  ...style.imgStyle,
                  backgroundImage: `url(${eventpage?.img}) !important`,
                }}
              ></Box>
              <Box
                sx={{
                  display: "block",
                  position: "absolute",
                  width: "100%",
                  top: "360px",
                  left: "0px",
                }}
              >
                {campaignUnit && (
                  <ImageCards
                    data={data}
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                  />
                )}
              </Box>
              {hasEvents && hasOffers && hasRewards && (
                <CustomButton
                  sx={{
                    width: "30%",
                    fontFamily: "SF Pro Display",
                    fontSize: "16px",
                    fontWeight: "900",
                    position: "absolute",
                    textWrap: "nowrap",
                  }}
                  label="Save the Campaign"
                  // onClick={() => { campaignId ? handleCampaignUpdate() : handleCampaignSave() }}
                  onClick={() => handleCampaignSave()}
                />
              )}
            </Box>
            <Typography sx={style.textCover}>
              {firstName.length > 0 ? (
                <>
                  {firstName[0]} <br /> {firstName.slice(1).join(" ")}
                </>
              ) : (
                <>
                  SUMMER <br /> Season
                </>
              )}
            </Typography>
            <Typography sx={style.textContent}>
              {eventpage?.content?.length > 0 ? (
                <>{eventpage?.content}</>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default EventsImageNew;
