const style = {
  color: "#FFF",
  fontFamily: "SFProText-Regular",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

export default style;
