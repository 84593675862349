import React, { useState, useEffect } from "react";
import Headerblack from "./partial/Headerblack";
import Headerwhite from "./partial/Headerwhite";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import menus from "../menu";

const Header = ({ pathName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.main?.store?.users);
  const profile_name = useSelector(
    (state) => state.main?.store?.profile_data?.resturant_name
  );

  const handlemenu = (submenu) => {
    navigate(submenu.link);
    dispatch(storeInLocalStore({ currentsubmenu: submenu.key }));
  };
  return (
    <>
      {pathName == "/" ? (
        <>
          <Headerwhite
            menus={menus}
            user={user}
            profile_name={profile_name}
            handlemenu={handlemenu}
          />
        </>
      ) : (
        <Headerblack
          menus={menus}
          pathName={pathName}
          user={user}
          profile_name={profile_name}
          handlemenu={handlemenu}
        />
      )}
    </>
  );
};

export default Header;
