import { eventnonefunc } from "../../CustomHook/addressUtills.js";
import ALL_API_CONST from "../constants.jsx";
import axios from "axios";

const AUTH_API = ALL_API_CONST.auth;
const token = {
  headers: {
    Authorization: `Bearer ${
      localStorage.getItem("token") ? localStorage.getItem("token") : ""
    }`,
  },
};
const handleApiCall = async (apiCall) => {
  // const dispatch = useDispatch();
  eventnonefunc(true);
  try {
    const response = await apiCall();
    eventnonefunc(false);
    return response.data;
  } catch (error) {
    eventnonefunc(false);
    console.log(error?.response?.data?.detail);
    if (error?.response?.data?.detail == "Not enough privileges") {
      document.getElementById("showalert").click();
    }
    throw error;
  }
};

export const loginAPI = () => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.login + `?sync_uid=true`,
      {},
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};

export const registerUserAPI = (data) => {
  return handleApiCall(() => axios.post(AUTH_API.register, data));
};
export const otpverifyAPI = (id, data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.otpverify + (id ? `?verify_for=forgot_pass` : ``), data)
  );
};
export const cuisineAPI = () => {
  return handleApiCall(() =>
    axios.get(AUTH_API.cuisine, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};

export const createProfileAPI = (data, sync) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.createProfile + `?sync_uid=${sync ? sync : false}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const getCaptionsAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.captions, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const updateTextAPI = (data) => {
  return handleApiCall(() => axios.post(AUTH_API.update, data));
};
export const getAllCaptionpost = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.getallcaption, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const uploadDishAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.uploadDish, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "multipart/form-data",
      },
    })
  );
};
export const addDishAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.addDish, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const addtempPost = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.tempPostdate, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const getDishAPI = (id) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.getDishes + `${id}`, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const imageUpload = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.imageupload + `${data.id}&date=${data.date}`,
      { image: data.image },
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
};
export const updateResturantDataAPI = (data) => {
  return handleApiCall(() =>
    axios.put(AUTH_API.updateResturant, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const updateCaptionAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.updateCaption, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const signUpAPI = (data) => {
  return handleApiCall(() => axios.post(AUTH_API.signUp, data));
};
export const getmainTemplateAPI = () => {
  return handleApiCall(() =>
    axios.get(AUTH_API.homepagemaintemplate, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const geteventTemplatebyidAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.childeventtemplate + data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const saveSinglecompainAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.savesinglecompain +
        `update=${data?.urlPayload?.update}&adunit_id=${data?.urlPayload?.unitid}`,
      data?.bodyPayload,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const getallsavedunitAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.getsavedunit + data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const unitImageuploadAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.unitimageupload +
        `profile_id=${data.profileid}&addunitId=${data.unitid}`,
      { image: data.image },
      {
        ...token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
};
export const createCampaignAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.createcampaign, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const updateCampaignAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.updatecampaign +
        `profile_id=${data.payload_url.profile_id}&camp_id=${data.payload_url.camp_id}`,
      data.payload_data,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const getallcampaignAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getallcampaign +
        `profile_id=${data.profile_id}&with_addunit=${data?.unit_id || 0}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getcampaignbyidAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getsavedcampbyid +
        `?profile_id=${data.profile_id}&camp_id=${data.campaign_id}&with_addunit=1`,
      token
    )
  );
};
export const updatesingleunitAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.updatesingleunit, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const generateUnitAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.generateunitimage +
        `profile_id=${data.profile_id}&addunitId=${data.unitid}&response_type=${data.type}`,
      token
    )
  );
};
export const getChildTemplate = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.childeventtemplate + `&child_temp_id=${data}`, token)
  );
};
export const getcampaignbyEventnameAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getsavedcampbyid +
        `?profile_id=${data.profile_id}&camp_id=${data.campaign_id}&with_addunit=1&event_type=${data.eventtype}`,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const updatepostbysocialnameAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.updatesocialmediabyname +
        `profile_id=${data.profile_id}&addunitId=${data.addunit_id}&platform=${data.platform}&with_ai=False`,
      data.payload,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const generateplatformAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.generateplatformDescription +
        `profile_id=${data.profile_id}&addunitId=${data.unitid}&platform=${data.platform}`,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const deleteunitAPI = (data) => {
  return handleApiCall(() =>
    axios.delete(
      AUTH_API.deleteaddunit +
        `profile_id=${data.profile_id}&camp_id=${data.camp_id}&addunitId=${data.unitid}`,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const uservaification = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.varificationurl +
        `?profile_id=${data?.profile_id}&token=${data?.token}`
    )
  );
};
export const confirmcalenderAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.confirmcalender +
        `?profile_id=${data.profile_id}&camp_id=${data.campaign_id}`,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
        },
      }
    )
  );
};
export const firsttimeViewerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.firsttimeviewerurl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getengagedViewerApi = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.engagedviewerurl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const setTargetAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.targetsettingurl, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const getallviewerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.totalurl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getallconsumerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.consumerviewerurl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getAllfreqdinerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.freqdinerurl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const publishcampaignAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.campaignpublish + `?platform=${data.platform}`, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const getmaxViewerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.maxviewersurl + `profile_id=${data.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const getAllNotificationAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getallnotificationurl +
        `?profile_id=${data.profile_id}&is_all=${data?.isall}&platform=${
          data.platform
        }&is_fetch=1&pg=${data?.page || 1}${
          data.isall ? "" : `&caption_id=${data?.caption_id}`
        }`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getdetailedpostAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getdetailsposturl +
        `?profile_id=${data.profile_id}&caption_id=${data.caption_id}&platform=${data?.platform}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const commentreplyAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.replycommneturl, data, {
      headers: {
        data: {},
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const commentLikeAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.commentlikeAPI +
        `?profile_id=${data.profile_id}&caption_id=${data.caption_id}&msgid=${data.msgid}&like=${data.like}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const commentReadAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.commentReadurl +
        `?profile_id=${data.profile_id}&caption_id=${data.caption_id}&msgid=${data.msgid}&is_read=1&platform=${data?.platform}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getspendInfoAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.getspendsinfo + `?profile_id=${data.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const getspendhistoryAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.getspendhistoryurl + `?profile_id=${data.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const getfirsttimeviewergraphAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfirsttimeviewergraphurl + `?profile_id=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getengagementviewergraphAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getengagedviewergraphurl + `?profile_id=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfirsttimeconsumergraphAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfirsttimeconsumergraphurl + `?profile_id=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfreqdinnerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.getfreqdinerurl + `?profile_id=${data.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const getallviewerchartAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getallviewerdatachart +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getengagedviewerchartAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getengagedviewerchart +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfirsttimeconsumerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfirsttimeconsumerurl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfirsttimeviewerchartAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfirsttimeviewerchart +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfreqdinnerchartAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfreqdinnercharturl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getallrevenuechartAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getallrevenuecharturl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getcustomerdetailsAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getcustomerdetailsurl +
        `?profile_id=${data.profile_id}&customer_id=${data.customer_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfirstimeviewerspendrevenueAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfirstimeviewerspendrevenueurl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getengagedviewerrevenueAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getengagedviewerrevenueurl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfirsttimeconsumerrevenueAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfirsttimeconsumerrevenueurl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfreqdinerrevenueAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getfreqdinerrevenueurl +
        `?granularity=${data.granularity}&filter[start_at]=${data.start_date}&filter[end_at]=${data.end_date}&filter[profile_id]=${data.profile_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getfirsttimeViewerinfoAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.firstviewerinfourl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const engagedviewerinfoAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.engagedviewerinfourl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const firsttimeconsumerAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.firsttimeconsumerurl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const freqdinnerinfoAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.freqdinnerinfourl +
        `?profile_id=${data.profile_id}&campaign_id=${data.campaign_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const confirmsmpostcalenderAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.confirmsmpostcalendar, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const postagreeAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.postagree, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const authtokenforsmfbAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.authtokenforfbsm, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const scribeanalyticsAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.scribeanalyticsurl + `?profile_id=${data?.profile_id}`, {
      data: {},
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const scribeanalyticsChartInfo = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.scribeanalyticscharturl +
        `?profile_id=${data?.profile_id}&granularity=${data?.granularity}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const scribecardinfourlAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.scribecardinfourl + `?profile_id=${data?.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const deletesocialmediaPostAPI = (data) => {
  return handleApiCall(() =>
    axios.delete(AUTH_API.deletesocialmediapost, {
      headers: {
        data: {},
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
      data: {
        profile_id: data?.profile_id,
        caption_id: data?.caption_id,
      },
    })
  );
};
export const fetchProfileAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.fetchprofileurl, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const sendOtpAPI = (data) => {
  return handleApiCall(() => axios.post(AUTH_API.sendopturl, data));
};
export const forgetpasswordAPI = (currenttokenn, data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.forgetpasswordurl, data, {
      headers: {
        Authorization: `Bearer ${currenttokenn}`,
      },
    })
  );
};
export const createCustomThemeAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.createCustomTheme, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const saveCustomThemeAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.saveCustomTheme, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const uploadImageCustomThemeAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.uploadImageCustomTheme +
        `profile_id=${data.profile_id}&theme_id=${data.theme_id}`,
      { image: data.image },
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
};
export const uploadImageAddUnitAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.uploadimageaddunit +
        `profile_id=${data.profile_id}&addunitId=${data.addunitId}`,
      { image: data.image },
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
};
export const createAddUnitAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.createAddunit, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const updateAddUnitAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.updateAddunit, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const getCampaignDataAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getcampaignbyevent +
        `profile_id=${data.profile_id}&camp_id=${data.camp_id}&with_addunit=1`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getlastfivedataAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.lastfivedataurl + `?profile_id=${data?.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const changeuserpasswordAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.changepasswordurl, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const confirmcampaignAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.confirmcampaign +
        `profile_id=${data.profile_id}&camp_id=${data.camp_id}&platform=all&is_trial=${data?.is_trial}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const yelpsettingAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.yelpsettingurl, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const subscribepaymentAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.subscriptionpaymentapiURL, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const manageprofileAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.managesubscriptioUrl, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const settagetpriceAPI = (data, is_all) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.settargeturl + `${is_all}`, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const boostspaymentAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.boostpaymentUrl, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
      },
    })
  );
};
export const clerkuserfetch = () => {
  return handleApiCall(() =>
    axios.get("https://wealthy-lobster-85.clerk.accounts.dev/v1/me")
  );
};
export const getSavedthemeAPI = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.getsavedtheme +
        `?profile_id=${data?.profile_id}&theme_id=${data?.theme_id}&is_all=${data?.is_all}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};
export const getpaymentHistory = (data) => {
  return handleApiCall(() =>
    axios.get(
      AUTH_API.paymenthistory +
        `?profile_id=${data?.profile_id}&user_id=${data?.user_id}`,
      {
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};

// Add Multiple Location
export const addlocationscsvAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.locationsaddcsv, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "multipart/form-data",
      },
    })
  );
};
export const getlocationsdataAPI = (data) => {
  return handleApiCall(() =>
    axios.get(AUTH_API.locationsdata + `?profile_id=${data?.profile_id}`, {
      data: {},
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const addlocationsAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.locationsadd, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};
export const thoughtspottokenAPI = (data) => {
  return handleApiCall(() =>
    axios.post(AUTH_API.thoughtspottoken, data, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") ? localStorage.getItem("token") : ""
        }`,
        "Content-Type": "application/json",
      },
    })
  );
};

export const createthemebyuserAPI = (data) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.createcustomthemeURL,
      data,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "application/json",
        },
      }
    )
  );
};

export const usercustomthemeImageUploadAPI = (
  profile_id,
  template_id,
  data
) => {
  return handleApiCall(() =>
    axios.post(
      AUTH_API.usercustomthemeimgupload +
        `?profile_id=${profile_id}&template_id=${template_id}&ismain=1&image_type=image_url_for_card`,
      data,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") ? localStorage.getItem("token") : ""
          }`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
};
