const style = {
  container: {
    position: "absolute",
    top: "57%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "#0B0B0B",
    padding: "10px",
    border: "0px",
    outline: "none",
    borderRadius: "20px",
    paddingBottom: "30px",
    height: "500px",
    overflow: "scroll",
  },
  mainContent: {
    padding: "40px 20px",
  },
  imgContainer: {
    width: "100%",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
    borderRadius: "10px",
    margin: "auto",
  },
  orangeButtonText: {
    color: "#eb2426",
    fontSize: "12px",
    textWrap: "noWrap",
    textTransform: "none",
   
  },
  blackEventPopUpBox: {
    background: "#181818",
    borderRadius: "20px",
    padding: "16px",
  },
  orangeEventPopUpBox: {
    background: "#eb2426",
    borderRadius: "20px",
    padding: "16px",
    position: "relative",
  },
  boxHeading: {
    fontFamily: "helvetica-font",
    fontSize: "14px",
    
  },
  boxContent: {
    fontFamily: "helvetica-font",
    fontSize: "14px",
   
    textWrap: "wrap",
  },
  btnBox: {
    width: "40px",
    height: "40px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    margin: "10px 10px 10px 0px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: "10px",
    border: "none",
    outline: "none",
  },
  borderButton: {
    borderRadius: "30px",
    padding: "0px 32px",
    border: "1px solid #eb2426",
    color: "white",
    marginTop: "10px",
  },
  transparentButton: {
    background: "transparent",
    border: "none",
    outline: "none",
  },
  pseudoInput: {
    background: "#000",
    borderRadius: "50px",
    border: "1px solid #8e8e8e",
  },
  popUpInput: {
    border: "none",
    background: "transparent",
    width: "90%",
    outline: "none",
    fontSize: "16px",
  },
  pseudoPopUpInput: {
    border: "none",
    background: "transparent",
    width: "100%",
    color: "#fff",
    padding: "0px",
    resize: "none",
    fontSize: "16px",
  },
  aiLogo: {
    position: "absolute",
    bottom: "-15px",
    right: "-20px",
  },
  headingPopup: {
    fontFamily: "helvetica-font",
    fontSize: "20px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "center",
  },
};

export default style;
