import React, { useEffect, useState } from "react";
import "./paymentstyle.css";
import macimage from "../../assets/images/Payment/mac-payment.png";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { publishcampaignAPI } from "../../apis/auth";
import Loadericon from "../../components/Common/Loadericon";

import moment from "moment";
import GlobalAlert from "../../components/Common/GlobalAlert";
const Bootscongratulation = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const startDate = moment().format("YYYY-MM-DD");
  const endData = moment(startDate).add(7, "days").format("YYYY-MM-DD");
  const txid = queryParameters.get("tx_id");
  const [loginmessages, setLogingMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const user_id = useSelector((state) => state.main.store?.users?.id);
  const payment_data = useSelector(
    (state) => state.main.store?.payment_summary
  );
  const initialrender = () => {
    if (txid) {
      setLoader(true);
      let payload = {
        profile_id: profile_id,
        camp_id: payment_data?.camp_id,
        amount: parseFloat(payment_data?.totalamount),
        // start_date: startDate,
        // end_date: endData,
        platform: "all",
        tx_id: txid,
      };
      console.log(payload);
      publishcampaignAPI(payload)
        .then((res) => {
          setLoader(false);
          if (res.status == "success") {
            setLogingMessage(
              "campagin has been posted on all the connected channels."
            );
          } else {
            setLogingMessage(res.message);
          }
        })
        .catch((res) => {
          console.log(res);
          setLogingMessage("Something went wrong. ");
          setLoader(false);
        });
    }
  };
  useEffect(() => {
    if (payment_data?.type == "publish") {
      initialrender();
    }
  }, []);
  const handlepoup = () => [setLogingMessage("")];
  return (
    <>
      <div className="full-width headerpadding boost-congratulation ">
        {loader && <Loadericon />}
        <div className="content-padding-both py-5">
          <div className="row py-5">
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <div className="card custom-card">
                  <div className="image-container">
                    <img src={macimage} className="w-100" />
                  </div>
                  <h3 className="congratulation-text">Congratulations!</h3>
                  <p className="congratulation-message">
                    Your Campaign is now boosted! Wait for millions of followers
                    from all over the world.
                  </p>
                  <div className="image-container">
                    <Link to={"/"} className="byteready-btn byteready-primary">
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loginmessages && (
        <GlobalAlert message={loginmessages} handleClose={handlepoup} />
      )}
    </>
  );
};

export default Bootscongratulation;
