import React, { useEffect, useState } from "react";
import {
  init,
  LiveboardEmbed,
  EmbedEvent,
  AuthType,
} from "@thoughtspot/visual-embed-sdk";
import { useDispatch, useSelector } from "react-redux";
import { thoughtspottokenAPI } from "../../apis/auth";
import Loadericon from "../Common/Loadericon";

const Thoughtspotcomponent = ({
  thoughtspotData,
  setThoughtspot,
  liveboardId,
  handleevent,
  saveToLocalStorage,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);
  const profile_id = useSelector(
    (state) => state.main.store.profile_data?.profile_id
  );
  const user_id = useSelector((state) => state.main.store?.users?.id);

  useEffect(() => {
    let isMounted = true;

    init({
      thoughtSpotHost: "https://byteready.thoughtspot.cloud",
      authType: AuthType.TrustedAuthTokenCookieless,
      getAuthToken: () => {
        return thoughtspottokenAPI({
          profile_id: profile_id,
          user_id: user_id,
        }).then((data) => data.token);
      },
      disableTokenVerification: true,
      customizations: {
        style: {
          customCSS: {
            variables: {
              "--ts-var-root-background": "transparent",
              "--ts-var-root-font-family": "helvetica",
              "--ts-var-button--primary--font-family": "helvetica",
              "--ts-var-root-font-family": "helvetica",
            },
          },
        },
      },
    });

    const embed = new LiveboardEmbed("#liveboard", {
      liveboardId: liveboardId,
      fullHeight: true,
    });

    embed
      .on(EmbedEvent.Init, () => {
        if (isMounted) setIsLoading(true);
      })
      .on(EmbedEvent.Load, () => {
        setIsLoading(false);
      })
      .on(EmbedEvent.LiveboardRendered, () => {
        setIsLoading(false);
      })
      .on(EmbedEvent.Data, (payload) => {
        handleevent("Data", payload);
      })
      .on(EmbedEvent.ALL, (data) => {
        // Handle all events
        handleevent("ALL", data);
      })
      .on(EmbedEvent.APP_INIT, (data) => {
        // Handle app initialization
        handleevent("APP_INIT", data);
      })
      .on(EmbedEvent.CustomAction, (payload) => {
        // Handle custom actions
        handleevent("CustomAction", payload);
      })
      .on(EmbedEvent.AnswerChartSwitcher, (payload) => {
        // Handle chart switcher
        handleevent("AnswerChartSwitcher", payload);
      })
      .on(EmbedEvent.Save, (data) => {
        // Handle save event
        handleevent("Save", data);
      })
      .on(EmbedEvent.Error, (error) => {
        if (isMounted) {
          setIsLoading(false);
          if (error?.data?.errorType === "FULLSCREEN") {
            setError(null);
          } else if (typeof error.error === "string") {
            setError(error.error);
          } else {
            setError("An error occurred");
          }
          console.log("Error", error);
        }
      })
      .render();

    return () => {
      isMounted = false;
      embed.destroy();
    };
  }, [profile_id, user_id]);

  const handleErrorBannerClose = () => {
    setError(null);
  };

  return (
    <>
      {isLoading && <Loadericon />}
      <div className="" id="liveboard"></div>
    </>
  );
};

export default Thoughtspotcomponent;
