import React, { useState } from "react";
import { Box } from "@mui/material";
import EditButton from "../../Common/EditButton.jsx";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../assets/SVG/edit.svg";

export const withEditFeature = (WrappedComponent) => {
  return (props) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleMouseHover = () => {
      setShow(true);
    };

    const handleRemoveHover = () => {
      setShow(false);
    };

    return (
      <Box
        sx={{
          position: "relative",
        }}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleRemoveHover}
      >
        {show && (
          <Box
            sx={{
              position: "absolute",
              top: "38%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#fff",
              borderRadius: "50%",
            }}
          >
            <img src={editIcon} alt="Edit" style={{ width: "20px", height: "20px" }} />
          </Box>
        )}
        <WrappedComponent {...props} />
      </Box>
    );
  };
};