import React from "react";
import Banner from "../components/Scribeai/Banner/Banner";
import Futurerich from "../components/Scribeai/Futurerich/Futurerich";
import Automated from "../components/Scribeai/Automated/Automated";
import Review from "../components/Scribeai/Review/Review";
import { useNavigate } from "react-router-dom";
import { storeInLocalStore } from "../redux/reducers/mainSlice";
import { useDispatch } from "react-redux";

const Scribeaipage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstfunction = () => {
    dispatch(storeInLocalStore({ submenusidename: "AI Crafted Social Media" }));
    dispatch(storeInLocalStore({ lastpageurl: "/scribe-ai" }));
    navigate("/create-calender");
  };
  const secondfunct = () => {
    dispatch(storeInLocalStore({ submenusidename: "AI Crafted Social Media" }));
    dispatch(storeInLocalStore({ lastpageurl: "/scribe-ai" }));
    navigate("/create-calender");
  };
  return (
    <>
      <Banner
        firstfunction={firstfunction}
        secondfunct={secondfunct}
        firstbtntext={`Free Trial 30 Days`}
        secondbtntext={`Start $199/Month`}
      />
      <Futurerich />
      <Automated />
      <Review firstfunction={firstfunction} secondfunct={secondfunct} />
    </>
  );
};

export default Scribeaipage;
