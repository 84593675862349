import React from "react";
import ReviewsMain from "../../components/Reviewsmain/ReviewsMain";

const Reviews = () => {
  return (
    <>
      <ReviewsMain />
    </>
  );
};

export default Reviews;
