import React from "react";
import WrapperComp from "../../components/WrapperComp/index.jsx";
import { Button, Stack, Typography } from "@mui/material";
import style from "./thankyoustyle.js";

const Thankyou = () => {
  return (
    <>
      <div className="customcontainerglobal">
        <WrapperComp
          SingleCompnents={
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={style.boldText} variant="h4">
                Thank You
              </Typography>
              <Typography sx={style.text} variant="p">
                Your Order is successfully created!
              </Typography>
              <Typography sx={style.text} variant="p">
                Order Number : 125
              </Typography>
              {/* <Button sx={style.loginButton} variant="contained">
              Let’s Go Home
            </Button> */}
            </Stack>
          }
        />
      </div>
    </>
  );
};

export default Thankyou;
