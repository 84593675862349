import React, { useEffect, useState } from "react";

const CustomTimePicker = ({ defaultTime, onTimeChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(defaultTime);
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 60) {
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return options;
  };
  const timeOptions = generateTimeOptions();

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
    onTimeChange(time);
    setIsOpen(false);
  };
useEffect(()=>{
  setSelectedTime(defaultTime)
},[defaultTime])
  return (
    <div className="time-picker">
      <input
        type="text"
        className="events"
        onClick={() => setIsOpen(!isOpen)}
        value={selectedTime}
        placeholder={placeholder}
        readOnly
      />
      {isOpen && (
        <div className="time-dropdown">
          {timeOptions.map((time, index) => (
            <div
              key={index}
              className="time-option"
              onClick={() => handleTimeSelection(time)}
            >
              {time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomTimePicker;
