import React from "react";
import Customer from "../components/customer-behaviour/Customer";

const Customerbehaviour = () => {
  
  return (
    <div className="full-width headerpadding customer-behavior">
      <div className="content-padding-both">
        <Customer />
      </div>
    </div>
  );
};

export default Customerbehaviour;
