import { Grid, Stack, Typography, Button, Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import upArrow from "../../../../assets/SVG/material-symbols_arrow-right-rounded_green.svg";
import firstTimeIcon from "../../../../assets/SVG/ph_eye-fill.svg";
import CardBottom from "../../components/CardBottom";
import voiletcircle from "../../../../assets/SVG/total/totalvoilet.svg";
import bluecircle from "../../../../assets/SVG/total/totalblue.svg";
import skycircle from "../../../../assets/SVG/total/totalsky.svg";
import greencircle from "../../../../assets/SVG/total/totalgreen.svg";
import downarrow from "../../../../assets/SVG/total/downarrow.svg";
const Totalcard = ({
  total,
  bgspecialclass,
  colorCode,
  data,
  name,
  imgurl,
  totalviewer,
}) => {
  const [maxViewer, setMaxViewer] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [targetViewer, setTargetViewer] = useState(0);
  const [targetPrice, setTargetPrice] = useState(0);
  const [currentViewer, setCurrentViewer] = useState();
  const [currentPrice, setCurrentPrice] = useState();
  const [allviewervalue, setAllviewervalue] = useState();
  const [currentpercentage, setCurrentpercetage] = useState();
  const [targetpercentage, setTagetpercentage] = useState();
  const handleSliderChange = (event, newValue) => {
    console.log(event, newValue);
    let baseprice = parseFloat(data?.base_price_in_usd);
    let trgprice = (parseFloat(newValue) * baseprice).toFixed(2);
    setTargetViewer(newValue);
    setTargetPrice(trgprice);
  };
  useEffect(() => {
    if (data) {
      let baseprice = parseFloat(data.base_price_in_usd);
      let mxprice = (parseFloat(data.max_viewers) * baseprice).toFixed(2);
      let trgprice = 0;
      if (name === "Total Spend") {
        setAllviewervalue(totalviewer);
        trgprice = data?.all
          ? (parseFloat(data.all) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(data.all);
      } else if (name === "First Time Viewers") {
        let currentp = (
          (data["first-time-viewers"] / data.current_viewers) *
          100
        ).toFixed(2);
        trgprice = data["first-time-viewers"]
          ? (parseFloat(data["first-time-viewers"]) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(data["first-time-viewers"]);
        setCurrentpercetage(currentp);
      } else if (name === "Engaged Viewers") {
        trgprice = data["engaged-viewers"]
          ? (parseFloat(data["engaged-viewers"]) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(data["engaged-viewers"]);
      } else if (name === "First Time Consumers") {
        trgprice = data["first-time-customers"]
          ? (parseFloat(data["first-time-customers"]) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(data["first-time-customers"]);
      } else {
        trgprice = data["freq-diners"]
          ? (parseFloat(data["freq-diners"]) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(data["freq-diners"]);
      }
      let targetper = ((data.current_viewers / data.max_viewers) * 100).toFixed(
        2
      );
      setTagetpercentage(targetper);
      setMaxViewer(data.max_viewers);
      setCurrentPrice(trgprice);
      setTargetViewer(data.current_viewers);
      setTargetPrice(data.current_spend.toFixed(2));
      setMaxPrice(mxprice);
    }
  }, [data]);
  if (total) {
    return (
      <div className="totalbox-item">
        <div className={`total-container total-cards pc`}>
          <p className="text-total text-light-gray">Total Spend</p>
          <p className="text7 py-1">$ {currentPrice}</p>
          <p className="total-text-green py-1">$ {targetPrice}</p>
          <div className="total-circle">
            <img src={voiletcircle} alt="voilet" className="voilet" />
            <img src={bluecircle} alt="voilet" className="blue" />
            <img src={skycircle} alt="voilet" className="sky" />
            <img src={greencircle} alt="voilet" className="green" />
            <div className="text-over">
              <p className="total-text">{allviewervalue?.firstviewer}</p>
              <img src={downarrow} alt="down" className="darrow" />
              <p className="total-text">{allviewervalue?.engagedviewer}</p>
              <img src={downarrow} className="darrow" />
              <p className="total-text">{allviewervalue?.firsttimeconsumer}</p>
              <img src={downarrow} className="darrow" />
              <p className="total-text">{allviewervalue?.freqdiner}</p>
            </div>
          </div>
          <div className="d-flex flex-column my-1">
            <Typography variant="p" className="text4-cb">
              Max
            </Typography>
            <Typography variant="p" className="text4-cb text-white">
              {maxViewer}
            </Typography>
            <div className="slider-box px-2">
              <Slider
                sx={{ color: "#eb2426" }}
                value={targetViewer}
                max={maxViewer}
                min={0}
                onChange={handleSliderChange}
              />
              <div className="slider-circle"></div>
            </div>
            <Typography variant="p" className="text4-cb text-white">
              ${maxPrice}
            </Typography>
          </div>

          <Button
            sx={{
              width: "100%",
              margin: "2px 0",
              borderRadius: "50px",
              opacity: 1,
              background: "#eb2426",
              "&:hover": {
                background: "#eb2426",
              },
            }}
            variant="contained"
          >
            Boost
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="totalbox-item">
        <div className={`total-container total-cards pc ${bgspecialclass}`}>
          <div className="d-flex align-items-center">
            <img width="25px" height="25px" src={imgurl} alt="error" />
            <p className="tabheaderbox">{name}</p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="circular-progress-bar-total"
              style={{ width: 110, height: 110 }}
            >
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  pathColor: "#29CA1B",
                  trailColor: "#262626",
                  strokeLinecap: "butt",
                })}
                value={currentpercentage || ""}
                strokeWidth={12}
              >
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: colorCode,
                    trailColor: "transparent",
                    strokeLinecap: "butt",
                  })}
                  value={targetpercentage}
                  strokeWidth={12}
                />
              </CircularProgressbarWithChildren>
              <div className="progress-bar-child-total">
                <img width="20px" height="20px" src={upArrow} alt="error" />
                <p className="progress-bar-text-total">
                  {currentpercentage || "00"}%
                </p>
                <p className="progress-bar-text-total text-green">
                  {targetpercentage || "00"}%
                </p>
              </div>
            </div>
          </div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: "10px",
            }}
          >
            <div className="d-flex flex-column">
              <Typography variant="p" className="text-cb">
                Total No.
              </Typography>
              <Typography variant="p" className="text-cb text2-cb">
                {currentViewer || "00"}
              </Typography>
              <Typography variant="p" className="text-cb text-green text2-cb">
                {targetViewer || "00"}
              </Typography>
            </div>
            <div className="d-flex flex-column">
              <Typography variant="p" className="text-cb">
                Spend
              </Typography>
              <Typography variant="p" className="text-cb text2-cb">
                ${currentPrice || "0.00"}
              </Typography>
              <Typography variant="p" className="text-cb text-green text2-cb">
                ${targetPrice || "0.00"}
              </Typography>
            </div>
          </Stack>
          <div className="d-flex flex-column my-1">
            <Typography variant="p" className="text4-cb">
              Max
            </Typography>
            <Typography variant="p" className="text4-cb text-white">
              {maxViewer || "00"}
            </Typography>
            <div className="slider-box px-2">
              <Slider
                sx={{ color: "#eb2426" }}
                value={targetViewer}
                max={maxViewer}
                min={0}
                onChange={handleSliderChange}
              />
              <div className="slider-circle"></div>
            </div>
            <Typography variant="p" className="text4-cb text-white">
              ${maxPrice || "0.00"}
            </Typography>
          </div>
          <Button
            sx={{
              width: "100%",
              margin: "2px 0",
              borderRadius: "50px",
              opacity: 0.3,
              background: "#eb2426",
              "&:hover": {
                background: "#eb2426",
              },
            }}
            variant="contained"
          >
            Boost
          </Button>
        </div>
      </div>
    );
  }
};

export default Totalcard;
