import React, { useEffect, useState } from "react";
import circleIcon from "../../../assets/SVG/Ellipse.svg";
import fbIcon from "../../../assets/images/mysocialmedia/facebook-icon.png";
import instaIcon from "../../../assets/images/mysocialmedia/instagram-icon.png";
import yelp from "../../../assets/images/mysocialmedia/yelpIcon.png";
import moment from "moment";

const NotificationCards = ({
  indexnumber,
  socialmedia,
  onClick,
  currentid,
}) => {
  const postdate = moment(socialmedia.created_time).format("DD MMM");
  return (
    <>
      <div
        onClick={onClick}
        className={`notification-cards ${
          indexnumber % 2 === 0 ? "card-bg1" : "card-bg2"
        } ${currentid === socialmedia.msgid ? "active" : ""}`}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="cards-main">
              <div className="main-card-header">
                <div className="name-area">
                  {socialmedia?.is_read ? (
                    ""
                  ) : (
                    <>
                      <img
                        alt="pointError"
                        width="10px"
                        height="10px%"
                        src={circleIcon}
                      />
                    </>
                  )}

                  <img
                    src={
                      socialmedia?.platform === "insta"
                        ? instaIcon
                        : socialmedia?.platform === "fb"
                        ? fbIcon
                        : socialmedia?.platform === "yelp"
                        ? yelp
                        : ""
                    }
                    className="icon-design"
                  />
                  <span>{socialmedia?.name}</span>
                </div>
                <div className="time-area">
                  <p>{postdate}</p>
                </div>
              </div>

              <p className="description">{socialmedia?.message}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationCards;
