import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
// import "./Banner.css";
import bannerone from "../../../assets/images/home/Home-banner.png";
import Chaticon from "../Chaticon";
import Breadcrumb from "../Breadcrumb";
import { Link } from "react-router-dom";

const BannerComponent = ({
  breadcrumbdata,
  bannerimg,
  title,
  subtitle,
  btnone,
  btnonefunction,
  btntwo,
  btntwofunction,
}) => {
  return (
    <>
      <div
        className="banner"
        // style={{
        //   backgroundImage: `url(${bannerimg})`,
        // }}
      >
        <img src={bannerimg} className="banner-image" />
        <div className="full-width headerpadding  banner-inner-width">
          {/* <Breadcrumb normal={breadcrumbdata} current={"Scribe AI"} /> */}
          <div className="row content-padding-left">
            <div className="col-md-6 py-5">
              <div className="inner-banner">
                <h1
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></h1>
                <h3
                  className="subheader"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                ></h3>
              </div>
              <div className="banner-btn-area">
                <button
                  onClick={btnonefunction}
                  className="byteready-btn byteready-outlinne"
                >
                  {btnone}
                </button>
                <button
                  onClick={btntwofunction}
                  className="byteready-btn byteready-primary"
                >
                  {btntwo}
                </button>
              </div>
            </div>
            <div className="col-md-6 py-5"></div>
          </div>
        </div>
        <Chaticon />
      </div>
    </>
  );
};

export default BannerComponent;
