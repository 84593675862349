import { Box, Fab, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import style from "../viewersStyle";
import MediaTextSwitch from "../../Common/MediaTextSwitch";
import IncreaseViewers from "../../Viewers/IncreaseViewers/IncreaseViewers";
import Reach from "../../Viewers/Reach/Reach";
import MobView from "../../Viewers/MobView/MobView";
import arrowIcon from "../../../assets/SVG/white-left-arrow.svg";
import CheckoutPanel from "../../../components/Common/CheckoutPanel";
import visitorIcon from "../../../assets/SVG/visiters-icon.svg";
import commentPoint from "../../../assets/SVG/Ellipse-83.svg";
import commentIcon from "../../../assets/SVG/gridicons_comment.svg";
import likePoint from "../../../assets/SVG/Ellipse-84.svg";
import likeIcon from "../../../assets/SVG/mdi_like.svg";
import heartIcon from "../../../assets/SVG/heart.svg";
import AddPoint from "../AddPoint/AddPoint";

const checkoutData = [{ campaign: "Summer Season", price: 2 }];

const FirstTimeView = () => {
  const [checkout, setCheckout] = useState(false);

  return (
    <>
      <Grid sx={style.box} container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography sx={style.label}>Choose From Your Campaigns</Typography>
          <MediaTextSwitch
            customStyle={{ width: { xs: "70%", sm: "50%", md: "75%" } }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={style.behaviorBox}>
            <Typography sx={style.text}>Behavioral</Typography>
            <Stack direction="row" spacing={2}>
              <Box align="center">
                <Box sx={style.iconBox}>
                  <img src={visitorIcon} alt="error" />
                </Box>
                <Typography sx={style.text2}>
                  Web Site Visitors <br /> (No Order)
                </Typography>
              </Box>
              <Box align="center">
                <Stack
                  sx={{ ...style.iconBox, marginTop: "10%" }}
                  direction="row"
                  spacing={-1}
                >
                  <Box
                    sx={{
                      ...style.imgBox,
                      zIndex: 1,
                      backgroundImage: `url(${commentPoint})`,
                    }}
                  >
                    <img
                      width="20px"
                      height="20px"
                      src={commentIcon}
                      alt="error"
                    />
                  </Box>
                  <Box
                    sx={{
                      ...style.imgBox,
                      width: "43px",
                      height: "43px",
                      zIndex: 2,
                      backgroundImage: `url(${likePoint})`,
                    }}
                  >
                    <img
                      width="25px"
                      height="25px"
                      src={likeIcon}
                      alt="error"
                    />
                  </Box>
                  <Box
                    sx={{
                      ...style.imgBox,
                      zIndex: 1,
                      backgroundImage: `url(${commentPoint})`,
                    }}
                  >
                    <img
                      width="20px"
                      height="20px"
                      src={heartIcon}
                      alt="error"
                    />
                  </Box>
                </Stack>
                <Typography sx={{ ...style.text2, marginTop: "9%" }}>
                  SM Likes & <br /> Comments
                </Typography>
              </Box>
              <AddPoint />
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <MobView />
      <Grid sx={{ marginTop: "5%" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <IncreaseViewers />
        </Grid>
        <Grid item xs={12} md={6}>
          <Reach />
        </Grid>
      </Grid>
      {!checkout && (
        <Fab sx={style.fabIconStyle} onClick={() => setCheckout(true)}>
          <img src={arrowIcon} alt="error" />
        </Fab>
      )}
      <CheckoutPanel
        setCheckout={setCheckout}
        checkout={checkout}
        data={checkoutData}
      />
    </>
  );
};

export default FirstTimeView;
