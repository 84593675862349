import { createSlice } from "@reduxjs/toolkit";

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: {
    profile_id: "",
    resturant_name: "",
    cuisine: "",
    origins: "",
    img_url: "",
    promt_text: "",
  },
  reducers: {
    createProfile: (state, actions) => {
      return {
        ...state,
        ...actions.payload,
      };
    },
  },
});

export const { createProfile } = restaurantSlice.actions;

export default restaurantSlice.reducer;
