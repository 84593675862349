const style = {
    mainWrapper: {
        position: 'relative',
        width: "max-content",
        backgroundSize: "contain",
        height: "570px",
        width: "279px", 
        margin:"0px 8px"
    },
    googleHeading: {
        background: '#f5f5f5',
        display: "flex",
        alignItems: "start",
        position: "absolute",
        top: "6%",
        left: '5%',
        padding: "10px 0px",
        width: "252px"
    },
    sms: {
        background: "#fff",
        position: 'absolute',
        top: '20%',
        left: '5%',
        right: '4.7%',
        height: '90px',
    }
}
export default style