import React from "react";
import { Step, Stepper } from "react-form-stepper";

const MultiStepBar = ({ steps, active, handlechange, showNext }) => {
  return (
    <>
      <div>
        <ul className="multisteps-form">
          {steps.length > 0 &&
            steps.map((item, index) => {
              return (
                <li key={index} className="form-item">
                  <div className="item-container">
                    <div
                      className={`btn-wrapper ${active > index ? "activeline" : "disableline"
                        }`}
                    >
                      <button
                        className={`topbutton ${active == index
                            ? "activebtn"
                            : active > index
                              ? "donebtn"
                              : "disablebtn"
                          }`}
                        onClick={() => {
                          showNext ? handlechange(index) : active > index ? handlechange(index) : "";
                        }}
                      ></button>
                    </div>
                    <div className="name-container">
                      <span>{item?.label}</span>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default MultiStepBar;
