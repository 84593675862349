import React, { useRef, useState } from "react";
import "./Aireslt.css";
import flowericon from "../../../assets/images/create-calender/airesultbanner.png";
import editicon from "../../../assets/images/create-calender/edit-icon.png";
import reloadicon from "../../../assets/images/create-calender/reload-icon.png";
const Airesult = ({ output, cancleoutput, applyOutput, generatecaption }) => {
  const paragraphRef = useRef();
  const [contenteditable, setContentEditable] = useState(false);
  const handleApplybtn = () => {
    const paragraph = paragraphRef.current.innerText;
    const finalData = output.text === paragraph ? "" : paragraph;
    //console.log(finalData);
    applyOutput(finalData);
  };
  return (
    <>
      <>
        <div className="aicontainer-main">
          <div className="airesult-container">
            <div className="ai-result">
              <p className="text-design font-normal">
                <strong>Title</strong>
              </p>
              <p
                ref={paragraphRef}
                className="airesult-text"
                contentEditable={contenteditable}
                onBlur={() => setContentEditable(false)}
              >
                {output?.text}
              </p>
            </div>

            <img src={flowericon} className="flower-icon" />
          </div>
          <div className="edit-btn-area">
            <img
              onClick={() => {
                setContentEditable(true);
              }}
              src={editicon}
              className="edit-icon"
              style={{
                cursor: "pointer",
              }}
            />
            <img
              src={reloadicon}
              onClick={() => {
                generatecaption("text");
              }}
              className="edit-icon"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="d-flex justify-content-end gap-20">
            <button
              onClick={() => {
                cancleoutput();
              }}
              className="byteready-btn byteready-outlinne"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleApplybtn();
              }}
              className="byteready-btn byteready-primary"
            >
              Apply
            </button>
          </div>
        </div>
      </>
    </>
  );
};

export default Airesult;
