import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import moment from "moment";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import style from "./MySocialMediaStyle";
import Arrow from "../../../assets/SVG/arrow.svg";
import SwitchButton from "../../Common/SwitchButton/SwitchButton.jsx";
import MediaTextSwitch from "../../Common/MediaTextSwitch";
import TextSwitchButton from "../../Common/TextSwitchButton/TextSwitchButton.jsx";
import { useDispatch, useSelector } from "react-redux";
import GlobalLoader from "../../Common/GlobalLoader/GlobalLoader.jsx";
import { getAllCaptionpost, addtempPost } from "../../../apis/auth/index.jsx";
import PostConfirm from "../../../pages/Calendar/Signup/PostConfirm.jsx";
import Signup from "../../../pages/Calendar/Signup/Signup.jsx";
import PopupShow from "../../../pages/Calendar/Signup/PopupShow";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { withEditFeature } from "./EditSocialMedia";
import SocialMediaCards from "../../Common/SocialMediaCards/SocialMediaCards.jsx";
import FacebookMediaCard from "../../Common/SocialMediaCards/FacebookMediaCard";
import SocialMediafbCards from "../../Common/SocialMediaFbCards/SocialMediaFbCards";
import MediaSwitchButton from "../../Common/MediaSwitch/MediaSwitchButton";
import {
  storeInLocalStore,
  setoriginscroller,
} from "../../../redux/reducers/mainSlice";
const MySocialMedia = () => {
  const cardData = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state?.main?.store?.profile_data);
  const locationstoreData = useSelector(
    (state) => state?.main?.store?.location_data
  );
  const profile_id = profileData?.profile_id;
  const wesiturl = profileData?.website;
  const resName = profileData?.resturant_name;
  const [monthalydata, setMonthalydata] = useState(cardData);
  const [designSwicher, setDesingswitcher] = useState("insta");
  const [currentDate, setCurrentDate] = useState();
  const tempCurrentDate = new Date();
  const [signupModel, setSignupModel] = useState(false);
  const [modelData, setModelData] = useState("");
  const [loader, setLoader] = useState(false);
  // get Post Data
  const fetchData = async () => {
    setLoader(true);
    getAllCaptionpost({
      profile_id: profile_id,
      number_of_day: 30,
    })
      .then((res) => {
        if (res.status == "success") {
          setLoader(false);
          setMonthalydata(res.captions);
          setCurrentDate(res?.captions[0]?.date);
          let temporigin = {
            date: res?.captions[0]?.date,
            output_image: res?.captions[0]?.prompt_output_img_url,
            output_text: res?.captions[0]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ origin_output: temporigin }));
          let templocation = {
            date: res?.captions[1]?.date,
            output_image: res?.captions[1]?.prompt_output_img_url,
            output_text: res?.captions[1]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ location_output: templocation }));
          let tempcuisine = {
            date: res?.captions[2]?.date,
            output_image: res?.captions[2]?.prompt_output_img_url,
            output_text: res?.captions[2]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ cuisine_output: tempcuisine }));
        } else {
          setMonthalydata(cardData);
          setCurrentDate(tempCurrentDate);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
        setMonthalydata(cardData);
        setCurrentDate(tempCurrentDate);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (profileData === null) {
      navigate("/");
    } else {
      fetchData();
    }
  }, []);

  //Date Change
  let startDate = moment(currentDate);
  const weekdays = moment.weekdaysShort();
  const currentDayOfWeek = moment(currentDate).day();
  const rotatedWeekdays = [
    ...weekdays.slice(currentDayOfWeek),
    ...weekdays.slice(0, currentDayOfWeek),
  ];
  // Social Media Switcher
  const designSwitch = (data) => {
    setDesingswitcher(data);
  };

  const handleSave = () => {
    addtempPost({
      date: currentDate,
      profile_id: profile_id,
    }).then((res) => {
      if (res.status == "success") {
        setSignupModel(true);
      }
    });
  };
  const handleSignupmodel = () => {
    setSignupModel(false);
  };
  const handlePopup = (data) => {
    // console.log(data);
    setModelData(data);
  };
  const clodeModel = (data) => {
    if ((data = "reload")) {
      setModelData("");
      fetchData();
    } else {
      setModelData("");
    }
  };
  const EditSocialMedia = withEditFeature(
    designSwicher === "insta" ? SocialMediaCards : FacebookMediaCard
  );
  const handleCreateCalendar = () => {
    dispatch(setoriginscroller(true));
    navigate("/");
  };
  return (
    <>
      {loader ? (
        <GlobalLoader text={"Getting Posts..."} open={loader} />
      ) : (
        <>
          {monthalydata.length > 0 ? (
            <>
              <Box sx={style.mainContainer}>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={12} md={12}>
                    <Typography sx={style.headText}>
                      Select The Post You Want To Edit
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Box sx={style.titleBox}>
                          <Typography sx={style.title}>
                            Choose The Platform
                          </Typography>
                        </Box>
                        <MediaSwitchButton designSwitch={designSwitch} />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Box sx={style.titleBox}>
                          <Typography sx={style.title}>
                            Choose From Your Campaigns
                          </Typography>
                        </Box>
                        <MediaTextSwitch
                          mymedia={false}
                          customStyle={{ width: "fit-content" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Box align="right" justify="bottom">
                      <CustomButton
                        sx={{ width: "fit-content" }}
                        label="Confirm Calendar"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  ...style.tableContainer,
                  background: "#000000",
                }}
              >
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          borderBottom: "0px",
                        }}
                      >
                        {rotatedWeekdays.map((day) => (
                          <TableCell key={day} align="left">
                            <Typography sx={style.dayText} variant="h5">
                              {day.charAt(0)}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {monthalydata &&
                        monthalydata
                          .reduce((weeks, day, index) => {
                            startDate =
                              index === 0
                                ? startDate.add(0, "days")
                                : startDate.add(1, "days");
                            const weekIndex = Math.floor(index / 7);
                            if (!weeks[weekIndex]) {
                              weeks[weekIndex] = [];
                            }
                            weeks[weekIndex].push(
                              <TableCell
                                key={index}
                                align="center"
                                sx={{
                                  padding: "2px",
                                  margin: "auto",
                                  cursor: "pointer",
                                }}
                                onClick={() => handlePopup(day)}
                              >
                                <EditSocialMedia
                                  dataperday={day}
                                  url={wesiturl}
                                  name={resName}
                                />
                                <Typography
                                  sx={style.dateText}
                                  variant="h5"
                                >{`${startDate.format("MM/DD")}`}</Typography>
                              </TableCell>
                            );
                            return weeks;
                          }, [])
                          .map((week, index) => (
                            <TableRow key={index}>{week}</TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <PopupShow
                  open={modelData}
                  wesiturl={wesiturl}
                  resName={"Demo"}
                  handleClose={clodeModel}
                  // resData={profileData}
                />
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography sx={style.boldText} variant="h4">
                  Calender is Not Created.
                </Typography>
                <Button
                  sx={{
                    margin: "50px auto",
                    backgroundColor: "#eb2426",
                    borderRadius: "50px",
                    display: "block",
                    padding: "16px 50px",
                    textAlign: "center",
                    "&:hover": {
                      background: "#eb2426",
                    },
                  }}
                  variant="contained"
                  onClick={handleCreateCalendar}
                >
                  Create Calender
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MySocialMedia;
