import { Box } from "@mui/material";
import React from "react";
import whitePointImg from "../../assets/SVG/White_Ellipse.svg";
import leftArrow from "../../assets/SVG/dropdown_arrow4.svg";

const style = {
  nextBtnBox: {
    width: "10%",
    height: "100%",
    marginBottom: "15px",
    background: "transparent",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
};

const NextBtn = ({ handleNext, width }) => {
  return (
    <>
      <Box onClick={handleNext} sx={{ ...style.nextBtnBox, width: width ? width : '10%' }}>
        <img width="100%" height="100%" src={leftArrow} alt="error" />
      </Box>
    </>
  );
};

export default NextBtn;
