import React, { useState } from "react";
import style from "./TextSwitchButton.js";
import { Box, Typography } from "@mui/material";

const TextSwitchButton = () => {
  const [isActive, setIsActive] = useState(2);

  const toggleSwitch = () => {
    if (isActive === 1) {
      setIsActive(2);
    } else {
      setIsActive(1);
    }
  };
  return (
    <>
      <Box sx={style.mainContainer}>
        <Box
          onClick={toggleSwitch}
          sx={{
            ...style.circle,
            backgroundColor: isActive === 1 && "#fff",
            width: "140px",
            borderRadius: "60px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...style.text,
              color: isActive === 1 ? "#eb2426" : "#FCFCFC",
            }}
          >
            Advertisement
          </Typography>
        </Box>
        <Box
          onClick={toggleSwitch}
          sx={{ ...style.circle, backgroundColor: isActive === 2 && "#fff" }}
        >
          <Typography
            variant="h5"
            sx={{
              ...style.text,
              color: isActive === 2 ? "#eb2426" : "#FCFCFC",
            }}
          >
            Post
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TextSwitchButton;
