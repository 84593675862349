import React, { useEffect, useState } from "react";
import EventsImage from "../../components/Events/EventsImage/EventsImages";
import Body from "../../components/Events/Body/Body";
import Footer from "../../components/Home/Footer/Footer.jsx";
import { Box } from "@mui/material";
import style from "./EventPageStyle.js";
import { CampaignContext } from "../../Context/CampaignContext.js";
import { useSelector } from "react-redux";
import { geteventTemplatebyidAPI } from "../../apis/auth";
import GlobalLoader from "../../components/Common/GlobalLoader/GlobalLoader";
import { useNavigate } from "react-router-dom";

const EventPage = () => {
  const eventTitle = useSelector((state) => state?.main?.store?.eventTitle);
  const campaigncreated = useSelector((state) => state?.main.campaigncreated);
  const history = useNavigate();
  const [pageTitle, setPageTitle] = useState();
  const [offersInitialData, setOffersInitialData] = useState();
  const [isLoading, setLoading] = useState(false);
  const geteventTemplateData = () => {
    setLoading(true);
    geteventTemplatebyidAPI(eventTitle.id).then((res) => {
      setLoading(false);
      setOffersInitialData(res.template);
    });
  };
  useEffect(() => {
    if (eventTitle) {
      geteventTemplateData();
      setPageTitle(eventTitle.text);
    } else {
      setPageTitle("");
      history("/");
    }
  }, [eventTitle]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <CampaignContext>
      {isLoading ? (
        <>
          <GlobalLoader open={isLoading} text={"Getting Templates..."} />
        </>
      ) : (
        <>
          <Box sx={style.container}>
            <EventsImage eventpage={pageTitle} />
            {!campaigncreated && (
              <>
                <Box className="customcontainerglobal">
                  <Body templateData={offersInitialData} />
                </Box>
               
              </>
            )}
          </Box>
        </>
      )}
    </CampaignContext>
  );
};

export default EventPage;
