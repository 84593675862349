import React, { useEffect, useState } from "react";
import Campaigs from "./customerjourney/Campaigs";
import Publishcard from "./customerjourney/Publishcard";
import "./mysocialmedia.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignDataAPI,
  getallcampaignAPI,
  getmaxViewerAPI,
  publishcampaignAPI,
  settagetpriceAPI,
} from "../../apis/auth";
import moment from "moment";
import GlobalAlert from "../Common/GlobalAlert";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import { useNavigate } from "react-router-dom";
import Customerbehaviour from "../customerbehaviour/Customerbehaviour";
import Loadericon from "../Common/Loadericon";
import Customer from "../customer-behaviour/Customer";

const Customerjourney = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileViewData, setMobileViewData] = useState();
  const [campaign, setCampaign] = useState([]);
  const [campId, setCampId] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [alertMessage, setAlertmessage] = useState("");
  const [initialamountall, setinitialamount] = useState("");
  const [maxviewer, setMaxviewer] = useState(1000);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const itemsPerPage = 3;
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const profileData = useSelector((state) => state.main.store?.profile_data);
  const users = useSelector((state) => state.main.store?.users);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNext = () => {
    const newStartIndex =
      (startIndex + itemsPerPage) % mobileViewData?.campaign?.length;
    setStartIndex(newStartIndex);
  };

  const handlePrevious = () => {
    let newStartIndex = startIndex - itemsPerPage;
    if (newStartIndex < 0) {
      newStartIndex = mobileViewData?.campaign?.length + newStartIndex;
    }
    setStartIndex(newStartIndex);
  };

  const visibleItems = [];
  for (let i = 0; i < itemsPerPage; i++) {
    const index = (startIndex + i) % mobileViewData?.campaign?.length;
    visibleItems.push(mobileViewData?.campaign?.[index]);
  }
  const getAllCampaign = () => {
    setLoader(true);
    let data = {
      profile_id: profile_id,
      unit_id: 0,
    };
    getallcampaignAPI(data)
      .then((res) => {
        setLoader(false);
        if (res.status == "success") {
          setCampaign(res?.campaign);
          setCampId(res?.campaign?.[0]?.id);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const getSingleCampaignData = () => {
    let data = {
      profile_id: profile_id,
      camp_id: campId,
    };
    getCampaignDataAPI(data)
      .then((res) => {
        if (res.status == "success") {
          // setCampaignData(res?.campaign)
          setMobileViewData({
            profile: profileData,
            campaign: res?.campaign?.add_unit_ids,
          });
        }
      })
      .catch((err) => {
        console.log("Err ", err);
      });
  };
  const functionOnModalClose = async () => {
    getSingleCampaignData();
  };
  const changeCampId = (id) => {
    setCampId(id);
  };
  useEffect(() => {
    getAllCampaign();
  }, []);
  useEffect(() => {
    if (campId) {
      getSingleCampaignData();
    }
  }, [campId]);
  const handletotal = (data) => {
    let payload = { ...data, type: "publish" };
    dispatch(storeInLocalStore({ payment_summary: payload }));
    navigate("/payment-summary");
  };
  const handleengagedviewer = () => {
    console.log("Hello world Engaged");
  };
  const handlefirstimeviewer = () => {
    console.log("Hello world First time V Handle");
  };
  const handlefirsttimerconsumer = () => {
    console.log("Hello world First time C Handle");
  };
  const handlefreqdiner = () => {
    console.log("Hello world Freq D Handle");
  };
  const handlealertclose = () => {
    setAlertmessage("");
  };
  const setTargetprice = (payload, isall, firstime) => {
    setLoader1(true);

    settagetpriceAPI(payload, isall)
      .then((res) => {
        setLoader1(false);
        if (firstime) {
          setinitialamount(res?.data);
        }
      })
      .catch((res) => {});
    if (firstime) {
      setLoader2(true);
      getmaxViewerAPI({
        profile_id: profile_id,
      })
        .then((res) => {
          setLoader2(false);
          if (res.status == "success") {
            setMaxviewer(res?.data);
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    }
  };

  useEffect(() => {
    if (campId) {
      let payload = {
        profile_id: profile_id,
        campagin_id: campId,
        viewer_type: "all",
        viewer_count: "4",
      };
      setTargetprice(payload, true, true);
    }
  }, [campId]);
  return (
    <>
      {loader && loader1 && loader2 ? (
        <>
          <Loadericon />
        </>
      ) : (
        <>
          <div className="customer-journey">
            <Campaigs
              campId={campId}
              value={value}
              handleChange={handleChange}
              mobileViewData={mobileViewData}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              visibleItems={visibleItems}
              profileData={profileData}
              campaign={campaign}
              changeCampId={changeCampId}
              functionOnModalClose={functionOnModalClose}
            />
            {/* {campId && (
              <Publishcard
                campId={campId}
                profileData={profileData}
                maxviewer={maxviewer}
                allviewerbaseprice={initialamountall}
                handletotal={handletotal}
                handleengagedviewer={handleengagedviewer}
                handlefirstimeviewer={handlefirstimeviewer}
                handlefirsttimerconsumer={handlefirsttimerconsumer}
                handlefreqdiner={handlefreqdiner}
              />
            )} */}
            {/* <Customerbehaviour
              value={value}
              handleChange={handleChange}
              mobileViewData={mobileViewData}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              visibleItems={visibleItems}
              profileData={profileData}
              functionOnModalClose={functionOnModalClose}
            /> */}
            <div className="full-width customer-behavior">
              <Customer />
            </div>
            {alertMessage && (
              <GlobalAlert
                message={alertMessage}
                handleClose={handlealertclose}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Customerjourney;
