import React, { useState } from "react";
import { Box } from "@mui/material";
import EditButton from "../../Common/EditButton.jsx";
import { useNavigate } from "react-router-dom";

export const withEditFeature = (WrappedComponent) => {
  return (props) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleMouseHover = () => {
      setShow(true);
    };

    const handleRemoveHover = () => {
      setShow(false);
    };
    return (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "35%",
          }}
        >
          {show && <EditButton />}
        </Box>
        <WrappedComponent
          {...props}
          handleHover={handleMouseHover}
          handleRemoveHover={handleRemoveHover}
        />
      </Box>
    );
  };
};
