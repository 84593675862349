import React from "react";
import Banner from "../components/Unifieddigital/Banner/Banner";
import Yourstory from "../components/Unifieddigital/Yourstory/Yourstory";
import Driveresults from "../components/Unifieddigital/Driveresults/Driveresults";
import Mycustomer from "../components/Newhome/Mycustomer/Mycustomer";
import Intelligent from "../components/Unifieddigital/Intelligent/Intelligent";
import Decisions from "../components/Unifieddigital/Decision/Decisions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeInLocalStore } from "../redux/reducers/mainSlice";

const Unifiedgenerate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstfunction = () => {
    dispatch(
      storeInLocalStore({
        submenusidename: "Unifying Digital Engagement Program",
      })
    );
    dispatch(storeInLocalStore({ lastpageurl: "/unifying-digital" }));
    navigate("/create-calender");
  };
  const secondfunct = () => {
    dispatch(
      storeInLocalStore({
        submenusidename: "Unifying Digital Engagement Program",
      })
    );
    dispatch(storeInLocalStore({ lastpageurl: "/unifying-digital" }));
    navigate("/create-calender");
  };
  return (
    <>
      <Banner firstfunction={firstfunction} secondfunct={secondfunct} />
      <Yourstory />
      <Driveresults />
      <Intelligent />
      <Mycustomer />
      <Decisions firstfunction={firstfunction} secondfunct={secondfunct} />
    </>
  );
};

export default Unifiedgenerate;
