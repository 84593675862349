import React, { useState } from "react";
import "./../signupstyle.css";
import eyeopen from "../../../assets/images/header/eye-open.png";
import eyeclose from "../../../assets/images/header/eye-close.png";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI } from "../../../apis/auth";
import LoginConfirm from "./LoginConfirm.jsx";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice.jsx";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

const index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showpassword, setShowpassword] = useState(false);
  const [showphonenumber, setShowphonenumber] = useState(false);
  const [lossAlert, setLossAlert] = useState();
  const [loginData, setLoninData] = useState();
  const profileData = useSelector((state) => state.main.store?.profile_data);
  const moveto = useSelector((state) => state.main?.store?.moveto);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [fieldalertmessage, setFieldalertmessage] = useState("");
  const handlesubmit = (e) => {
    e.preventDefault();
    if (formData.email.trim() == "") {
      setFieldalertmessage("Please Enter Email.");
    } else if (formData.password.trim() == "") {
      setFieldalertmessage("Please Enter Password.");
    } else {
      loginAPI({
        email_or_phone_number: showphonenumber
          ? `+${formData?.email}`
          : formData?.email,
        password: formData?.password,
        profile_id: profileData?.profile_id || "",
      })
        .then((res) => {
          if (res.status == "success") {
            let data = res;
            if (res?.message != "login successfully.") {
              setLossAlert(
                "Your calendar profile already exists. Logging in now will loss of current data. Are you sure you want to proceed?"
              );
              setLoninData(data);
            } else {
              localStorage.setItem("token", data.token);
              storeinlocalstorage(data);
            }
          }
        })
        .catch((rej) => {
          console.log("rej", rej);
          setFieldalertmessage(rej?.response?.data?.detail);
        });
    }
    setTimeout(() => {
      setFieldalertmessage("");
    }, 3000);
  };
  const handleClose = () => {
    setLossAlert("");
    setLoninData("");
  };
  const handleokay = () => {
    localStorage.setItem("token", loginData?.token);
    storeinlocalstorage(loginData);
    setLossAlert("");
  };
  const storeinlocalstorage = (data) => {
    const currentdate = moment().format("YYYY-MM-DD");
    let profile_data = {
      profile_id: data?.user_profiles?.profile_id,
      resturant_name: data?.user_profiles?.resturant_name,
      website: data?.user_profiles?.resturant_website,
    };
    const subscribe = data?.user_profile?.is_trial
      ? "free"
      : data?.user_profiles?.subscib_end_date
      ? moment(data?.user_profiles?.subscib_end_date).format("YYYY-MM-DD") >
        currentdate
        ? "paid"
        : "expire"
      : "start";
    let payload = {
      id: data?.user_profiles?.user_id,
      subscribe_status: subscribe,
      ...data?.user_profiles,
    };
    let originData_payload = {
      cuisine: data?.user_profiles?.cuisine,
    };
    let location_data = {
      resturant_address: data?.user_profiles?.resturant_address,
      resturant_website: data?.user_profiles?.resturant_website,
      vibe: data?.user_profiles?.vibe,
    };
    let selected_themeid = data?.user_profiles?.theme;
    dispatch(storeInLocalStore({ profile_data: profile_data }));
    dispatch(storeInLocalStore({ users: payload }));
    dispatch(storeInLocalStore({ selectedthemeid: selected_themeid }));
    dispatch(storeInLocalStore({ origin_data: originData_payload }));
    dispatch(storeInLocalStore({ location_data: location_data }));
    setTimeout(() => {
      if (data?.user_profiles?.fb_connected) {
        navigate("/my-social-media");
      } else {
        navigate("/social-media-connect");
      }
    }, 1000);
  };
  return (
    <>
      <div className="full-width headerpadding loging signup-page">
        <div className="content-padding-both">
          <div className="row py-5">
            <div className="col-md-6">
              <div className="title-wrap">
                <div className="">
                  <h1 className="title extra-font">
                    Welcome <br />
                    back
                  </h1>
                  <p>The next chapter starts here</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="form-wrapper">
                    <div className="position-relative py-3">
                      {fieldalertmessage && (
                        <span className="alerm-maeesage">
                          {fieldalertmessage}
                        </span>
                      )}
                    </div>
                    <form onSubmit={handlesubmit}>
                      <div className="form-group">
                        <div className="inner-text">
                          <span className="text-left">
                            Email or Phone Number
                          </span>

                          <span
                            className="bold-inner"
                            onClick={() => {
                              setShowphonenumber(!showphonenumber);
                              setFormData({
                                ...formData,
                                emailorphone: "",
                              });
                            }}
                          >
                            {showphonenumber ? "Email" : "Phone Number"}
                          </span>
                        </div>
                        {showphonenumber ? (
                          <PhoneInput
                            country={"us"}
                            value={formData.email}
                            onChange={(phone) => {
                              setFormData({
                                ...formData,
                                email: phone,
                              });
                            }}
                            className="phone-number"
                          />
                        ) : (
                          <input
                            type={"email"}
                            className="custom-input"
                            value={formData.email}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              });
                            }}
                          />
                        )}
                      </div>
                      {/* <div className="form-group">
                        <label>Email or Phone Number</label>
                        <input
                          type={"text"}
                          className="custom-input"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div> */}
                      <div className="form-group">
                        <label>Password</label>
                        <div className="position-relative password">
                          <input
                            type={showpassword ? "text" : "password"}
                            className="custom-input"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              });
                            }}
                          />
                          <img
                            src={showpassword ? eyeopen : eyeclose}
                            onClick={() => {
                              setShowpassword(!showpassword);
                            }}
                            className="password-eye"
                          />
                        </div>
                      </div>
                      <div className="form-group text-end">
                        <label
                          onClick={() => {
                            navigate("/forget-password");
                          }}
                          className="forget-password"
                        >
                          Forgot Password?
                        </label>
                      </div>
                      <div className="form-group text-end">
                        <button
                          type="submit"
                          className="byteready-btn byteready-primary custom-width"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginConfirm
        message={lossAlert}
        handleClose={handleClose}
        handleokay={handleokay}
      />
    </>
  );
};

export default index;
