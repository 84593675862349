import React, { useEffect, useRef, useState } from "react";
import profiledummy from "../../assets/images/circleInstaImg.png";
import reloadicon from "../../assets/images/myprofile/reload.png";
import { handleAddressglobal } from "../../CustomHook/addressUtills";

const Basicinformation = ({
  profileData,
  handleimageupload,
  myprofileurl,
  setProfilechange,
}) => {
  const inputfileref = useRef();
  const inputRef = useRef();
  const autoCompleteRef = useRef();
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"],
  };
  const isInitialRender = useRef(true);
  const [formData, setFormData] = useState({
    resturant_name: "",
    resturant_website: "",
    resturant_address: "",
    resturant_zipcode: "",
  });
  useEffect(() => {
    if (profileData) {
      setFormData({
        resturant_name: profileData?.resturant_name,
        resturant_website: profileData?.resturant_website,
        resturant_address: profileData?.resturant_address,
        resturant_zipcode: profileData?.resturant_zipcode,
      });
    }
  }, [profileData]);
  useEffect(() => {
    if (!isInitialRender.current) {
      const hasValueChanged =
        formData.resturant_name !== profileData?.resturant_name ||
        formData.resturant_website !== profileData?.resturant_website ||
        formData.resturant_address !== profileData?.resturant_address ||
        formData.resturant_zipcode !== profileData?.resturant_zipcode;

      if (hasValueChanged) {
        setProfilechange(formData);
      } else {
        setProfilechange("");
      }
    } else {
      isInitialRender.current = false;
    }
  }, [formData]);
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      if (place) {
        let confirmAddress = handleAddressglobal(place);
        setFormData((formData) => ({
          ...formData,
          resturant_address:
            `${confirmAddress?.addressLine1} ${confirmAddress?.city} ${confirmAddress?.zipCode}, ${confirmAddress?.country} ` ||
            inputRef.current.value,
        }));
      }
    });
  }, [inputRef.current]);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="content-padding-both">
            <div className="basic-card information">
              <h3 className="basic-title">Basic Information</h3>
              <div className="row align-items-center py-2">
                <div className="col-md-4">
                  <div className="profile-container-main">
                    <div className="profile-container">
                      <img
                        src={myprofileurl || profiledummy}
                        className="profile-image"
                        onError={(e) => {
                          e.target.src = profiledummy;
                        }}
                      />
                      <input
                        type="file"
                        ref={inputfileref}
                        hidden
                        onChange={handleimageupload}
                      />
                      <p
                        onClick={() => {
                          if (inputfileref.current) {
                            inputfileref.current.click();
                          }
                        }}
                      >
                        <img src={reloadicon} />
                        Replace
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Restaurant Name</label>
                        <input
                          type={"text"}
                          className="custom-input"
                          value={formData?.resturant_name}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              resturant_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Website URL</label>
                        <input
                          type={"text"}
                          className="custom-input"
                          value={formData?.resturant_website}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              resturant_website: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Location</label>
                        <input
                          ref={inputRef}
                          type={"text"}
                          className="custom-input"
                          value={formData?.resturant_address}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              resturant_address: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Delivery Area Zip Code</label>
                        <input
                          type={"text"}
                          className="custom-input"
                          value={formData?.resturant_zipcode}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              resturant_zipcode: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Basicinformation;
