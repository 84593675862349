import React, { useEffect, useState } from "react";
import "./Tabview.css";
import MultiStepBar from "../Common/MultiStepBar";
import Origin from "./Origin/Origin";
import Location from "./Location/Location";
import Cuisine from "./Cuisine/Cuisine";
import Theme from "./Theme/Theme";
import Monthlycalender from "./monthlycalender/Monthlycalender";
import { useDispatch, useSelector } from "react-redux";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import {
  createProfileAPI,
  createthemebyuserAPI,
  getAllCaptionpost,
  getCaptionsAPI,
  getmainTemplateAPI,
  updateResturantDataAPI,
  usercustomthemeImageUploadAPI,
} from "../../apis/auth";
import Descriptionset from "./Theme/Popup/Descriptionset";

import DashboardLoader from "../Common/DashboardLoader";
import ProfileSetup from "./Theme/Popup/ProfileSetup";
import Themesliders from "./Theme/Popup/Themesliders";
import Savecustomtemplate from "./Theme/Popup/Savecustomtemplate";

const Tabview = () => {
  const dispatch = useDispatch();
  const [opencustomtheme, setOpencustomtheme] = useState(false);
  const [monthlycalenderdata, setMonthlycalenderdata] = useState([]);
  const [loader, setLoader] = useState(false);

  const [originlastfive, setOriginlastfive] = useState([]);
  const [locationlastfive, setLocationlastfive] = useState([]);
  const [cuisinelastfive, setCuisinelastfive] = useState([]);
  const [mainloader, setMainloader] = useState(false);
  const [openprofilecreate, setOpenprofilecreate] = useState(null);
  const currentcomid = useSelector(
    (state) => state.main.store?.currentcomponentid
  );
  const [currentStep, setCurrentStep] = useState(currentcomid || 0);
  const calendertime = useSelector((state) => state.main.store?.calendertime);
  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  const users = useSelector((state) => state.main.store?.users);
  const selectedtheme = useSelector((state) => state.main.store?.selectedevent);
  const selectedthemeid = useSelector(
    (state) => state.main.store?.selectedthemeid
  );
  const origin_input = useSelector((state) => state.main.store?.origin_data);
  const location_input = useSelector(
    (state) => state.main.store?.location_data
  );
  const cuisine_input = useSelector((state) => state.main.store?.cuisine_data);
  const lasturl = useSelector((state) => state.main.store?.lastpageurl);
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const [templateAll, setTemplateAll] = useState([]);
  const [openthemesetting, setOpenthemesetting] = useState("");
  const data = [
    { label: "Brand Story" },
    { label: "Location" },
    { label: "Cuisine" },
    { label: "Themes" },
    { label: "Confirm" },
  ];
  const handletabchange = (index) => {
    // console.log(index);
    if (index < 3) {
      setCurrentStep(3);
    } else {
      setCurrentStep(index);
    }
  };
  const getMainTemplatedata = () => {
    getmainTemplateAPI()
      .then((res) => {
        let tempData = res.template.map((item) => ({
          id: item._id,
          imgUrl: item.image_url_for_card,
          text: item.title,
          desc: item.description,
        }));
        setTemplateAll(tempData);
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const handleClick = (data) => {
    if (profile_info?.profile_id) {
      if (lasturl == "/unifying-digital" || users?.is_unified_web) {
        setOpenthemesetting(data);
      } else {
        setLoader(true);
        createmonthlycalendar(data?.id)
          .then((success) => {
            if (success) {
              setLoader(false);
              handletabchange(4);
            } else {
              console.log("Process failed");
            }
          })
          .catch((error) => {
            console.log("Error ", error);
          });
      }
      dispatch(storeInLocalStore({ selectedevent: data }));
    } else {
      setOpenprofilecreate(true);
    }
  };
  const createmonthlycalendar = (selectedtheme) => {
    return new Promise((resolve, reject) => {
      if (monthlycalenderdata.length == 0) {
        if (origin_input?.cuisine) {
          let data = {
            profile_id: profile_info?.profile_id,
            resturant_name: profile_info?.resturant_name,
            resturant_address: location_input?.resturant_address,
            resturant_website: profile_info?.website,
            cuisine: origin_input?.cuisine,
            origins: origin_input?.origins,
            dish: cuisine_input?.menuData[0][1],
            description: cuisine_input?.menuData[0][1],
            dish_or_menu: cuisine_input?.menuData,
            vibe: location_input?.vibe,
            caption_type: "cuisine",
            caption_response: "30days",
            count: 30,
          };
          getCaptionsAPI(data)
            .then((res) => {
              if (res.status) {
                updateResturantData(selectedtheme);
                resolve(true);
              } else {
                resolve(true);
              }
            })
            .catch((err) => {
              resolve(true);
            });
        } else {
          console.log("Tab Changes");
          setLoader(false);
          dispatch(storeInLocalStore({ currentsubmenu: "createmypaidads" }));
          reject(false);
        }
      } else {
        resolve(true);
      }
    });
  };

  const updateResturantData = (selectedtheme) => {
    setLoader(true);
    let currentDate = new Date().toISOString();
    let data = {
      profile_id: profile_info?.profile_id,
      resturant_name: profile_info?.resturant_name,
      resturant_address: location_input?.resturant_address,
      resturant_website: profile_info?.website,
      start_date: currentDate,
      vibe: location_input?.vibe,
      cuisine: origin_input?.cuisine,
      origins: origin_input?.origins,
      dish: cuisine_input?.menuData[0][0],
      description: cuisine_input?.menuData[0][1],
      theme: selectedtheme,
    };
    updateResturantDataAPI(data)
      .then((res) => {
        setLoader(false);
        fetchData();
      })
      .catch((err) => {
        console.log("Error ", err);
        setLoader(false);
      });
  };
  const cancleClosedescription = () => {
    setOpenthemesetting("");
  };
  const fetchData = () => {
    setMainloader(true);
    getAllCaptionpost({
      profile_id: profile_info?.profile_id,
      number_of_day: 30,
    })
      .then((res) => {
        if (res.status) {
          setMonthlycalenderdata(res);
          if (res.captions?.length > 0) {
            handletabchange(currentcomid || 4);
          } else {
            handletabchange(0);
          }
        }
        setMainloader(false);
      })
      .catch((rej) => {
        console.log(rej);
        setMainloader(false);
        handletabchange(currentcomid || 3);
      });
  };
  useEffect(() => {
    if (currentcomid) {
      setCurrentStep(currentcomid);
      setTimeout(() => {
        dispatch(storeInLocalStore({ moveto: "" }));
        dispatch(storeInLocalStore({ currentcomponentid: "" }));
      }, 500);
    }
    if (currentsubmenu == "createmysmcalender" && calendertime) {
      setCurrentStep(4);
    }
  }, [currentcomid]);
  const handlecreateprofile = (formdata) => {
    setMainloader(true);
    createProfileAPI({
      resturant_name: formdata.restaurant_name,
      resturant_address: formdata?.restaurant_address,
      resturant_website: formdata?.restaurant_website,
      is_unified_web: true,
    })
      .then((res) => {
        if (res.status === "success") {
          let resturantData = res.user_profile;
          let tempcleanurl = resturantData?.resturant_website
            ?.replace(/^https?:\/\//, "")
            ?.replace(/\/$/, "");
          let profile_info = {
            profile_id: resturantData.profile_id,
            resturant_name: resturantData.resturant_name,
            website: tempcleanurl,
          };
          let location_data = {
            resturant_address: resturantData?.resturant_address,
            resturant_website: resturantData?.resturant_website,
          };
          dispatch(storeInLocalStore({ profile_data: profile_info }));
          dispatch(storeInLocalStore({ location_data: location_data }));
          setMainloader(false);
          setOpenprofilecreate(null);
        }
      })
      .catch((err) => {
        console.log("Error ", err);
        setMainloader(false);
        handleClick();
      });
  };
  useEffect(() => {
    getMainTemplatedata();
    fetchData();
  }, []);
  const savecustomtheme = () => {
    setOpencustomtheme(true);
  };
  const handleClosethmesave = () => {
    setOpencustomtheme(false);
  };
  const savecustomthemedata = (data) => {
    let payload = {
      profile_id: profile_info?.profile_id,
      title: data.title,
      description: data.description,
      image_url_for_card: "",
      image_url_for_tab: "",
    };
    createthemebyuserAPI(payload)
      .then((res) => {
        if (res.status == "success") {
          if (data.image) {
            let payloadimg = {
              image: data.image,
            };
            usercustomthemeImageUploadAPI(
              profile_info?.profile_id,
              res.template._id,
              payloadimg
            )
              .then((res) => {
                if (res.status == "success") {
                  handleClosethmesave();
                  getMainTemplatedata();
                }
              })
              .catch((rej) => {
                console.log("error", rej);
              });
          }
          getMainTemplatedata();
        }
      })
      .catch((rej) => {
        console.log("Error:", rej);
      });
  };
  return (
    <>
      {mainloader ? (
        <>
          <DashboardLoader />
        </>
      ) : (
        <>
          <div className="tab-view">
            <div className="row">
              <div className="col-md-12">
                <div className="content-padding-both calender">
                  <MultiStepBar
                    steps={data}
                    active={currentStep}
                    handlechange={handletabchange}
                  />
                </div>
              </div>
            </div>
            {/* {currentStep == 0 && (
              <Origin
                originlastfive={originlastfive}
                mystory={false}
                handletabchange={handletabchange}
              />
            )}
            {currentStep == 1 && (
              <Location
                locationlastfive={locationlastfive}
                mystory={false}
                handletabchange={handletabchange}
              />
            )}
            {currentStep == 2 && (
              <Cuisine
                cuisinelastfive={cuisinelastfive}
                mystory={false}
                handletabchange={handletabchange}
              />
            )} */}
            {currentStep == 3 && (
              <Theme savecustomtheme={savecustomtheme}>
                <Themesliders
                  templateAll={templateAll}
                  selectedtheme={selectedtheme}
                  selectedthemeid={selectedthemeid}
                  users={users}
                  handleClick={handleClick}

                  // Saved theme
                />
              </Theme>
            )}
            {currentStep == 4 && (
              <Monthlycalender
                allData={monthlycalenderdata}
                handletabchange={handletabchange}
                fetchData={fetchData}
              />
            )}
            {openthemesetting && (
              <Descriptionset
                open={openthemesetting}
                cancleHandle={cancleClosedescription}
                profile={profile_info}
                createmonthlycalendar={createmonthlycalendar}
                handletabchange={handletabchange}
              />
            )}
            {openprofilecreate && (
              <ProfileSetup
                openprofilecreate={openprofilecreate}
                setOpenprofilecreate={setOpenprofilecreate}
                handlecreateprofile={handlecreateprofile}
              />
            )}
          </div>
          {opencustomtheme && (
            <Savecustomtemplate
              open={opencustomtheme}
              savecustomthemedata={savecustomthemedata}
              cancleHandle={handleClosethmesave}
            />
          )}
        </>
      )}
    </>
  );
};

export default Tabview;
