import { Box, Divider, Fab, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "./CustomButton/CustomButton";
import rightArrowIcon from "../../assets/SVG/white-right-arrow.svg";

const style = {
  checkoutBox: {
    width: "35%",
    height: "60vh",
    height: "70vh",
    display: "inline-flex",
    padding: "24px 16px 24px 32px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "40px",
    position: "fixed",
    right: "5%",
    bottom: "5%",
    borderRadius: "20px",
    background: "#181818",
    zIndex: 50,
  },
  text: {
    color: "#9E9E9E",
    fontFamily: "SFProText-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  text2: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "20px",

    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "1.2",
    textTransform: "uppercase",
  },
};

const CheckoutPanel = ({ setCheckout, checkout, data }) => {
  const totalPrice =
    data?.reduce((acc, element) => acc + element.price, 0) || 0;

  return (
    <>
      {checkout && (
        <Box sx={style.checkoutBox}>
          <Box sx={{ width: "100%", height: "100%" }}>
            {data?.map((element, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ margin: "5% 0%" }}
                >
                  <Stack direction="column" spacing={2}>
                    <Typography sx={style.text}>Campaign</Typography>
                    <Typography sx={style.text2}>{element.campaign}</Typography>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <Typography sx={style.text}>Price</Typography>
                    <Typography sx={style.text2}>${element.price}</Typography>
                  </Stack>
                </Stack>
              );
            })}
            <Divider sx={{ backgroundColor: "#FCFCFC", margin: "10% 0%" }} />
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography sx={style.text2}>Total</Typography>
              <Typography sx={style.text2}>${totalPrice}</Typography>
            </Stack>
            <CustomButton
              label="Checkout"
              sx={{ width: "100%", margin: "10% 0%" }}
            />
          </Box>
          <Fab
            sx={{
              position: "absolute",
              left: "-5%",
              bottom: "40%",
              background: "#eb2426",
              "&:hover": {
                background: "#eb2426",
              },
            }}
            onClick={() => setCheckout(false)}
          >
            <img src={rightArrowIcon} alt="error" />
          </Fab>
        </Box>
      )}
    </>
  );
};

export default CheckoutPanel;
