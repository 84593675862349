import React from "react";
import horizontaldots from "../../../assets/images/create-calender/horizontal-dots.png";
import staticlogo from "../../../assets/images/create-calender/static-logo.png";
import dummypost from "../../../assets/images/create-calender/dummy-image.png";
import horizontallike from "../../../assets/images/create-calender/horizontal-like.png";
import { useSelector } from "react-redux";
const Instagram = ({ data }) => {
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const displayText =
    data?.prompt_output_text ||
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
  const displayedText =
    displayText.length > 45 ? displayText.slice(0, 45) + "..." : displayText;

  return (
    <>
      <div className="social-view">
        <div className="social-view-header">
          <div className="header-item">
            <div className="resname-container">
              <div className="logo-item">
                <img
                  src={
                    profile_info?.website
                      ? `https://s2.googleusercontent.com/s2/favicons?domain=${profile_info?.website}`
                      : staticlogo
                  }
                  className="reslogo"
                  onError={(e) => {
                    e.target.src = staticlogo;
                  }}
                />
              </div>
              <div className="resname-item">
                <span className="name">
                  {profile_info?.resturant_name || "ilmelograno"}
                </span>
              </div>
            </div>
          </div>
          <div className="header-item">
            <img src={horizontaldots} className="horizontal-dots" />
          </div>
        </div>
        <div className="post-view">
          <div className="post-image">
            <img
              src={data?.prompt_output_img_url || dummypost}
              className="w-100"
              onError={(e) => {
                e.target.src = dummypost;
              }}
            />
          </div>
          <div className="like-main-container">
            <img src={horizontallike} className="w-100" />
          </div>
          <div className="description-container">
            <p className="font-tiny">{displayedText}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instagram;
