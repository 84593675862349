import React from "react";
import WrapperComp from "../../components/WrapperComp/index.jsx";
import { Button, Stack, Typography } from "@mui/material";
import style from "./congrate.js";

const Congratulation = () => {
  return (
    <>
      <WrapperComp
        SingleCompnents={
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography sx={style.boldText} variant="h4">
              Congratulations
            </Typography>
            <Typography sx={style.text} variant="p">
              Your calendar was successfully created!
            </Typography>
            <Button sx={style.loginButton} variant="contained">
              Let’s Go Home
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default Congratulation;
