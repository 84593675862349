import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

const style = {
  container: {
    margin: "2% 0%",
    width: "50%",
    height: "70px",
    borderRadius: "20px",
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    background: "#240A00",
  },
  highlightBtn: {
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flex: "1 0 0",
    borderRadius: "20px",
    background: "#eb2426",
    cursor: "pointer",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    flex: "1 0 0",
    borderRadius: "20px",
    cursor: "pointer",
  },
  highlightText: {
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "helvetica-font",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "38px" /* 146.154% */,
    textTransform: "uppercase",
  },
  text: {
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "helvetica-font",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "38px" /* 146.154% */,
    textTransform: "uppercase",
  },
};

const MediaSwitch = ({ toggleSwitch, mediaSwitch }) => {

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Box sx={style.container}>
          <Box
            onClick={()=>toggleSwitch(1)}
            sx={mediaSwitch === 1 ? style.highlightBtn : style.btn}
          >
            <Typography
              sx={mediaSwitch === 1 ? style.highlightText : style.text}
            >
              My Social Media
            </Typography>
          </Box>
          <Box
            onClick={()=>toggleSwitch(2)}
            sx={mediaSwitch === 2 ? style.highlightBtn : style.btn}
          >
            <Typography
              sx={mediaSwitch === 2 ? style.highlightText : style.text}
            >
              My Media
            </Typography>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default MediaSwitch;
