import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    profile_id: "",
    resturant_name: "",
    resturant_address: "",
    resturant_website: "",
    img_url: "",
    promt_text: "",
    cuisine: "",
    origins: "",
    vibe: "",
  },
  reducers: {
    createLocation: (state, actions) => {
      return {
        ...state,
        ...actions.payload,
      };
    },
  },
});

export const { createLocation } = locationSlice.actions;

export default locationSlice.reducer;
