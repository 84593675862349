import React from "react";
import PropTypes from "prop-types";
import "./Phonewrapper.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import logo from "./../../../assets/images/vertical_container.png";
const PhoneWrapper = ({ children, template, name, iconurl, hide }) => {
  function headerData() {
    return (
      <>
        <div className="postheader">
          <div className="left-header">
            <div className="left-wrapper">
              <div className="logo">
                <img className="logo-img" src={iconurl || logo} />
              </div>
              <div className="name-area">
                <p className="name">{name}</p>
                {template != "googleads" && (
                  <p className={` sponsored ${hide}`}>Sponsored</p>
                )}
              </div>
            </div>
          </div>
          <div className="right-header">
            {template != "googleads" && <MoreHorizIcon />}
          </div>
        </div>
      </>
    );
  }

  if (template == "sms") {
    return (
      <>
        <div className="instagramContainer sms">
          <div className="innerwrapper-sms">{children}</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="instagramContainer common">
          <div className="innerwrapper">
            {headerData()}
            {children}
          </div>
        </div>
      </>
    );
  }
};

PhoneWrapper.propTypes = {
  children: PropTypes.node,
  template: PropTypes.oneOf(["sms", "common"]).isRequired,
  name: PropTypes.node,
  iconurl: PropTypes.node,
  hide: PropTypes.node,
};
export default PhoneWrapper;
