const style = {
  mainContainer: {
    padding: 0,
    maxWidth: "100%",
    position: "relative",
  },
  textContainer: {
    paddingLeft: "135px",
  },
  text: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.2",
  },
  circleContainer: {
    position: "relative",
    margin: "20px",
    width: "100%",
    height: { sm: "650px", md: "650px", lg: "750px", xl: "800px" },
    borderRadius: "0 0 650px 650px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: "blur(8px)",
  },
  ipadBox: {
    position: "absolute",
    width: "50%",
    height: "80%",
    top: "10%",
    left: "25%",
  },
  btnBox: {
    position: "absolute",
    top: "75px",
    right: "60px",
  },
  browseBtn: {
    width: "100%",
    color: "#eb2426",
    backgroundColor: "#FFF",
    fontFamily: "helvetica-font",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "normal",
    "&:hover": {
      backgroundColor: "#eb2426",
      boxShadow: "none",
      color: "#fff",
      cursor: "pointer",
    },
  },
  createText: {
    color: "#fff",
    fontFamily: "helvetica-font",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "normal",
  },
  ipadFrame: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  imgStyle: {
    width: "91.5%",
    height: "88%",
    marginBottom: "15px",
    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  textCover: {
    position: "absolute",
    color: "var(--semiwhite)",
    fontFamily: "helvetica-font",
    fontSize: "60px",
    fontStyle: "normal",
    lineHeight: "60px",
    // textAlign: "center",
    textTransform: "uppercase",
    top: "20%",
    left: "-26%",
  },
  textContent: {
    position: "absolute",
    color: "#FCFCFC",
    fontFamily: "helvetica-font",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    // lineHeight: "28px",
    // textAlign: "center",
    textTransform: "none",
    top: "45%",
    left: "-26%",
    width: "40%",
  },
  frame: {
    position: "absolute",
    height: "220px",
    width: "150px",
  },
  eventFrame: {
    left: "15%",
    top: "70%",
  },
  offerFrame: {
    left: "40%",
    top: "75%",
  },
  rewardsFrame: {
    left: "65%",
    top: "70%",
  },
};

export default style;
