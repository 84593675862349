import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./EventsImagesStyle";
import RightArrow from "../../Common/RightArrow/RightArrow";
import displayImage from "../../../assets/images/IpadDisplayImage.png";
import ipadFrame from "../../../assets/images/IpadImage.png";
import ImageCards from "../../Common/ImageCards";
import CustomButton from "../../Common/CustomButton/CustomButton.jsx";
import { useCompaignData } from "../../../Context/CampaignContext";
import { useNavigate } from "react-router-dom";
import { createCampaignAPI } from "../../../apis/auth";
import { useDispatch, useSelector } from "react-redux";
import CreatedButton from "./CreatedButton";
import {
  storeInLocalStore,
  settogodownposition,
} from "../../../redux/reducers/mainSlice";
import CircularProgress from "@mui/material/CircularProgress";

const EventsImage = ({ eventpage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [compaignData, setCompaignData] = useCompaignData();
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const campaignstoreData = useSelector(
    (state) => state.main.store?.campaignsList
  );
  const campaignStoreUnit = useSelector(
    (state) => state.main.store.campaignUnit
  );
  const [submitted, setSubmitted] = useState(false);
  const firstName = eventpage ? eventpage.split(" ") : "";
  const handleSave = () => {
    setLoading(true);
    let unitId = compaignData.map((item) => item.id);
    let data = {
      profile_id: profile_id,
      name: eventpage,
      add_unit_ids: unitId,
      custom_theme_id:eventTitleID
    };
    console.log('data',data)
    // createCampaignAPI(data)
    //   .then((res) => {
    //     if (res.status == "success") {
    //       let data = {
    //         id: res.campaign.id,
    //         name: res.campaign.name,
    //       };
    //       let ss = [];
    //       if (campaignstoreData?.length > 0) {
    //         ss = [...campaignstoreData, data];
    //       } else {
    //         ss = [data];
    //       }
    //       dispatch(storeInLocalStore({ campaignsList: ss }));
    //       setLoading(false);
    //       navigate("/saved-campaign");
    //       dispatch(storeInLocalStore({ campaignUnit: [] }));
    //       setCompaignData([]);
    //     } else {
    //       setLoading(false);
    //       dispatch(storeInLocalStore({ campaignUnit: [] }));
    //       setCompaignData([]);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Err ", err);
    //     setLoading(false);
    //   });
  };
  const handleUpdate = () => {

  }
  const handlePrevious = () => {
    if (index > 0 && compaignData.length > 3) {
      let _index = index - 1;
      let filterData = compaignData?.filter(
        (element, i) => i >= _index && i <= _index + 2
      );
      setData(filterData);
      setIndex(_index);
    }
  };

  const handleNext = () => {
    if (index < compaignData.length - 3 && compaignData.length > 3) {
      let newIndex = index + 1;
      let filterData = compaignData?.filter(
        (element, i) => i >= newIndex && i < newIndex + 3
      );
      setData(filterData);
      setIndex(newIndex);
    }
  };

  useEffect(() => {
    let _data = compaignData?.filter((element, i) => i < 3);
    setData(_data);
  }, [compaignData]);
  useEffect(() => {
    if (campaignStoreUnit) {
      setCompaignData(campaignStoreUnit);
    }
  }, [campaignStoreUnit]);
  // useEffect(() => {
  //   dispatch(storeInLocalStore({ campaignUnit: [] }));
  //   dispatch(storeInLocalStore({ campaignsList: [] }));
  // }, []);
  const browsertopic = () => {
    dispatch(settogodownposition(true));
    navigate("/");
  };
  console.log(data,'DATA')
  return (
    <>
      <div className="customcontainerglobal">
        <Box sx={style.mainContainer}>
          <Stack sx={style.textContainer} direction="row" spacing={2}>
            <Typography sx={style.text}>Viewers</Typography>
            <RightArrow />
            <Typography sx={{ ...style.text, opacity: "0.30000001192092896" }}>
              Visitors
            </Typography>
            <RightArrow sx={{ opacity: "0.30000001192092896" }} />
            <Typography sx={{ ...style.text, opacity: "0.30000001192092896" }}>
              1st Time Customers
            </Typography>
            <RightArrow sx={{ opacity: "0.30000001192092896" }} />
            <Typography sx={{ ...style.text, opacity: "0.30000001192092896" }}>
              Reg Customers
            </Typography>
          </Stack>
          <Box
            sx={{
              ...style.circleContainer,
              backgroundImage: `url(${displayImage})`,
            }}
          ></Box>

          <Box sx={style.btnBox}>
            {campaignstoreData?.length > 0 && <CreatedButton />}
          </Box>
          <Box sx={style.ipadBox}>
            <Box
              sx={{ ...style.ipadFrame, backgroundImage: `url(${ipadFrame})` }}
            >
              <Box
                sx={{
                  ...style.imgStyle,
                  backgroundImage: `url(${displayImage})`,
                }}
              >
                <Stack direction="column" spacing={2}>
                  {compaignData?.length > 0 && !submitted && (
                    <>
                      {loading ? (
                        <>
                          <Button
                            sx={{
                              width: "100%",
                              height: "51px",
                              padding: "16px 32px 16px 32px",
                              borderRadius: "50px",
                              gap: "8px",
                              backgroundColor: "#eb2426",
                              color: "#FCFCFC",
                              textAlign: "center",
                              fontFamily: "SF Pro Display",
                              textTransform: "none",
                              cursor: "pointer",
                              zIndex: "9",
                              "&:hover": {
                                backgroundColor: "#eb2426",
                                boxShadow: "none",
                                cursor: "pointer",
                              },
                              "&:active": {
                                boxShadow: "none",
                                backgroundColor: "#eb2426",
                                cursor: "pointer",
                              },
                              "&:hover": {
                                backgroundColor: "#eb2426",
                                boxShadow: "none",
                                cursor: "pointer",
                              },
                              "&:active": {
                                boxShadow: "none",
                                backgroundColor: "#eb2426",
                                cursor: "pointer",
                              },
                            }}
                            disabled={true}
                          >
                            <Typography
                              sx={{
                                color: "white",
                              }}
                            >
                              Creating campaign
                            </Typography>{" "}
                            <CircularProgress
                              size={30}
                              sx={{
                                color: "white",
                              }}
                            />
                          </Button>
                        </>
                      ) : (
                        <>
                          <CustomButton
                            onClick={handleSave}
                            sx={{ width: "100%", fontFamily: "SF Pro Display" }}
                            label="Save the Campaign"
                            disabling={loading}
                          />
                        </>
                      )}
                    </>
                  )}
                  <CustomButton
                    sx={style.browseBtn}
                    label="Browse Other Topics"
                    onClick={browsertopic}
                    disabling={loading}
                  />
                </Stack>
                {/* <Box
                sx={{
                  width: "100%",
                  height: "52vh",
                  top: "48%",
                  left: "0%",
                  position: "absolute",
                }}
              >
                {compaignData && (
                  <ImageCards
                    data={data}
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                  />
                )}
              </Box> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "block",
                position: "absolute",
                width: "100%",
                top: "360px",
                left: "0px",
              }}
            >
              {compaignData && (
                <ImageCards
                  data={data}
                  handlePrevious={handlePrevious}
                  handleNext={handleNext}
                />
              )}
            </Box>
            <Typography sx={style.textCover}>
              {firstName.length > 0 ? (
                <>
                  {firstName[0]} <br /> {firstName.slice(1).join(" ")}
                </>
              ) : (
                <>
                  AUTUMN <br /> Season
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default EventsImage;
