import React from "react";
import logoicon from "../../../assets/images/create-calender/static-logo.png";
import horizontaldots from "../../../assets/images/create-calender/single-post-horizontal-dots.png";
import postdummyimage from "../../../assets/images/create-calender/dummy-image.png";
import loveicon from "../../../assets/images/create-calender/single-post-love.png";
import chaticon from "../../../assets/images/create-calender/single-post-chat.png";
import sendicon from "../../../assets/images/create-calender/single-post-send.png";
import bookmark from "../../../assets/images/create-calender/bookmark.png";
import likeimg from "../../../assets/images/mysocialmedia/like-face-one.png";
import password from "../../../assets/images/mysocialmedia/pasword.png";
import Comments from "./Comments";
import { useSelector } from "react-redux";

const Singlepost = ({
  data,
  commentdata,
  addreply,
  likeanddislikefunc,
  currentdesign,
}) => {
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  return (
    <div className="single-post">
      <div className="header-top">
        <div className="logoandname">
          <div className="logo">
            <img
              src={
                profile_info?.website
                  ? `https://s2.googleusercontent.com/s2/favicons?domain=${profile_info?.website}`
                  : logoicon
              }
              className="logo-img"
              onError={(e) => {
                e.target.src = logoicon;
              }}
            />
          </div>
          <div className="res-name">
            {profile_info?.resturant_name || "ilmelograno"}
          </div>
        </div>
        <div className="dots">
          <img src={horizontaldots} className="horizonal-dots" />
        </div>
      </div>
      <div className="description-text">{data?.prompt_output_text || ""}</div>
      <div className="post-image">
        <img
          src={data?.prompt_output_img_url || postdummyimage}
          onError={(e) => {
            e.target.src = postdummyimage;
          }}
        />
      </div>
      {currentdesign == "fb" ? (
        <div className="instagram-container">
          <div className="like-area">
            <img
              src={likeimg}
              style={{
                width: "50px",
              }}
            />
            {data?.matrics?.post_total_reactions || "0"}
          </div>
          <div className="like-area">
            <img
              src={password}
              style={{
                width: "30px",
              }}
            />
            {data?.matrics?.post_unique_impressions || "0"}
          </div>
        </div>
      ) : (
        <div className="instagram-container">
          <div className="like-area">
            <img src={loveicon} />
            <img src={chaticon} />
            <img src={sendicon} />
          </div>
          <div className="bookmark-area">
            <img src={bookmark} />
          </div>
        </div>
      )}

      <div className="comment-container scroll-design">
        {commentdata.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <Comments
                data={data}
                addreply={addreply}
                likeanddislikefunc={likeanddislikefunc}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Singlepost;
