import { Box } from "@mui/material";
import React from "react";
import whitePointImg from "../../assets/SVG/White_Ellipse.svg";
import rightArrow from "../../assets/SVG/dropdown_arrow3.svg";

const style = {
  nextBtnBox: {
    width: "10%",
    height: "100%",
    // marginBottom: "15px",
    // backgroundImage: `url(${whitePointImg})`,
    background: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
};

const PreviousBtn = ({ handlePrevious, width }) => {
  return (
    <>
      <Box onClick={handlePrevious} sx={{ ...style.nextBtnBox, width: width ? width : '10%' }}>
        <img width="100%" height="100%" src={rightArrow} alt="error" />
      </Box>
    </>
  );
};

export default PreviousBtn;
