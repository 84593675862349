import React from "react";
import aiimage from "./../../assets/img/data-ai.png";
import Homesections from "../Common/Homesections";

const Sectionthree = () => {
  return (
    <Homesections
      bgclass={"bg-one"}
      aiimage={aiimage}
      title={`<span>MEDIA</span>`}
      subtitle={`AI Managed Real Time Engagement`}
      content={`<p>AI driven media management allows for increased retention and growth in customer lifetime value.</p>`}
      right={false}
      videourl={
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
      }
    />
  );
};

export default Sectionthree;
