import React, { useEffect } from "react";
import Banner from "../components/homecomponents/Banner";
import Sectionone from "../components/homecomponents/Sectionone";
import Sectiontwo from "../components/homecomponents/Sectiontwo";
import Sectionthree from "../components/homecomponents/Sectionthree";
import Sectionfour from "../components/homecomponents/Sectionfour";
import Sectionfive from "../components/homecomponents/Sectionfive";
import { useDispatch } from "react-redux";
import { storeInLocalStore } from "../redux/reducers/mainSlice";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeInLocalStore({ currentsubmenu: "" }));
    document.body.classList.add("white-home");
    return () => {
      document.body.classList.remove("white-home");
    };
  }, []);
  return (
    <>
      <Banner />
      <Sectionone />
      <Sectiontwo />
      <Sectionthree />
      <Sectionfour />
      <Sectionfive />
    </>
  );
};

export default Home;
