import React, { useEffect, useState } from "react";
import "./Emailstyle.css";
import logoImage from "../../../assets/images/VectorEmail.png";
import img1 from "../../../assets/images/emailImg1.png";
import PhoneWrapper from "../PhoneWrapper/PhoneWrapper";
import InstaView from "../InstaView/InstaView";
import EditButton from "../../Common/EditButton";
import {
  storeInLocalStore,
  selectedPlatform,
} from "../../../redux/reducers/mainSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const EmailView = ({
  data,
  restaurantName,
  loader,
  customStyle,
  operation,
  iconurl,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState(restaurantName);
  const [firstPart, setFirstPart] = useState("");
  const [lastPart, setLastPart] = useState("");
  useEffect(() => {
    const words = inputText.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop();
      const firstPart = words.join(" ");

      setFirstPart(firstPart);
      setLastPart(lastWord);
    }
  }, [inputText]);
  const handleEdit = (data) => {
    let tempdata = { ...data?.alldata, selectedplatform: "email" };
    dispatch(storeInLocalStore({ ediatablepost: tempdata }));
    dispatch(selectedPlatform("email"));
    navigate("/edit-posts");
  };
  return (
    <>
      <div className="responsive-data">
        <div className="email-wrapper">
          <div className="email-inner">
            <div className="top-logo"></div>
            {/* <img src={logoImage} className="top-logo" /> */}
            <p className="email-description">
              {data.description.split("\n").map((item, index) => {
                if (item != "." && item != "") {
                  return (
                    <React.Fragment key={index}>
                      {item}
                      <br />
                    </React.Fragment>
                  );
                }
              })}
            </p>
            <div className="btn-area">
              <button className="secondry-cta">Secondary CTA</button>
              <button className="primary-btn">Primary CTA</button>
            </div>
            <div className="email-image">
              <img src={img1} className="imgfull" />
            </div>
            <div className="event">
              <h1 className="event-name">{data?.event}</h1>
            </div>
            <div className="insta-view">
              <PhoneWrapper
                template="common"
                name={restaurantName}
                hide={"d-none"}
                iconurl={iconurl}
              >
                <InstaView
                  restaurantName={restaurantName}
                  operation="edit"
                  data={data}
                  emailview={true}
                  hide={"d-none"}
                  iconurl={iconurl}
                />
                {/* <div className="tags">
                  <p className="tags-design">
                    FREE <br /> WINE
                  </p>
                </div> */}
              </PhoneWrapper>
            </div>

            <div className="btn-area m-auto">
              <button className="primary-btn">Primary CTA</button>
            </div>
            <hr
              style={{
                background: "#E1E1E1",
                height: "0.32px",
              }}
            />
            <div className="btn-area m-auto">
              <p className="res-name">
                <span className="one">{firstPart + " "}</span>
                <span className="two">{lastPart}</span>
              </p>
            </div>
            {!data?.alldata?.is_posted && (
              <div className="editbtn">
                <EditButton
                  onClick={() => {
                    handleEdit(data);
                  }}
                  style={{ margin: "15px" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailView;
