import React from "react";
import chatimg from "../../assets/images/home/chat.svg";

const Chaticon = () => {
  return (
    <>
      {/* <img src={chatimg} className="chat-img" /> */}
    </>
  );
};

export default Chaticon;
