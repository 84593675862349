import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./assets/css/style.css";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ClerkProvider } from "@clerk/clerk-react";

const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;
const SECRET_KEY = process.env.REACT_APP_CLERK_SECRET_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <Provider store={store}>
        <RouterProvider router={appRouter} />
      </Provider>
    </ClerkProvider>
  </React.StrictMode>
);
