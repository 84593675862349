const style = {
  imageSlider: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  mobileStyle: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "250px",
    height: "520px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  secondBox: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100%",
    height: "100%",
    padding: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // paddingBottom: "20px",
    color: "red",
    "@media (max-width: 600px)": {
      maxWidth: "100vw",
    },
  },
  dotLineStyle: {
    margin: "2px",
    width: "75px",
    height: "10px",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.40)",
    marginTop: "3px",
    cursor: "pointer",
  },
  wrapperbox: {
    display: "block",
    width: "89%",
    marginTop: "4%",
  },
  instagramHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    color: "#000000",
  },
  instaHeaderText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "2.397px",
    paddingLeft: "6px",
  },
  firstInstaCont: {
    display: "flex",
    flexDirection: "row",
  },
  settingContainer: {
    // padding: "30px",
  },
  instaPicBox: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginTop: "2%",
    width: "100%",
    height: "230px",
  },
  shopBox: {
    display: "flex",

    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    alignSelf: "stretch",
  },
  shoptext: {
    color: "#1EA1F7",
    textAlign: "center",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1",
  },
  instaFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  instaBtn: {
    marginLeft: "0px",
    // padding: "0px 1px 0px 1px"
  },
  editBox: {
    display: "flex",
    alignItems: "center",
  },
  editText: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "12px",
    marginLeft: "-12px",
    cursor: "pointer",
  },
  mobBotttomBox: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  mobBotttomText: {
    color: "#000000",
    fontSize: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1",
    margin: "3px 0px",
  },
  btnContainer: {
    display: "flex",
    flexdirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  btnBox: {
    width: "30px",
    height: "30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    margin: "10px 10px 10px 0px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  uploadBtn: {
    marginTop: "40px",
    display: "inline-flex",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "50px",
    background: "rgba(255, 255, 255, 0.70)",
    backdropFilter: "blur(7.5px)",
    color: "#eb2426",
    textAlign: "center",
    fontSize: "12px",
    fontFamily: "SFProText-Regular",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "1",
    textTransform: "none",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.70)",
      color: "#eb2426",
    },
  },
  textArea: {
    marginTop: "5px",
    color: "#000000",
    fontSize: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1",
    background: "transparent",
    borderColor: "transparent",
    outline: "none",
  },
  deleteBox: {
    margin: "26px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: "50%",
    cursor: "pointer",
  },
  // Facebook Design
  facePicBox: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginTop: "0px",
    width: "100%",
    height: "230px",
  },
  faceboolikecontainer: {
    width: "96%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "9px",
  },
  like: {
    marginLeft: "-24px",
  },
  editfbtext: {
    color: "#717171",
    fontWeight: "normal",
    fontSize: "9px",
  },
  learnmore: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    alignSelf: "stretch",
    background: "#cbc9c9",
  },
  website: {
    fontSize: "10px",
    color: "#353434",
  },
  resname: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "700",
  },
  learnbutton: {
    background: "#d8d5d5",
    border: "1px solid #717171",
    borderRadius: "0px",
    padding: "1px",
    margin: "5px",
    fontSize: "7px",
    color: "#717171",
  },
};

export default style;
