import { Box } from "@mui/material";
import React, { useState } from "react";
import style from "./MediaPageStyle";
import MediaSwitch from "../../components/Common/MediaSwitch";
import MySocialMedia from "../../components/Media/MySocialMedia/MySocialMedia";
import ViewMyMedia from "../../components/Media/ViewMyMedia/ViewMyMedia";
import EditMyMedia from "../../components/Media/EditMyMedia/EditMyMedia";
import Footer from "../../components/Home/Footer/Footer";
import { useLocation } from "react-router-dom";

const MediaPage = () => {
  const location = useLocation();
  const [mediaSwitch, setMediaSwitch] = useState(1);

  const toggleSwitch = (params) => {
    setMediaSwitch(params);
  };

  return (
    <>
      <Box sx={style.container}>
        <MediaSwitch toggleSwitch={toggleSwitch} mediaSwitch={mediaSwitch} />
        {mediaSwitch === 1 && (
          <MySocialMedia
            operation={location.pathname === "/edit-media" ? "edit" : "view"}
          />
        )}
        {mediaSwitch === 2 &&
          (location.pathname === "/edit-media" ? (
            <EditMyMedia />
          ) : (
            <ViewMyMedia />
          ))}
      </Box>
    
    </>
  );
};

export default MediaPage;
