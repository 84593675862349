import React, { useEffect, useState } from "react";
import style from "./EditPostsStyle";
import { Box, Grid, Typography } from "@mui/material";
import leftArrow from "../../assets/images/Vector.png";
import CustomButton from "../../components/Common/CustomButton/CustomButton";
import ImageSlider from "./imageSlider/ImageEdit";
import img1 from "../../assets/images/mediaInstaimg4.png";
import DeletePopover from "../../components/Common/DeletePopover";
import CustomSelect from "../../components/Common/CustomSelect";
import Footer from "../../components/Home/Footer/Footer";
import ResetBtn from "../../assets/SVG/ic_round-refresh.svg";
import { useDispatch, useSelector } from "react-redux";
import Inputs from "../../components/Events/PopOver/Collectiveinput";

import {
  updatesingleunitAPI,
  unitImageuploadAPI,
  generateUnitAPI,
  getChildTemplate,
  getcampaignbyEventnameAPI,
  getcampaignbyidAPI,
  updatepostbysocialnameAPI,
  generateplatformAPI,
  deleteunitAPI,
} from "../../apis/auth";
import {
  setmediatabswitch,
  storeInLocalStore,
} from "../../redux/reducers/mainSlice";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import GlobalAlert from "../../components/Common/GlobalAlert";

const EditPosts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editableData = useSelector((state) => state.main.store.ediatablepost);
  const profile_data = useSelector((state) => state.main.store.profile_data);
  const currentCampaignname = useSelector(
    (state) => state.main.store.selectedcampaign
  );
  const allCampaignList = useSelector(
    (state) => state.main.store.campaignsList
  );
  const [options, setOptions] = useState([]);
  const [mobileLoader, setMobileLoader] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(
    editableData?.selectedplatform
  );

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [defaultInput, setDefaultInput] = useState({});
  const [platformInput, setPlatformInput] = useState({});
  const [imageUrl, setImageUrl] = useState(img1);
  const [fieldData, setFieldData] = useState([]);
  const [unitTitle, setUnitTitle] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [sidebarLoader, setSidebarLoader] = useState(false);
  const [savemessage, setSaveMessage] = useState("");
  const [allcurrentpost, setAllCurrentPot] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousDes, setPreviousDes] = useState(false);
  const handleSelected = (data) => {
    setSelectedOption(data);
    if (data != selectedOption) {
      getOtherCampaign(data);
    }
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDelete = () => {
    let currentcampaignId = allCampaignList.filter(
      (item) => item.name == currentCampaignname
    )[0].id;

    let data = {
      profile_id: profile_data.profile_id,
      camp_id: currentcampaignId,
      unitid: editableData?.id,
    };
    deleteunitAPI(data)
      .then((res) => {
        if (res.status == "success") {
          navigate("/media");
          setDeleteOpen(false);
        } else {
          setDeleteOpen(false);
        }
      })
      .catch((rej) => {
        console.log(rej);
        setDeleteOpen(false);
      });
    // setDeleteOpen(false);
  };
  const handleImageUpload = (image) => {
    const file = image.target.files[0];
    setIsLoading(true);
    const newFileName = image.target.files[0].name.replace(/\([^()]*\)/g, "");
    const modifiedFile = new File([file], newFileName, { type: file.type });
    let data = {
      profileid: profile_data.profile_id,
      unitid: editableData.id,
      image: modifiedFile,
    };
    unitImageuploadAPI(data).then((res) => {
      if (res.status == "success") {
        setIsLoading(false);
        let storedata = {
          ...editableData,
          image_url: res?.image_url,
        };
        dispatch(storeInLocalStore({ ediatablepost: storedata }));
        setImageUrl(res?.image_url);
      }
    });
  };
  const resetImage = () => {
    setIsLoading(true);
    let data = {
      profile_id: profile_data.profile_id,
      unitid: editableData.id,
      type: "img",
    };
    generateUnitAPI(data).then((res) => {
      if (res.status == "success") {
        setIsLoading(false);
        let storedata = {
          ...editableData,
          image_url: res?.data[0],
        };
        dispatch(storeInLocalStore({ ediatablepost: storedata }));
        setImageUrl(res?.data[0]);
      }
    });
  };
  const resetText = () => {
    let data = {
      profile_id: profile_data.profile_id,
      unitid: editableData.id,
      platform: selectedPlatform,
    };
    generateplatformAPI(data).then((res) => {
      if (res.status == "success") {
        let tempDes = res.addunit.other_platforms
          .filter((item) => item.platform == selectedPlatform)
          .map((item) => item.caption)[0];
        let storedata = {
          ...editableData,
          other_platforms: res.addunit.other_platforms,
        };
        dispatch(storeInLocalStore({ ediatablepost: storedata }));
        setDescription(tempDes.replace(/\n/g, ""));
      }
    });
  };
  const getTemplateData = () => {
    getChildTemplate(editableData.child_temp_id).then((res) => {
      if (res.status == "success") {
        setFieldData(res.template[0].content.fields);
        const transformedObject = res.template[0].content.fields.reduce(
          (result, item) => {
            result[item.field_name] = "";
            return result;
          },
          {}
        );
        let inputValue = {};
        let plateformData = editableData.other_platforms
          .filter((item) => item.platform == selectedPlatform)
          .map((item) => item)[0];
        if (plateformData?.input_text) {
          inputValue = JSON.parse(plateformData?.input_text);
        } else {
          inputValue = JSON.parse(editableData.input_text);
        }

        let readyField = {
          ...transformedObject,
          ...inputValue,
        };
        setInputValues(readyField);
      }
    });
  };
  const handleValueData = (fieldName, value) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [fieldName]: value,
    }));
  };
  function checkAllKeysHaveValues(obj) {
    const blankKeys = [];
    for (const key in obj) {
      if (!obj[key]) {
        blankKeys.push(key);
      }
    }
    return blankKeys;
  }
  useEffect(() => {
    if (editableData) {
      let plateformData = editableData.other_platforms
        .filter((item) => item.platform == selectedPlatform)
        .map((item) => item)[0];
      let tempDes = plateformData?.caption?.replace(/\n/g, "");
      if (plateformData?.input_text) {
        setPlatformInput(plateformData?.input_text);
      } else {
        setPlatformInput({});
      }
      let tempinput = JSON.parse(editableData?.input_text);
      setUnitTitle(editableData?.title);
      setSelectedOption(editableData.type);
      setDescription(tempDes);
      setPreviousDes(tempDes);
      setImageUrl(editableData?.image_url);
      getTemplateData();
      setDefaultInput(tempinput);
      dispatch(storeInLocalStore({ backurl: "/media" }));
    }
  }, [editableData]);
  const handleSave = () => {
    let inputField = checkAllKeysHaveValues(inputValues);
    if (inputField?.length > 0) {
      setAlertMessage(`${inputField[0]} is required.`);
    } else {
      if (description.length > 500) {
        setAlertMessage(
          "Description should not be greater than 500 characters."
        );
      } else {
        let data = {
          profile_id: profile_data.profile_id,
          addunit_id: editableData.id,
          title: unitTitle,
          input_text: JSON.stringify(defaultInput),
          description: description,
          image_url: imageUrl,
          is_deschange: description == previousDes,
        };
        updatesingleunitAPI(data).then((res) => {
          if (res.status == "success") {
            let socialdata = {
              profile_id: profile_data.profile_id,
              addunit_id: editableData.id,
              platform: selectedPlatform,
              payload: {
                data: JSON.stringify(inputValues),
                description: description,
              },
            };
            updatepostbysocialnameAPI(socialdata).then((res) => {
              if (res.status) {
                setSaveMessage("Post is saved.");
              }
            });
          }
        });
      }
    }
    setTimeout(() => {
      setAlertMessage("");
      setSuccessMessage("");
    }, 2000);
  };
  // get Other Component
  const getOtherCampaign = (data) => {
    setIsLoading(true);
    setMobileLoader(true);
    setSidebarLoader(true);
    let currentcampaignId = allCampaignList.filter(
      (item) => item.name == currentCampaignname
    )[0].id;
    let datas = {
      profile_id: profile_data?.profile_id,
      campaign_id: currentcampaignId,
      eventtype: data,
    };
    getcampaignbyEventnameAPI(datas).then((res) => {
      if (res.status == "success") {
        if (res.campaign.add_unit_ids.length > 0) {
          let currentcamp = res.campaign.add_unit_ids.find(
            (x) => x.id === editableData.id
          );
          let finalCamp = currentcamp
            ? currentcamp
            : res.campaign.add_unit_ids[0];
          setAllCurrentPot(res.campaign.add_unit_ids);
          let temPData = {
            ...finalCamp,
            platform: selectedPlatform,
          };
          dispatch(storeInLocalStore({ ediatablepost: temPData }));
        } else {
          dispatch(storeInLocalStore({ ediatablepost: editableData }));
          setSelectedOption(editableData.type);
        }
        setIsLoading(false);
        setMobileLoader(false);
        setSidebarLoader(false);
      }
    });
  };
  useEffect(() => {
    getAllOption();
    getOtherCampaign(editableData?.type);
  }, []);
  const getAllOption = () => {
    let currentcampaignId = allCampaignList.filter(
      (item) => item.name == currentCampaignname
    )[0].id;
    let datas = {
      profile_id: profile_data?.profile_id,
      campaign_id: currentcampaignId,
    };
    getcampaignbyidAPI(datas).then((res) => {
      if (res.status == "success") {
        let tempOption = res.campaign.add_unit_ids.map((item) => item.type);
        let uniqueValues = [...new Set(tempOption)];
        setOptions(uniqueValues);
      }
    });
  };
  const handlePopup = () => {
    setSaveMessage("");
  };
  const nextContent = () => {
    setIsLoading(true);
    setMobileLoader(true);
    setSidebarLoader(true);
    let indexs = (currentIndex + 1) % allcurrentpost.length;
    let nextContentData = allcurrentpost[indexs];
    dispatch(storeInLocalStore({ ediatablepost: nextContentData }));
    setCurrentIndex(indexs);
    setTimeout(() => {
      setIsLoading(false);
      setMobileLoader(false);
      setSidebarLoader(false);
    }, 1000);
  };
  return (
    <>
      <Box sx={style.container}>
        <Box className="customcontainerglobal">
          <Box sx={{ filter: deleteOpen && "blur(8px)" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={style.rightContainer}>
                  <Box align="right">
                    <img
                      style={{
                        cursor: "pointer",
                        pointerEvents: allcurrentpost.length > 1 ? "" : "none",
                      }}
                      src={leftArrow}
                      onClick={() => {
                        nextContent();
                      }}
                      alt="error"
                    />
                  </Box>
                  <Typography sx={style.headerText}>Edit</Typography>
                  <CustomSelect
                    handleSelected={handleSelected}
                    selectedOption={selectedOption}
                    options={options}
                  />
                  {sidebarLoader ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          margin: "30px 0px",
                        }}
                      >
                        <CircularProgress
                          style={{
                            color: "#FCFCFC",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      {fieldData &&
                        fieldData.length > 0 &&
                        fieldData.map((obj, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Inputs
                                obj={obj}
                                handleValue={handleValueData}
                                inputValue={inputValues}
                                currentDate={""}
                                time={""}
                              />
                            </React.Fragment>
                          );
                        })}
                      <Typography sx={style.descStyle}>Title</Typography>
                      <input
                        placeholder="$50 for 2"
                        value={unitTitle}
                        className="events"
                      />

                      <Typography sx={style.descStyle}>Description</Typography>
                      <Box sx={style.descBox}>
                        <textarea
                          cols="70"
                          rows="4"
                          charswidth="23"
                          style={style.textArea}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Box>
                      {alertMessage && (
                        <span style={{ fontSize: "12px", color: "red" }}>
                          {alertMessage}
                        </span>
                      )}
                      {successMessage && (
                        <span style={{ fontSize: "12px", color: "green" }}>
                          {successMessage}
                        </span>
                      )}
                      <Box sx={style.btnContainer}>
                        <Box onClick={resetText} sx={style.btnBox}>
                          <img src={ResetBtn} alt="ResetBtn" />
                        </Box>
                      </Box>
                    </>
                  )}

                  <CustomButton
                    sx={{
                      ...style.saveBtn,
                      ...style.descText,
                      textAlign: "center",
                      fontWeight: 900,
                    }}
                    onClick={handleSave}
                    label="Save"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageSlider
                  image={imageUrl}
                  description={description}
                  deleteIcon="true"
                  mobileLoader={mobileLoader}
                  loader={isLoading}
                  resetImage={resetImage}
                  resturantNameValue={profile_data.resturant_name}
                  handleImageUpload={handleImageUpload}
                  handleDeleteOpen={handleDeleteOpen}
                />
              </Grid>
            </Grid>
            <DeletePopover
              open={deleteOpen}
              handleClose={handleDeleteClose}
              handleDelete={handleDelete}
            />
          </Box>
          {savemessage && (
            <GlobalAlert message={savemessage} handleClose={handlePopup} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditPosts;
