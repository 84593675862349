import React, { useRef } from "react";
import phonedesign from "../../../../assets/images/create-calender/iPhone 14 Pro.png";
const Withoutvalue = ({ staticIphone, left, disable, handleImageupload }) => {
  const inputRef = useRef();
  return (
    <>
      <div className="have-nodata">
        <div className="upload-image-btn-container">
          <button
            className={disable ? "disabled" : ""}
            onClick={(e) => {
              inputRef.current.click();
            }}
          >
            Upload Image
          </button>
          <input
            ref={inputRef}
            onChange={handleImageupload}
            type="file"
            accept=".jpg, .jpeg, .png"
            hidden
          />
        </div>
        <div
          className={`${
            left ? "mobile-container-left" : "mobile-container-right"
          }`}
        >
          <div className="phone-design">
            <img src={staticIphone||phonedesign} className="w-100" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Withoutvalue;
