import React, { useEffect, useState } from "react";
import "./insight.css";
import Firsttimeviewer from "../../components/insight360/Firsttimeviewer";
import Engagementviewer from "../../components/insight360/Engagementviewer";
import Firsttimeconsumer from "../../components/insight360/Firsttimeconsumer";
import Freqdinner from "../../components/insight360/Freqdinner";
import Overview from "../../components/insight360/Overview";
import { useSelector } from "react-redux";
import Scribeoverview from "../../components/insight360/Scribeoverview";
import Corporate from "./partials/Corporate";
import Operations from "./partials/Operations";
import Locations from "./partials/Locations";

const Insightpage = () => {
  const [activetab, setActivetab] = useState();
  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  useEffect(() => {
    if (currentsubmenu) {
      setActivetab(currentsubmenu);
    }
  }, [currentsubmenu]);
  return (
    <>
      <div className="headerpadding">
        <div className="full-width">
          {activetab === "overview" && <Corporate />}
          {activetab === "firsttimeviewer" && <Operations />}
          {activetab === "engagedviewer" && <Locations />}
          {/* {activetab === "firsttimeconsumer" && <Firsttimeconsumer />}
          {activetab === "freqdinner" && <Freqdinner />}
          {activetab === "scribeoverview" && <Scribeoverview />} */}
        </div>
      </div>
    </>
  );
};

export default Insightpage;
