const allurls = {
  login: "login",
  signUp: "signup",
  verify: "verify",
  forgotPassword: "forget-password",
  checkmail: "checkmail",
  newpassword: "newpassword",
  congratulations: "congratulations",
  calendar: "calendar",
  socialmedia: "social-media-connect",
  agree: "agree",
  congratulation: "social-media-congratulation",
  events: "events",
  reviews: "reviews",
  editOffers: "edit-offers",
  editRewards: "edit-rewards",
  editEvents: "edit-events",
  firstTimeViewers: "first-time-viewers",
  savedcampaign: "saved-campaign",
  mediaViewers: "media-viewers",
  firstTimeViewers: "first-time-viewers",
  engagedViewers: "engaged-viewers",
  firstTimeConsumers: "first-time-consumers",
  media: "media",
  editMedia: "edit-media",
  viewMedia: "view-media",
  editPosts: "edit-posts",
  paymentSummary: "payment-summary",
  stories: "stories",
  dashboard: "dashboard",
  thankyou: "thank-you",
  emailverify: "confirm",
  newhome: "new-home",
  scribeai: "scribe-ai",
  createcalender: "create-calender",
  createcalendertab: "create-calender-tab",
  unifyingdigital: "unifying-digital",
  mysavedsocialmedia: "my-social-media",
  mycustomer: "my-customers",
  insight360: "insight-360",
  newevents: "new-events",
  postpreview: "post-preview",
  mypaidmedia: "my-paid-media",
  myprofile: "my-profile",
  customerjourney: "customer-journey",
  infos: "info",
  payment: "payment",
  addPayment: "add-payment",
  paymentForm: "payment-form",
  paymentHistory: "payment-history",
  boostsuccessfullurl: "boost-payment-done",
  paymentfail: "payment-failed",
  paymentdone: "payment-done",
  profileseting: "profile-setting",
  authenticate: "redirecting",
  locations: "locations",
  testhought: "corporate-data",
  customerbehaviour: "customer-behavior",
  customerdata: "customer-segment",
};

export default allurls;
