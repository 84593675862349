import React, { useState } from "react";
import "./Switch.css";
import activeinsta from "../../../../assets/images/create-calender/active-insta.png";
import deactiveinsta from "../../../../assets/images/create-calender/deactive-insta.png";
import activefacebook from "../../../../assets/images/create-calender/active-facebook.svg";
import deactivefacenook from "../../../../assets/images/create-calender/deactive-facebook.png";
const Designswitch = ({ currenttab, handleChange }) => {
  const [activetab, setActivetab] = useState(currenttab);
  const handletogle = (data) => {
    setActivetab(data);
    handleChange(data);
  };
  return (
    <div className="switchbtn">
      <div
        className={`item-section ${activetab == "insta" ? "active" : ""}`}
        onClick={() => {
          handletogle("insta");
        }}
      >
        <img
          src={activetab == "insta" ? activeinsta : deactiveinsta}
          className="icon "
        />
      </div>
      <div
        className={`item-section ${activetab == "fb" ? "active" : ""}`}
        onClick={() => {
          handletogle("fb");
        }}
      >
        <img
          src={activetab == "fb" ? activefacebook : deactivefacenook}
          className="icon"
        />
      </div>
    </div>
  );
};

export default Designswitch;
