import React, { useEffect, useState } from "react";

import facebookicon from "../../assets/images/header/social-connect-facebook.png";
import instagramicon from "../../assets/images/header/social-connect-instagram.png";
import tickicon from "../../assets/images/header/social-connect-tick.png";
import googleicon from "../../assets/images/myprofile/google.png";
import twitter from "../../assets/images/myprofile/twitter.png";
import yelp from "../../assets/images/myprofile/yelp.png";
const Socialmedia = ({ profileData, handleFacebook, setyelpConnect }) => {
  return (
    <>
      <div className="row py-4">
        <div className="col-md-12">
          <div className="content-padding-both">
            <div className="basic-card social-media-wrapper">
              <h3 className="basic-title">Connect Your Accounts</h3>
              <div className="row align-items-center py-2">
                <div className="col-md-4">
                  <div className="social-input-wrapper">
                    <input
                      type={"text"}
                      value={profileData?.fb_user_name || ""}
                      className="custom-input social-connect"
                      readOnly
                    />
                    <img src={facebookicon} className="left-icon" />
                    {profileData?.fb_connected ? (
                      <>
                        <div className="right-icon">
                          <img src={tickicon} />
                          Connected
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="right-icon">
                          <button
                            onClick={handleFacebook}
                            className="social-connect-btn"
                          >
                            Connect
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="social-input-wrapper">
                    <input
                      type={"text"}
                      value={profileData?.fb_user_name || ""}
                      className="custom-input social-connect"
                      readOnly
                    />
                    <img src={instagramicon} className="left-icon" />
                    {profileData?.insta_connected ? (
                      <>
                        <div className="right-icon">
                          <img src={tickicon} />
                          Connected
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="right-icon">
                          <button
                            onClick={handleFacebook}
                            className="social-connect-btn"
                          >
                            Connect
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-4"></div> */}
                {/* <div className="col-md-4">
                  <div className="social-input-wrapper">
                    <input
                      type={"text"}
                      value={""}
                      className="custom-input social-connect"
                      readOnly
                    />
                    <img src={googleicon} className="left-icon" />
                    {profileData?.google_connected ? (
                      <>
                        <div className="right-icon">
                          <img src={tickicon} />
                          Connected
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="right-icon">
                          <button
                            onClick={handleFacebook}
                            className="social-connect-btn"
                          >
                            Connect
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="social-input-wrapper">
                    <input
                      type={"text"}
                      value={""}
                      className="custom-input social-connect"
                      readOnly
                    />
                    <img src={twitter} className="left-icon" />
                    {connect ? (
                      <>
                        <div className="right-icon">
                          <img src={tickicon} />
                          Connected
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="right-icon">
                          <button
                            onClick={handleFacebook}
                            className="social-connect-btn"
                          >
                            Connect
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="social-input-wrapper">
                    <input
                      type={"text"}
                      value={profileData?.yelp_business_id}
                      className="custom-input social-connect"
                      readOnly
                    />
                    <img src={yelp} className="left-icon" />
                    {profileData?.yelp_connected ? (
                      <>
                        <div className="right-icon">
                          <img src={tickicon} />
                          Connected
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="right-icon">
                          <button
                            onClick={() => {
                              setyelpConnect(true);
                            }}
                            className="social-connect-btn"
                          >
                            Connect
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Socialmedia;
