const style = {
  mainContainer: {
    backgroundColor: "transprant",
    width: "35%",
    height: "8vh",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    padding: "2px",
    cursor: "pointer",
    border: "2px solid #FCFCFC",
  },
  mainContainerMob: {
    backgroundColor: "transparent",
    width: "60px",
    height: "30px",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "-5px",
    marginTop: "0px",
    cursor: "pointer",
    border: "1px solid #eb2426",
    zIndex: 1001
  },
  activeCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    height: "8vh",
    backgroundColor: "#FCFCFC",
    color: "#000",
    borderRadius: "50%",
    transition: "transform 0.3s",
  },
  activeCircleMob: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    backgroundColor: "#eb2426",
    color: "#000",
    borderRadius: "50%",
    transition: "transform 0.3s",
    cursor: "pointer",
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    height: "8vh",
    backgroundColor: "transparent",
    borderRadius: "50%",
    transition: "transform 0.3s",
  },
  circleMob: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    backgroundColor: "transparent",
    borderRadius: "50%",
    transition: "transform 0.3s",
    cursor: "pointer",
  },
};

export default style;
