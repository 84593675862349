import React from "react";
import "./paymentstyle.css";
import macimage from "../../assets/images/Payment/mac-payment.png";
import { Link } from "react-router-dom";
const PaymentFail = () => {
  return (
    <div className="full-width headerpadding boost-congratulation ">
      <div className="content-padding-both py-5">
        <div className="row py-5">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <div className="card custom-card">
                <div className="image-container">
                  <img src={macimage} className="w-100" />
                </div>
                <h3 className="congratulation-text">Failed!</h3>
                <p className="congratulation-message">
                  Your Payment is Failed.
                </p>
                <div className="image-container">
                  <Link to={"/"} className="byteready-btn byteready-primary">
                    Try Again
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFail;
