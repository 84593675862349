import React from "react";
import aiimage from "./../../assets/img/data-ai.png";
import Homesections from "../Common/Homesections";

const Sectionone = () => {
  return (
    <Homesections
      bgclass={"bg-one"}
      aiimage={aiimage}
      title={`DATA-<span>AI</span> <br/>DRIVEN`}
      subtitle={`Data Transformation: Making Data Actionable in Real Time`}
      content={`<p>Transform Fragmented Data Sources into Clear Consumer Profiles</p> <p>Byte Ready's AI models provide real time actionable insight</p>`}
      right={false}
      videourl={
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
      }
    />
  );
};

export default Sectionone;
