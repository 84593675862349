import React, { useState, useEffect, useRef } from "react";
import locationimg from "../../../assets/images/home/location.png";
import Imageview from "../../Common/ImageView/Imageview";
import { useDispatch, useSelector } from "react-redux";
import {
  updateResturantDataAPI,
  getCaptionsAPI,
  imageUpload,
  updateCaptionAPI,
} from "../../../apis/auth";
import Airesult from "../Airesult/Airesult";
import AIsavebutton from "../Commonbtn/AIsavebutton";
import phone from "../../../assets/images/create-calender/location-iphone.png";
import { handleAddressglobal } from "../../../CustomHook/addressUtills";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import GlobalAlert from "../../Common/GlobalAlert";
import Loadericon from "../../Common/Loadericon";
import Allhistory from "../History/Allhistory";
const Location = ({ locationlastfive, handletabchange }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"],
  };
  const autoCompleteRef = useRef();
  const [disablebtn, setDisablebtn] = useState(false);
  const [savebtndisable, setSavebtndisable] = useState("");
  const [savebtnworking, setSavebtnworking] = useState(false);
  const [zipcode, setZipcode] = useState(true);
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const location_storedata = useSelector(
    (state) => state.main.store?.location_data
  );
  const location_storeoutput = useSelector(
    (state) => state.main.store?.location_output
  );
  const [savemessage, setSaveMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [locationData, setlocationData] = useState({
    profile_id: "",
    restaurant_name: "",
    restaurant_website: "",
    restaurant_address: "",
    vibe: "",
    cuisine_story: "",
    output_image: "",
    date: "",
  });
  const [errors, setErrors] = useState({});
  const [addreesvalue, setaddressvalue] = useState();
  const [locationOut, setlocationOutput] = useState({
    text: "",
    image: "",
    date: "",
  });

  const savecaption = () => {
    if (
      location_storeoutput?.output_text == locationData?.cuisine_story &&
      locationData.output_image == location_storeoutput?.output_image &&
      locationData.restaurant_website ==
        location_storedata?.resturant_website &&
      locationData.restaurant_address ==
        location_storedata?.resturant_address &&
      locationData.vibe == location_storedata?.vibe
    ) {
      setSaveMessage("Day 2 post created successfully.");
    } else {
      let payload = {
        profile_id: locationData?.profile_id,
        resturant_name: locationData?.restaurant_name,
        resturant_address: locationData?.restaurant_address?.trim(),
        resturant_website: locationData?.restaurant_website,
        vibe: locationData?.vibe,
        origins: locationData.cuisine_story,
        caption_type: "location",
        caption_response: "custom",
        count: 1,
      };

      setLoader(true);
      getCaptionsAPI(payload)
        .then((res) => {
          if (res.status) {
            setlocationData({
              ...locationData,
              cuisine_story:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                locationData?.cuisine_story,
              output_image:
                res.captions?.prompt_output_img_url ||
                locationData?.output_image,
              date: res.captions?.date || locationData.date,
            });
            let tempcleanurl = locationData?.restaurant_website
              .replace(/^https?:\/\//, "")
              // .replace(/^www\./, "")
              .replace(/\/$/, "");
            let profile_info = {
              profile_id: locationData.profile_id,
              resturant_name: locationData.restaurant_name,
              website: tempcleanurl,
            };
            let location_output = {
              output_image:
                res.captions?.prompt_output_img_url ||
                locationData?.output_image,
              output_text:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                locationData?.cuisine_story,
              date: res.captions?.date || locationData?.date,
            };
            let location_data = {
              resturant_address: locationData?.restaurant_address,
              resturant_website: locationData?.restaurant_website,
              vibe: locationData?.vibe,
            };
            dispatch(storeInLocalStore({ location_data: location_data }));
            dispatch(storeInLocalStore({ location_output: location_output }));
            dispatch(storeInLocalStore({ profile_data: profile_info }));
            updateretaurant();
            setTimeout(() => {
              setSaveMessage("Day 2 post created successfully.");
            }, 500);
            setLoader(false);
          }
        })
        .catch((res) => {
          console.log(res);
          setLoader(false);
        });
    }
  };
  const cancleoutput = () => {
    setlocationOutput({
      text: "",
      image: "",
      date: "",
    });
  };
  const handleEditAPI = (textpayload) => {
    setLoader(true);
    let data = {
      profile_id: locationData?.profile_id,
      prompt_output_text: textpayload || locationData?.cuisine_story,
      prompt_output_img_url: locationOut?.image || locationData?.output_image,
      date: locationOut?.date || locationData?.date,
    };

    updateCaptionAPI(data)
      .then((res) => {
        if (res?.status) {
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  };

  const applyOutput = (data) => {
    if (data.trim() != "") {
      handleEditAPI(data);
    }
    setlocationData({
      ...locationData,
      cuisine_story: data || locationOut.text,
      output_image: locationOut.image,
      date: locationOut.date,
    });
    setTimeout(() => {
      setlocationOutput({
        text: "",
        image: "",
        date: "",
      });
    }, 100);
  };
  const handleImageupload = (e) => {
    if (locationData.date) {
      const file = e.target.files[0];
      if (file) {
        const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
        const modifiedFile = new File([file], newFileName, { type: file.type });
        setLoader(true);
        imageUpload({
          id: locationData.profile_id,
          date: locationData.date,
          image: modifiedFile,
        })
          .then((res) => {
            if (res?.status) {
              setLoader(false);
              setlocationData({
                ...locationData,
                output_image: res.image_url,
              });
            } else {
              setLoader(false);
              setSaveMessage(res.message);
            }
          })
          .catch((rej) => {
            console.log(rej);
          });
      }
    } else {
      setSaveMessage("Please save the post before upload.");
    }
  };
  const generatecaption = (caption) => {
    if (locationData?.profile_id) {
      let payload = {};
      if (locationData.date) {
        payload = {
          profile_id: locationData?.profile_id,
          date: locationData?.date || "",
          refresh: true,
          caption_type: "N/A",
          caption_response: caption,
        };
      } else {
        payload = {
          profile_id: locationData?.profile_id,
          resturant_name: locationData?.restaurant_name,
          resturant_address: locationData?.restaurant_address?.trim(),
          resturant_website: locationData?.restaurant_website,
          vibe: locationData?.vibe,
          caption_type: "location",
          caption_response: caption,
          count: 1,
        };
      }

      setLoader(true);
      getCaptionsAPI(payload)
        .then((res) => {
          if (res.status) {
            if (caption == "image") {
              setlocationData({
                ...locationData,
                cuisine_story:
                  res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                  locationData?.cuisine_story,
                output_image:
                  res.captions?.prompt_output_img_url ||
                  locationData?.output_image,
                date: res.captions?.date || locationData.date,
              });
            } else {
              if (caption == "text") {
                setlocationOutput({
                  ...locationOut,
                  text: res.captions?.prompt_output_text?.replace(/\n/g, ""),
                });
              } else {
                setlocationOutput({
                  text: res.captions?.prompt_output_text?.replace(/\n/g, ""),
                  image: res.captions?.prompt_output_img_url,
                  date: res.captions?.date,
                });
              }
            }
            setLoader(false);
          }
        })
        .catch((res) => {
          console.log(res);
          setLoader(false);
        });
    } else {
      setSaveMessage("Please Save Day 1 Post.");
    }
  };
  const updateretaurant = () => {
    if (locationData.restaurant_name != "") {
      if (locationData?.profile_id) {
        let payload = {
          profile_id: locationData?.profile_id,
          resturant_name: locationData.restaurant_name,
          resturant_address: locationData?.restaurant_address,
          resturant_website: locationData?.restaurant_website,
        };
        updateResturantDataAPI(payload)
          .then((res) => {
            if (res.status == "success") {
              let resturantData = res.user_profile;
              setlocationData({
                ...locationData,
                profile_id: resturantData.profile_id,
                restaurant_name: resturantData.resturant_name,
              });
            }
          })
          .catch((err) => {
            console.log("Error ", err);
          });
      }
    }
  };

  useEffect(() => {
    const fieldsToValidate = [
      "restaurant_website",
      "restaurant_address",
      "vibe",
    ];
    let isAnyFieldBlank = fieldsToValidate.some(
      (field) => locationData[field] === ""
    );
    // console.log("DisableData", isAnyFieldBlank, locationData);
    setDisablebtn(isAnyFieldBlank);
  }, [locationData]);
  useEffect(() => {
    if (profile_info) {
      setlocationData({
        ...locationData,
        profile_id: profile_info.profile_id,
        restaurant_name: profile_info.resturant_name,
      });
    }
  }, [profile_info]);
  const handlePopup = () => {
    setSaveMessage("");
    handletabchange(2);
  };
  // Google address API
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      if (place) {
        let confirmAddress = handleAddressglobal(place);
        if (confirmAddress.zipCode == "") {
          setErrors({
            resturant_address: "Enter zip Code",
          });
          setZipcode(false);
          setTimeout(() => {
            setErrors({
              resturant_address: "",
            });
          }, 4000);
        }
        setaddressvalue(confirmAddress);
        setlocationData((locationData) => ({
          ...locationData,
          restaurant_address:
            `${confirmAddress?.addressLine1} ${
              addreesvalue?.addressLine1 ? "," : ""
            } ${confirmAddress?.city} ${confirmAddress?.zipCode}, ${
              confirmAddress?.country
            } ` || inputRef.current.value,
        }));
      }
    });
  }, [inputRef.current]);
  const handlezipcode = (e) => {
    let zipcode = e.target.value;
    console.log(addreesvalue);
    setlocationData({
      ...locationData,
      restaurant_address: `${addreesvalue?.addressLine1} ${
        addreesvalue?.addressLine1 ? "," : ""
      } ${addreesvalue?.city} ${zipcode}, ${addreesvalue?.country} `,
    });
    if (zipcode.length > 1) {
      setZipcode(true);
    }
  };
  useEffect(() => {
    if (profile_info || location_storedata || location_storeoutput) {
      setlocationData({
        ...locationData,
        profile_id: profile_info?.profile_id || "",
        restaurant_name: profile_info?.resturant_name || "",
        restaurant_website: profile_info?.website || "",
        restaurant_address: location_storedata?.resturant_address || "",
        vibe: location_storedata?.vibe || "",
        cuisine_story: location_storeoutput?.output_text || "",
        output_image: location_storeoutput?.output_image || "",
        date: location_storeoutput?.date || "",
      });
    }
  }, [profile_info, location_storedata, location_storeoutput]);
  useEffect(() => {
    if (locationData.output_image == "") {
      setSavebtndisable(true);
    } else {
      setSavebtndisable(false);
    }
  }, [locationData?.output_image]);
  const handleDatareturn = (data) => {
    setlocationData({
      ...locationData,
      cuisine_story: data?.prompt_output_text?.replace(/\n/g, "") || "",
      output_image: data?.prompt_output_img_url?.replace(/\n/g, "") || "",
    });
  };
  useEffect(() => {
    if (
      location_storeoutput?.output_text == locationData?.cuisine_story &&
      locationData.output_image == location_storeoutput?.output_image &&
      locationData.restaurant_website ==
        location_storedata?.resturant_website &&
      locationData.restaurant_address ==
        location_storedata?.resturant_address &&
      locationData.vibe == location_storedata?.vibe
    ) {
      setSavebtndisable("Saved Created Post");
    } else {
      setSavebtndisable("Save Created Post");
    }
  }, [locationData, location_storeoutput, location_storedata]);
  useEffect(() => {
    if (locationData?.cuisine_story && locationData.output_image) {
      setSavebtnworking(false);
    } else {
      setSavebtnworking(true);
    }
  }, [locationData]);
  return (
    <>
      <div className="full-width location-container cutom-bg p-0">
        {loader && <Loadericon />}
        <div className="row zero-column">
          <div className="col-md-6 p-0">
            <Imageview
              image={locationimg}
              left={false}
              disable={disablebtn}
              handleImageupload={handleImageupload}
              generatecaption={generatecaption}
              alldata={locationData}
              outputdata={locationOut}
              staticIphone={phone}
            />
          </div>
          <div className="col-md-6">
            <div className="max-height-create-calender scroll-design-calender toright">
              <div className="content-padding-right create-calender-padding toleft">
                <h1 className="title">Location</h1>
                {/* <p className="text-design">
                  Giovanni was the head chef at an inn in a small village in
                  Sicily. He had learned how to cook from his mother and
                  grandmother
                </p> */}
                <div className="row py-2">
                  <div className="col-md-6">
                    <label>Web site URL </label>
                    <input
                      type="text"
                      placeholder="Your Website URL"
                      value={locationData?.restaurant_website}
                      className="form-control custom-input"
                      onChange={(e) => {
                        setlocationData({
                          ...locationData,
                          restaurant_website: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Address ( Corporate )</label>
                    <input
                      ref={inputRef}
                      type="text"
                      placeholder="Location"
                      value={locationData?.restaurant_address}
                      className="form-control custom-input"
                      onChange={(e) => {
                        setlocationData({
                          ...locationData,
                          restaurant_address: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {!zipcode && (
                    <div className="col-md-6 pt-2">
                      <input
                        type="text"
                        placeholder="ZIP Code"
                        className="form-control custom-input"
                        disabled={loader}
                        onBlur={handlezipcode}
                      />
                    </div>
                  )}
                </div>
                <div className=" py-3">
                  <div className="row py-2">
                    <div className="col-md-12">
                      <label>VIBE</label>
                      <input
                        type={"text"}
                        className="form-control custom-input"
                        placeholder="Vibe"
                        value={locationData?.vibe}
                        onChange={(e) => {
                          setlocationData({
                            ...locationData,
                            vibe: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {locationData?.cuisine_story && (
                    <div className="row">
                      <div className="col-md-12">
                        <textarea
                          value={locationData.cuisine_story}
                          onChange={(e) => {
                            setlocationData({
                              ...locationData,
                              cuisine_story: e.target.value,
                            });
                          }}
                          rows="2"
                          className="form-control custom-input custom-textarea"
                        />
                      </div>
                    </div>
                  )}

                  <div className="row pt-2 pb-4">
                    <div className="col-md-12 ">
                      {locationOut.text ? (
                        <>
                          <Airesult
                            output={locationOut}
                            cancleoutput={cancleoutput}
                            applyOutput={applyOutput}
                            generatecaption={generatecaption}
                          />
                        </>
                      ) : (
                        <>
                          <AIsavebutton
                            disable={disablebtn}
                            savebtndisable={savebtnworking || disablebtn}
                            savebtncontent={savebtndisable}
                            generatecaption={generatecaption}
                            saveCaption={savecaption}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {locationlastfive?.length > 0 && (
                    <>
                      <hr className="hr-line-calender" />
                      <div className="row pb-2">
                        <Allhistory
                          cuisine={false}
                          data={locationlastfive}
                          handleDatareturn={handleDatareturn}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {savemessage && (
        <GlobalAlert message={savemessage} handleClose={handlePopup} />
      )}
    </>
  );
};

export default Location;
