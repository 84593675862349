import React, { useEffect, useState } from "react";
import cuisineimg from "../../../assets/images/home/cuisin.png";
import uploadicon from "../../../assets/images/home/upload.png";
import plusbtn from "../../../assets/images/home/plus-icon.png";
import "./Cuisine.css";
import Imageview from "../../Common/ImageView/Imageview";
import phone from "../../../assets/images/create-calender/cuisine-phone.png";
import Adddishes from "./components/Adddishes";
import UploadMenu from "./components/UploadMenu";
import Airesult from "../Airesult/Airesult";
import AIsavebutton from "../Commonbtn/AIsavebutton";
import { useDispatch, useSelector } from "react-redux";
import {
  addDishAPI,
  getCaptionsAPI,
  updateCaptionAPI,
  imageUpload,
  updateResturantDataAPI,
} from "../../../apis/auth";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import GlobalAlert from "../../Common/GlobalAlert";
import Loadericon from "../../Common/Loadericon";
import Allhistory from "../History/Allhistory";

const Cuisine = ({ cuisinelastfive, handletabchange }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fields, setFields] = useState([]);
  const [valChanged, setValChanged] = useState(true);
  const [disablebtn, setDisablebtn] = useState(false);
  const [savebtndisable, setSavebtndisable] = useState("");
  const [loader, setLoader] = useState(false);
  const [savemessage, setSaveMessage] = useState("");
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const [savebtnworking, setSavebtnworking] = useState(false);
  const location_storedata = useSelector(
    (state) => state.main.store?.location_data
  );
  const origin_storedata = useSelector(
    (state) => state.main.store?.origin_data
  );
  const cuisine_storeData = useSelector(
    (state) => state?.main?.store?.cuisine_data
  );
  const cuisine_Output = useSelector(
    (state) => state?.main?.store?.cuisine_output
  );
  const [cuisineData, setcuisineData] = useState({
    profile_id: "",
    restaurant_name: "",
    restaurant_website: "",
    restaurant_address: "",
    cuisine: "",
    origins: "",
    vibe: "",
    cuisine_story: "",
    output_image: "",
    date: "",
  });
  const [cuisineOut, setcuisineOutput] = useState({
    text: "",
    image: "",
    date: "",
  });
  // Function
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleAddDishes = (dishes) => {
    if (dishes.dish_name.trim() !== "") {
      setFields((prevFields) => [...prevFields, dishes]);
      setValChanged(true);
    }
  };
  const handleDeleteDish = (element) => {
    if (fields?.length > 0) {
      let updatedFields = fields.filter(
        (field) => element.dish_name !== field.dish_name
      );
      setFields(updatedFields);
    }
  };
  const savecaption = () => {
    if (
      cuisine_Output?.output_text == cuisineData?.cuisine_story &&
      cuisine_Output?.output_image == cuisineData?.output_image
    ) {
      setSaveMessage("Day 3 post created successfully.");
    } else {
      setLoader(true);
      const menuData = fields?.map((obj) => [obj.dish_name, obj.description]);
      let fileData = "";
      const menu = menuData?.length > 0 ? menuData : fileData;
      let payload = {
        profile_id: cuisineData?.profile_id,
        resturant_name: cuisineData?.restaurant_name,
        cuisine: cuisineData?.cuisine,
        origins: cuisineData.cuisine_story,
        caption_type: "cuisine",
        caption_response: "custom",
        dish_or_menu: menu,
        dish: menu?.[0]?.[0],
        dish_description: menu?.[0]?.[1],
        is_add: true,
        count: 1,
        date: cuisineData?.date || "",
      };
      getCaptionsAPI(payload)
        .then((res) => {
          if (res.status) {
            setLoader(false);
            setcuisineData({
              ...cuisineData,
              cuisine_story:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                cuisineData?.cuisine_story,
              output_image:
                res.captions?.prompt_output_img_url ||
                cuisineData?.output_image,
              date: res.captions?.date || cuisineData.date,
            });
            const menuData = fields?.map((obj) => [
              obj.dish_name,
              obj.description,
            ]);
            let cuisinedata = {
              output_image:
                res.captions?.prompt_output_img_url ||
                cuisineData?.output_image,
              output_text:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                cuisineData?.cuisine_story,
              date: res.captions?.date || cuisineData?.date,
            };
            let cuisinemenu = { menuData: menuData };
            dispatch(storeInLocalStore({ cuisine_output: cuisinedata }));
            dispatch(storeInLocalStore({ cuisine_data: cuisinemenu }));

            updateretaurant();
            setTimeout(() => {
              setSaveMessage("Day 3 post created successfully.");
            }, 500);
          }
        })
        .catch((res) => {
          //console.log(res);
          setLoader(false);
        });
    }
  };

  const cancleoutput = () => {
    setcuisineOutput({
      text: "",
      image: "",
      date: "",
    });
  };
  const handleEditAPI = (textpayload) => {
    setLoader(true);
    let data = {
      profile_id: cuisineData?.profile_id,
      prompt_output_text: textpayload || cuisineData?.cuisine_story,
      prompt_output_img_url: cuisineOut?.image || cuisineData?.output_image,
      date: cuisineOut?.date || cuisineData?.date,
    };

    updateCaptionAPI(data)
      .then((res) => {
        if (res?.status) {
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  };

  const applyOutput = (data) => {
    if (data.trim() != "") {
      handleEditAPI(data);
    }
    setcuisineData({
      ...cuisineData,
      cuisine_story: data || cuisineOut.text,
      output_image: cuisineOut.image,
      date: cuisineOut.date,
    });
    setTimeout(() => {
      setcuisineOutput({
        text: "",
        image: "",
        date: "",
      });
    }, 100);
  };
  const handleImageupload = (e) => {
    if (cuisineData.date) {
      const file = e.target.files[0];
      if (file) {
        const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
        const modifiedFile = new File([file], newFileName, { type: file.type });
        setLoader(true);
        imageUpload({
          id: cuisineData.profile_id,
          date: cuisineData.date,
          image: modifiedFile,
        })
          .then((res) => {
            if (res?.status) {
              setLoader(false);
              setcuisineData({
                ...cuisineData,
                output_image: res.image_url,
              });
            } else {
              setLoader(false);
              setSaveMessage(res.message);
            }
          })
          .catch((rej) => {
            console.log(rej);
          });
      }
    } else {
      setSaveMessage("Please save the post before upload.");
    }
  };
  const generatecaption = (caption) => {
    if (cuisineData?.profile_id) {
      setLoader(true);
      let payload = {};
      const menuData = fields?.map((obj) => [obj.dish_name, obj.description]);
      let fileData = "";
      const menu = menuData?.length > 0 ? menuData : fileData;
      if (cuisineData.date || cuisineOut?.date) {
        if (caption != "both") {
          payload = {
            profile_id: cuisineData?.profile_id,
            date: cuisineData?.date || cuisineOut?.date || "",
            refresh: true,
            caption_type: "N/A",
            caption_response: caption,
          };
        } else {
          payload = {
            profile_id: cuisineData?.profile_id,
            resturant_name: cuisineData?.restaurant_name,
            cuisine: cuisineData?.cuisine,
            caption_type: "cuisine",
            caption_response: caption,
            dish_or_menu: menu,
            is_add: true,
            count: 1,
            date: cuisineData?.date || cuisineOut?.date || "",
          };
        }
      } else {
        payload = {
          profile_id: cuisineData?.profile_id,
          resturant_name: cuisineData?.restaurant_name,
          cuisine: cuisineData?.cuisine,
          caption_type: "cuisine",
          caption_response: caption,
          dish_or_menu: menu,
          is_add: true,
          count: 1,
          date: cuisineData?.date || cuisineOut?.date || "",
        };
      }
      if (caption == "both") {
        addDishAPI(payload)
          .then((res) => {
            if (res.status) {
              if (caption == "image") {
                setcuisineData({
                  ...cuisineData,
                  cuisine_story:
                    res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                    cuisineData?.cuisine_story,
                  output_image:
                    res.captions?.prompt_output_img_url ||
                    cuisineData?.output_image,
                  date: res.captions?.date || cuisineData.date,
                });
              } else {
                setcuisineOutput({
                  text:
                    res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                    cuisineData?.cuisine_story,
                  image:
                    res.captions?.prompt_output_img_url ||
                    cuisineData?.output_image,
                  date: res.captions?.date || cuisineData.date,
                });
              }
              setLoader(false);
            }
          })
          .catch((res) => {
            setLoader(false);
          });
      } else {
        getCaptionsAPI(payload)
          .then((res) => {
            if (res.status) {
              if (caption == "image") {
                setcuisineData({
                  ...cuisineData,
                  cuisine_story:
                    res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                    cuisineData?.cuisine_story,
                  output_image:
                    res.captions?.prompt_output_img_url ||
                    cuisineData?.output_image,
                  date: res.captions?.date || cuisineData.date,
                });
              } else {
                if (caption == "text") {
                  setcuisineOutput({
                    ...cuisineOut,
                    text: res.captions?.prompt_output_text?.replace(/\n/g, ""),
                  });
                } else {
                  setcuisineOutput({
                    text: res.captions?.prompt_output_text?.replace(/\n/g, ""),
                    image: res.captions?.prompt_output_img_url,
                    date: res.captions?.date,
                  });
                }
              }
              setLoader(false);
            }
          })
          .catch((res) => {
            setLoader(false);
          });
      }
    } else {
      setSaveMessage("Please Save Day 1 or Day 2  Post.");
    }
  };
  const updateretaurant = () => {
    if (cuisineData.restaurant_name != "") {
      if (cuisineData?.profile_id) {
        let payload = {
          profile_id: cuisineData?.profile_id,
          resturant_name: cuisineData.restaurant_name,
          resturant_address: cuisineData?.restaurant_address,
          resturant_website: cuisineData?.restaurant_website,
        };
        updateResturantDataAPI(payload)
          .then((res) => {
            if (res.status == "success") {
              let resturantData = res.user_profile;
              setcuisineData({
                ...cuisineData,
                profile_id: resturantData.profile_id,
                restaurant_name: resturantData.resturant_name,
              });
            }
          })
          .catch((err) => {
            console.log("Error ", err);
          });
      }
    }
  };
  useEffect(() => {
    if (fields.length > 0) {
      setDisablebtn(false);
    } else {
      setDisablebtn(true);
    }
  }, [fields]);
  useEffect(() => {
    if (
      profile_info ||
      cuisine_storeData ||
      cuisine_Output ||
      origin_storedata ||
      location_storedata
    ) {
      setcuisineData({
        ...cuisineData,
        profile_id: profile_info?.profile_id || "",
        restaurant_name: profile_info?.resturant_name || "",
        output_image: cuisine_Output?.output_image || "",
        cuisine_story: cuisine_Output?.output_text || "",
        date: cuisine_Output?.date || "",
        restaurant_address: location_storedata?.resturant_address || "",
        restaurant_website: location_storedata?.resturant_website || "",
        vibe: location_storedata?.vibe || "",
        cuisine: origin_storedata?.cuisine || "",
        origins: origin_storedata?.origins || "",
      });
      let tempmenu = cuisine_storeData?.menuData?.map((item) => ({
        dish_name: item[0],
        description: item[1],
      }));
      setFields(tempmenu || []);
    }
  }, [
    profile_info,
    cuisine_storeData,
    cuisine_Output,
    origin_storedata,
    location_storedata,
  ]);
  const handlePopup = () => {
    setSaveMessage("");
    handletabchange(3);
  };
  // useEffect(() => {
  //   if (cuisineData.output_image == "" || fields.length == 0) {
  //     setSavebtndisable(true);
  //   } else {
  //     setSavebtndisable(false);
  //   }
  // }, [cuisineData?.output_image, fields]);
  const handleDatareturn = (data) => {
    setcuisineData({
      ...cuisineData,
      cuisine_story: data?.prompt_output_text?.replace(/\n/g, "") || "",
      output_image: data?.prompt_output_img_url?.replace(/\n/g, "") || "",
    });
  };
  useEffect(() => {
    const menuData = fields?.map((obj) => [obj.dish_name, obj.description]);

    if (
      cuisine_Output?.output_text === cuisineData?.cuisine_story &&
      cuisine_Output?.output_image === cuisineData?.output_image &&
      (menuData.length === 0 ||
        cuisine_storeData?.menuData
          .flatMap((innerArr) => innerArr)
          .every((value, index) => value === menuData.flat()[index]))
    ) {
      setSavebtndisable("Saved Created Post");
    } else {
      setSavebtndisable("Save Created Post");
    }
  }, [cuisineData, cuisine_Output, cuisine_storeData, fields]);
  useEffect(() => {
    //console.log("Cusine  Data :>> ", cuisineData);
    if (cuisineData?.cuisine_story && cuisineData?.output_image) {
      setSavebtnworking(false);
    } else {
      setSavebtnworking(true);
    }
  }, [cuisineData]);
  return (
    <div className="full-width custom-bg p-0 cuisine-container">
      {loader && <Loadericon />}
      <div className="row zero-column">
        <div className="col-md-6 p-0">
          <div className="max-height-create-calender scroll-design-calender">
            <div className="content-padding-left create-calender-padding">
              <div className="">
                <h1 className="title">Cuisine</h1>
                <p>MenuMuse: AI-Powered Culinary Narratives</p>
              </div>
              <UploadMenu
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                handleFileChange={handleFileChange}
                uploadicon={uploadicon}
              />
              <Adddishes
                fields={fields}
                handleAddDishes={handleAddDishes}
                handleDeleteDish={handleDeleteDish}
              />
              {cuisineData?.cuisine_story && (
                <div className="row">
                  <div className="col-md-12">
                    <textarea
                      value={cuisineData.cuisine_story}
                      onChange={(e) => {
                        setcuisineData({
                          ...cuisineData,
                          cuisine_story: e.target.value,
                        });
                      }}
                      rows="2"
                      className="form-control custom-input custom-textarea"
                    />
                  </div>
                </div>
              )}

              <div className="row pt-2 pb-4">
                <div className="col-md-12 ">
                  {cuisineOut.text ? (
                    <>
                      <Airesult
                        output={cuisineOut}
                        cancleoutput={cancleoutput}
                        applyOutput={applyOutput}
                        generatecaption={generatecaption}
                      />
                    </>
                  ) : (
                    <>
                      <AIsavebutton
                        disable={disablebtn}
                        savebtndisable={savebtnworking}
                        savebtncontent={savebtndisable}
                        generatecaption={generatecaption}
                        saveCaption={savecaption}
                      />
                    </>
                  )}
                </div>
              </div>
              {cuisinelastfive.length > 0 && (
                <>
                  <hr className="hr-line-calender" />
                  <div className="row pb-2">
                    <Allhistory
                      cuisine={true}
                      data={cuisinelastfive}
                      handleDatareturn={handleDatareturn}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <Imageview
            image={cuisineimg}
            left={true}
            staticIphone={phone}
            disable={disablebtn}
            handleImageupload={handleImageupload}
            generatecaption={generatecaption}
            alldata={cuisineData}
            outputdata={cuisineOut}
          />
        </div>
      </div>
      {savemessage && (
        <GlobalAlert message={savemessage} handleClose={handlePopup} />
      )}
    </div>
  );
};

export default Cuisine;
