import React, { useEffect, useState } from "react";
import "./Reviewstyle.css";
import SocialMediaSwitch from "../Common/SocialMediaSwitch/SocialMediaSwitch";
import NotificationCards from "./components/NotificationCards";
import NormalView from "./NormalView/NormalView";
import { useSelector } from "react-redux";
import Loadericon from "../Common/Loadericon";
import SearchIcon from "@mui/icons-material/Search";
import Switcher from "./components/Switcher";

const ReviewsMain = ({
  isActive,
  handleActiveSocialMedia,
  searchtext,
  handlesearch,
  socialMediaCards,
  loader,
  socialmediaView,
  detailedpost,
  addreply,
  handleprevious,
  handlenext,
  currentIndex,
  allnotification,
  likeanddislikefunc,
  currentcaptionid,
  setSearchtext,
  handleNotificationClick,
  reviewpostComment,
}) => {
  return (
    <>
      <div className="review-container py-3">
        <div className="row  py-3 heading-background">
          <div className="col-md-12">
            <div className="content-padding-left">
              <h1 className="title bold-inner">Reviews & Remarks</h1>
            </div>
          </div>
        </div>
        <div className="row py-5 review-bg position-relative">
          <div className="col-md-6">
            <div className="content-padding-left">
              <div className="row">
                <div className="col-md-12">
                  <div className="choose-form-container pb-4">
                    <div className="form-item">
                      <p className="form-text">Choose The Platform</p>
                      <Switcher
                        isActive={isActive}
                        handleActiveSocialMedia={handleActiveSocialMedia}
                      />
                    </div>
                    <div className="form-item">
                      <p className="form-text">Find a user</p>
                      <div className="from-group">
                        <input
                          type="text"
                          className="form-control custom-input "
                          value={searchtext}
                          onChange={(e) => {
                            setSearchtext(e.target.value);
                          }}
                        />

                        <span
                          className="input-group-icon"
                          onClick={handlesearch}
                        >
                          <SearchIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="notification-container scroll-design">
                  {socialMediaCards.length > 0 &&
                    socialMediaCards.map((card, index) => (
                      <NotificationCards
                        key={index + 1}
                        indexnumber={index + 1}
                        socialmedia={card}
                        onClick={() => handleNotificationClick(card)}
                        currentid={currentcaptionid}
                      />
                    ))}
                  {socialMediaCards.length == 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          width: "100%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <>
                          <h3 className="white-subtitle">
                            You Don’t Have <br /> Reviews or Remarks
                          </h3>
                        </>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-padding-right">
              <div className="view-container">
                {socialmediaView?.platform == "yelp" || isActive == "yelp" ? (
                  <>
                    <h3 className="h3-title">
                      Ops! <br />
                      You Don’t Have Post
                    </h3>
                  </>
                ) : (
                  <>
                    <NormalView
                      isActive={isActive}
                      postdetails={detailedpost}
                      addreply={addreply}
                      handleprevious={handleprevious}
                      handlenext={handlenext}
                      currentIndex={currentIndex}
                      maxlenght={allnotification.length - 1 || 0}
                      likeanddislikefunc={likeanddislikefunc}
                      reviewpostComment={reviewpostComment}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsMain;
