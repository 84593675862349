const style = {
    mainContainer: {
     
    },
    firstContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "90vh",
    },
    loginForm: {
      padding: "100px",
      position: "relative",
    },
    singleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "90vh",
    },
   
  };
  
  export default style;
  