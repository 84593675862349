import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import Subheader from "./partial/Subheader";

const Mycustomerssubheader = ({ unified, submenu }) => {
  const currentcode = useSelector((state) => state.main.store?.currentsubmenu);
  const [currentmenu, setCurrentmenu] = useState();
  const dispatch = useDispatch();
  const handleactivemenu = (item) => {
    setCurrentmenu(item);
  };
  let menulist = [];
  if (unified) {
    menulist = [
      {
        name: "Customer by Value",
        key: "firsttimeviewercus",
      },
      {
        name: "Personal Attributes",
        key: "engagedviewercus",
      },
      {
        name: "Customer by Meal Segment",
        key: "firsttimeconsumercus",
      },
      // {
      //   name: "Freq Diners",
      //   key: "freqdinnercus",
      // },
    ];
  } else {
    menulist = [
      {
        name: "Number of Views",
        key: "scribenumber",
      },
    ];
  }

  useEffect(() => {
    if (currentmenu) {
      dispatch(storeInLocalStore({ currentsubmenu: currentmenu }));
    }
  }, [currentmenu]);
  useEffect(() => {
    if (currentcode) {
      let tempcode = menulist.map((item) => item.key);
      if (tempcode.includes(currentcode)) {
        setCurrentmenu(currentcode);
      } else {
        setCurrentmenu(unified ? "firsttimeviewercus" : "scribenumber");
      }
    } else {
      setCurrentmenu(unified ? "firsttimeviewer" : "scribenumber");
    }
  }, [currentcode]);

  return (
    <>
      <Subheader
        menuname={menulist}
        currentmenu={currentmenu}
        handleactivemenu={handleactivemenu}
      />
    </>
  );
};

export default Mycustomerssubheader;
