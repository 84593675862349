import { Box, Typography } from "@mui/material";
import React from "react";
import editImg from "../../assets/SVG/edit.svg";
import whitePoint from "../../assets/SVG/White_Ellipse.svg";
import upload from '../../assets/images/upload.png'

const UploadBtn = ({ style, handleChange, showText }) => {
  const orangeButtonText = {
    color: '#eb2426',
    fontSize: '12px',
    textWrap: 'noWrap',
    textTransform: 'none',
    fontWeight: '900',
    marginLeft:'10px'
  }
  return (
    <>
      <Box
        sx={{
          background: showText ? 'transparent' : `#fff`,
          width: "40px",
          height: "40px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "40px 40px",
          cursor: "pointer",
          borderRadius: "50%",
          ...style,
        }}
        component="label"
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: showText ? 'flex' : 'block',
            alignItems: showText ? 'center' : ''
          }}
        >
          {!showText && <img
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            alt="ImgError"
            src={editImg}
          />}
          {
            showText && <>
              <img src={upload} />
              <Typography sx={orangeButtonText}>Upload Image</Typography>
            </>
          }
        </div>
        <input onChange={handleChange} type="file" accept=".jpg, .jpeg, .png" hidden />
      </Box>
    </>
  );
};

export default UploadBtn;
