import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import style from "./SocialMediaCards.js";
import ProfileImage from "../../../assets/images/profileImage.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CardImage from "../../../assets/images/Image_1.png";
import LikeButton from "../../../assets/SVG/like.svg";
import CommentButton from "../../../assets/SVG/comment.svg";
import ShareButton from "../../../assets/SVG/share.svg";
import SaveButton from "../../../assets/SVG/save.svg";
import fbIcon from "../../../assets/images/fbLike.png";
import fbLikeIcon from "../../../assets/images/instaLike.png";

const FacebookMediaCard = ({
  dataperday,
  url,
  name,
  handleHover,
  handleRemoveHover,
}) => {
  let cleanurl = "";
  if (url) {
    cleanurl = url
      .replace(/^https?:\/\//, "")
      // .replace(/^www\./, "")
      .replace(/\/$/, "");
  }
  return (
    <>
      <Card
        onMouseEnter={handleHover}
        onMouseLeave={handleRemoveHover}
        sx={{ maxWidth: 120, maxHeight: 180, margin: "auto", zIndex: -1 }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "2px 4px",
            }}
          >
            <Box>
              <Avatar
                sx={{ width: 12, height: 12, marginRight: "3px" }}
                alt="Remy Sharp"
                src={
                  cleanurl
                    ? `https://s2.googleusercontent.com/s2/favicons?domain=${cleanurl}`
                    : ProfileImage
                }
              />
            </Box>
            <Box>
              <Typography sx={{ variant: "p", fontSize: "5px" }}>
                {name || "IL MELOGRANO"}
              </Typography>
            </Box>
          </Box>
          <CardContent sx={{ padding: "1px" }}>
            <Typography
              sx={{ padding: "0px", fontSize: "4px", lineHeight: "5px" }}
              variant="body2"
              color="text.secondary"
            >
              {(dataperday?.prompt_output_text &&
              dataperday?.prompt_output_text.length > 70
                ? dataperday?.prompt_output_text.slice(0, 70) + "..."
                : dataperday?.prompt_output_text) ||
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry"}
              .
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            height="94"
            image={dataperday?.prompt_output_img_url || CardImage}
            alt="CardImage"
          />
          <Box sx={{ padding: "5px 3px" }}>
            <CardActions sx={style.cardActions} disableSpacing>
              <Box
                sx={{
                  padding: "0px",
                  margin: "0px",
                  lineHeight: "0px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft:'5px'
                }}
                disableSpacing
              >
                <img
                  width="10px"
                  height="10px"
                  src={fbLikeIcon}
                  alt="LikeButton"
                />
                <img
                  width="10px"
                  height="10px"
                  src={fbIcon}
                  alt="CommentButton"
                  style={{
                    marginLeft: "-16px",
                  }}
                />
                <Typography
                  variant="span"
                  sx={{ padding: "0px", fontSize: "4px", lineHeight: "5px", marginLeft:'10px' }}
                >
                  3,4K
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ padding: "0px", fontSize: "4px", lineHeight: "5px" }}
                >
                  <span>70 comments</span> <span> 104 shares</span>
                </Typography>
              </Box>
            </CardActions>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default FacebookMediaCard;
