import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Session = ({ children }) => {
  const { pathname } = useLocation();
  const previousTime = localStorage.getItem("sessiontime");
  const [resetbtn, setresetbtn] = useState(false);
  const handleInteraction = () => {
    const currentTime = new Date();
    localStorage.setItem("sessiontime", currentTime);
  };
  const onLoadintraction = () => {
    const currentTime = new Date();
    const pretime = new Date(previousTime);
    const timeDiff = currentTime - pretime;
    const inactivityThreshold = 60 * 60 * 1000;
    if (previousTime) {
      if (timeDiff > inactivityThreshold) {
        setresetbtn(true);
      } else {
        handleInteraction();
      }
    } else {
      setresetbtn(true);
    }
  };
  useEffect(() => {
    onLoadintraction();
    window.addEventListener("click", handleInteraction);
    window.addEventListener("keypress", handleInteraction);
    return () => {
      window.removeEventListener("click", handleInteraction);
      window.removeEventListener("keypress", handleInteraction);
    };
  }, []);
  useEffect(() => {
    const currentTime = new Date();
    if (resetbtn) {
      if (pathname == "/") {
        localStorage.clear();
        window.location.reload(true);
        localStorage.setItem("sessiontime", currentTime);
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }, [resetbtn]);
  const renderChild = () => {
    return children;
  };
  return renderChild();
};

export default Session;
