import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import staticimage from "../../../../assets/images/mysocialmedia/dummy-image-popup.png";
import uploadicon from "../../../../assets/images/create-calender/upload-icon.png";
import cross from "../../../../assets/images/mysocialmedia/cross.png";

const Savecustomtemplate = ({ open, cancleHandle, savecustomthemedata }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imgurl, setImgurl] = useState(staticimage);
  const [image, setImage] = useState(null);

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    image: "",
  });

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImgurl(URL.createObjectURL(file));
      e.target.value = ""; // Clear file input after selection
      setErrors({ ...errors, image: "" }); // Clear image error if valid
    }
  };

  const handleSubmit = () => {
    let hasError = false;
    const newErrors = {
      title: "",
      description: "",
      image: "",
    };

    if (!title) {
      newErrors.title = "Title is required.";
      hasError = true;
    }
    if (!description) {
      newErrors.description = "Description is required.";
      hasError = true;
    }
    if (!image) {
      newErrors.image = "Image is required.";
      hasError = true;
    }

    setErrors(newErrors);

    if (!hasError) {
      let data = {
        title: title,
        description: description,
        image: image,
      };
      savecustomthemedata(data)
    } else {
      setTimeout(() => {
        setErrors({
          title: "",
          description: "",
          image: "",
        });
      }, 1500);
    }
  };
  const handleClose = () => {
    setErrors({
      title: "",
      description: "",
      image: "",
    });
    cancleHandle();
  };
  return (
    <Modal
      className="description-body savecustomtheme"
      size="lg"
      show={open}
      centered
      onHide={handleClose}
    >
      <Modal.Body>
        <div className="row p-3">
          <div className="col-md-12">
            <h1 className="title text-center">Custom Theme</h1>
          </div>
          <div className="row align-items-center py-4">
            <div className="col-md-4">
              <div className="image-container">
                <img src={imgurl} className="imagedata w-100" alt="Theme" />
                <label className="upload-btn">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImage}
                  />
                  <img src={uploadicon} alt="Upload" />
                </label>
                {errors.image && (
                  <div className="alert-maeesage">{errors.image}</div>
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="card-container">
                <div className="mb-4">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    className="form-control custom-input"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && (
                    <div className="alert-maeesage">{errors.title}</div>
                  )}
                </div>
                <div className="mb-4">
                  <label>Description</label>
                  <textarea
                    className="custom-textarea"
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={200}
                  />
                  {errors.description && (
                    <div className="alert-maeesage">{errors.description}</div>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Save
                  </button>
                  <button className="btn btn-white" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Savecustomtemplate;
