import { Box } from "@mui/material";
import React from "react";
import editImg from "../../assets/SVG/edit.svg";
import whitePoint from "../../assets/SVG/White_Ellipse.svg";

const EditButton = ({ style, onClick }) => {
  return (
    <>
      <Box
        onClick={onClick}
        sx={{
          backgroundImage: `url(${whitePoint})`,
          width: "40px",
          height: "40px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "40px 40px",
          // padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          
          ...style,
        }}
      >
        <img width="20px" height="20px" alt="ImgError" src={editImg} />
      </Box>
    </>
  );
};

export default EditButton;
