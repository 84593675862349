import React, { useEffect, useState } from "react";
import customerbanner from "../../../assets/images/mysocialmedia/customer-journey-banner.png";
import campaignimg from "../../../assets/images/mysocialmedia/campaign-photo.png";
import rightarrow from "../../../assets/images/mysocialmedia/brand-right-arrow.png";
import addicon from "../../../assets/images/mysocialmedia/add-icon.png";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import style from "./CampaignStyle";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";
import InstaMobileView from "../../commoncomponents/InstaMobileView/InstaMobileView";
import FacebookMobileView from "../../commoncomponents/FacebookMobileView/FacebookMobileView";
import GoogleView from "../../commoncomponents/GoogleView/GoogleView";
import SmsView from "../../commoncomponents/SMSView/SmsView";
import GmailMobileView from "../../commoncomponents/GmailMobileView/GmailMobileView";
import dummyimg from "../../../assets/images/create-calender/dummy-image.png";
import { useDispatch, useSelector } from "react-redux";
import PreviousBtn from "../../Common/PreviousBtn";
import NextBtn from "../../Common/NextBtn";
import { getCampaignDataAPI, getallcampaignAPI } from "../../../apis/auth";
import Loadericon from "../../Common/Loadericon";
import { useNavigate } from "react-router-dom";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
const tabStyles = {
  labelStyle: {
    color: "black",
  },
  selectedLabelStyle: {
    color: "blue", // Change label color when active
  },
  iconStyle: {
    fill: "black",
  },
  selectedIconStyle: {
    fill: "green", // Change icon color when active
  },
  indicator: {
    display: "none", // Remove the indicator (border)
  },
};
function CustomTabPanel(props) {
  const { children, value, index, handlePrevious, handleNext, data, ...other } =
    props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className="d-flex justify-content-center align-items-center"
          sx={{ p: 3 }}
        >
          {data > 3 && (
            <PreviousBtn width={"50px"} handlePrevious={handlePrevious} />
          )}
          <Box className="d-flex">{children}</Box>
          {data > 3 && <NextBtn handleNext={handleNext} width={"50px"} />}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Campaigs = ({
  campId,
  value,
  handleChange,
  mobileViewData,
  handlePrevious,
  handleNext,
  visibleItems,
  profileData,
  campaign,
  changeCampId,
  functionOnModalClose,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      {campId ? (
        <div className="campaingn-area">
          <div className="row  py-3 heading-background">
            <div className="col-md-12">
              <div className="content-padding-both">
                <div className="position-relative">
                  <h1 className="title bold-inner">Customer Journey</h1>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row py-5">
            <div className="col-md-10 offset-md-1">
              <div className="content-padding-both ">
                <img src={customerbanner} className="w-100" />
              </div>
            </div>
          </div> */}
          <div className="customer-campaign">
            <div className="row py-5">
              <Box
                className="d-flex flex-column"
                style={{
                  marginLeft: "40px",
                  position: "relative",
                  paddingTop: "80px",
                }}
              >
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      position: "absolute",
                      left: "50%",
                      transform: `translate(-50%, -50%)`,
                      top: "3%",
                    }}
                  >
                    <Typography style={style.smallHeading}>
                      Choose The Platform
                    </Typography>
                    <Tabs
                      style={{}}
                      TabIndicatorProps={{ style: tabStyles.indicator }}
                      className="socialmediatabs"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        style={{ color: "#fff" }}
                        icon={<InstagramIcon />}
                        {...a11yProps(0)}
                      />
                      <Tab
                        style={{ color: "#fff" }}
                        label="f"
                        {...a11yProps(1)}
                      />
                      <Tab
                        style={{ color: "#fff" }}
                        icon={<GoogleIcon />}
                        {...a11yProps(2)}
                      />
                      <Tab
                        style={{ color: "#fff" }}
                        label="@"
                        {...a11yProps(3)}
                      />
                      <Tab
                        style={{ color: "#fff" }}
                        label="SMS"
                        {...a11yProps(4)}
                      />
                    </Tabs>
                  </Box>
                  <Box sx={{ maxWidth: "2560px" }}>
                    <CustomTabPanel
                      data={mobileViewData?.campaign?.length}
                      value={value}
                      index={0}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    >
                      <InstaMobileView
                        data={visibleItems}
                        profile={profileData}
                        functionOnModalClose={functionOnModalClose}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel
                      data={mobileViewData?.campaign?.length}
                      value={value}
                      index={1}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    >
                      <FacebookMobileView
                        data={visibleItems}
                        profile={profileData}
                        functionOnModalClose={functionOnModalClose}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel
                      data={mobileViewData?.campaign?.length}
                      value={value}
                      index={2}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    >
                      <GoogleView
                        data={visibleItems}
                        profile={profileData}
                        functionOnModalClose={functionOnModalClose}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel
                      data={mobileViewData?.campaign?.length}
                      value={value}
                      index={3}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    >
                      <GmailMobileView
                        data={visibleItems}
                        profile={profileData}
                        functionOnModalClose={functionOnModalClose}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel
                      data={mobileViewData?.campaign?.length}
                      value={value}
                      index={4}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    >
                      <SmsView
                        data={visibleItems}
                        profile={profileData}
                        functionOnModalClose={functionOnModalClose}
                      />
                    </CustomTabPanel>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
          <div className="campaign-list-container">
            <div className="row py-5">
              <div className="col-md-12">
                <div className="content-padding-both">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-item">
                      <h3 className="title">Campaigns</h3>
                    </div>
                    <div className="flex-item">
                      <p className="bold-inner font-normal">
                        See All <img src={rightarrow} />{" "}
                      </p>
                    </div>
                  </div>
                  <div className="campaignlist">
                    {campaign?.map((x, id) => {
                      return (
                        <div
                          key={id}
                          onClick={() => changeCampId(x.id)}
                          className="item has-campaign"
                          style={{
                            cursor: "pointer",
                            overflow: "hidden",
                            border:
                              campId == x.id
                                ? "2px solid #eb2426"
                                : "2px solid #fff",
                          }}
                        >
                          <img
                            src={x?.custom_theme_image_url || dummyimg}
                            className="w-100"
                            style={{
                              height: "100%",
                            }}
                            onError={({ target }) => {
                              target.onerror = null;
                              target.src = dummyimg;
                            }}
                            alt="Product Image"
                          />
                          <div className="name">
                            <p className="text-center">{x?.name}</p>
                          </div>
                        </div>
                      );
                    })}
                    <div className="item btn">
                      <div
                        onClick={() => {
                          navigate("/create-calender");
                          dispatch(
                            storeInLocalStore({
                              currentsubmenu: "createmysmcalender",
                            })
                          );
                          dispatch(
                            storeInLocalStore({ currentcomponentid: 3 })
                          );
                        }}
                      >
                        <img src={addicon} />
                        <p className="font-normal text-white">Create New</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>{/* <Loadericon /> */}</>
      )}
    </>
  );
};

export default Campaigs;
