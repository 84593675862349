import React from "react";
import aiimage from "./../../assets/img/creative.png";
import Homesections from "../Common/Homesections";

const Sectiontwo = () => {
  return (
    <Homesections
      bgclass={"bg-two"}
      aiimage={aiimage}
      title={`<span>AI</span> DRIVEN CREATIVE`}
      subtitle={`AI driven creative personalization leads to higher customer engagement and loyalty`}
      content={``}
      right={true}
      videourl={
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
      }
    />
  );
};

export default Sectiontwo;
