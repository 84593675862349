import React from "react";

const Nodata = ({
  locationicon,
  plusicon,
  uploadicon,
  handlepopup,
  handlecsvupload,
}) => {
  return (
    <div className="card-inner-data">
      <div className="content-data">
        <img
          src={locationicon}
          alt="locations"
          style={{
            width: "72px",
            margin: "10px",
          }}
        />
        <p
        className="font-normal"
          style={{
            color: "#959595",
            marginBottom: "10px",

          }}
        >
          Add locations of Your restaurant
        </p>
        <p
         className="font-normal"
          
        >
          Click{" "}
          <span
            style={{
              color: "var(--primary-color)",
            }}
          >
            Add New Address
          </span>{" "}
          or{" "}
          <span
            style={{
              color: "var(--primary-color)",
            }}
          >
            Add CSV File
          </span>
        </p>
        <div
          className="btn-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            margin: "20px 0px",
          }}
        >
          <button
            className="byteready-btn byteready-primary"
            onClick={() => {
              handlepopup();
            }}
          >
            <img src={plusicon} alt="Upload Icon" /> Add New Address
          </button>
          <div className="file-main">
            <label
              className="byteready-btn byteready-outlinne upload-button"
              style={{
                border: "1px solid #fff",
              }}
              onClick={() => {
                handlecsvupload();
              }}
            >
              <img src={uploadicon} alt="Upload Icon" />
              Add CSV File
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nodata;
