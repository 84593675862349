const style = {
  smallHeading: {
    fontFamily: "helvetica-font",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0em",
    color: "var(--card-text)",
    textWrap: "nowrap",
    textAlign: "center",
  },
};
export default style;
