import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Modal, Stack, Typography } from "@mui/material";

const style = {
  mainContainer: {
    backgroundColor: "#000000",
    color: "#FCFCFC",
    width: "100vw",
    height: "100Vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textStyle: {
    marginTop: "20px",
    color: "#FCFCFC",
    fontFamily: "SFProText-Regular",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: "400",
  },
};

const GlobalLoader = ({ open, text }) => {
  return (
    <Box sx={style.mainContainer}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <CircularProgress sx={{ color: "#eb2426" }} />
        <Typography sx={style.textStyle} variant="h4">
          {text || 'Creating Calendar...'}
        </Typography>
      </Stack>
    </Box>
  );
};

export default GlobalLoader;
