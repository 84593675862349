const style = {
  mainContainer: {
    padding: "10px 0px 135px 135px",
     marginTop:{md:'70px', lg:'120px'}
  },
  text: {
    color: "#FCFCFC",
    fontFamily: "helvetica-font",
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "1.2",
    textTransform: "uppercase",
    // cursor: "pointer",
  },
  iconStyle: { color: "#eb2426", fontSize: "10px", },
  cardContainer: {
    width: "100%",
    marginTop: "20px",
    paddingRight: "-200px"
  },
};

export default style;
