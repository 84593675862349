import React, { useEffect, useState } from "react";
import "./myprofile.css";
import logouticon from "../../assets/images/myprofile/logout.png";
import Basicinformation from "../../components/myprofile/Basicinformation";
import Socialmedia from "../../components/myprofile/Socialmedia";
import Changepassword from "../../components/myprofile/Changepassword";
import Canclesubscription from "../../components/myprofile/Canclesubscription";
import Sbscribenow from "../../components/myprofile/Sbscribenow";
import Payments from "../../components/myprofile/Payments";

import { useDispatch, useSelector } from "react-redux";
import {
  authtokenforsmfbAPI,
  changeuserpasswordAPI,
  manageprofileAPI,
  updateResturantDataAPI,
  getpaymentHistory,
} from "../../apis/auth";
import DashboardLoader from "../../components/Common/DashboardLoader";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import GlobalAlert from "../../components/Common/GlobalAlert";
import Yelpconnect from "./Yelpconnect";
import moment from "moment";
import { fetchprofile } from "../../CustomHook/addressUtills";
import axios from "axios";

const Myprofile = () => {
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.main?.store?.users?.id);
  const [myprofileurl, setMyprofileurl] = useState(Clerk?.user?.imageUrl || "");
  const currentdate = moment().format("YYYY-MM-DD");
  const profile_id = useSelector(
    (state) => state.main?.store?.profile_data?.profile_id
  );
  const locationdata = useSelector((state) => state.main?.store?.location_data);
  const origindata = useSelector((state) => state.main?.store?.origin_data);
  const [profileData, setProfileInfo] = useState("");
  const [profileinital, setProfileintial] = useState("");
  const [profileChange, setProfilechange] = useState("");
  const [profilesuccess, setProfilesuccess] = useState("");
  const [passwordsuccess, setPasswordsuccess] = useState("");
  const [yelpconnect, setyelpConnect] = useState(false);
  const [passwordChange, setPasswordChange] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [paymenthistory, setpaymenthistory] = useState([]);
  const [alertMessage, setAlertmessage] = useState("");
  const [loading, setLoading] = useState(false);
  const logoutData = () => {
    Clerk.signOut();
    localStorage.clear();
    window.location.href = "/";
  };
  const forgetpassword = () => {
    Clerk.signOut();
    localStorage.clear();
    window.location.href = "/login";
  };
  const initialrender = () => {
    setLoading(true);
    if (profile_id) {
      let payload = {
        profile_id: profile_id,
      };
      fetchprofile(payload)
        .then((result) => {
          dispatch(
            storeInLocalStore({
              profile_data: result?.dispachabledata?.profile_data,
            })
          );
          dispatch(
            storeInLocalStore({
              users: result?.dispachabledata?.users,
            })
          );
          setLoading(false);
          setProfileInfo(result?.profileData);
        })
        .catch((error) => {
          setLoading(false);
        });
      getpaymentHistory({
        profile_id: profile_id,
        user_id: userid,
      })
        .then((res) => {
          if (res.status == "success") {
            setpaymenthistory(res.data);
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    }
  };
  const savechanges = async () => {
    await changepassword();
    await profileudpdate();
  };
  const profileudpdate = async () => {
    if (profileChange) {
      updateResturantDataAPI({
        profile_id: profile_id,
        resturant_name: profileChange?.resturant_name,
        resturant_address: profileChange?.resturant_address,
        resturant_zipcode: profileChange?.resturant_zipcode,
        resturant_website: profileChange?.resturant_website,
      })
        .then((res) => {
          if (res.status) {
            setProfilesuccess("Profile is updated successfully.");
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    }
  };
  const changepassword = async () => {
    if (passwordChange.current_password) {
      if (passwordChange?.current_password.trim() == "") {
        setAlertmessage("Current Password Resquired.");
      } else if (passwordChange?.new_password.trim() == "") {
        setAlertmessage("New Password Resquired.");
      } else if (passwordChange?.confirm_password.trim() == "") {
        setAlertmessage("Confirm Password Resquired.");
      } else if (
        passwordChange.confirm_password.trim() !==
        passwordChange.new_password.trim()
      ) {
        setAlertmessage("Password does not match.");
      } else {
        Clerk.user
          .updatePassword({
            current_password: passwordChange?.current_password,
            new_password: passwordChange?.new_password,
            sign_out_of_other_sessions: false,
          })
          .then(() => {
            setPasswordChange({
              current_password: "",
              new_password: "",
              confirm_password: "",
            });
            setPasswordsuccess("Password is changed successfully.");
          })
          .catch((rej) => {
            setAlertmessage(rej.errors[0]?.message);
          });
      }
    }
    setTimeout(() => {
      setAlertmessage("");
    }, 3000);
  };
  const handleClose = () => {
    initialrender();
    setPasswordsuccess("");
    setProfilesuccess("");
    setyelpConnect("");
  };
  const handleFacebook = () => {
    FB.login(
      (response) => {
        if (response.authResponse) {
          fetch(
            `https://graph.facebook.com/me?access_token=${response.authResponse?.accessToken}&fields=name`
          )
            .then((response) => response.json())
            .then((data) => {
              let payload = {
                user_id: userid,
                profile_id: profile_id,
                platform_user_id: response.authResponse?.userID,
                platform_user_token: response.authResponse?.accessToken,
                fb_name: data.name,
              };
              authtokenforsmfbAPI(payload).then((res) => {
                if (res) {
                  initialrender();
                } else {
                }
              });
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.log("You are not logged in.", response);
        }
      },
      {
        scope:
          "public_profile,email,pages_show_list,user_photos,user_posts,pages_manage_posts,instagram_manage_comments,read_insights,pages_read_engagement,pages_manage_engagement,instagram_basic,instagram_content_publish,pages_manage_metadata",
        return_scopes: true,
      }
    );
  };

  const manageprofile = () => {
    manageprofileAPI({
      user_id: userid,
      profile_id: profile_id,
    })
      .then((res) => {
        if (res?.url) {
          window.location.href = res.url;
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const handleImageUpload = (e) => {
    console.log("Selected file:", e.target.files[0]);

    const selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);

    const jwttoken = localStorage.getItem("clerk-db-jwt");

    const url = `https://wealthy-lobster-85.clerk.accounts.dev/v1/me/profile_image?_clerk_js_version=${Clerk.version}&_clerk_session_id=${Clerk.session.id}&__dev_session=${jwttoken}`;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };
  useEffect(() => {
    initialrender();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <DashboardLoader />
        </>
      ) : (
        <>
          <div className="full-width headerpadding my-profile">
            <div className="row">
              <div className="col-md-12">
                <div className="content-padding-both">
                  <div className="d-flex  justify-content-between align-items-center">
                    <div className="item">
                      <h1 className="title">My Profile</h1>
                    </div>
                    <div className="item">
                      {profileChange || passwordChange.current_password ? (
                        <>
                          <button
                            onClick={() => {
                              savechanges();
                            }}
                            className="byteready-btn byteready-primary logout-btn"
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              logoutData();
                            }}
                            className="byteready-btn byteready-outlinne logout-btn"
                          >
                            <img src={logouticon} className="logout-icon" />
                            Log Out
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Basicinformation
              profileData={profileData}
              myprofileurl={myprofileurl}
              setProfilechange={setProfilechange}
              handleimageupload={handleImageUpload}
            />
            <Socialmedia
              profileData={profileData}
              setyelpConnect={setyelpConnect}
              handleFacebook={handleFacebook}
            />
            <Changepassword
              alertMessage={alertMessage}
              profileData={profileData}
              passwordChange={passwordChange}
              setPasswordChange={setPasswordChange}
              forgetpassword={forgetpassword}
            />
            {/* {profileData?.is_trial ? (
              <>
                <Sbscribenow profileData={profileData} />
              </>
            ) : (
              <>
                
              </>
            )} */}
            {/* <Canclesubscription
              profileData={profileData}
              manageprofile={manageprofile}
            />
            {paymenthistory.length > 0 && (
              <Payments profileData={profileData} payments={paymenthistory} />
            )} */}
          </div>
          <GlobalAlert
            message={`${profilesuccess}${
              profilesuccess && passwordsuccess && " and "
            }${passwordsuccess}`}
            handleClose={handleClose}
          />
          <Yelpconnect yelpconnect={yelpconnect} handleClose={handleClose} />
        </>
      )}
    </>
  );
};

export default Myprofile;
