import React, { useEffect } from "react";
import Loadericon from "../components/Common/Loadericon";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI } from "../apis/auth";
import { fetchprofile } from "../CustomHook/addressUtills";
import { storeInLocalStore } from "../redux/reducers/mainSlice";

const Authenticate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usersdata = useSelector((state) => state.main.store?.users) || {};
  const { getToken, isLoaded, isSignedIn, userId, sessionId } = useAuth();
  const getuservalue = async () => {
    try {
      const token = await getToken({ template: "byteready" });
      localStorage.setItem("token", token);
      localStorage.setItem("second-token", token);
      loginAPI()
        .then((res) => {
          if (res.status == "success") {
            localStorage.setItem("token", res.token);
            setTimeout(() => {
              fetchprofile({
                profile_id: res.user_profiles.profile_id,
              }).then((result) => {
                dispatch(
                  storeInLocalStore({
                    profile_data: result?.dispachabledata?.profile_data,
                  })
                );
                dispatch(
                  storeInLocalStore({
                    users: result?.dispachabledata?.users,
                  })
                );
                navigate("/my-social-media");
              });
            }, 500);
          } else {
            navigate("/profile-setting");
          }
        })
        .catch((rej) => {
          console.log(rej);
          setTimeout(() => {
            navigate("/profile-setting");
          });
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getuservalue();
  }, []);
  return (
    <div
      className="full-width"
      style={{
        height: "100vh",
      }}
    >
      <Loadericon />
    </div>
  );
};

export default Authenticate;
