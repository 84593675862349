import React from "react";
import "./../signupstyle.css";

const Agree = ({afreefunc,disagreefunc}) => {
  return (
    <div className="agree-banner">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="row align-items-top">
            <div className="col-md-6">
              <h1>Do you agree?</h1>
            </div>
            <div className="col-md-6">
              <h3>30 Days For Free</h3>
              <p>
                Connect with customers on the most popular social platforms on
                the planet. No marketing and design skills required. Connect
                with customers on the most popular social platforms on the
                planet. No marketing and design skills required.
              </p>
              <div className="model-delete-btn-conatiner">
                <button
                  className="byteready-btn byteready-primary"
                  onClick={afreefunc}
                >
                  I Agree
                </button>
                <button
                  className="byteready-btn byteready-outlinne"
                  onClick={disagreefunc}
                >
                  I Disagree
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agree;
