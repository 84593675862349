import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import CustomVideoPlayer from "./CustomVideoPlayer";

const Homesections = ({
  bgclass,
  aiimage,
  title,
  content,
  subtitle,
  right,
  videourl,
}) => {
  return (
    <div className={`section-padding common-section ${bgclass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className={`col-md-6 ${right ? "order-2" : ""}`}>
            <CustomVideoPlayer videoSrc={videourl} posterImage={aiimage} />
          </div>
          <div className="col-md-6">
            {title && (
              <h1
                className="heading-main text-black"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {subtitle && <h3 className="subtitle-main text-black">{subtitle}</h3>}
            {content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homesections;
