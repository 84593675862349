import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "./Banner/Banner";
import Origin from "./Origin/Origin";
import Location from "./Location/Location";
import Cuisine from "./Cuisine/Cuisine";
import Theme from "./Theme/Theme";
import {
  createthemebyuserAPI,
  getSavedthemeAPI,
  getlastfivedataAPI,
  getmainTemplateAPI,
  usercustomthemeImageUploadAPI,
} from "../../apis/auth";
import { useNavigate } from "react-router-dom";
import GlobalAlert from "../Common/GlobalAlert";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import Savedtheme from "./Theme/Savedtheme";
import Updatedescription from "./Theme/Popup/Updatedescription";
import Savecustomtemplate from "./Theme/Popup/Savecustomtemplate";
import Themesliders from "./Theme/Popup/Themesliders";

const Main = () => {
  const lastpageurl = useSelector((state) => state.main.store?.lastpageurl);
  const originRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.main.store?.users);
  const [savemessage, setSaveMessage] = useState("");
  const profileid = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const selectedtheme = useSelector((state) => state.main.store?.selectedevent);
  const selectedthemeid = useSelector(
    (state) => state.main.store?.selectedthemeid
  );
  const [editabletheme, setEditabletheme] = useState("");
  const [originlastfive, setOriginlastfive] = useState([]);
  const [locationlastfive, setLocationlastfive] = useState([]);
  const [cuisinelastfive, setCuisinelastfive] = useState([]);
  const [templateAll, setTemplateAll] = useState();
  const [showsavedtheme, setShowsavedtheme] = useState(false);
  const [savedthemelist, setSavedthemelist] = useState([]);
  const [opencustomtheme, setOpencustomtheme] = useState(false);
  const origin_output = useSelector((state) => state.main.store?.origin_output);
  const cuisine_output = useSelector(
    (state) => state.main.store?.cuisine_output
  );
  const location_output = useSelector(
    (state) => state.main.store?.location_output
  );
  const handletabchange = () => {};
  const initialrender = () => {
    getlastfivedataAPI({
      profile_id: profileid,
    })
      .then((res) => {
        if (res.status) {
          setOriginlastfive(res?.last_5_history?.origin);
          setLocationlastfive(res?.last_5_history?.location);
          setCuisinelastfive(res?.last_5_history?.cuisine);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
    if (users?.is_unified_web) {
      let payloadtheme = {
        profile_id: profileid,
        theme_id: "",
        is_all: true,
      };
      getSavedthemeAPI(payloadtheme)
        .then((res) => {
          if (res.status == "success") {
            if (res.data.length > 0) {
              setSavedthemelist(res.data);
              setShowsavedtheme(true);
            }
          } else {
            setShowsavedtheme(false);
          }
        })
        .catch((rej) => {
          console.log(rej);
          setShowsavedtheme(false);
        });
    }
  };
  const getMainTemplatedata = () => {
    getmainTemplateAPI()
      .then((res) => {
        let tempData = res.template.map((item) => ({
          id: item._id,
          imgUrl: item.image_url_for_card,
          text: item.title,
          desc: item.description,
        }));
        setTemplateAll(tempData);
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  useEffect(() => {
    if (users?.id) {
      initialrender();
    }
  }, [users?.id]);
  useEffect(() => {
    getMainTemplatedata();
  }, []);
  const handleClick = (data) => {
    dispatch(storeInLocalStore({ selectedevent: data }));
    dispatch(storeInLocalStore({ currentsubmenu: "smcalender" }));
  };
  function isAnyKeyEmpty(obj) {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        (obj[key] === undefined || obj[key] === null || obj[key] === "")
      ) {
        return true;
      }
    }
    return false;
  }
  const handlePopup = () => {
    setSaveMessage("");
  };
  const handlleedit = (data) => {
    console.log("edit", data);
    setEditabletheme(data);
  };
  const closeDescriptio = (data) => {
    if (data == "reload") {
      initialrender();
      setEditabletheme("");
    } else {
      setEditabletheme("");
    }
  };
  const scrolltodown = () => {
    if (originRef.current) {
      originRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const closesavedtheme = () => {
    setShowsavedtheme(false);
  };

  const savecustomtheme = () => {
    setOpencustomtheme(true);
  };
  const handleClosethmesave = () => {
    setOpencustomtheme(false);
  };
  const savecustomthemedata = (data) => {
    let payload = {
      profile_id: profileid,
      title: data.title,
      description: data.description,
      image_url_for_card: "",
      image_url_for_tab: "",
    };
    createthemebyuserAPI(payload)
      .then((res) => {
        if (res.status == "success") {
          if (data.image) {
            let payloadimg = {
              image: data.image,
            };
            usercustomthemeImageUploadAPI(
              profileid,
              res.template._id,
              payloadimg
            )
              .then((res) => {
                if (res.status == "success") {
                  handleClosethmesave();
                  getMainTemplatedata();
                }
              })
              .catch((rej) => {
                console.log("error", rej);
              });
          }
          initialrender();
        }
      })
      .catch((rej) => {
        console.log("Error:", rej);
      });
  };
  return (
    <>
      {/* <Banner
        lastpageurl={lastpageurl}
        handletabchange={handletabchange}
        scrolltodown={scrolltodown}
      /> */}
      <div ref={originRef}>
        <Origin
          originlastfive={originlastfive}
          mystory={true}
          handletabchange={handletabchange}
        />
      </div>

      <Location
        locationlastfive={locationlastfive}
        handletabchange={handletabchange}
      />
      <Cuisine
        cuisinelastfive={cuisinelastfive}
        handletabchange={handletabchange}
      />
      <Theme
        savecustomtheme={savecustomtheme}
        showsavedtheme={showsavedtheme}
        closesavedtheme={closesavedtheme}
      >
         <Themesliders
            templateAll={templateAll}
            selectedtheme={selectedtheme}
            selectedthemeid={selectedthemeid}
            users={users}
            handleClick={handleClick}

            // Saved theme
          />
        {/* {showsavedtheme ? (
          <>
            <Savedtheme
              templateAll={savedthemelist}
              selectedtheme={selectedtheme}
              selectedthemeid={selectedthemeid}
              users={users}
              handleClick={handleClick}
              handlleedit={handlleedit}
            />
          </>
        ) : (
         
        )} */}
      </Theme>
      {savemessage && (
        <GlobalAlert message={savemessage} handleClose={handlePopup} />
      )}
      {opencustomtheme && (
        <Savecustomtemplate
          open={opencustomtheme}
          savecustomthemedata={savecustomthemedata}
          cancleHandle={handleClosethmesave}
        />
      )}

      <Updatedescription
        open={editabletheme}
        cancleHandle={closeDescriptio}
        profile={profileid}
      />
    </>
  );
};

export default Main;
