import React from "react";
import totalIcon from "../../../assets/SVG/total_icon.svg";
import firstTimeIcon from "../../../assets/SVG/ph_eye-fill.svg";
import engagedIcon from "../../../assets/SVG/ph_eye-fill2.svg";
import firstConsumersIcon from "../../../assets/SVG/solar_user-bold.svg";
import dinersIcon from "../../../assets/SVG/solar_dollar-bold.svg";
import { Grid, Typography } from "@mui/material";

const Tabdesign = ({ index, handleTab }) => {
  return (
    <div className="tab-container">
      <div className="tab-item">
        <div className="total-card card-align" onClick={() => handleTab(0)}>
          <img src={totalIcon} className="card-icon" alt="error1" />
          <p className="text-d">Total</p>
        </div>
        <div className={index === 0 ? "under-line" : ""}></div>
      </div>
      <div className="tab-item">
        <div
          className="first-viewers-card card-align"
          onClick={() => handleTab(1)}
        >
          <img src={firstTimeIcon} className="card-icon" alt="error2" />
          <p className="text-d">First Time Viewers</p>
        </div>
        <div className={index === 1 ? "under-line" : ""}></div>
      </div>
      <div className="tab-item">
        <div
          className="engaged-viewers-card card-align"
          onClick={() => handleTab(2)}
        >
          <img src={engagedIcon} className="card-icon" alt="error3" />
          <p className="text-d">Engaged Viewers</p>
        </div>
        <div className={index === 2 ? "under-line" : ""}></div>
      </div>
      <div className="tab-item">
        <div className="consumers-card card-align" onClick={() => handleTab(3)}>
          <img src={firstConsumersIcon} className="card-icon" alt="error4" />
          <p className="text-d">First Time Consumers</p>
        </div>
        <div className={index === 3 ? "under-line" : ""}></div>
      </div>
      <div className="tab-item">
        <div className="diners-card card-align" onClick={() => handleTab(4)}>
          <img src={dinersIcon} className="card-icon" alt="error5" />
          <p className="text-d">Freq Diners</p>
        </div>
        <div className={index === 4 ? "under-line" : ""}></div>
      </div>
    </div>
  );
};

export default Tabdesign;
