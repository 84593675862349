import React, { useEffect, useState } from "react";
import "./MobileWrapper.css";
import phoneWrapper from "../../../assets/images/create-calender/phone-wrapper.png";
import staticlogo from "../../../assets/images/create-calender/static-logo.png";
import Designswitch from "./Switch/Designswitch";
import Instagram from "./Socialcomponent/Instagram";
import Facebook from "./Socialcomponent/Facebook";
import { useSelector } from "react-redux";

const MainWrapper = ({
  alldata,
  outputImage,
  outputtext,
  handleImageupload,
  generatecaption,
}) => {
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const [resname, setResname] = useState();
  const [currenttab, setCurrenttab] = useState("insta");
  const [website, setWebsite] = useState("");
  const designSwitch = (data) => {
    setCurrenttab(data);
  };
  useEffect(() => {
    setWebsite(profile_info?.website || "");
    setResname(profile_info?.resturant_name || "");
  }, [profile_info?.website, profile_info?.resturant_name]);
  return (
    <div className="mobile-main-wrapper">
      <div className="mobile-design">
        <img src={phoneWrapper} className="w-100 phone-wrapper-img" />
        <div className="design-switch">
          <Designswitch currenttab={currenttab} handleChange={designSwitch} />
        </div>
        <div className="mobile-header">
          <div className="left-header">
            <div className="icon">
              <img
                src={
                  website
                    ? `https://s2.googleusercontent.com/s2/favicons?domain=${website}`
                    : staticlogo
                }
                onError={staticlogo}
                className="icon"
              />
            </div>
            <div className="restaurant-name">
              <span className="name">{resname}</span>
              <span className="sponsored">Sponsored</span>
            </div>
          </div>
          <div className="right-header">...</div>
        </div>
        <div className="social-content">
          {currenttab == "insta" ? (
            <>
              <Instagram
                alldata={alldata}
                outputImage={outputImage}
                outputtext={outputtext}
                handleImageupload={handleImageupload}
                generatecaption={generatecaption}
              />
            </>
          ) : (
            <>
              <Facebook
                alldata={alldata}
                outputImage={outputImage}
                handleImageupload={handleImageupload}
                generatecaption={generatecaption}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainWrapper;
