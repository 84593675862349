import React, { useEffect, useState, useRef } from "react";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Box, Stack, Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton/CustomButton.jsx";
import BookMark from "../../Common/BookMark";
import eventCardImage1 from "../../../assets/images/EventCard1.png";
import PopOver from "../PopOver/PopOver";
import style from "./BodyStyle.js";
import EventCard from "../../Common/EventsCard/EventCard.jsx";
import eventCardImage2 from "../../../assets/images/EventCard2.png";
import eventCardImage3 from "../../../assets/images/EventCard3.png";
import eventCardImage4 from "../../../assets/images/EventCard4.png";
import eventCardImage5 from "../../../assets/images/EventCard5.png";
import offerImage1 from "../../../assets/images/offer_image1.png";
import offerImage2 from "../../../assets/images/offer_image2.png";
import offerImage3 from "../../../assets/images/offer_image3.png";
import offerImage4 from "../../../assets/images/offer_image4.png";
import offerImage5 from "../../../assets/images/offer_image5.png";
import rewardsImage1 from "../../../assets/images/rewards_image1.png";
import rewardsImage2 from "../../../assets/images/rewards_image2.png";
import rewardsImage3 from "../../../assets/images/rewards_image3.png";
import rewardsImage4 from "../../../assets/images/rewards_image4.png";
import rewardsImage5 from "../../../assets/images/rewards_image5.png";
import { useDispatch, useSelector } from "react-redux";
import { useCompaignData } from "../../../Context/CampaignContext.js";
import { getallsavedunitAPI } from "../../../apis/auth/index.jsx";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice.jsx";

const eventInitialData = [
  {
    imgUrl: eventCardImage1,
    text: "Taco Day",
    editBtn: false,
    isBookMark: false,
  },
  {
    imgUrl: eventCardImage2,
    text: "Dessert Day",
    editBtn: false,
    isBookMark: false,
  },
  {
    imgUrl: eventCardImage3,
    text: "Pizza Month",
    editBtn: false,
    isBookMark: false,
  },
  {
    imgUrl: eventCardImage4,
    text: "Pasta Day",
    editBtn: false,
    isBookMark: false,
  },
  {
    imgUrl: eventCardImage5,
    text: "October",
    editBtn: false,
    isBookMark: false,
  },
  {
    imgUrl: eventCardImage4,
    text: "Pasta Day",
    editBtn: false,
    isBookMark: false,
  },
];

const offersInitialData = [
  {
    imgUrl: offerImage1,
    text: "get $X off your order if it’s over $X.",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: offerImage2,
    text: "buy  X  {item}, get X  free!",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: offerImage3,
    text: "you will get X% off orders over $X!",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: offerImage4,
    text: "buy {item} and get a free {item}!",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: offerImage5,
    text: "Join us {time} pm, featuring $X drinks and more!",
    editBtn: true,
    isBookMark: false,
  },
];

const rewardsInitialData = [
  {
    imgUrl: rewardsImage1,
    text: "Free GLASS OF WINE WITH DINNER FOR 2",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: rewardsImage2,
    text: "FREE DESSERT WITH EVERY FAMILY DINNER",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: rewardsImage3,
    text: "Where happy hours are the happiest. Join us {time} pm, featuring $X drinks and more!",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: rewardsImage4,
    text: "Give the gift of great taste this Christmas! For every $X spent, get $X off!",
    editBtn: true,
    isBookMark: false,
  },
  {
    imgUrl: rewardsImage5,
    text: "Join us {time} pm, featuring $X drinks and more!",
    editBtn: true,
    isBookMark: false,
  },
];

const Body = ({ templateData }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTabs] = useState("events");
  const [eventData, setEventData] = useState(eventInitialData);
  const [offersData, setOffersData] = useState(offersInitialData);
  const [rewardsData, setRewardsData] = useState(rewardsInitialData);
  const handlenextRef = useRef(null);
  const [compaignData, setCompaignData] = useCompaignData();
  const dispatch = useDispatch();
  const profile_id = useSelector(
    (state) => state?.main?.store?.profile_data?.profile_id
  );
  const [popoverData, setPopoverData] = useState();
  const [count, setCount] = useState({
    eventCount: 0,
    offersCount: 0,
    rewardsCount: 0,
  });
  const [index, setIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState();

  const handleNextImage = () => {
    let button = document.querySelector(".nextButton.d-none");
    button.click();
    // console.log(button);
    // if (handlenextRef.current && handlenextRef.current.goToNext) {
    //   handlenextRef.current.goToNext();
    // }

    // if (
    //   tab === "events" &&
    //   index < eventData.length - 5 &&
    //   eventData.length > 5
    // ) {
    //   let newIndex = index + 1;
    //   let filterData = eventData?.filter(
    //     (element, i) => i >= newIndex && i < newIndex + 5
    //   );
    //   setCurrentImage(filterData);
    //   setIndex(newIndex);
    // } else if (
    //   tab === "offers" &&
    //   index < offersData.length - 5 &&
    //   offersData.length > 5
    // ) {
    //   let newIndex = index + 1;
    //   let filterData = offersData?.filter(
    //     (element, i) => i >= newIndex && i < newIndex + 5
    //   );
    //   setCurrentImage(filterData);
    //   setIndex(newIndex);
    // } else if (
    //   tab === "rewards" &&
    //   index < rewardsData.length - 5 &&
    //   rewardsData.length > 5
    // ) {
    //   let newIndex = index + 1;
    //   let filterData = rewardsData?.filter(
    //     (element, i) => i >= newIndex && i < newIndex + 5
    //   );
    //   setCurrentImage(filterData);
    //   setIndex(newIndex);
    //   // let filterData = rewardsData?.filter((element, i) => i > index);
    //   // setCurrentImage(filterData);
    //   // setIndex(index + 1);
    //   // if (index === rewardsData.length - 1) {
    //   //   setCurrentImage(rewardsInitialData);
    //   //   setIndex(0);
    //   // }
    // }
  };

  const handleFilter = () => {
    if (tab === "events") {
      let filterData = eventData?.filter((element) => element.isBookMark);
      setCurrentImage(filterData);
    } else if (tab === "offers") {
      let filterData = offersData?.filter((element) => element.isBookMark);
      setCurrentImage(filterData);
    } else if (tab === "rewards") {
      let filterData = rewardsData?.filter((element) => element.isBookMark);
      setCurrentImage(filterData);
    }
  };

  const handleEventCounter = (index) => {
    const newData = eventData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isBookMark: !item?.isBookMark,
        };
      } else {
        return item;
      }
    });
    setEventData(newData);
  };

  const handleOfferCounter = (index) => {
    const newData = offersData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isBookMark: !item?.isBookMark,
        };
      } else {
        return item;
      }
    });
    setOffersData(newData);
  };

  const handleRewardsCounter = (index) => {
    const newData = rewardsData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isBookMark: !item?.isBookMark,
        };
      } else {
        return item;
      }
    });
    setRewardsData(newData);
  };

  const handlePopover = (element) => {
    setOpen(true);
    setPopoverData(element);
  };
  const handlePopoverClose = () => {
    setOpen(false);
    setPopoverData("");
  };

  useEffect(() => {
    const eventCount = eventData.reduce((accumulator, currentValue) => {
      if (currentValue.isBookMark) {
        accumulator++;
      }
      return accumulator;
    }, 0);
    setCount({ ...count, eventCount: eventCount });
  }, [eventData]);

  useEffect(() => {
    const offerCount = offersData.reduce((accumulator, currentValue) => {
      if (currentValue.isBookMark) {
        accumulator++;
      }
      return accumulator;
    }, 0);

    setCount({ ...count, offersCount: offerCount });
  }, [offersData]);

  useEffect(() => {
    const rewardCount = rewardsData.reduce((accumulator, currentValue) => {
      if (currentValue.isBookMark) {
        accumulator++;
      }
      return accumulator;
    }, 0);
    setCount({ ...count, rewardsCount: rewardCount });
  }, [rewardsData]);

  useEffect(() => {
    if (tab === "events") {
      setCurrentImage(eventData);
    } else if (tab === "offers") {
      setCurrentImage(offersData);
    } else if (tab === "rewards") {
      setCurrentImage(rewardsData);
    }
  }, [tab, eventData, offersData, rewardsData]);

  useEffect(() => {
    if (templateData) {
      const eventData = templateData
        .filter((item) => item.template_category == "events")
        .map((item) => ({
          id: item.id,
          imgUrl: item.content?.image_url,
          text: item?.template_title,
          editBtn: false,
          contentData: item.content,
          isBookMark: false,
        }));
      const rewardData = templateData
        .filter((item) => item.template_category == "rewards")
        .map((item) => ({
          id: item.id,
          imgUrl: item.content?.image_url,
          text: item?.template_title,
          editBtn: true,
          contentData: item.content,
          isBookMark: false,
        }));
      const offersData = templateData
        .filter((item) => item.template_category == "offers")
        .map((item) => ({
          id: item.id,
          imgUrl: item.content?.image_url,
          text: item?.template_title,
          editBtn: true,
          contentData: item.content,
          isBookMark: false,
        }));
      setEventData(eventData);
      setOffersData(offersData);
      setRewardsData(rewardData);
    } else {
      setEventData(eventInitialData);
      setOffersData(offersInitialData);
      setRewardsData(rewardsInitialData);
    }
  }, [templateData]);
  const getAllsavedunitData = () => {
    if (profile_id) {
      // getallsavedunitAPI(profile_id).then((res) => {
      //   const _data = res.addunit.map((item) => ({
      //     id: item.id,
      //     imgUrl: item.image_url,
      //     text: item.title,
      //     description: item.description,
      //     date: item.updated_at,
      //     time: "",
      //     event_price: "",
      //   }));
      //   setCompaignData(_data);
      //   dispatch(storeInLocalStore({ campaignUnit: _data }));
      // });
    }
  };
  useEffect(() => {
    if (compaignData.length == 0) {
      getAllsavedunitData();
    } else {
      dispatch(storeInLocalStore({ campaignUnit: compaignData }));
    }
  }, []);
  const handletab = (tabdata) => {
    // console.log(tabdata);
    setTabs(tabdata);
  };
  return (
    <>
      <Box sx={style.mainContainer}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            marginBottom: "20px",
            padding: "10px 0px",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Typography
              onClick={() => setTabs("events")}
              sx={{
                ...style.text,
                opacity: tab !== "events" && "0.30000001192092896",
              }}
            >
              events
            </Typography>
            <Brightness1Icon sx={style.iconStyle} />
            <Typography
              onClick={() => setTabs("offers")}
              sx={{
                ...style.text,
                opacity: tab !== "offers" && "0.30000001192092896",
              }}
            >
              Offers
            </Typography>
            <Brightness1Icon sx={style.iconStyle} />
            <Typography
              onClick={() => setTabs("rewards")}
              sx={{
                ...style.text,
                opacity: tab !== "rewards" && "0.30000001192092896",
              }}
            >
              Rewards
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <CustomButton
              onClick={handleNextImage}
              sx={{ width: "20%", height: "40px" }}
              label="Next"
            />
            <BookMark
              handleSelect={handleFilter}
              count={
                tab === "events"
                  ? count?.eventCount
                  : tab === "offers"
                  ? count?.offersCount
                  : tab === "rewards"
                  ? count?.rewardsCount
                  : 0
              }
            />
          </Stack>
        </Stack>
        {tab === "events" && (
          <EventCard
            ref={handlenextRef}
            handleCounter={handleEventCounter}
            data={currentImage}
            handlePopover={handlePopover}
          />
        )}
        {tab === "offers" && (
          <EventCard
            ref={handlenextRef}
            handleCounter={handleOfferCounter}
            data={currentImage}
            handlePopover={handlePopover}
          />
        )}
        {tab === "rewards" && (
          <EventCard
            ref={handlenextRef}
            handleCounter={handleRewardsCounter}
            data={currentImage}
            handlePopover={handlePopover}
          />
        )}

        <PopOver
          open={open}
          handleClose={handlePopoverClose}
          data={popoverData}
          tab={tab}
          changeTab={handletab}
        />
      </Box>
    </>
  );
};

export default Body;
