const style = {
  cardContainer: {
    display: "flex",
    padding: "3.347px 4.463px 6.694px 4.463px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: "4.463px",
    alignSelf: "stretch",
  },
  header: {
    display: "flex",
    padding: "3.347px 4.463px",
    alignItems: "center",
    gap: "50.208px",
    alignSelf: "stretch",
  },
  headerText: {
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: 600,
  },
  body: {
    width: "100%",
    height: "100%",
    flexShrink: 0,
    margin: "0px",
    padding: "0px"
  },
  cardActions:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px"
  }
};

export default style;
