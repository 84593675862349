import React from "react";
import style from "../viewersStyle";
import { Box, Stack, Typography } from "@mui/material";
import plusIcon from "../../../assets/SVG/plusMinus.svg";

const AddPoint = ({ customStyle }) => {
  return (
    <>
      <Box sx={{ ...style.addPointBox, ...customStyle }} align="center">
        <Box sx={style.whiteBox}>
          <img width="24px" height="24px" src={plusIcon} alt="error" />
        </Box>
        <Typography sx={{ ...style.text2, marginTop: "1%" }}>
          Add a Point
        </Typography>
      </Box>
    </>
  );
};

export default AddPoint;
