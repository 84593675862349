const style = {
    container: {
        padding: "98px 0 50px 0",
        backgroundColor: "#000000",
        width: "100%",
        margin: "0px",
        position: "relative",
        margin: "20px",
        width: "100%",
    },
    orangeHeading: {
        fontFamily: 'Whyte Inktrap',
        fontSize: '70px',
        fontWeight: '1000',
        lineHeight: '101px',
        letterSpacing: '0em',
        color: '#eb2426',
        // backgroundColor:"#181818"
    },
    whiteHeading: {
        fontFamily: 'Whyte Inktrap',
        fontSize: '50px',
        fontWeight: '1000',
        lineHeight: '101px',
        letterSpacing: '0em',
        color: '#fff',
        textTransform:'uppercase'
        // backgroundColor:"#181818"
    },
    smallHeading: {
        fontFamily: 'Whyte Inktrap',
        fontSize: '16px',
        fontWeight: '1000',
        lineHeight: '8px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#9E9E9E',
        textWrap: 'nowrap',
        position:"absolute",
        top:'3%',
        right:0
    }
}
export default style