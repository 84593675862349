import React from "react";
import cardtrial from "../../../assets/images/info/image2.png";

const Cardtwo = ({ handleFocus }) => {
  return (
    <>
      <div className="suggestion-card-container">
        <div className="suggetion-inner-container">
          <div className="image-container">
            <img src={cardtrial} className="w-100" />
          </div>
          <div className="content-container">
            <h6 className="sugesstion-class">Use Descriptive Language</h6>
            <p className="font-small">Promote Special Features</p>
            <div className="btn-container">
              <span
                onClick={() => {
                  handleFocus("skip");
                }}
                className="skip-btn"
              >
                Skip
              </span>
              <button
                onClick={() => {
                  handleFocus(3);
                }}
                className="byteready-btn byteready-primary"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cardtwo;
