import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/commonStyle.css";
import Header from "./components/Layout/Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/Layout/Footer/Footer";
import { useClerk } from "@clerk/clerk-react";
import GlobalAlert from "./components/Common/GlobalAlert";
import { useDispatch } from "react-redux";
import { storeInLocalStore } from "./redux/reducers/mainSlice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const clerk = useClerk();
  const [errorAlert, setErroralert] = useState(null);
  const handleError = () => {
    setErroralert(null);
    window.location.reload();
  };
  useEffect(() => {
    dispatch(storeInLocalStore({ lastpageurl: "/unifying-digital" }));
  }, []);
  return (
    <div className="maincontainer">
      {clerk.loaded && (
        <>
          <Header pathName={location.pathname} />
          <div
            style={{
              minHeight: "80vh",
            }}
          >
            <Outlet />
          </div>
          <Footer pathName={location.pathname} />
          {errorAlert && (
            <GlobalAlert message={errorAlert} handleClose={handleError} />
          )}
        </>
      )}
      <button
        id="showalert"
        onClick={() => {
          setErroralert("Not enough privileges.");
        }}
        hidden
      >
        Show Alert
      </button>
    </div>
  );
}

export default App;
