import React from "react";

const Card = ({ icon, bgcode, title, subtitle }) => {
  return (
    <div className="cards custom-card">
      <div className="top-container">
        <img src={icon} alt="icon" className="icons" />
        <p className="title-name">{title}</p>
      </div>
      <div className={`bottom-container ${bgcode}`}>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

export default Card;
