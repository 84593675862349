import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import MediaTextSwitch from "../../Common/MediaTextSwitch";
import IncreaseViewers from "../../Viewers/IncreaseViewers/IncreaseViewers";
import Reach from "../../Viewers/Reach/Reach";
import MobView from "../../Viewers/MobView/MobView";

const style = {
  label: {
    margin: "5% 0% 2% 0%",
    color: "#9E9E9E",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "center",
  },
};

const FirstTimeView = ({ loader }) => {
  return (
    <>
      <Typography sx={style.label}>Choose From Your Campaigns</Typography>
      <MediaTextSwitch
        customStyle={{ width: { xs: "70%", sm: "50%", md: "30%" } }}
      />
      <MobView />
      <Grid sx={{ marginTop: "5%" }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <IncreaseViewers />
        </Grid>
        <Grid item xs={12} md={6}>
          <Reach />
        </Grid>
      </Grid>
    </>
  );
};

export default FirstTimeView;
