import React from "react";

const Slidercard = ({ blankimg, content }) => {
  return (
    <div className="right-card">
      <div className="card custom-card">
        <img src={blankimg} className="w-100" />
        <div className="content-area">
          <p className="font-small">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default Slidercard;
