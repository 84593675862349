import React, { useState } from "react";
import "./../signupstyle.css";
import facebookicon from "../../../assets/images/header/social-connect-facebook.png";
import instagramicon from "../../../assets/images/header/social-connect-instagram.png";
import tickicon from "../../../assets/images/header/social-connect-tick.png";
import noteicon from "../../../assets/images/header/social-media-connect-note.png";
import Agree from "./Agree";
import {
  authtokenforsmfbAPI,
  confirmsmpostcalenderAPI,
  fetchProfileAPI,
  postagreeAPI,
} from "../../../apis/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GlobalAlert from "../../../components/Common/GlobalAlert";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import { fetchprofile } from "../../../CustomHook/addressUtills";

const SocialmediaConnect = () => {
  const [loginDetails, setLogindetails] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moveto = useSelector((state) => state.main.store?.moveto);
  const lastpageurl = useSelector((state) => state.main.store?.lastpageurl);
  const calendartime = useSelector((state) => state.main.store?.calendertime);
  const profile_data = useSelector((state) => state.main.store?.profile_data);
  const user_id = useSelector((state) => state.main.store?.users.id);
  const users = useSelector((state) => state.main.store?.users.id);
  const [agreeopen, setAgreeopen] = useState(false);
  const [message, setMessage] = useState("");
  const [loginStatus, setLoginstatus] = useState();
  const [savebtn, setSavebtn] = useState(true);
  const [connect, setConnect] = useState(false);
  const [fbname, setFbname] = useState("ilmelograno");
  const agreeopenfunc = () => {
    setAgreeopen(true);
  };
  const handleClose = () => {
    setMessage("");
  };
  const afreefunc = () => {
    if (calendartime) {
      let payload = {
        profile_id: profile_data?.profile_id,
        start_time: calendartime,
      };
      confirmsmpostcalenderAPI(payload)
        .then((res) => {
          if (res.status) {
            let payload = {
              user_id: user_id,
              profile_id: profile_data?.profile_id,
              is_trial: true,
            };
            postagreeAPI(payload)
              .then((res) => {
                if (res.status) {
                  fetchprofile({
                    profile_id: profile_data?.profile_id,
                  })
                    .then((res) => {
                      if (res) {
                        dispatch(
                          storeInLocalStore({
                            profile_data: res?.dispachabledata?.profile_data,
                          })
                        );
                        dispatch(
                          storeInLocalStore({
                            users: res?.dispachabledata?.users,
                          })
                        );
                        if (lastpageurl == "/unifying-digital") {
                          dispatch(
                            storeInLocalStore({
                              currentsubmenu: "createmypaidads",
                            })
                          );
                          navigate("/create-calender");
                        } else {
                          navigate("/my-social-media");
                        }
                      }
                    })
                    .catch((rej) => {
                      console.log(rej);
                    });
                }
              })
              .catch((rej) => {
                console.log(rej);
              });
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    } else {
      let payload = {};
      if (profile_data?.profile_id) {
        payload = { profile_id: profile_data?.profile_id };
      } else {
        payload = { user_id: users };
      }
      fetchprofile(payload)
        .then((res) => {
          if (res) {
            dispatch(
              storeInLocalStore({
                profile_data: res?.dispachabledata?.profile_data,
              })
            );
            dispatch(
              storeInLocalStore({
                users: res?.dispachabledata?.users,
              })
            );
            setAgreeopen(false);
            setTimeout(() => {
              navigate(moveto || "/");
            }, 3000);
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    }
  };
  const disagreefunc = () => {
    setAgreeopen(false);
  };
  const handleFacebook = () => {
    FB.login(
      (response) => {
        if (response.authResponse) {
          setLoginstatus(true);
          setLogindetails(response.authResponse);
          fetch(
            `https://graph.facebook.com/me?access_token=${response.authResponse?.accessToken}&fields=name`
          )
            .then((response) => response.json())
            .then((data) => {
              setFbname(data.name);
              let payload = {
                user_id: user_id,
                profile_id: profile_data?.profile_id,
                platform_user_id: response.authResponse?.userID,
                platform_user_token: response.authResponse?.accessToken,
                fb_name: data.name,
              };
              authtokenforsmfbAPI(payload).then((res) => {
                if (res) {
                  setSavebtn(false);
                } else {
                }
              });
              setConnect(true);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.log("You are not logged in.", response);
        }
      },
      {
        scope:
          "public_profile,email,pages_show_list,user_photos,user_posts,pages_manage_posts,instagram_manage_comments,read_insights,pages_read_engagement,pages_manage_engagement,instagram_basic,instagram_content_publish,pages_manage_metadata",
        return_scopes: true,
      }
    );
  };
  return (
    <>
      <div className="full-width headerpadding signup-page">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-center h3-title-while-whynk">
                  Almost done
                </h1>
                <p className="text-center">Connect your socials</p>
              </div>
            </div>
            <div className="row position-relative pt-5 social-media-connect">
              <div className="col-md-6 offset-md-3">
                <div className="content-padding-both">
                  <div className="social-input-wrapper">
                    <input
                      type={"text"}
                      value={fbname}
                      className="custom-input social-connect"
                      readOnly
                    />
                    <img src={facebookicon} className="left-icon" />
                    {connect ? (
                      <>
                        <div className="right-icon">
                          <img src={tickicon} />
                          Connected
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="right-icon">
                          <button
                            onClick={handleFacebook}
                            className="social-connect-btn"
                          >
                            Connect
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="social-input-wrapper">
                    <div className="position-relative">
                      <input
                        type={"text"}
                        value={fbname}
                        className="custom-input social-connect"
                        readOnly
                      />
                      <img src={instagramicon} className="left-icon" />
                      {connect ? (
                        <>
                          <div className="right-icon">
                            <img src={tickicon} />
                            Connected
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="right-icon">
                            <button className="social-connect-btn">
                              Connect
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <p className="social-media-note">
                      <img src={noteicon} alt="note" className="note-icon" />
                      <span>
                        Make sure your instagram account is connected to your
                        facebook account.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-3 pt-5">
              <div className="col-md-12 text-center">
                <p className="py-2 font-normal">
                  Having trouble and need help?{" "}
                  <span className="signinbtn-span">Learn More</span>
                </p>
                <button
                  type="submit"
                  className={`byteready-btn byteready-primary signupbtn  ${
                    savebtn ? "disabled" : ""
                  }`}
                  onClick={agreeopenfunc}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {agreeopen && (
          <Agree afreefunc={afreefunc} disagreefunc={disagreefunc} />
        )}
        {message && <GlobalAlert message={message} handleClose={handleClose} />}
      </div>
    </>
  );
};

export default SocialmediaConnect;
