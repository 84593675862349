import { createSlice } from "@reduxjs/toolkit";

export const cuisineSlice = createSlice({
  name: "cuisine",
  initialState: {
    cuisine: {
      profile_id: "",
      resturant_name: "",
      resturant_address: "",
      resturant_website: "",
      img_url: "",
      promt_text: "",
      cuisine: "",
      origins: "",
      vibe: "",
      dish: "",
      description: "",
    },
  },
  reducers: {
    createCuisine: (state, actions) => {
      return {
        ...state,
        ...actions.payload,
      };
    },
    storeInLocalStore: (state, action) => {
      state.cuisine = Object.assign( state.cuisine, action.payload);
      localStorage.setItem(
        "resturantData",
        JSON.stringify({ ...state.cuisine, ...action.payload })
      );
    },
  },
});

export const { createCuisine, storeInLocalStore } = cuisineSlice.actions;

export default cuisineSlice.reducer;