import React, { useState } from "react";
import "./Facebookstyle.css";

import PhoneImage from "../../../assets/images/iPhone_14_Pro.png";
import VerticalContainer from "../../../assets/images/vertical_container.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FrontCamera from "../../../assets/SVG/Front_Camera.svg";
import EditButton from "../../Common/EditButton";
import globeIcon from "../../../assets/SVG/gridicons_globe.svg";
import OutlinedButton from "../../Common/OutlineButton";
import fbIcon from "../../../assets/images/fbLike.png";
import fbLikeIcon from "../../../assets/images/instaLike.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  storeInLocalStore,
  selectedPlatform,
} from "../../../redux/reducers/mainSlice";

const FacebookView = ({ restaurantName, data, loader, operation, weburl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEdit = (data) => {
    let tempdata = { ...data?.alldata, selectedplatform: "facebook" };
    dispatch(storeInLocalStore({ ediatablepost: tempdata }));
    dispatch(selectedPlatform("fb"));
    navigate("/edit-posts");
  };
  return (
    <>
      <div className="facebook-container">
        <div className="description">
          <span className="text">{data.description}</span>
        </div>
        <div
          className="post-image"
          style={{ backgroundImage: `url(${data?.imgUrl})` }}
        >
          {/* <h1 className="eventtitle">{data?.event}</h1> */}
          {!data?.alldata?.is_posted && (
            <div className="editbtn">
              <EditButton
                onClick={() => {
                  handleEdit(data);
                }}
              />
            </div>
          )}
        </div>
        <div className="learn-more">
          <div className="left-learnmore">
            <p className="website">{weburl || "Websitename.com"}</p>
            <p className="headline">{restaurantName}</p>
          </div>
          <div className="right-learnmore">
            <button className="learnbtn">Learn More</button>
          </div>
        </div>
        <div className="likearea">
          <div className="left-likearea">
            <img src={fbLikeIcon} className="love" alt="likeerror" />
            <img src={fbIcon} className="like" alt="fberror" />{" "}
            <span>3,4K</span>
          </div>
          <div className="right-likearea">
            <span>70 comments</span> <span> 104 shares</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookView;
