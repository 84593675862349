import React from "react";

const List = ({
  locations,
  handlepopup,
  handlecsvupload,
  plusicon,
  uploadicon,
  editpen,
  deletepen,
  handledelete,
  handleEdit,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="">
          <div className="basic-card payments-area">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="h3-title-while">Locations</h3>
                  </div>
                  <div>
                    <div
                      className="btn-container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        margin: "20px 0px",
                      }}
                    >
                      <button
                        className="byteready-btn byteready-primary"
                        onClick={() => {
                          handlepopup();
                        }}
                      >
                        <img src={plusicon} alt="Upload Icon" /> Add New Address
                      </button>
                      <div className="file-main">
                        <label
                          className="byteready-btn byteready-outlinne upload-button"
                          style={{
                            border: "1px solid #fff",
                          }}
                          onClick={() => {
                            handlecsvupload();
                          }}
                        >
                          <img src={uploadicon} alt="Upload Icon" />
                          Add CSV File
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="loation-table-wrapper scroll-design">
                  <table className="table table-dark table-borderless location-table">
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th>Authorize</th>
                        <th>Email</th>
                        <th className="text-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locations.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.address}</td>
                            <td>{item.authorize}</td>
                            <td>{item.email}</td>
                            <td className="text-end">
                              <div className="table-btn-container">
                                <span
                                  onClick={() => {
                                    handleEdit(index);
                                  }}
                                >
                                  <img src={editpen} alt="edit" />
                                </span>
                                <span
                                  onClick={() => {
                                    handledelete(index);
                                  }}
                                >
                                  <img src={deletepen} alt="edit" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
