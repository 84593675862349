import React from "react";
import ArrowRight from "../../../assets/SVG/arrow.svg";

const imgStyle = {
  width: "16px",
  height: "16px",
};

const RightArrow = ({ sx }) => {

  return (
    <>
      <img style={{ ...imgStyle, ...sx }} alt="ImgError" src={ArrowRight} />
    </>
  );
};

export default RightArrow;
