const style = {
  mainContainer: {
    backgroundColor: "transprant",
    width: "200px",
    height: "60px",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    padding: "2px",
    cursor: "pointer",
    border: "2px solid #FCFCFC",
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    height: "60px",
    backgroundColor: "transparent",
    borderRadius: "50%",
    transition: "transform 0.3s",
  },
  text: {
    color: "#FCFCFC",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "SFProText-Regular",
    fontWeight: 900,
  },
};

export default style;
