import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

import FacebookIcon from "../../../assets/SVG/fbIcon.svg";
import ActiveFacebookIcon from "../../../assets/SVG/activeFbicon.svg";
import InstgramIconactive from "../../../assets/SVG/IstaIcon.svg";
import InstagramNormal from "../../../assets/SVG/IstaIconnormal.svg";
import normalyelp from "../../../assets/images/mysocialmedia/yelp.png";
import activeyelp from "../../../assets/images/mysocialmedia/yelp-active.png";
const Switcher = ({ isActive, handleActiveSocialMedia }) => {
  return (
    <>
      <div className="switcher-container">
        <div
          className={`item ${isActive == "all" ? "active" : ""}`}
          onClick={() => handleActiveSocialMedia("all")}
        >
          <span>All</span>
        </div>
        <div
          className={`item ${isActive == "insta" ? "active" : ""}`}
          onClick={() => handleActiveSocialMedia("insta")}
        >
          <img
            style={{ fill: isActive === "insta" ? "#eb2426" : "#FCFCFC" }}
            src={isActive === "insta" ? InstgramIconactive : InstagramNormal}
            alt="InstgramIcon"
          />
        </div>
        <div
          className={`item ${isActive == "fb" ? "active" : ""}`}
          onClick={() => handleActiveSocialMedia("fb")}
        >
          <img
            style={{ fill: isActive === "fb" ? "#eb2426" : "#FCFCFC" }}
            src={isActive === "fb" ? ActiveFacebookIcon : FacebookIcon}
            alt="FacebookIcon"
          />
        </div>
        <div
          className={`item ${isActive == "yelp" ? "active" : ""}`}
          onClick={() => handleActiveSocialMedia("yelp")}
        >
          <img
            style={{ fill: isActive === "yelp" ? "#eb2426" : "#FCFCFC" }}
            src={isActive === "yelp" ? activeyelp : normalyelp}
            alt="yelpicon"
            width={"20"}
          />
        </div>
      </div>
    </>
  );
};

export default Switcher;
