import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import googleContainer from "../../../assets/images/postview/gmail-blank.png";
import style from "./Gmailstyle";
import bytereadylogo from "../../../assets/images/postview/top-bytereadylogo.png";
import post from "../../../assets/images/events-3.png";
import secondrybtn from "../../../assets/images/postview/secondry-btn.png";
import primarybtn from "../../../assets/images/postview/primary-btn.png";
import emailimage from "../../../assets/images/postview/email-image.png";
import InstaMobileView from "../InstaMobileView/InstaMobileView";
import bytereadyiconlogo from "../../../assets/images/postview/byteready-logo.png";
import PreviousBtn from "../../Common/PreviousBtn";
import NextBtn from "../../Common/NextBtn";
import UpdateEventPopUp from "../../NewEvents/EventPopOver/UpdateEventPopUp";
import { useSelector } from "react-redux";

function GoogleView({
  data,
  profile,
  showCustomHandleButton,
  functionOnModalClose,
}) {
  const itemsPerPage = 3;
  const [startIndex, setStartIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState();
  const [popUpData, setPopUpData] = useState();
  const [campaignData, setCampaignData] = useState([]);
  const visibleItems = data?.slice(startIndex, startIndex + itemsPerPage);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const getCaption = (data) => {
    let caption = data?.filter((x) => x.platform == "facebook");
    console.log(caption?.[0]?.caption, "CAPTION");
    return caption?.[0]?.caption;
  };
  const handleNext = (items) => {
    console.log("CAMPAIGN DATAAAAAAAAA");
    if (startIndex + itemsPerPage < data.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  const handlePrevious = (items) => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    if (showCustomHandleButton) {
      setCampaignData(visibleItems);
    } else {
      setCampaignData(data);
    }
  }, [data, showCustomHandleButton, visibleItems]);
  const getFontSize = (text) => {
    const wordCount = text?.split(/\s+/).length; // Split text into words

    // Calculate font size based on word count
    let fontSize = 30; // Default font size
    if (wordCount <= 2) {
      fontSize = 30; // Larger font for fewer words
    } else if (wordCount > 2) {
      fontSize = 18; // Smaller font for more words
    }
    return fontSize;
  };
  return (
    <>
      {showCustomHandleButton && data?.length > 3 && (
        <PreviousBtn width={"50px"} handlePrevious={() => handlePrevious()} />
      )}
      {campaignData?.map((campaign, index) => {
        return (
          <React.Fragment key={index}>
            {open && (
              <UpdateEventPopUp
                platform={"google ad"}
                functionOnModalClose={functionOnModalClose}
                profile_id={profile_id}
                data={popUpData}
                tab={tab}
                open={open}
                handleClose={() => setOpen(false)}
              />
            )}
            <Box
              onClick={() => {
                !campaign?.is_posted && setOpen(true);
                setTab(campaign?.type);
                setPopUpData({
                  ...campaign,
                  desc:
                    campaign?.other_platforms?.[0]?.caption ||
                    campaign?.description,
                });
              }}
              sx={{
                ...style.mainWrapper,
                backgroundImage: `url(${googleContainer})`,
                cursor: "pointer",
              }}
            >
              <img style={style.bytereadylogo} src={bytereadylogo} />
              <Typography sx={style.text}>
                {campaign?.description.slice(0,120)+'...'}
                {/* Physiological respiration involves the mechanisms that ensure
                that the composition of the functional residual capacity is kept
                constant */}
              </Typography>
              <Box className="d-flex align-items-center justify-content-around px-5">
                <img
                  style={{
                    height: "10px",
                    cursor: "pointer",
                  }}
                  src={secondrybtn}
                />
                <img
                  style={{
                    height: "25px",
                    cursor: "pointer",
                  }}
                  src={primarybtn}
                />
              </Box>
              <Box
                sx={{
                  margin: "10px 15px",
                }}
              >
                <img style={style.emailimage} src={emailimage} />
              </Box>
              <Box
                sx={{
                  margin: "10px 15px",
                  textAlign: "center",
                  marginTop: "-25px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: `${getFontSize(campaign?.title)}px`,
                    fontFamily: "helvetica-font",
                    lineHeight: "1",
                    color: "var(--primary-color)",
                  }}
                >
                  {campaign?.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  transform: `scale(0.4)`,
                  transformOrigin: "top",
                  height: "225px",
                }}
              >
                <InstaMobileView
                  data={campaign}
                  commingfromgmail={true}
                  profile={profile}
                />
              </Box>
              <Box className="d-flex align-items-center justify-content-center px-5">
                <img
                  style={{
                    height: "25px",
                    cursor: "pointer",
                  }}
                  src={primarybtn}
                />
              </Box>
              <Box className="d-flex align-items-center justify-content-center px-5 py-1">
                <img
                  style={{
                    height: "18px",
                    cursor: "pointer",
                  }}
                  src={bytereadyiconlogo}
                />
              </Box>
            </Box>
          </React.Fragment>
        );
      })}
      {showCustomHandleButton && data?.length > 3 && (
        <NextBtn width={"50px"} handleNext={() => handleNext()} />
      )}
    </>
  );
}

export default GoogleView;
