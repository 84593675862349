import React from "react";
import WrapperComp from "../../../components/WrapperComp/index.jsx";
import { Button, Stack, Typography } from "@mui/material";
import style from "./index.js";

const CongrsScreen = () => {
  return (
    <>
      <WrapperComp
        SingleCompnents={
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography sx={style.boldText} variant="h4">
              Congratulations
            </Typography>
            <Typography sx={style.text} variant="p">
              Your password was successfully changed
            </Typography>
            <Button sx={style.loginButton} variant="contained" >
              Log In
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default CongrsScreen;