import React from "react";
import logo from "./../../../../assets/img/logo.png";
import fbicon from "./../../../../assets/img/footer-fb.png";
import instaicon from "./../../../../assets/img/footer-insta.png";

const Footerhome = () => {
  const menuone = [
    {
      name: "DATA",
      link: "",
    },
    {
      name: "CONSUMER",
      link: "",
    },
    {
      name: "MEDIA",
      link: "",
    },
    {
      name: "CREATIVE",
      link: "",
    },
    {
      name: "START NOW",
      link: "",
    },
  ];
  const menutwo = [
    {
      name: "PRIVACY POLICY ",
      link: "",
    },
    {
      name: "TERMS & CONDITIONS",
      link: "",
    },
  ];

  return (
    <div className="footer-white">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-logo-container">
              <img src={logo} alt="" className="footer-logo" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-link">
              <ul className="footer-ui">
                {menuone.map((item, index) => {
                  return <li key={index}>{item.name}</li>;
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-link">
              <ul className="footer-ui">
                {menutwo.map((item, index) => {
                  return <li key={index}>{item.name}</li>;
                })}
              </ul>
            </div>
            <div className="social-media-link">
              <ul className="social-link">
                <li>
                  <a href="#" target="_blank">
                    <img src={fbicon} alt="fb" className="icon" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src={instaicon} alt="fb" className="icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footerhome;
