import React, { useEffect, useRef, useState } from "react";
import "./Socialmediadesign.css";
import reloadimg from "../../../../assets/images/create-calender/reload-icon.png";
import loveicon from "../../../../assets/images/create-calender/facebook-like.png";
import comment from "../../../../assets/images/create-calender/70comments.png";
import shares from "../../../../assets/images/create-calender/104shares.png";
import { useSelector } from "react-redux";

const Facebook = ({
  alldata,
  outputImage,
  outputtext,
  handleImageupload,
  generatecaption,
}) => {
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const imageuploadRef = useRef();
  const [website, setWebsite] = useState("");
  const [retaurantname, setRestaurantname] = useState("");
  useEffect(() => {
    setWebsite(profile_info?.website);
    setRestaurantname(profile_info?.resturant_name);
  }, [profile_info]);
  return (
    <>
      <div className="instagram-main-container facebook-container">
        <div className="description">{outputtext || alldata.cuisine_story}</div>
        <div className="post-image">
          <img
            src={outputImage || alldata.output_image}
            className="w-100"
            style={{ minHeight: "150px" }}
          />
          <img
            onClick={() => {
              generatecaption("image");
            }}
            src={reloadimg}
            className="reloadicon"
          />
          <button
            onClick={() => {
              imageuploadRef.current.click();
            }}
            className="upload-image"
          >
            Upload Image
          </button>
          <input
            ref={imageuploadRef}
            type="file"
            accept=".jpg, .jpeg, .png"
            hidden
            onChange={(e) => {
              handleImageupload(e);
            }}
          />
        </div>
        <div className="learn-more">
          <div className="left-learnmore">
            <p className="website">{website || "Websitename.com"}</p>
            <p className="headline">{retaurantname || "Restauramt Name"}</p>
          </div>
          <div className="right-learnmore">
            <button className="learnbtn">Learn More</button>
          </div>
        </div>
        <div className="icon-area">
          <div className="left-icon">
            <img src={loveicon} className="like-icon" />
          </div>
          <div className="right-icn">
            <div>
              <img src={comment} className="comment-icon" />
              <img src={shares} className="share-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facebook;
