import React, { useEffect, useState } from "react";
import WrapperComp from "../../components/WrapperComp/index.jsx";
import { Button, Stack, Typography } from "@mui/material";
import style from "./../Thankyou/thankyoustyle";
import { useLocation, useNavigate } from "react-router-dom";
import { uservaification } from "../../apis/auth";
import GlobalAlert from "../../components/Common/GlobalAlert";

const ConfirmUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState();
  let profile_id = "";
  let token = "";
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    profile_id = searchParams.get("profile_id");
    token = searchParams.get("id");
    console.log(profile_id, token);
  }, [location.search]);
  const varifyuser = () => {
    let payload = {
      profile_id: profile_id,
      token: token,
    };
    uservaification(payload)
      .then((res) => {
        if (res.status == "success") {
          setMessage("Verified!");
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const handleClose = () => {
    setMessage("");
    navigate("/login");
  };
  return (
    <>
      <div className="customcontainerglobal">
        <WrapperComp
          SingleCompnents={
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={style.boldText} variant="h4">
                User Verification
              </Typography>

              <Button
                onClick={() => {
                  varifyuser();
                }}
                sx={style.loginButton}
                variant="contained"
              >
                Confirm
              </Button>
            </Stack>
          }
        />
        {message && <GlobalAlert message={message} handleClose={handleClose} />}
      </div>
    </>
  );
};

export default ConfirmUser;
