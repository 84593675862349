import React, { useState } from "react";
import bannerone from "../../../assets/images/home/scribe-banner.png";
import BannerComponent from "../../Common/CommnerBanner/BannerComponent";
import { useNavigate } from "react-router-dom";

const Banner = ({
  firstfunction,
  secondfunct,
  firstbtntext,
  secondbtntext,
}) => {
 
  const breadcrumbdata = [
    {
      name: "Home",
      link: "/",
    },
  ];

  return (
    <>
      <BannerComponent
        breadcrumbdata={breadcrumbdata}
        bannerimg={bannerone}
        title={`SCRIBE AI <br> <span className="bold-inner"> AI Crafted <br /> Social Media</span>`}
        subtitle={`Share your story - <br />grow your customers`}
        btnone={firstbtntext}
        btnonefunction={firstfunction}
        btntwo={secondbtntext}
        btntwofunction={secondfunct}
      />
    </>
  );
};

export default Banner;
