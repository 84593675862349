import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import image from "../../../../assets/images/mysocialmedia/dummy-image-popup.png";
import uploadicon from "../../../../assets/images/create-calender/upload-icon.png";
import sendsuggestion from "../../../../assets/images/mysocialmedia/send-suggestion.png";
import editicon from "../../../../assets/images/create-calender/edit-icon.png";
import reloadicon from "../../../../assets/images/create-calender/reload-icon.png";
import cross from "../../../../assets/images/mysocialmedia/cross.png";
import { useState } from "react";
import { useEffect } from "react";
import {
  createCustomThemeAPI,
  saveCustomThemeAPI,
  uploadImageCustomThemeAPI,
} from "../../../../apis/auth";
import UploadBtn from "../../../Common/UploadBtn";
import Loadericon from "../../../Common/Loadericon";
import { useDispatch } from "react-redux";
import { storeInLocalStore } from "../../../../redux/reducers/mainSlice";

const Updatedescription = ({ open, cancleHandle, profile }) => {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(open?.image_url);
  const [imageFile, setImageFile] = useState();
  const [userThaughts, setUserThaughts] = useState(open?.user_thoughts);
  const [aiText, setAiText] = useState(open?.description);
  const [applied, setApplied] = useState(false);
  const [aiLoader, setAiLoader] = useState(false);
  const [textareaElement, setTextareaElement] = useState(null);
  const [contentEditable, setContentEditable] = useState(false);
  useEffect(() => {
    setImageUrl(open?.image_url);
  }, [open]);
  const handleCreateSuggestion = () => {
    if (profile) {
      setAiLoader(true);
      let payload = {
        profile_id: profile,
        temp_id: open?.temp_id,
        user_thoughts: userThaughts,
      };
      createCustomThemeAPI(payload)
        .then((res) => {
          if (res?.status == "success") {
            setAiText(res?.data?.description);
            setAiLoader(false);
            setContentEditable(false);
            setApplied(false);
          }
        })
        .catch((error) => {
          setAiLoader(false);
        });
    }
  };
  const handleImageUploadBackend = (data) => {
    let payload = {
      image: imageFile,
      profile_id: profile,
      theme_id: data,
    };
    uploadImageCustomThemeAPI(payload)
      .then((res) => {
        console.log(res, "RESULT");
        cancleHandle("reload");
      })
      .catch((error) => {
        console.log("Error ", error);
      });
  };
  const handleSaveTheme = () => {
    if (profile) {
      setAiLoader(true);
      let payload = {
        profile_id: profile,
        temp_id: open?.temp_id,
        user_thoughts: userThaughts || open?.user_thoughts,
        description: aiText || open?.description,
        image_url: imageUrl,
        custom_theme_id: open?.id,
      };
      saveCustomThemeAPI(payload)
        .then((res) => {
          if (res?.status == "success") {
            let eventData = {
              id: open?.id,
              description: open?.description,
              imageUrl: open?.image_url,
              text: open?.title,
              custom_themeid: res?.data?.id,
            };
            dispatch(storeInLocalStore({ eventTitle: eventData }));
            if (imageFile) {
              handleImageUploadBackend(res?.data?.id);
            } else {
              cancleHandle("reload");
            }
          }
        })
        .catch((error) => {
          setAiLoader(false);
        });
    }
  };
  const applyTheme = () => {
    setApplied(true);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const newFileName = e.target.files[0]?.name?.replace(/\([^()]*\)/g, "");
    const modifiedFile = new File([file], newFileName, { type: file.type });
    setImageFile(modifiedFile);
    setImageUrl(URL.createObjectURL(modifiedFile));
  };
  useEffect(() => {
    const textarea = document.getElementById("autoHeightTextarea");
    if (textarea) {
      setTextareaElement(textarea);
    }
  }, []);
  function adjustTextareaHeight(textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
  useEffect(() => {
    if (textareaElement) {
      adjustTextareaHeight(textareaElement);
    }
  }, [textareaElement, aiText]);

  return (
    <Modal
      className="description-body"
      size="lg"
      show={open}
      centered
      onHide={() => {
        cancleHandle();
        setAiText(false);
        setContentEditable(false);
        setApplied(false);
      }}
    >
      <Modal.Body>
        <>
          {aiLoader && <Loadericon />}
          <div className="cross-container">
            <img
              src={cross}
              onClick={() => {
                cancleHandle();
                setAiText(false);
                setContentEditable(false);
                setApplied(false);
              }}
              className="cross-icon"
            />
          </div>
          <div className="row p-3">
            <div className="col-md-12">
              <h1 className="title text-center">{open?.title}</h1>
            </div>
            <div className="row py-4">
              <div className="col-md-4">
                <div className="image-container">
                  <img src={imageUrl} className="imagedata w-100" />
                  <UploadBtn showText={true} handleChange={handleImageUpload} />
                </div>
              </div>
              <div className="col-md-8">
                <div className="card-container">
                  <p>Description</p>
                  {!applied && <textarea>{open?.description}</textarea>}
                  {applied && <textarea>{aiText}</textarea>}
                </div>
                <div className="ai-result-container">
                  {aiText && !applied && (
                    <>
                      <div className="ai-result">
                        <p className="font-normal">AI Suggestion</p>
                        <textarea
                          ref={setTextareaElement}
                          id="autoHeightTextarea"
                          value={aiText || open?.description}
                          disabled={!contentEditable}
                          onChange={(e) => setAiText(e.target.value)}
                          style={{
                            background: "transparent",
                            color: "#fff",
                            width: "100%",
                            padding: "10px 0px",
                            fontSize: "16px",
                            border: contentEditable ? "1px solid #fff" : "none",
                            outline: contentEditable
                              ? "1px solid #000"
                              : "none",
                          }}
                        />
                      </div>
                      <div className="edit-btn-area">
                        <div className="btn-container">
                          <img
                            src={editicon}
                            onClick={() => setContentEditable(true)}
                            className="edit-icon"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <img
                            src={reloadicon}
                            onClick={() => handleCreateSuggestion()}
                            className="edit-icon"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        {!applied && (
                          <button
                            onClick={() => applyTheme()}
                            className="byteready-btn byteready-outlinne"
                          >
                            Apply
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="ai-suggestions">
                  <p style={{ marginLeft: "5px", marginBottom: "10px" }}>
                    AI Suggestion
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="custom-input"
                      placeholder="Type your thoughts"
                      onChange={(e) => setUserThaughts(e.target.value)}
                    />
                    <img
                      src={sendsuggestion}
                      className="send-icon"
                      onClick={() => handleCreateSuggestion()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12">
                <button
                  onClick={() => handleSaveTheme()}
                  className="byteready-btn byteready-primary m-auto"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default Updatedescription;
