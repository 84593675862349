import React, { useState, useRef, useEffect } from "react";
import Origins from "../../components/Home/originComp/Origins.jsx";
import Location from "../../components/Home/Location/Location.jsx";
import Cuisine from "../../components/Home/Cuisine/Cuisine.jsx";
import Stories from "../../components/Home/Stories/Stories.jsx";
import Footer from "../../components/Home/Footer/Footer.jsx";
import Body from "../../components/Home/Body/Body.jsx";
import GlobalLoader from "../../components/Common/GlobalLoader/GlobalLoader.jsx";
import { Box } from "@mui/material";
import {
  useScrollerRefProvider,
  ScrollerRefContext,
} from "../../CustomHook/useScrollerRef.js";

const StoriesPage = () => {
  return (
    <>
      <Box>
        <Stories />
      </Box>
      
    </>
  );
};

export default StoriesPage;
