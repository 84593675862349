import React from "react";
import style from "./PopOverStyle";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { Modal } from "react-bootstrap";

const ProfileAlert = ({ open, handleClose, text }) => {
  return (
    <>
      <Modal show={open} centered>
        <Box sx={style.profileAlert}>
          <Typography
            sx={{ ...style.title, textAlign: "center", marginBottom: "20px" }}
          >
            {
              text ? text : 'Please fill in the Restaurant Information First.'
            }
          </Typography>
          <Box align="center">
            <CustomButton
              sx={{ width: "20%" }}
              label="Okay"
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileAlert;
