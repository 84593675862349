import React from "react";
import activeinsta from "../../assets/images/create-calender/switch/instagram-active.png";
import insta from "../../assets/images/create-calender/switch/instagram.png";
import facebook from "../../assets/images/create-calender/switch/facebook.png";
import activefacebook from "../../assets/images/create-calender/switch/active-facebook.png";

const Socialmediaswitch = ({ handlechange, activedesign }) => {
  return (
    <>
      <div className="socialmediaswitch">
        <p className="chhoose-platform-text">Choose The Platform</p>
        <div className="switch-btn-container">
          <div
            className={`btn-item ${activedesign == "insta" ? "active" : ""}`}
            onClick={() => {
              handlechange("insta");
            }}
          >
            <img
              src={activedesign == "insta" ? activeinsta : insta}
              className="w-100"
            />
          </div>
          <div
            className={`btn-item ${activedesign == "fb" ? "active" : ""}`}
            onClick={() => {
              handlechange("fb");
            }}
          >
            <img
              src={activedesign == "fb" ? activefacebook : facebook}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Socialmediaswitch;
