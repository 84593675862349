import React from "react";
import Footerinner from "./partial/Footerinner";
import "./Footerstyle.css";
import Footerhome from "./partial/Footerhome";

const Footer = ({ pathName }) => {
  return (
    <>
      <>
        {pathName == "/" ? (
          <>
            <Footerhome />
          </>
        ) : (
          <Footerinner />
        )}
      </>
    </>
  );
};

export default Footer;
