import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "./../../../../assets/img/logo.png";
import profileImg from "../../../../assets/images/vertical_container.png";
import dropdownIcon from "../../../../assets/SVG/dropdown-arrow.svg";
import { Dropdown } from "react-bootstrap";
import Mycustomers from "../../subheaders/Mycustomerssubheader";
import Insightsubmenu from "../../subheaders/Insightsubmenu";
import Mystory from "../../subheaders/Mystory";
import Mysocialmedia from "../../subheaders/Mysocialmedia";

const Headerblack = ({ menus, pathName, user, profile_name, handlemenu }) => {
  const navigate = useNavigate();

  const [scroll, setScroll] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [myprofileurl, setMyprofileurl] = useState(Clerk?.user?.imageUrl || "");
  const athenticationurl = [
    "/verify",
    "/signup",
    "/social-media-connect",
    "/forget-password",
    "/login",
    "/locations",
  ];
  const noheader = [
    "/login",
    "/verify",
    "/signup",
    "/social-media-connect",
    "/forget-password",
    "/payment-summary",
    "/boost-payment-done",
    "/payment-done",
    "/payment-failed",
    "/profile-setting",
    "/locations",
    "/redirecting",
  ];
  const is_unified_web = user?.is_unified_web;
  const lastpageurl = useSelector((state) => state.main.store?.lastpageurl);
  const [unified, setUnified] = useState(true);
  const userid = user?.id;
  const [submenuname, setSubmenuname] = useState("");
  const [scrollY, setScrollY] = useState(0);
  const [opacity, setOpacity] = useState({
    origin: false,
    location: false,
    cuisine: false,
  });

  const changeNavbar = () => {
    if (window.scrollY >= 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
    if (window.scrollY >= 660 && window.scrollY <= 1500) {
      setOpacity({ ...opacity, origin: true });
    } else if (window.scrollY >= 1550 && window.scrollY <= 2400) {
      setOpacity({ ...opacity, origin: true, location: true });
    } else if (window.scrollY >= 2400) {
      setOpacity({
        origin: true,
        location: true,
        cuisine: true,
      });
    }
  };
  window.addEventListener("scroll", changeNavbar);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollThreshold = 100;
  useEffect(() => {
    setIsScrolled(scrollY > scrollThreshold);
  }, [scrollY]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("token")) {
      if (is_unified_web) {
        setUnified(true);
        setSubmenuname("Unifying Digital Engagement Program");
      } else {
        setUnified(false);
        setSubmenuname("AI Crafted Social Media");
      }
    } else {
      if (lastpageurl == "/scribe-ai") {
        setUnified(false);
        setSubmenuname("AI Crafted Social Media");
      } else if (lastpageurl == "/unifying-digital") {
        setUnified(true);
        setSubmenuname("Unifying Digital Engagement Program");
      } else {
        setUnified(true);
        setSubmenuname("AI Crafted Social Media");
      }
    }
  }, [pathName]);

  return (
    <div className="full-width-header">
      <div
        className={`header-common header-black  fixed-top ${
          isScrolled ? "scrolled" : ""
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-container">
                <div className="logo">
                  <Link to={"/"} className="navbar-brand">
                    <img src={logo} alt="logo" className="logo" />
                  </Link>
                </div>
                {!noheader.includes(pathName) ? (
                  <>
                    <div className="menu-container">
                      <ul className="nav-menu">
                        {menus.map((item, index) => (
                          <li key={index} className="nav-link">
                            <span
                              className={`nav-item ${
                                item.link == pathName ? "active" : ""
                              }`}
                            >
                              {item.name}
                            </span>
                            {user?.id && (
                              <>
                                {item.submenu && (
                                  <ul className="submenu">
                                    {item.submenu.map((subItem, subIndex) => (
                                      <li
                                        key={subIndex}
                                        onClick={() => {
                                          handlemenu(subItem);
                                        }}
                                      >
                                        <span>{subItem.name}</span>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {!athenticationurl.includes(pathName) ? (
                      <>
                        {userid ? (
                          <>
                            <div className="admin-area">
                              <Dropdown
                                onClick={() => {
                                  navigate("/my-profile");
                                }}
                              >
                                <Dropdown.Toggle className="profile">
                                  <img
                                    src={myprofileurl || profileImg}
                                    alt="error"
                                    onError={(e) => {
                                      e.target.src = profileImg;
                                    }}
                                    style={{
                                      width: 44,
                                      height: 44,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <span
                                    className={
                                      pathName == "/my-profile" && "bold-inner"
                                    }
                                  >
                                    {profile_name || "Restaurant Name"}
                                  </span>
                                  <img src={dropdownIcon} alt="error" />
                                </Dropdown.Toggle>
                              </Dropdown>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="menu-area content-padding-right">
                              <ul className="nav-menu">
                                <li className="nav-link">
                                  <Link to={"/login"} className="btn btn-white">
                                    Sign In
                                  </Link>
                                </li>
                                <li className="nav-link">
                                  <Link
                                    to={"/signup"}
                                    className="btn btn-primary"
                                  >
                                    Sign Up
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {submenuname && (
                          <div className="signup-header-design">
                            {submenuname}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {submenuname && (
                      <div className="signup-header-design">{submenuname}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {pathName === "/my-customers" && (
          <Mycustomers unified={unified} submenu={submenuname} />
        )}
        {pathName === "/insight-360" && (
          <Insightsubmenu unified={unified} submenu={submenuname} />
        )}
        {pathName === "/create-calender" && (
          <Mystory unified={unified} submenu={submenuname} />
        )}
        {pathName === "/my-social-media" && (
          <Mysocialmedia unified={unified} submenu={submenuname} />
        )}
      </div>
    </div>
  );
};

export default Headerblack;
