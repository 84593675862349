import React from "react";
import style from "../Pupupstyle";
import {
  Box,
  CircularProgress,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import ResetBtn from "../../../../assets/SVG/ic_round-refresh.svg";
import RightArrow from "../../../../assets/SVG/RightArrow.svg";
import LikeButton from "../../../../assets/SVG/white_like.svg";
import CommentButton from "../../../../assets/SVG/white_comment.svg";
import ShareButton from "../../../../assets/SVG/white_share.svg";
import EditButton from "../../../../assets/SVG/tabler_edit.svg";

const Instagrams = ({
  uploadImage,
  loader,
  handleUploadImage,
  textLoader,
  editable,
  content,
  resetImage,
  setEditable,
  handleEdit,
  handleEditClose,
}) => {
  return (
    <>
      {loader ? (
        <Box
          sx={{
            ...style.instaPicBox,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "#eb2426" }} />
        </Box>
      ) : (
        <Box
          sx={{
            ...style.instaPicBox,
            backgroundImage: `url(${uploadImage})`,
          }}
        >
          <Box sx={style.btnContainer}>
            <Box onClick={() => resetImage("image")} sx={style.btnBox}>
              <img src={ResetBtn} alt="ResetBtn" />
            </Box>
          </Box>
          <Box align="center">
            <Button sx={style.imgunder} variant="contained" component="label">
              Upload Image
              <input
                onChange={handleUploadImage}
                type="file"
                accept=".jpg, .jpeg, .png"
                hidden
              />
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={style.shopBox}>
        <Typography sx={style.shoptext}>Shop Now</Typography>
        <img src={RightArrow} alt="RightArrow" />
      </Box>
      <Box sx={style.instaFooter}>
        <Box>
          <img
            width="20px"
            height="20px"
            style={style.instaBtn}
            src={LikeButton}
            alt="LikeButton"
          />
          <img
            width="20px"
            height="20px"
            style={style.instaBtn}
            src={CommentButton}
            alt="CommentButton"
          />
          <img
            width="20px"
            height="20px"
            style={style.instaBtn}
            src={ShareButton}
            alt="ShareButton"
          />
        </Box>
        <Stack sx={style.editBox} direction="row">
          <img
            style={{
              width: "25px",
              height: "25px",
              padding: "3px",
              cursor: "pointer",
              borderRadius: "50%",
              background: "#fff",
              boxShadow: "0 0 4px #888888",
              margin: "5px",
            }}
            onClick={() => resetImage("text")}
            src={ResetBtn}
            alt="EditButton"
          />
          <img
            style={{
              width: "25px",
              height: "25px",
              padding: "3px",
              cursor: "pointer",
              borderRadius: "50%",
              background: "#fff",
              boxShadow: "0 0 4px #888888",
              margin: "5px",
            }}
            onClick={() => setEditable(true)}
            src={EditButton}
            alt="EditButton"
          />
        </Stack>
      </Box>
      <Box sx={style.mobBotttomBox}>
        {textLoader ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: "#eb2426" }} />
            </Box>
          </>
        ) : (
          <>
            {editable ? (
              <>
                <textarea
                  // cols="60"
                  // rows="4"
                  charswidth="23"
                  style={{
                    height: "100px",
                    border: "1px solid",
                    width: "98%",
                    fontSize: "12px",
                    overflowY: "hidden",
                    resize: "none",
                  }}
                  value={content}
                  onChange={handleEdit}
                  onBlur={handleEditClose}
                />
              </>
            ) : (
              <>
                <p style={style.mobBotttomText}>{content}</p>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Instagrams;
