import React, { useEffect, useState } from "react";
import "./Tabview.css";
import MultiStepBar from "../Common/MultiStepBar";
import EventsNew from "./Events/EventsNew";
import FacebookMobileView from "../commoncomponents/FacebookMobileView/FacebookMobileView";
import PostPreview from "./PostPreview/PostPreview";
import { useCompaignData } from "../../Context/CampaignContext";
import { useSelector } from "react-redux";

const Tabview = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const campaignData = useSelector((state) => state?.main?.store?.campaignUnit);
  const campaignId = useSelector((state) => state?.main?.store?.campaignId);
  let lastStep = campaignData?.[campaignData?.length - 1]?.tab;
  // console.log(lastStep, campaignData, "Laststep");
  useEffect(() => {
    if (campaignId) {
      setCurrentStep(3);
    } else {
      if (lastStep == "events") {
        setCurrentStep(1);
      } else if (lastStep == "offers") {
        setCurrentStep(2);
      } else if (lastStep == "rewards") {
        setCurrentStep(2);
      }
    }
  }, [campaignData, campaignId]);
  const data = [
    { label: "Events" },
    { label: "Offers" },
    { label: "Rewards" },
    { label: "Confirm" },
  ];
  const handletabchange = (index) => {
    setCurrentStep(index);
  };
  return (
    <div className="tab-view">
      <div className="row">
        <div className="col-md-12">
          <div className="content-padding-both events-tab">
            <MultiStepBar
              showNext={true}
              steps={data}
              active={currentStep}
              handlechange={handletabchange}
            />
          </div>
        </div>
      </div>
      {(currentStep == 0 || currentStep == 1 || currentStep == 2) && (
        <EventsNew handletabchange={handletabchange} tab={currentStep} />
      )}
      {currentStep == 3 && <PostPreview />}
    </div>
  );
};

export default Tabview;
