const style = {
    boldText: {
      color: "#FCFCFC",
      fontSize: "80px",
      fontFamily: "WhyteInktrap-Black",
      fontWeight: 400,
      lineHeight: "105px",
      textTransform: "uppercase",
    },
    text: {
      color: "#FCFCFC",
      fontSize: "16px",
      fontFamily: "SFProText-Regular",
    },
    inputStyles: {
      backgroundColor: "black",
      border: "1px solid rgba(255, 255, 255, 0.20)",
      padding: "34px 20px 20px 20px",
      borderRadius: "5px",
      width: "300px",
      color: "#FCFCFC",
      textAlign: "justify",
      fontSize: "16px",
      fontFamily: "SFProText-Regular",
    },
    saveButton: {
      backgroundColor: "#eb2426",
      width: "342px",
      borderRadius: "50px",
      display: "flex",
      padding: "16px 50px",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        background: "#eb2426",
      },
    },
    inputLabel: {
      position: "absolute",
      top: "120px",
      paddingTop: "10px",
      paddingLeft: "20px",
      color: "#FCFCFC",
      textAlign: "center",
      fontSize: "18px",
      fontFamily: "WhyteInktrap-Black",
    },
  };
  
  export default style;
  