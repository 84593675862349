import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import style from "./EventPageStyle.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GlobalLoader from "../../Common/GlobalLoader/GlobalLoader.jsx";
import { CampaignContext } from "../../../Context/CampaignContext.js";
import { geteventTemplatebyidAPI } from "../../../apis/auth/index.jsx";
import EventsImageNew from "../EventsImage/NewEventsImage.jsx";
import EventBody from "../EventBody/EventBody.jsx";

const EventsNew = ({ handletabchange, tab }) => {
    const eventTitle = useSelector((state) => state?.main?.store?.eventTitle);
    const campaigncreated = useSelector((state) => state?.main.campaigncreated);
    const history = useNavigate();
    const [pageTitle, setPageTitle] = useState();
    const [type,setType] = useState()
    const [offersInitialData, setOffersInitialData] = useState();
    const [isLoading, setLoading] = useState(false);
    const [eventData, setEventData] = useState({
        firstName: 'SUMMER Season',
        content: "Most delicious pancakes in New York!!!My Restaurant is Greek. Giovanni was the head chef at an inn in a small village in Sicily. He had learned how to cook from his mother and grandmother, who had taught him the secrets of traditional Sicilian cuisine."
    })
    const geteventTemplateData = () => {
        setLoading(true);
        geteventTemplatebyidAPI(eventTitle.id).then((res) => {
            setLoading(false);
            setOffersInitialData(res.template);
        });
    };
    useEffect(() => {
        if (eventTitle) {
            geteventTemplateData();
            setPageTitle(eventTitle.text);
            setEventData({
                id:eventTitle?.id,
                firstName: eventTitle?.text,
                content: eventTitle?.description,
                img: eventTitle?.imageUrl
            })
        } else {
            setPageTitle("");
            history("/");
        }
    }, [eventTitle]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    useEffect(()=>{
        if(tab==0){
            setType("events")
        }else if(tab==1){
            setType("offers")
        }else if(tab==2){
            setType("rewards")
        }
    },[tab])

    return (

        isLoading ? (
            <>
                <GlobalLoader open={isLoading} text={"Getting Templates..."} />
            </>
        ) : (
            <>
                <Box sx={style.container}>
                    <EventsImageNew pageTitle={pageTitle} eventpage={eventData} tab={tab} handletabchange={handletabchange} />
                    <>
                        <Box className="customcontainerglobal">
                            <EventBody type={type} eventpage={eventData} templateData={offersInitialData} handletabchange={handletabchange} tab={tab} />
                        </Box>
                    </>
                </Box>
            </>
        )

    );
};

export default EventsNew;
