import React, { useState, useRef } from "react";
import { Box, Button, Fab, Grid, Stack, Typography } from "@mui/material";
import style from "./socialstyle";
import WrapperComp from "../../components/WrapperComp/index.jsx";
import CustomButton from "../../components/Common/CustomButton";
import OutlineButton from "../../components/Common/OutlineButton";

import { Link } from "react-router-dom";

const Agree = () => {
  const handleSubmit = () => {};
  return (
    <>
      <WrapperComp
        firstComp={
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography sx={style.boldText} variant="h4">
              Do you <br /> agree?
            </Typography>
          </Stack>
        }
        secondComp={
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Box
              sx={{
                paddingTop: "90px",
                width: "400px",
              }}
            >
              <Typography sx={style.agreeTitle} variant="h6">
                30 Days For Free
              </Typography>

              <Typography sx={style.agrrecontent} variant="text">
                Connect with customers on the most popular social platforms on
                the planet. No marketing and design skills required. Connect
                with customers on the most popular social platforms on the
                planet. No marketing and design skills required.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  marginTop:'20px'
                }}
              >
                <CustomButton
                  handleChange={handleSubmit}
                  text={"I Agree"}
                  spacing={"10px 30px"}
                  custommargin={"0px"}
                />
                <OutlineButton
                  handleChange={handleSubmit}
                  text={"I Disagree"}
                  spacing={"10px 30px"}
                  custommargin={"0px 10px"}
                />
              </Box>
            </Box>
          </Stack>
        }
      />
    </>
  );
};

export default Agree;
