import React, { useEffect, useState } from "react";
import style from "./Pupupstyle";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  Modal,
} from "@mui/material";
// import PHONEIMG from "../../../assets/SVG/phoneImg.svg";
import PhoneImage from "../../../assets/images/postview/homephone.png";
import SwitchButton from "./../../../components/Common/SwitchButton/SwitchButton.jsx";
import SocialHeader from "./../../../components/Common/ImageSlider/Postdesign/SocialHeader";
import VerticalContainer from "../../../assets/images/vertical_container.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RightArrow from "../../../assets/SVG/RightArrow.svg";
import LikeButton from "../../../assets/SVG/white_like.svg";
import CommentButton from "../../../assets/SVG/white_comment.svg";
import ShareButton from "../../../assets/SVG/white_share.svg";
import EditButton from "../../../assets/SVG/tabler_edit.svg";
import ResetBtn from "../../../assets/SVG/ic_round-refresh.svg";
import FrontCamera from "../../../assets/SVG/Front_Camera.svg";
import image from "../../../assets/images/Image.png";

import {
  getCaptionsAPI,
  imageUpload,
  updateCaptionAPI,
} from "../../../apis/auth/index.jsx";
import Facebooks from "./Posts/Facebooks";
import Instagrams from "./Posts/Instagrams";
import { useSelector } from "react-redux";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#151515",
  margin: "auto",
  border: "2px solid #151515",
  boxShadow: "0px 8px 25.596452713012695px 0px rgba(0, 0, 0, 0.10)",
  p: 4,
  padding: "20px",
  textAlign: "center",
  margin: "auto",
};

const PopupShow = ({ open, wesiturl, resName, handleClose, resData }) => {
  const [uploadImage, setUploadImage] = useState(image);
  const [currentIndex, setCurrentIndex] = useState("insta");
  const [content, setContent] =
    useState(`My Restaurant is Italian. Giovanni was the head chef at an inn in
  a small village in Sicily. He had learned how to cook from his
  mother and grandmother, who had taught him the secrets of
  traditional Sicilian cuisine.`);
  const [editable, setEditable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cleanurl, setCleanurl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [textLoader, setTextLoader] = useState(false);
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
    const modifiedFile = new File([file], newFileName, { type: file.type });

    setLoader(true);
    setImageFile(file);
    imageUpload({
      id: resData.profile_id,
      date: open.date,
      image: modifiedFile,
    }).then((res) => {
      if (res.status == "success") {
        setLoader(false);
        setUploadImage(res.image_url);
      } else {
        setLoader(false);
        setAlertMessage(res.message);
      }
    });
    setTimeout(() => {
      setAlertMessage("");
    }, 2000);
  };
  const resetImage = (type) => {
    if (type == "text") {
      setTextLoader(true);
    } else {
      setLoader(true);
    }

    let data = {
      profile_id: resData?.profile_id,
      date: open.date,
      refresh: true,
      caption_type: "N/A",
      caption_response: type,
    };
    getCaptionsAPI(data)
      .then((res) => {
        if (type == "text") {
          setTextLoader(false);
          setContent(res.captions?.prompt_output_text);
        } else {
          setUploadImage(res.captions?.prompt_output_img_url);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
        setLoader(false);
      });
    setTimeout(() => {
      setAlertMessage("");
    }, 2000);
  };
  const handleEdit = (event) => {
    const inputValue = event.target.value;
    setContent(inputValue);
    setAlertMessage("API Error");
  };
  useEffect(() => {
    setUploadImage(open.prompt_output_img_url);
    setContent(open?.prompt_output_text?.trim());
    if (wesiturl) {
      let tempcleanurl = wesiturl
        .replace(/^https?:\/\//, "")
        // .replace(/^www\./, "")
        .replace(/\/$/, "");
      setCleanurl(tempcleanurl);
    }
  }, [open]);
  const designSwitch = (data) => {
    setCurrentIndex(data);
  };
  const handleSave = () => {
    // console.log(uploadImage, content, open.date, resData.profile_id);
    if (
      open.prompt_output_img_url != uploadImage ||
      open?.prompt_output_text?.trim() != content
    ) {
      updateCaptionAPI({
        profile_id: resData.profile_id,
        prompt_output_text: content,
        prompt_output_img_url: uploadImage,
        date: open.date,
      }).then((res) => {
        if (res.status == "success") {
          handleClose("reload");
        } else {
          setAlertMessage(res.message);
        }
      });
    } else {
      handleClose();
    }
  };
  const handleEditClose = () => {
    setEditable(false);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setEditable(false);
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Box>
            {alertMessage && (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "700",
                }}
                component="span"
              >
                {alertMessage}
              </Typography>
            )}

            <Box
              sx={{
                ...style.mobileStyle,
                backgroundImage: `url(${PhoneImage})`,
              }}
            >
              <Box
                sx={{
                  margin: "0px auto",
                  marginTop: "15%",
                }}
              >
                <SwitchButton mobilePhone={true} designSwitch={designSwitch} />
              </Box>
              <Box
                sx={{
                  width: "90%",
                  marginTop: "3%",
                }}
              >
                <SocialHeader
                  VerticalContainer={`https://s2.googleusercontent.com/s2/favicons?domain=${cleanurl}`}
                  resturantNameValue={resName || "Resturant Name"}
                />
                {currentIndex == "insta" ? (
                  <>
                    <Instagrams
                      uploadImage={uploadImage}
                      loader={loader}
                      handleUploadImage={handleUploadImage}
                      textLoader={textLoader}
                      editable={editable}
                      content={content}
                      resetImage={resetImage}
                      setEditable={setEditable}
                      handleEdit={handleEdit}
                      handleEditClose={handleEditClose}
                      resturantNameValue={resName || "Resturant Name"}
                    />
                  </>
                ) : (
                  <>
                    <Facebooks
                      uploadImage={uploadImage}
                      loader={loader}
                      handleUploadImage={handleUploadImage}
                      textLoader={textLoader}
                      editable={editable}
                      content={content}
                      resetImage={resetImage}
                      setEditable={setEditable}
                      handleEdit={handleEdit}
                      handleEditClose={handleEditClose}
                      resturantNameValue={resName || "Resturant Name"}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box align="center">
            <Button
              sx={style.uploadBtn}
              onClick={handleSave}
              variant="contained"
              component="label"
              disabled={loader}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PopupShow;
