import React from "react";
import bgimage from "./../../assets/img/bg-second.png";

const Sectionfive = () => {
  return (
    <div className="common-section section-five">
      <img src={bgimage} alt="alt-image" className="w-100" />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="text-white heading-main ">
                  ENHANCE YOUR MARKETING STRATEGY WITH AI PRECISION
                </h1>
                <button className="btn-one">START NOW</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sectionfive;
