import React from "react";
import rightArrowIcon from "../../../assets/SVG/material-symbols_keyboard-arrow-down-rounded.svg";

const Breadcrumb = () => {
  return (
    <div>
      <div direction="row breadcrumb">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <p className="text-d text-white p-0 m-0">Home</p>
            <img src={rightArrowIcon} className="arrowdata" alt="error" />
            <p className="text-d text-orange p-0 m-0">Dashboard</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
