import React from "react";
import image from "../../../assets/images/home/card-i.png";

const Cards = ({ img, title, content }) => {
  return (
    <div className="cards">
      <img src={img} className="image" />
      <div className="card-content">
        <h5>{title}</h5>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Cards;
