import React from "react";
import style from "../Pupupstyle";
import {
  Box,
  CircularProgress,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import ResetBtn from "../../../../assets/SVG/ic_round-refresh.svg";
import RightArrow from "../../../../assets/SVG/RightArrow.svg";
import LikeButton from "../../../../assets/SVG/white_like.svg";
import CommentButton from "../../../../assets/SVG/white_comment.svg";
import ShareButton from "../../../../assets/SVG/white_share.svg";
import EditButton from "../../../../assets/SVG/tabler_edit.svg";
import fbIcon from "../../../../assets/images/fbLike.png";
import fbLikeIcon from "../../../../assets/images/instaLike.png";

const Facebooks = ({
  uploadImage,
  loader,
  handleUploadImage,
  textLoader,
  editable,
  content,
  resetImage,
  setEditable,
  handleEdit,
  handleEditClose,
  resturantNameValue,
}) => {
  return (
    <>
      <Box sx={style.instaFooter}>
        <Box></Box>
        <Stack sx={style.editBox} direction="row">
          <img
            style={{
              width: "25px",
              height: "25px",
              padding: "3px",
              cursor: "pointer",
              borderRadius: "50%",
              background: "#fff",
              boxShadow: "0 0 4px #888888",
              margin: "5px",
            }}
            onClick={() => resetImage("text")}
            src={ResetBtn}
            alt="EditButton"
          />
          <img
            style={{
              width: "25px",
              height: "25px",
              padding: "3px",
              cursor: "pointer",
              borderRadius: "50%",
              background: "#fff",
              boxShadow: "0 0 4px #888888",
              margin: "5px",
            }}
            onClick={() => setEditable(true)}
            src={EditButton}
            alt="EditButton"
          />
        </Stack>
      </Box>
      <Box sx={style.mobBotttomBox}>
        {textLoader ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: "#eb2426" }} />
            </Box>
          </>
        ) : (
          <>
            {editable ? (
              <>
                <textarea
                  // cols="60"
                  // rows="4"
                  charswidth="23"
                  style={{
                    height: "80px",
                    border: "1px solid",
                    width: "98%",
                    fontSize: "12px",
                    overflowY: "hidden",
                    resize: "none",
                  }}
                  value={content}
                  onChange={handleEdit}
                  onBlur={handleEditClose}
                />
              </>
            ) : (
              <>
                <p style={{ ...style.mobBotttomText, margin: "0px" }}>
                  {content}
                </p>
              </>
            )}
          </>
        )}
      </Box>
      {loader ? (
        <Box
          sx={{
            ...style.instaPicBox,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "#eb2426" }} />
        </Box>
      ) : (
        <Box
          sx={{
            ...style.instaPicBox,
            backgroundImage: `url(${uploadImage})`,
          }}
        >
          <Box sx={style.btnContainer}>
            <Box onClick={() => resetImage("image")} sx={style.btnBox}>
              <img src={ResetBtn} alt="ResetBtn" />
            </Box>
          </Box>
          <Box align="center">
            <Button sx={style.imgunder} variant="contained" component="label">
              Upload Image
              <input
                onChange={handleUploadImage}
                type="file"
                accept=".jpg, .jpeg, .png"
                hidden
              />
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={style.learnmore}>
        <Box>
          <Typography sx={style.website}>Website</Typography>
          <Typography sx={style.resname}>
            {resturantNameValue || "Resturant Name"}
          </Typography>
        </Box>
        <Box>
          <Button sx={style.learnbutton}>Learn More</Button>
        </Box>
      </Box>
      <Box sx={style.faceboolikecontainer}>
        <Box>
          <img
            width="15px"
            height="15px"
            style={style.love}
            src={fbLikeIcon}
            alt="LikeButton"
          />
          <img
            width="15px"
            height="15px"
            style={style.like}
            src={fbIcon}
            alt="CommentButton"
          />
          <Typography
            variant="span"
            sx={{ ...style.editfbtext, marginLeft: "12px" }}
          >
            3,4K
          </Typography>
        </Box>
        <Box sx={style.editBox}>
          <Typography sx={style.editfbtext}>
            <span>70 comments</span> <span> 104 shares</span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Facebooks;
