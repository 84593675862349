import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import smsContainer from "../../../assets/images/postview/sms.png";
import style from "./SmsViewStyle";
import profileImg from "../../../assets/images/circleInstaImg.png";
import left from "../../../assets/images/left-chevron.png";
import PreviousBtn from "../../Common/PreviousBtn";
import NextBtn from "../../Common/NextBtn";
import UpdateEventPopUp from "../../NewEvents/EventPopOver/UpdateEventPopUp";
import { useSelector } from "react-redux";

function SmsView({
  data,
  profile,
  showCustomHandleButton,
  functionOnModalClose,
}) {
  const itemsPerPage = 3;
  const [startIndex, setStartIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState();
  const [popUpData, setPopUpData] = useState();
  const [campaignData, setCampaignData] = useState([]);
  const visibleItems = data.slice(startIndex, startIndex + itemsPerPage);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const getCaption = (data) => {
    let caption;
    if (data && Object.keys(data).length > 0) {
      caption = data?.filter((x) => x.platform == "instagram");
    }
    return caption?.[0]?.caption;
  };
  const handleNext = (items) => {
    console.log("CAMPAIGN DATAAAAAAAAA");
    if (startIndex + itemsPerPage < data.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  const handlePrevious = (items) => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    if (showCustomHandleButton) {
      setCampaignData(visibleItems);
    } else {
      setCampaignData(data);
    }
  }, [data, showCustomHandleButton, visibleItems]);
  return (
    <>
      {showCustomHandleButton && data?.length > 3 && (
        <PreviousBtn width={"50px"} handlePrevious={() => handlePrevious()} />
      )}
      {campaignData?.map((campaign, index) => {
        return (
          <React.Fragment key={index}>
            <Box
              onClick={() => {
                !campaign?.is_posted && setOpen(true);
                setTab(campaign?.type);
                setPopUpData({
                  ...campaign,
                  desc:
                    campaign?.other_platforms?.[0]?.caption ||
                    campaign?.description,
                });
              }}
              sx={{
                ...style.mainWrapper,
                backgroundImage: `url(${smsContainer})`,
                cursor: "pointer",
              }}
            >
              <Box sx={style.googleHeading}>
                <div className="d-flex align-items-start">
                  <img src={left} width={"20px"} />
                  <Typography color="#000" fontSize={"15px"}>
                    Back
                  </Typography>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <img
                    src={profileImg}
                    width={"40px"}
                    style={{ marginLeft: "15px" }}
                  />
                  <Typography
                    ml={1}
                    color={"#000"}
                    fontWeight={"500"}
                    fontSize={"10px"}
                    mt={1}
                  >
                    {profile?.resturant_name}
                  </Typography>
                </div>
              </Box>
              <Box style={{ ...style.sms }}>
                <Box
                  style={{
                    background: "#f5f5f5",
                    margin: "15px",
                    padding: "10px",
                    borderRadius: "15px",
                  }}
                >
                  <Typography style={{ color: "#000", fontSize: "13px" }}>
                    {getCaption(campaign?.other_platforms) ||
                      campaign?.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {open && (
              <UpdateEventPopUp
                platform={"sms"}
                functionOnModalClose={functionOnModalClose}
                profile_id={profile_id}
                data={popUpData}
                tab={tab}
                open={open}
                handleClose={() => setOpen(false)}
              />
            )}
          </React.Fragment>
        );
      })}
      {showCustomHandleButton && data?.length > 3 && (
        <NextBtn width={"50px"} handleNext={() => handleNext()} />
      )}
    </>
  );
}

export default SmsView;
