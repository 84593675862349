const style = {
  imageSlider: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    overflowX: "hidden",
    marginTop: "5%",
  },
  innerImageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 50px 50px 80px",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    marginLeft: "-90%",
    width: "26vw",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "0 0 5% 5%",
    cursor: "pointer",
    flexShrink: "-2",
  },
  cardContainer: {
    marginRight: "4%",
  },
  imgStyle: {
    width: "22vw",
    height: "500px",
    margin: "15px",
    padding: "15px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    flexShrink: "-1",
  },
  sideImgStyle: {
    width: "350px",
    height: "530px",
    padding: "15px",
    position: "absolute",
  },
  text: {
    color: "#FCFCFC",
    fontSize: "45px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    lineHeight: "45px",
    textTransform: "uppercase",
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "10px",
    "@media (max-width: 700px)": {
      fontSize: "10px",
    },
  },
};

export default style;
