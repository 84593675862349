import { Box, Typography } from "@mui/material";
import React from "react";
import "./Reach.css";
import blackcircle from "../../../assets/SVG/total/blackcircle.svg";
import whitecircle from "../../../assets/SVG/total/whilecircle.svg";
import voiletcircle from "../../../assets/SVG/total/totalvoilet.svg";
import bluecircle from "../../../assets/SVG/total/totalblue.svg";
import skycircle from "../../../assets/SVG/total/totalsky.svg";
import greencircle from "../../../assets/SVG/total/totalgreen.svg";
import downarrow from "../../../assets/SVG/total/downarrow.svg";
const style = {
  container: {
    background: "#181818",
  },
  header: {},
  headerText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "38px",
    textTransform: "uppercase",
  },
};
const Reach = ({ maxviewersData, currentViewer }) => {
  return (
    <>
      <div style={style.container}>
        <div style={style.header}>
          <p style={style.headerText}>Your Reach</p>
        </div>
        <div className="reach-test">
          <div className="left">
            <div className="leftheight">
              <img src={blackcircle} className="blackcircle" />
              <img src={whitecircle} className="whitecicle" />
              <p className="viewer">Viewers</p>
              <p className="numbertop">{currentViewer.viewer || "00"}</p>
              <p className="price">${currentViewer.price || "00"}</p>
            </div>
            <p className="buttomtext">First Time Viewers</p>
          </div>
          <div className="right">
            <div className="right-total-circle">
              <img src={voiletcircle} alt="voilet" className="voilet" />
              <img src={bluecircle} alt="voilet" className="blue" />
              <img src={skycircle} alt="voilet" className="sky" />
              <img src={greencircle} alt="voilet" className="green" />
              <div className="text-over">
                <p className="right-total-text">
                  {maxviewersData?.max_viewers}
                </p>
                <img
                  src={downarrow}
                  alt="down"
                  className="darrow"
                  style={{ marginTop: "25px" }}
                />
                <img
                  src={downarrow}
                  className="darrow"
                  style={{ marginTop: "5px" }}
                />
                <img
                  src={downarrow}
                  className="darrow"
                  style={{ marginTop: "15px" }}
                />
              </div>
            </div>
            <p className="buttomtext">First Time Viewers</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reach;
