const style = {
  btn: {
    width: "55%",
    height: "51px",
    padding: "16px 32px 16px 32px",
    borderRadius: "50px",
    gap: "8px",
    fontSize: "16px",
    backgroundColor: "var(--primary-color)",
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "helvetica-font",
    textTransform: "none",
    cursor: "pointer",
    zIndex: "9",
    "&:hover": {
      backgroundColor: "var(--primary-color)",
      boxShadow: "none",
      cursor: "pointer",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "var(--primary-color)",
      cursor: "pointer",
    },
    "&:hover": {
        backgroundColor: "var(--primary-color)",
        boxShadow: "none",
        cursor: "pointer",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "var(--primary-color)",
        cursor: "pointer",
      },
  },
};

export default style;
