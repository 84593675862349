import React, { useEffect, useRef, useState } from "react";
import locationicon from "./../assets/images/location_icon.png";
import uploadicon from "./../assets/images/home/upload.png";
import editpen from "./../assets/images/edit-pen.png";
import deletepen from "./../assets/images/delete-icon.png";
import plusicon from "./../assets/images/plus.png";
import Nodata from "../components/locations/Nodata";
import Addform from "../components/locations/Addform";
import List from "../components/locations/List";
import "../components/locations/addstyle.css";
import {
  addlocationsAPI,
  addlocationscsvAPI,
  getlocationsdataAPI,
} from "../apis/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import GlobalAlert from "../components/Common/GlobalAlert";
import Loadericon from "../components/Common/Loadericon";

const Addlocations = () => {
  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editabledata, setEditabledata] = useState({});
  const [currentIndex, setCurrentindex] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const csvuploadRef = useRef();
  const navigate = useNavigate();

  const handlepopup = () => {
    setOpen(true);
  };

  const handleclose = () => {
    setOpen(false);
  };

  const handlecsvupload = () => {
    csvuploadRef.current.click();
  };

  const handleEdit = (index) => {
    const currentdata = locations[index];
    setOpen(true);
    setCurrentindex(index);
    setEditabledata(currentdata);
  };

  const handledelete = (index) => {
    const newdata = locations.filter((_, i) => i !== index);
    setLocations(newdata);
  };

  const handlefileupload = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;

      let newData = [];
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        // Process Excel file
        const workbook = XLSX.read(fileContent, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        newData = XLSX.utils.sheet_to_json(sheet);
      } else if (fileExtension === "csv") {
        // Process CSV file
        Papa.parse(fileContent, {
          header: true,
          complete: (results) => {
            newData = results.data;
          },
        });
      } else {
        setAlertMessage("Unsupported file format!");
        return;
      }

      // Filter out entries that do not have all three required keys
      newData = newData.filter(
        (item) =>
          item.address &&
          item.authorize &&
          item.email &&
          Object.keys(item).length === 3
      );

      // Check for duplicate emails
      const emailSet = new Set();
      const filteredData = [];
      let duplicateEmailFound = false;

      newData.forEach((item) => {
        if (item.email) {
          if (emailSet.has(item.email)) {
            duplicateEmailFound = true;
          } else {
            emailSet.add(item.email);
            filteredData.push(item);
          }
        }
      });

      if (duplicateEmailFound) {
        setAlertMessage(
          "Duplicate emails found. Only unique emails have been considered."
        );
      }

      setLocations((prevLocations) => [...prevLocations, ...filteredData]);
      console.log(filteredData); // Print filtered data to the console
    };

    if (fileExtension === "xlsx" || fileExtension === "xls") {
      reader.readAsBinaryString(file);
    } else if (fileExtension === "csv") {
      reader.readAsText(file);
    }
  };

  const initialrender = () => {
    setLoader(true);
    getlocationsdataAPI({
      profile_id: profile_id,
    })
      .then((res) => {
        if (res.status === "success") {
          setLocations(res.multi_loc_users);
        }
        setLoader(false);
      })
      .catch((rej) => {
        console.log(rej);
        setLoader(false);
      });
  };
  const handleSave = () => {
    setLoader(true);
    let readydata = locations.map((item) => ({
      address: item.address,
      authorize: item.authorize,
      email: item.email,
    }));
    addlocationsAPI({
      profile_id: profile_id,
      multi_loc_users: readydata,
    })
      .then((res) => {
        if (res.status == "success") {
          setAlertMessage("Locations added successfully");
          setLocations(res.multi_loc_users);
          setTimeout(() => {
            navigate("/social-media-connect");
          }, 2000);
        }
        setLoader(false);
      })
      .catch((rej) => {
        console.log(rej);
        setLoader(false);
        setAlertMessage("Something went worng.");
      });
  };
  useEffect(() => {
    initialrender();
  }, []);

  return (
    <div className="full-width headerpadding loging signup-page locations">
      {loader && <Loadericon />}

      <div className="content-padding-both">
        <div className="py-5">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="title-wrap">
                <h1 className="title">Create your account</h1>
              </div>
            </div>
            <div className="col-md-8 offset-md-2 my-2">
              <div className="content-area">
                <div className="cards">
                  <div>
                    {locations.length > 0 ? (
                      <List
                        locations={locations}
                        handlepopup={handlepopup}
                        handlecsvupload={handlecsvupload}
                        setLocations={setLocations}
                        uploadicon={uploadicon}
                        plusicon={plusicon}
                        editpen={editpen}
                        deletepen={deletepen}
                        handleEdit={handleEdit}
                        handledelete={handledelete}
                      />
                    ) : (
                      <Nodata
                        locationicon={locationicon}
                        plusicon={plusicon}
                        uploadicon={uploadicon}
                        handlepopup={handlepopup}
                        handlecsvupload={handlecsvupload}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex justify-content-center my-3">
                <button
                  className={`byteready-btn byteready-primary ${
                    locations.length > 0 ? "" : "disabled"
                  }`}
                  onClick={handleSave}
                >
                  Save & Finish
                </button>
              </div>
              <p className="text-center font-normal">
                Already have an account?{" "}
                <span
                  style={{
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Sign In
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <input
        ref={csvuploadRef}
        type={"file"}
        accept=".csv, .xlsx, .xls"
        onChange={handlefileupload}
        hidden
      />
      <Addform
        open={open}
        handleclose={handleclose}
        setLocations={setLocations}
        locations={locations}
        editabledata={editabledata}
        setEditabledata={setEditabledata}
        currentIndex={currentIndex}
        setCurrentindex={setCurrentindex}
      />
      {alertMessage && (
        <GlobalAlert
          message={alertMessage}
          handleClose={() => {
            setAlertMessage("");
          }}
        />
      )}
    </div>
  );
};

export default Addlocations;
