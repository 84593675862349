import React from "react";
import "./Themestyle.css";
import Loadericon from "../../Common/Loadericon";
import plusicon from "../../../assets/images/plus.png";
import scrossicon from "../../../assets/images/cros-white-icon.png";
import CloseIcon from "@mui/icons-material/Close";

const Theme = ({
  loader,
  children,
  savecustomtheme,
  showsavedtheme,
  closesavedtheme,
}) => {
  return (
    <>
      <div className="full-width theme py-5">
        {loader && <Loadericon />}
        <div className="row">
          <div className="col-md-12">
            <div className="content-padding-right">
              <div className="d-flex align-items-center justify-content-between">
                <h1 className="title">Themes , inspirations</h1>
                {/* {showsavedtheme && (
                  <button
                    className="byteready-btn byteready-primary plus-btn"
                    onClick={closesavedtheme}
                  >
                    <CloseIcon color={"#fff"} />
                  </button>
                )} */}
              </div>
              <div className="theme-main-container">
                <div className="item sliders">{children}</div>
                <div className="item addbutton">
                  <div className="theme-item custom-theme">
                    <div className="content">
                      <p className="name">Add Custom Theme</p>
                      <button
                        className="byteready-btn byteready-primary plus-btn m-auto"
                        onClick={savecustomtheme}
                      >
                        <img src={plusicon} alt="Add" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Theme;
