import React from "react";
import drivesimg from "../../../assets/images/home/drives-result.png";

const Driveresults = () => {
  return (
    <div className="full-width py-5">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="content-padding-left">
            <h1 className="title">
              Content <span className="bold-inner">that drives results</span>
            </h1>
            <h3 className="h3-title-while">
              <span className="bold-inner">
                Our AI crafts tailored messages:
              </span>
              <br /> from compelling offers that convert first-time viewers to
              enticing rewards that bring back frequent diners.
            </h3>
          </div>
          <div className="custom-bg overflow-shadow">
            <div className="actual-content-area">
              <div className="content-padding-left">
                <p className="text-design">
                  The system is versatile and Creates content:
                  <br />{" "}
                  <span className="bold-inner">
                    From Social Media and Google Ads to SMS and Emails"{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="image-padding-right">
            <img src={drivesimg} className="w-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driveresults;
