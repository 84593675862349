const style = {
    mainWrapper: {
        position: 'relative',
        width: "max-content",
        backgroundSize: "contain",
        height: "540px",
        width: "278px",
        margin: "0px 7px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        cursor:'pointer'
    },
    heading: {
        background: '#fff',
        top: '25%',
        position: 'absolute',
        left: '7.5%',
        width: '85.5%',
        padding: '10px'
    },
    post: {
        backgroundSize: "cover",
        height: '230px',
        position: 'absolute',
        top: '34%',
        width: '85.5%',
        left: '7.5%',
        backgroundPosition: 'center',
    },
    postHeadingText: {
        textAlign: "center",
        position: "absolute",
        color: "#fff",
        fontSize: "25px",
        fontWeight: "900",
        fontFamily: "WhyteInktrap",
        width: "100%"
    },
    commentBox: {
        background: "#fff",
        position: "absolute",
        top: "72%",
        width: "85.5%",
        left: "7.5%",
        padding: "5px",
        alignItems: 'center'
    },
    commentImage: {
        marginRight: "10px"
    },
    comments: {
        background: '#fff',
        position: "absolute",
        top: "78%",
        width: "85.5%",
        left: "7.5%",
        padding: "10px",
        alignItems: 'flex-start'
    },
    postCaption: {
        background: '#fff',
        position: "absolute",
        top: "84%",
        width: "85.5%",
        left: "7.5%",
        padding: "3px",
        
    },
    postCaptionText:{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}
export default style