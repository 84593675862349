import React, { useRef, useState } from "react";
import blankimg from "./../../../assets/img/imgone.png";
import rightarrow from "./../../../assets/img/right-dual-arrow.png";
import Slidercard from "./Slidercard";
import Verticleline from "./Verticleline";
import Slidertop from "./Slidertop";
import Sliders from "./Sliders";
import { FormControlLabel, Switch } from "@mui/material";
import Switchbutton from "../../Common/Switchbutton";

const Slidersection = ({ title, bgcode, description, allads, left_slider }) => {
  const [checked, setChecked] = useState(false);
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNextAd = () => {
    sliderRef.current.swiper.slideNext();
  };
  const handlePreviousAd = () => {
    sliderRef.current.swiper.slidePrev();
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div className="slider">
      <div className="slider-container">
        <Slidertop
          bgcode={bgcode}
          title={title}
          description={description}
          handleNextAd={handleNextAd}
          handlePreviousAd={handlePreviousAd}
        />
        <div className="slider-row">
          <div className="slider-item">
            <Slidercard
              blankimg={left_slider?.image || blankimg}
              content={
                left_slider?.description ||
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu urna elementum, pharetra libero non, cursus leo. "
              }
            />
          </div>
          <div className="slider-item arrow-width">
            <Verticleline
              rightarrow={rightarrow}
              bgcode={bgcode}
              handleNextAd={handleNextAd}
            />
          </div>
          <div className="slider-item">
            <Sliders
              sliderRef={sliderRef}
              allads={allads}
              // currentIndex={currentIndex}
              // blankimg={allads[currentIndex]?.image}
              // content={allads[currentIndex]?.description}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Switchbutton checked={checked} onChange={handleChange} />
      </div>
    </div>
  );
};

export default Slidersection;
