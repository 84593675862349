import React from "react";
import "./customerbehavior.css";
import searchicon from "./../../assets/img/customer-behaviour-search-icon.png";
import usericon from "./../../assets/img/customer-behaviour-user-icon.png";
import indicatoricon from "./../../assets/img/customer-behaviour-indicator-icon.png";
import carticon from "./../../assets/img/customer-behaviour-cart-icon.png";
import locationicon from "./../../assets/img/customer-behaviour-location-icon.png";
import TuneIcon from "@mui/icons-material/Tune";
import Card from "./partials/Card";
import Slidersection from "./partials/Slidersection";
import blankimg from "./../../assets/img/imgone.png";
import exp_left from "../../assets/img/exp_left.png";
import exp_one from "../../assets/img/exp_01.png";
import exp_two from "../../assets/img/exp_02.png";
import exp_three from "../../assets/img/exp_03.png";
//Sandwich
import san_left from "../../assets/img/san_left.png";
import san_one from "../../assets/img/san_01.png";
import san_two from "../../assets/img/Onboarding_1.png";
import san_three from "../../assets/img/Onboarding_2.png";
//Omelet
import omelet_left from "../../assets/img/omelet_left.png";
import omelet_one from "../../assets/img/omelet_01.png";
import omelet_two from "../../assets/img/omelet_02.png";
import omelet_three from "../../assets/img/omelet_03.png";
//Egg
import egg_left from "../../assets/img/egg_left.png";
import egg_one from "../../assets/img/egg_01.png";
import egg_two from "../../assets/img/Purchase_1.png";
import egg_three from "../../assets/img/Purchase_2.png";

//proximity
import prox_left from "../../assets/img/prox_left.png";
import prox_one from "../../assets/img/prox_01.png";
import prox_two from "../../assets/img/Proximity_1.png";
import prox_three from "../../assets/img/Proximity_2.png";

import imgtwo from "./../../assets/img/imgtwo.png";
import imgthree from "./../../assets/img/imgthree.png";
import imgfour from "./../../assets/img/imgfour.png";

const Customer = () => {
  const cardData = [
    {
      icon: searchicon,
      bgcode: "bg-blue",
      title: "EXPLORATION",
      subtitle: "20 Brand Response",
      left_slider: {
        image: exp_left,
        description:
          "Consumer searches on the site for products or promotions, then leaves the site.",
      },
      description:
        "Uncovering Interests Through Online and Social Searches: This stage captures user behavior during online searches, on-site browsing, and social media interactions.",
      ads: [
        {
          image: exp_one,
          description:
            "Based on the last item searched by the viewer, a coupon is generated featuring that item. An ad is then published targeting the same audience. The content of this ad dynamically changes according to the viewer's search behavior.",
        },
        {
          image: exp_two,
          description:
            "Based on the last item searched by the viewer, a coupon is generated featuring that item. An ad is then published targeting the same audience. The content of this ad dynamically changes according to the viewer's search behavior.",
        },
        {
          image: exp_three,
          description:
            "Based on the last item searched by the viewer, a coupon is generated featuring that item. An ad is then published targeting the same audience. The content of this ad dynamically changes according to the viewer's search behavior.",
        },
      ],
    },
    {
      icon: usericon,
      bgcode: "bg-voilet",
      title: "ONBOARDING",
      subtitle: "20 Brand Response",
      left_slider: {
        image: san_left,
        description: "Consumer goes online and signs up on the website or app.",
      },
      description:
        "Interacting with Brands Across Apps and Platforms: Focused on driving deeper connections through site visits, product exploration, and app engagement.",
      ads: [
        {
          image: san_one,
          description: `The system generates a personalized message: "Welcome to Hardees! Here's your first offer - 50% OFF." This message encourages engagement by providing an immediate incentive.`,
        },
        {
          image: san_two,
          description: `The system generates a personalized message: "Welcome to Hardees! Here's your first offer - 50% OFF." This message encourages engagement by providing an immediate incentive.`,
        },
        {
          image: san_three,
          description: `The system generates a personalized message: "Welcome to Hardees! Here's your first offer - 50% OFF." This message encourages engagement by providing an immediate incentive.`,
        },
      ],
    },
    {
      icon: indicatoricon,
      bgcode: "bg-saf",
      title: "ACTIVE USER",
      subtitle: "20 Brand Response",
      left_slider: {
        image: omelet_left,
        description:
          "Consumer adds an item to the cart but does not complete the purchase",
      },
      description:
        "Influencing Purchase Decisions During Checkout: Tracking and analyzing consumer choices during the e-commerce phase, influencing the path to purchase.",
      ads: [
        {
          image: omelet_one,
          description:
            "The system generates a coupon specifically for the item left in the cart and publishes it within the next few minutes, encouraging the consumer to finalize the purchase.",
        },
        {
          image: omelet_two,
          description:
            "The system generates a coupon specifically for the item left in the cart and publishes it within the next few minutes, encouraging the consumer to finalize the purchase.",
        },
        {
          image: omelet_three,
          description:
            "The system generates a coupon specifically for the item left in the cart and publishes it within the next few minutes, encouraging the consumer to finalize the purchase.",
        },
      ],
    },
    {
      icon: carticon,
      bgcode: "bg-yellow",
      title: "PURCHASE",
      subtitle: "20 Brand Response",
      left_slider: {
        image: egg_left,
        description: "Consumer completes an online purchase.",
      },
      description:
        "Predicting Future Actions from Past Purchases: Leveraging historical data to forecast buying behavior and personalize post-sales interactions.",
      ads: [
        {
          image: egg_one,
          description: `The system sends a "Thank you for your order" message, accompanied by a coupon for the same item to incentivize a repeat purchase.`,
        },
        {
          image: egg_two,
          description: `The system sends a "Thank you for your order" message, accompanied by a coupon for the same item to incentivize a repeat purchase.`,
        },
        {
          image: egg_three,
          description: `The system sends a "Thank you for your order" message, accompanied by a coupon for the same item to incentivize a repeat purchase.`,
        },
      ],
    },
    {
      icon: locationicon,
      bgcode: "bg-green",
      title: "PROXIMITY",
      subtitle: "20 Brand Response",
      left_slider: {
        image: prox_left,
        description: "Customer is within proximity of the store",
      },
      description:
        "Driving Conversions Based on Store Proximity: Engaging consumers with targeted offers when they’re near physical store locations.",
      ads: [
        {
          image: prox_one,
          description:
            "The system generates a time-sensitive coupon based on the time of day and publishes it, encouraging the customer to visit the store.",
        },
        {
          image: prox_two,
          description:
            "The system generates a time-sensitive coupon based on the time of day and publishes it, encouraging the customer to visit the store.",
        },
        {
          image: prox_three,
          description:
            "The system generates a time-sensitive coupon based on the time of day and publishes it, encouraging the customer to visit the store.",
        },
      ],
    },
  ];
  return (
    <>
      <div className="row  py-3 heading-background mb-3">
        <div className="col-md-12">
          <div className="content-padding-both">
            <div className="position-relative">
              <h1 className="title bold-inner">Consumer Behavior</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="content-padding-both">
        <div className="d-flex align-item-center justify-content-between">
          <div>
            <p className="select-category">Select Category</p>
            <p className="font-small">
              Explore all the Brand Responses Suggested for every action across
              the customer journey
            </p>
          </div>
          <div>
            <TuneIcon />
          </div>
        </div>
        <div className="card-container">
          {cardData.map((card, index) => (
            <Card
              key={index}
              icon={card.icon}
              bgcode={card.bgcode}
              title={card.title}
              subtitle={card.subtitle}
            />
          ))}
        </div>
        {cardData.map((card, index) => (
          <div key={index} className="row py-5">
            <div className="col-md-12">
              <Slidersection
                key={index}
                icon={card.icon}
                bgcode={card.bgcode}
                title={card.title}
                left_slider={card.left_slider}
                description={card.description}
                allads={card.ads}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Customer;
