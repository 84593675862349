import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import style from "../viewersStyle"
import EmailView from "../../../components/Common/EmailView/EmailView";
import SmsView from "../../../components/Common/SmsView/SmsView";
import emailIcon from "../../../assets/SVG/gray-email-icon.svg";
import smsIcon from "../../../assets/SVG/gray-sms-icon.svg";

const TabView = ({ loader }) => {
  return (
    <>
         <Grid
          sx={{ ...style.mobViewContainer, width: "60%" }}
          container
          spacing={1}
        >
          <Grid item xs={12} md={8}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "2.5% 15% 2.5% 0%",
              }}
              direction="row"
              spacing={1}
            >
              <img width="24px" height="24px" src={emailIcon} alt="error" />
              <Typography sx={style.mobHeaderText}>Email</Typography>
            </Stack>
            <EmailView loader={loader} customStyle={{ width: "70%" }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5% 0%",
              }}
              direction="row"
              spacing={1}
            >
              <img width="24px" height="24px" src={smsIcon} alt="error" />
              <Typography sx={style.mobHeaderText}>Sms</Typography>
            </Stack>
            <SmsView loader={loader} />
          </Grid>
        </Grid>
    </>
  );
};

export default TabView;
