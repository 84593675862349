import React from "react";
import intelligentdata from "../../../assets/images/home/Intelligent-Consumer-Engagement.png";

const Intelligent = () => {
  return (
    <div className="full-width custom-bg py-5">
      <div className="">
        <div className="row align-items-center">
          <div className="col-md-12 text-center">
            <h1 className="title">
              Intelligent <br />
              <span className="bold-inner">Consumer Engagement</span>
            </h1>
            <h3 className="h3-title-while">Your all in one Social Media</h3>
          </div>
          <div className="col-md-8 offset-md-2 text-center">
            <p className="text-design">
              The consumer journey is complex. Strategically place your messages
              at all the stages of the consumer journey. From Social Media posts
              , Google ads to behavior driven emails and SMS From the awareness
              stage to the decision-making moment, your brand maintains a
              consistent and compelling presence, guiding potential customers
              towards conversion
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <img src={intelligentdata} className="w-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intelligent;
