import React from "react";
import "./Review.css";
import imagedata from "../../../assets/images/home/scribe-managment.png";
import { useNavigate } from "react-router-dom";

const Review = ({ firstfunction, secondfunct }) => {
  return (
    <>
      <div className="full-width review py-5">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="content-padding-left">
              <h1 className="title px-2">
                Review <span className="bold-inner">Management</span>
              </h1>
            </div>
            <div className="cutom-bg py-5 overflow-shadow">
              <div className="actual-content-area">
                <div className="content-padding-left">
                  <p>
                    Our platform aggregates all your reviews and comments from
                    multiple channels. AI generates suggested responses that
                    reflect your brand’s voice, making it easy acknowledge and
                    engage with your community.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="">
              <div className="position-relative">
                <img src={imagedata} className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width custom-bg footer-top-scribe">
        <div className="row">
          <div className="col-md-12">
            <h1 className="title text-center">
              SCRIBE <span className="bold-inner">AI</span>
            </h1>
            <div className="inner-btn-area">
              <button
                onClick={firstfunction}
                className="byteready-btn byteready-outlinne"
              >
                Free Trial 30 Days
              </button>
              <button
                onClick={secondfunct}
                className="byteready-btn byteready-primary"
              >
                Start $199/Month
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
