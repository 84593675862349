import React, { useState, useEffect } from "react";
import "./monthlystyle.css";
import Socialmediaswitch from "../../../commoncomponents/Socialmediaswitch";
import Calenderdatepicker from "../../../commoncomponents/Calenderdatepicker";
import moment from "moment-timezone";
import Calenderdata from "./Calenderdata";
import { useDispatch, useSelector } from "react-redux";
import { getAllCaptionpost } from "../../../../apis/auth";
import { storeInLocalStore } from "../../../../redux/reducers/mainSlice";
const Monthlydata = ({
  allData,
  handlePopup,
  handleConfirmcalendar,
  setselectTime,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const cardData = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const [currentDate, setCurrentDate] = useState();
  const tempCurrentDate = new Date();
  const [monthalydata, setMonthalydata] = useState(cardData);
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const starttime = useSelector((state) => state.main.store?.users?.start_time);
  const [startTime, setStartime] = useState(starttime || "");
  const [currentdesign, setCurrentdesign] = useState("insta");
  const changedesign = (data) => {
    setCurrentdesign(data);
  };

  console.log(allData);
  useEffect(() => {
    if (allData?.captions?.length > 0) {
      setMonthalydata(allData?.captions);
      setCurrentDate(allData?.captions[0]?.date);
      let temporigin = {
        date: allData?.captions[0]?.date,
        output_image: allData?.captions[0]?.prompt_output_img_url,
        output_text: allData?.captions[0]?.prompt_output_text,
      };
      dispatch(storeInLocalStore({ origin_output: temporigin }));
      let templocation = {
        date: allData?.captions[1]?.date,
        output_image: allData?.captions[1]?.prompt_output_img_url,
        output_text: allData?.captions[1]?.prompt_output_text,
      };
      dispatch(storeInLocalStore({ location_output: templocation }));
      let tempcuisine = {
        date: allData?.captions[2]?.date,
        output_image: allData?.captions[2]?.prompt_output_img_url,
        output_text: allData?.captions[2]?.prompt_output_text,
      };
      dispatch(storeInLocalStore({ cuisine_output: tempcuisine }));
    } else {
      setMonthalydata(cardData);
      setCurrentDate(tempCurrentDate);
      setLoader(false);
    }
  }, [allData?.captions]);
  const handleTime = (e) => {
    const convertedTime = moment(e.target.value, "HH:mm").utc().format("HH:mm");
    setselectTime(convertedTime);
  };
  return (
    <>
      <div className="row py-5 monthly-data">
        <div className="col-md-12">
          <div className="content-padding-both">
            <div className="title-area">
              <h3 className="h3-title-while-whynk">
                Select The Post You Want To Edit
              </h3>
            </div>
            <div className="btnandsocialarean">
              <div className="btnandsocialarean-item">
                <div className="leftbtn-container">
                  <div className="leftbtn-container-item">
                    <Socialmediaswitch
                      handlechange={changedesign}
                      activedesign={currentdesign}
                    />
                  </div>
                  <div className="leftbtn-container-item">
                    <Calenderdatepicker
                      handletime={handleTime}
                      activedesign={currentdesign}
                    />
                  </div>
                </div>
              </div>
              <div className="btnandsocialarean-item">
                {!startTime && (
                  <>
                    <button
                      className="byteready-btn byteready-primary confirm-calender"
                      onClick={handleConfirmcalendar}
                    >
                      Confirm
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Calenderdata
        cardData={monthalydata}
        currentdesign={currentdesign}
        currentDate={currentDate}
        handlePopup={handlePopup}
        loader={loader}
        starttime={startTime}
      />
    </>
  );
};

export default Monthlydata;
