import React, { useEffect, useState } from "react";
import originimg from "../../../assets/images/home/origin-image.png";
import Imageview from "../../Common/ImageView/Imageview";
import AIsavebutton from "../Commonbtn/AIsavebutton";
import Airesult from "../Airesult/Airesult";
import {
  cuisineAPI,
  createProfileAPI,
  updateResturantDataAPI,
  getCaptionsAPI,
  imageUpload,
  updateCaptionAPI,
  updateTextAPI,
} from "../../../apis/auth";
import { useDispatch, useSelector } from "react-redux";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import GlobalAlert from "../../Common/GlobalAlert";
import Loadericon from "../../Common/Loadericon";
import Allhistory from "../History/Allhistory";
import Cardsone from "../infocards/Cardsone";
import Cardtwo from "../infocards/Cardtwo";
import Cardthree from "../infocards/Cardthree";
import Cardfour from "../infocards/Cardfour";
const Origin = ({ originlastfive, mystory, handletabchange }) => {
  const dispatch = useDispatch();
  const [disablebtn, setDisablebtn] = useState(false);
  const [savebtndisable, setSavebtndisable] = useState("");
  const [cuisineList, setCuisinlist] = useState([]);
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const commingfrom = useSelector((state) => state.main.store?.lastpageurl);
  const origin_input = useSelector((state) => state.main.store?.origin_data);
  const origin_output = useSelector((state) => state.main.store?.origin_output);
  const [savemessage, setSaveMessage] = useState("");
  const [showtutorial, setShowtutorial] = useState("");
  const [loader, setLoader] = useState(false);
  const [originData, setOriginData] = useState({
    profile_id: "",
    restaurant_name: "",
    cuisine_name: "",
    cuisine_story: "",
    output_image: "",
    date: "",
  });
  const [originOut, setOriginoutput] = useState({
    text: "",
    image: "",
    date: "",
  });
  const initialRender = () => {
    cuisineAPI()
      .then((res) => {
        if (res.status == "success") {
          setCuisinlist(res.cuisine);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };

  const savecaption = () => {
    if (
      profile_info?.resturant_name == originData?.restaurant_name &&
      origin_output?.output_text == originData?.cuisine_story &&
      origin_output?.output_image == originData?.output_image &&
      origin_input?.cuisine == originData?.cuisine_name
    ) {
      setSaveMessage("Day 1 post created successfully.");
    } else {
      setLoader(true);
      let payload = {
        profile_id: originData?.profile_id,
        cuisine: originData.cuisine_name,
        resturant_name: originData.restaurant_name,
        origins: originData.cuisine_story,
        caption_type: "origin",
        caption_response: "custom",
        count: 1,
      };
      getCaptionsAPI(payload)
        .then((res) => {
          if (res.status) {
            setOriginData({
              ...originData,
              cuisine_story:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                originData?.cuisine_story,
              output_image:
                res.captions?.prompt_output_img_url || originData?.output_image,
              date: res.captions?.date || originData.date,
            });
            let originData_payload = {
              cuisine: originData.cuisine_name,
              origins: originData.cuisine_story,
            };

            let origin_output = {
              output_image:
                res.captions?.prompt_output_img_url || originData.output_image,
              output_text:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                originData.cuisine_story,
              date: res.captions?.date || originData.date,
            };
            let profile_infos = {
              ...profile_info,
              profile_id: originData.profile_id,
              resturant_name: originData.restaurant_name,
            };
            dispatch(storeInLocalStore({ origin_data: originData_payload }));
            dispatch(storeInLocalStore({ origin_output: origin_output }));
            dispatch(storeInLocalStore({ profile_data: profile_infos }));

            setTimeout(() => {
              setSaveMessage("Day 1 post created successfully.");
            }, 500);
            setLoader(false);
          }
        })
        .catch((res) => {
          console.log(res);
          setLoader(false);
        });
    }
  };
  const cancleoutput = () => {
    setOriginoutput({
      text: "",
      image: "",
      date: "",
    });
  };
  const handleEditAPI = (textpayload) => {
    console.log("Update");
    setLoader(true);
    let data = {
      profile_id: originData?.profile_id,
      prompt_output_text: textpayload || originData?.cuisine_story,
      prompt_output_img_url: originOut?.image || originData?.output_image,
      date: originOut?.date || originData?.date,
    };

    updateCaptionAPI(data)
      .then((res) => {
        if (res?.status) {
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error ", err);
      });
  };
  const applyOutput = (data) => {
    if (data.trim() != "") {
      handleEditAPI(data);
    }
    setOriginData({
      ...originData,
      cuisine_story: data || originOut.text,
      output_image: originOut.image,
      date: originOut.date,
    });
    setTimeout(() => {
      setOriginoutput({
        text: "",
        image: "",
        date: "",
      });
    }, 100);
  };
  const handleImageupload = (e) => {
    if (originData.date) {
      const file = e.target.files[0];
      if (file) {
        const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
        const modifiedFile = new File([file], newFileName, { type: file.type });
        setLoader(true);
        imageUpload({
          id: originData.profile_id,
          date: originData.date,
          image: modifiedFile,
        })
          .then((res) => {
            if (res?.status) {
              setLoader(false);
              setOriginData({
                ...originData,
                output_image: res.image_url,
              });
            } else {
              setLoader(false);
              setSaveMessage(res.message);
            }
          })
          .catch((rej) => {
            console.log(rej);
            setLoader(false);
          });
      }
    } else {
      setSaveMessage("Please save the post before upload.");
    }
  };
  const generatecaption = (caption) => {
    let payload = {};
    if (originData.date) {
      payload = {
        profile_id: originData?.profile_id,
        date: originData?.date || "",
        refresh: true,
        caption_type: "N/A",
        caption_response: caption,
      };
    } else {
      payload = {
        profile_id: originData?.profile_id,
        cuisine: originData.cuisine_name,
        resturant_name: originData.restaurant_name,
        origins: originData.cuisine_story,
        caption_type: "origin",
        caption_response: caption,
        count: 1,
      };
    }

    setLoader(true);
    getCaptionsAPI(payload)
      .then((res) => {
        if (res.status) {
          if (caption == "image") {
            setOriginData({
              ...originData,
              cuisine_story:
                res.captions?.prompt_output_text?.replace(/\n/g, "") ||
                originData?.cuisine_story,
              output_image:
                res.captions?.prompt_output_img_url || originData?.output_image,
              date: res.captions?.date || originData.date,
            });
          } else {
            if (caption == "text") {
              console.log(originData);
              setOriginoutput({
                ...originOut,
                text: res.captions?.prompt_output_text?.replace(/\n/g, ""),
              });
            } else {
              setOriginoutput({
                text: res.captions?.prompt_output_text?.replace(/\n/g, ""),
                image: res.captions?.prompt_output_img_url,
                date: res.captions?.date,
              });
            }
          }
          setLoader(false);
        }
      })
      .catch((res) => {
        console.log(res);
        setLoader(false);
      });
  };
  const createrestaurant = () => {
    if (originData.restaurant_name != "") {
      if (originData?.profile_id) {
        let payload = {
          profile_id: originData?.profile_id,
          resturant_name: originData.restaurant_name,
        };
        updateResturantDataAPI(payload)
          .then((res) => {
            if (res.status == "success") {
              let resturantData = res.user_profile;
              setOriginData({
                ...originData,
                profile_id: resturantData.profile_id,
                restaurant_name: resturantData.resturant_name,
              });
            }
          })
          .catch((err) => {
            console.log("Error ", err);
          });
      } else {
        createProfileAPI({
          resturant_name: originData.restaurant_name,
          is_unified_web: commingfrom == "/scribe-ai" ? false : true,
        })
          .then((res) => {
            if (res.status == "success") {
              let resturantData = res.user_profile;
              setOriginData({
                ...originData,
                profile_id: resturantData.profile_id,
                restaurant_name: resturantData.resturant_name,
              });
            }
          })
          .catch((err) => {
            console.log("Error ", err);
          });
      }
    }
  };
  useEffect(() => {
    if (originData.restaurant_name) {
      const timerId = setTimeout(() => {
        if (profile_info?.resturant_name != originData.restaurant_name) {
          createrestaurant();
        }
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [originData.restaurant_name]);
  useEffect(() => {
    initialRender();
  }, []);

  useEffect(() => {
    const fieldsToValidate = [
      "cuisine_name",
      "restaurant_name",
      "cuisine_story",
    ];
    const isAnyFieldBlank = fieldsToValidate.some(
      (field) => originData[field] === ""
    );
    setDisablebtn(isAnyFieldBlank);
  }, [originData]);
  useEffect(() => {
    if (profile_info || origin_input || origin_output) {
      setOriginData({
        profile_id: profile_info?.profile_id || "",
        restaurant_name: profile_info?.resturant_name || "",
        cuisine_name: origin_input?.cuisine || "",
        cuisine_story: origin_output?.output_text || "",
        output_image: origin_output?.output_image || "",
        date: origin_output?.date || "",
      });
    }
  }, [profile_info, origin_input, origin_output]);
  const handlePopup = () => {
    setSaveMessage("");
    handletabchange(1);
  };
  // useEffect(() => {
  //   if (originData.output_image == "") {
  //     setSavebtndisable(true);
  //   } else {
  //     setSavebtndisable(false);
  //   }
  // }, [originData?.output_image]);
  const handleFocus = (data) => {
    if (data == "skip") {
      setShowtutorial("");
      localStorage.setItem("hasTutorialBeenShown", "true");
    } else {
      setShowtutorial(data);
    }
  };
  useEffect(() => {
    const hasTutorialBeenShown = localStorage.getItem("hasTutorialBeenShown");
    if (!profile_info?.profile_id) {
      if (!hasTutorialBeenShown) {
        setTimeout(() => {
          handleFocus(1);
        }, 800);
      }
    }
  }, []);
  const handleDatareturn = (data) => {
    setOriginData({
      ...originData,
      cuisine_story: data?.prompt_output_text?.replace(/\n/g, "") || "",
      output_image: data?.prompt_output_img_url?.replace(/\n/g, "") || "",
    });
  };
  useEffect(() => {
    if (
      profile_info?.resturant_name == originData?.restaurant_name &&
      origin_output?.output_text == originData?.cuisine_story &&
      origin_output?.output_image == originData?.output_image &&
      origin_input?.cuisine == originData?.cuisine_name
    ) {
      setSavebtndisable("Saved Created Post");
    } else {
      setSavebtndisable("Save Created Post");
    }
  }, [originData, origin_output, origin_input, profile_info]);
  return (
    <>
      {mystory && (
        <div className="full-width py-5">
          <div className="row">
            <div className="col-md-12">
              <h1 className="title bold-inner text-center">My Story</h1>
            </div>
          </div>
        </div>
      )}
      <div className="full-width cutom-bg p-0">
        {loader && <Loadericon />}

        <div className="row zero-column">
          <div className="col-md-6">
            <div className="max-height-create-calender scroll-design-calender">
              <div className="content-padding-left create-calender-padding">
                <div className="">
                  <h1 className="title">Origins</h1>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={originData.restaurant_name}
                        placeholder="Restaurant Name"
                        className="form-control custom-input"
                        onChange={(e) => {
                          setOriginData({
                            ...originData,
                            restaurant_name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <select
                        name=""
                        id=""
                        className="form-select custom-input"
                        onChange={(e) => {
                          setOriginData({
                            ...originData,
                            cuisine_name: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select Cuisine</option>
                        {cuisineList.length > 0 &&
                          cuisineList.map((item, index) => (
                            <option
                              key={index}
                              value={item}
                              selected={item == originData.cuisine_name}
                            >
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className=" py-3 px-2">
                  {/* <div className="row">
                    <div className="col-md-6">
                      <p className="text-design bold-inner">
                        1. Start with the Why:
                      </p>
                      <ul className="origin-ul colored-ul">
                        <li>Why you started the restaurant</li>
                        <li>Describe what or who inspired you.</li>
                        <li>
                          Share specific memories or moments that were pivotal
                          in your decision to start the restaurant.
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <p className="text-design bold-inner">
                        2. Talk about Your Creative Process:
                      </p>
                      <ul className="origin-ul colored-ul">
                        <li>Describe where your ideas come from.</li>
                        <li>
                          Emphasize what sets your restaurant apart. An heirloom
                          recipe, an unconventional cooking technique, or a
                          unique fusion of cuisines.
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="row py-2">
                    <div className="col-md-12">
                      <p>The Story of Our Origin - Our Story</p>

                      <textarea
                        value={originData.cuisine_story}
                        onChange={(e) => {
                          setOriginData({
                            ...originData,
                            cuisine_story: e.target.value,
                          });
                        }}
                        rows="2"
                        className="form-control custom-input custom-textarea"
                      />
                    </div>
                  </div>
                  <div className="row pt-2 pb-4">
                    <div className="col-md-12 ">
                      {originOut.text ? (
                        <>
                          <Airesult
                            output={originOut}
                            cancleoutput={cancleoutput}
                            applyOutput={applyOutput}
                            generatecaption={generatecaption}
                          />
                        </>
                      ) : (
                        <>
                          <AIsavebutton
                            disable={disablebtn}
                            savebtndisable={disablebtn}
                            generatecaption={generatecaption}
                            savebtncontent={savebtndisable}
                            saveCaption={savecaption}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {originlastfive.length > 0 && (
                    <>
                      <hr className="hr-line-calender" />
                      <div className="row pb-2">
                        <Allhistory
                          cuisine={false}
                          data={originlastfive}
                          handleDatareturn={handleDatareturn}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <Imageview
              image={originimg}
              left={true}
              disable={disablebtn}
              handleImageupload={handleImageupload}
              generatecaption={generatecaption}
              alldata={originData}
              outputdata={originOut}
            />
          </div>
        </div>
        {showtutorial && (
          <>
            <div className="suggestion-originmain">
              <div className="suggestion-second">
                {showtutorial == 1 && <Cardsone handleFocus={handleFocus} />}
                {showtutorial == 2 && <Cardtwo handleFocus={handleFocus} />}
                {showtutorial == 3 && <Cardthree handleFocus={handleFocus} />}
                {showtutorial == 4 && <Cardfour handleFocus={handleFocus} />}
              </div>
            </div>
          </>
        )}
      </div>
      {savemessage && (
        <GlobalAlert message={savemessage} handleClose={handlePopup} />
      )}
    </>
  );
};

export default Origin;
