import React, { useEffect, useState } from "react";
import FacebookMobileView from "../../commoncomponents/FacebookMobileView/FacebookMobileView";
import GoogleView from "../../commoncomponents/GoogleView/GoogleView";
import SmsView from "../../commoncomponents/SMSView/SmsView";
import InstaMobileView from "../../commoncomponents/InstaMobileView/InstaMobileView";
import GmailMobileView from "../../commoncomponents/GmailMobileView/GmailMobileView";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";
import style from "./PostPreviewStyle";
import "./PostPreviewdesign.css";
import { useDispatch, useSelector } from "react-redux";
import { confirmcampaignAPI, getCampaignDataAPI } from "../../../apis/auth";
import PreviousBtn from "../../Common/PreviousBtn";
import NextBtn from "../../Common/NextBtn";
import CustomButton from "../../Common/CustomButton/CustomButton";
import ProfileAlert from "../../Events/PopOver/ProfileAlert";
import { useNavigate } from "react-router-dom";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import Loadericon from "../../Common/Loadericon";
import { fetchprofile } from "../../../CustomHook/addressUtills";
function CustomTabPanel(props) {
  const { children, value, index, handleNext, handlePrevious, data, ...other } =
    props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className="d-flex justify-content-center align-items-center"
          sx={{ p: 3 }}
        >
          {data > 3 && (
            <PreviousBtn width={"50px"} handlePrevious={handlePrevious} />
          )}
          <Box className="d-flex">{children}</Box>
          {data > 3 && <NextBtn handleNext={handleNext} width={"50px"} />}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function PostPreview() {
  const [value, setValue] = React.useState(0);
  const [campaignData, setCampaignData] = useState();
  const [mobileViewData, setMobileViewData] = useState();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const profileData = useSelector((state) => state.main.store?.profile_data);
  const user = useSelector((state) => state.main.store?.users);
  const camp_id = useSelector((state) => state.main.store?.campaignId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 3;
  const tabStyles = {
    labelStyle: {
      color: "black",
    },
    selectedLabelStyle: {
      color: "blue", // Change label color when active
    },
    iconStyle: {
      fill: "black",
    },
    selectedIconStyle: {
      fill: "green", // Change icon color when active
    },
    indicator: {
      display: "none", // Remove the indicator (border)
    },
  };
  const getCampaignData = () => {
    let data = {
      profile_id: profile_id,
      camp_id: camp_id,
    };
    getCampaignDataAPI(data)
      .then((res) => {
        if (res.status == "success") {
          setCampaignData(res?.campaign);
          setMobileViewData({
            profile: profileData,
            campaign: res?.campaign?.add_unit_ids,
          });
        }
      })
      .catch((err) => {
        console.log("Err ", err);
      });
  };
  useEffect(() => {
    getCampaignData();
  }, []);

  const functionOnModalClose = async () => {
    getCampaignData();
  };
  const handleNext = () => {
    const newStartIndex =
      (startIndex + itemsPerPage) % mobileViewData?.campaign?.length;
    setStartIndex(newStartIndex);
  };
  const handlePrevious = () => {
    let newStartIndex = startIndex - itemsPerPage;
    if (newStartIndex < 0) {
      newStartIndex = mobileViewData?.campaign?.length + newStartIndex;
    }
    setStartIndex(newStartIndex);
  };

  const visibleItems = [];
  for (let i = 0; i < itemsPerPage; i++) {
    const index = (startIndex + i) % mobileViewData?.campaign?.length;
    visibleItems.push(mobileViewData?.campaign?.[index]);
  }
  const handleCampaignConfirm = () => {
    const token = localStorage.getItem("token");

    if (token) {
      setLoader(true);
      let data = {
        profile_id: profile_id,
        camp_id: camp_id,
        platform: "all",
        is_trial: !user.is_trial,
      };
      confirmcampaignAPI(data)
        .then((res) => {
          if (res.status == "success") {
            fetchprofile({
              profile_id: profile_id,
            })
              .then((result) => {
                if (result) {
                  dispatch(
                    storeInLocalStore({
                      profile_data: result?.dispachabledata?.profile_data,
                    })
                  );
                  dispatch(
                    storeInLocalStore({
                      users: result?.dispachabledata?.users,
                    })
                  );
                  setTimeout(() => {
                    navigate("/my-social-media");
                    dispatch(
                      storeInLocalStore({ currentsubmenu: "customerjourney" })
                    );
                    dispatch(storeInLocalStore({ campaignId: "" }));
                    dispatch(storeInLocalStore({ campaignUnit: [] }));
                    setLoader(false);
                  }, 500);
                }
              })
              .catch((rej) => {
                console.log(rej);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    } else {
      navigate("/signup");
      dispatch(storeInLocalStore({ moveto: "/create-calender" }));
    }
  };
  return (
    <Box sx={style.container}>
      <Box className="d-flex align-items-end justify-content-between">
        <Box>
          {loader && <Loadericon />}
          <h3 className="h3-title-while-whynk">
            Select The Post You Want To Edit
          </h3>
          <div className="d-flex align-items-start justify-content-between">
            <div>
              <Typography style={style.smallHeading}>Campaign</Typography>
              <Typography
                style={{
                  background: "var(--white-color)",
                  color: "var(--primary-color)",
                  fontFamily: "SF Pro Display",
                  fontSize: "16px",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  width: "max-content",
                  marginTop: "16px",
                }}
              >
                {campaignData?.name}
              </Typography>
            </div>
            <div className="d-flex flex-column">
              <Typography style={style.smallHeading}>
                Choose The Platform
              </Typography>
              <Box sx={{ width: "100%", mt: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    TabIndicatorProps={{ style: tabStyles.indicator }}
                    className="socialmediatabs"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      style={{ color: "#fff" }}
                      icon={<InstagramIcon />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      style={{ color: "#fff" }}
                      label="f"
                      {...a11yProps(1)}
                    />
                    <Tab
                      style={{ color: "#fff" }}
                      icon={<GoogleIcon />}
                      {...a11yProps(2)}
                    />
                    <Tab
                      style={{ color: "#fff" }}
                      label="@"
                      {...a11yProps(3)}
                    />
                    <Tab
                      style={{ color: "#fff" }}
                      label="SMS"
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </Box>
              </Box>
            </div>
          </div>
        </Box>
        <Box>
          <CustomButton
            onClick={() => handleCampaignConfirm()}
            label={"Confirm"}
            sx={{ width: "150px", height: "50px" }}
          />
        </Box>
      </Box>
      <Box sx={{ maxWidth: "2560px" }}>
        <CustomTabPanel
          data={mobileViewData?.campaign?.length}
          value={value}
          index={0}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        >
          <InstaMobileView
            functionOnModalClose={functionOnModalClose}
            data={visibleItems}
            profile={profileData}
          />
        </CustomTabPanel>
        <CustomTabPanel
          data={mobileViewData?.campaign?.length}
          value={value}
          index={1}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        >
          <FacebookMobileView
            functionOnModalClose={functionOnModalClose}
            data={visibleItems}
            profile={profileData}
          />
        </CustomTabPanel>
        <CustomTabPanel
          data={mobileViewData?.campaign?.length}
          value={value}
          index={2}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        >
          <GoogleView
            functionOnModalClose={functionOnModalClose}
            data={visibleItems}
            profile={profileData}
          />
        </CustomTabPanel>
        <CustomTabPanel
          data={mobileViewData?.campaign?.length}
          value={value}
          index={3}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        >
          <GmailMobileView
            functionOnModalClose={functionOnModalClose}
            data={visibleItems}
            profile={profileData}
          />
        </CustomTabPanel>
        <CustomTabPanel
          data={mobileViewData?.campaign?.length}
          value={value}
          index={4}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        >
          <SmsView
            functionOnModalClose={functionOnModalClose}
            data={visibleItems}
            profile={profileData}
          />
        </CustomTabPanel>
      </Box>
      <ProfileAlert
        text={"You need to login first to confirm the campaign"}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
}

export default PostPreview;
