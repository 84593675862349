import GeneratingParagraph from "../../Reviews/GeneratingPara/GeneratingParagraph";
import avatar from "../../../assets/SVG/avatar.svg";
import { useRef, useState } from "react";
import { commentreplyAPI } from "../../../apis/auth";
import sendicon from "../../../assets/images/create-calender/send-icon.png";
import { useSelector } from "react-redux";

const Comments = ({ data, addreply, likeanddislikefunc }) => {
  const { name, message } = data;
  const inputRef = useRef();
  const [replyboxid, setReplybox] = useState("");
  const [paragraph, setParagraph] = useState("");
  const isInstagram = data?.platfrom === "insta";
  const isLiked = data?.is_liked;
  const profile_id = useSelector(
    (state) => state.main.store.profile_data?.profile_id
  );
  const handleParagraph = () => {
    if (paragraph.trim() != "") {
      let payload = {
        profile_id: profile_id,
        caption_id: data.caption_id,
        message: paragraph,
        msgid: data.msgid,
        platform: data.platform,
      };
      addreply(payload);
      setTimeout(() => {
        setReplybox("");
        setParagraph("");
      }, 500);
    }
  };
  const openreplybox = (rdata) => {
    if (replyboxid) {
      setReplybox("");
    } else {
      setReplybox(rdata.caption_id);
    }
  };
  const handlelike = (likedata) => {
    // console.log(likedata);
    likeanddislikefunc(likedata);
  };
  const buttonClasses = `btn-action ${isInstagram ? "disabled" : ""} ${
    isLiked ? " bold-inner" : ""
  }`;
  return (
    <div className="comment">
      <div className="avatar">
        <img src={avatar} alt="error" />
      </div>
      <div className="comments-data">
        <div>
          <div>
            <p className="comentator-name">{name || "John"}</p>
            <p className="comment-message">
              {message || "Generating random paragraphs can be a excellent"}
            </p>
          </div>
          <div>
            <span
              className={buttonClasses}
              onClick={() => {
                handlelike(data);
              }}
            >
              Like
            </span>
            <span
              className="btn-action"
              onClick={() => {
                openreplybox(data);
              }}
            >
              Reply
            </span>
          </div>
          {replyboxid && (
            <>
              <div className="position-relative form-groum">
                <input
                  type={"text"}
                  className="form-control custom-input"
                  placeholder="Reply..."
                  onChange={(e) => {
                    setParagraph(e.target.value);
                  }}
                />
                <span className="input-group-icon" onClick={handleParagraph}>
                  <img src={sendicon} className="send-icon" />
                </span>
              </div>
            </>
          )}
          {data?.replies?.length > 0 && (
            <>
              <div>
                {data?.replies.map((item, index) => {
                  return (
                    <div key={index} className="reply-container">
                      <p>{item.message}</p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;
