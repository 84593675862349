import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  fetchprofile,
  handleAddressglobal,
} from "../../../../CustomHook/addressUtills";
import { storeInLocalStore } from "../../../../redux/reducers/mainSlice";
import { useDispatch } from "react-redux";
import Loadericon from "../../../Common/Loadericon";
import { createProfileAPI } from "../../../../apis/auth";

const ProfileSetup = ({
  openprofilecreate,
  setOpenprofilecreate,
  handlecreateprofile,
}) => {
  const [loader, setLoader] = useState(false);
  const inputRef = useRef();
  const autoCompleteRef = useRef();
  const dispatch = useDispatch();
  const [formdata, setFormdata] = useState({
    restaurant_name: "",
    restaurant_website: "",
    restaurant_address: "",
  });
  const [addreesvalue, setaddressvalue] = useState();
  const [zipcode, setZipcode] = useState(true);

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"],
  };

  const handleSubmit = () => {
    handlecreateprofile(formdata);
  };

  const handlevalue = (val) => {
    setFormdata(val);
  };

  const handlezipcode = (e) => {
    let zipcode = e.target.value;
    setFormdata({
      ...formdata,
      restaurant_address: `${addreesvalue?.addressLine1} ${
        addreesvalue?.addressLine1 ? "," : ""
      } ${addreesvalue?.city} ${zipcode}, ${addreesvalue?.country} `,
    });
    if (zipcode.length > 1) {
      setZipcode(true);
    }
  };

  const handleClose = () => {
    setOpenprofilecreate(false);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", () => {
        const place = autoCompleteRef.current.getPlace();
        if (place) {
          let confirmAddress = handleAddressglobal(place);
          if (confirmAddress.zipCode === "") {
            setZipcode(false);
            setTimeout(() => {}, 4000);
          }
          setaddressvalue(confirmAddress);
          setFormdata((locationData) => ({
            ...locationData,
            restaurant_address:
              `${confirmAddress?.addressLine1} ${
                confirmAddress?.addressLine1 ? "," : ""
              } ${confirmAddress?.city} ${confirmAddress?.zipCode}, ${
                confirmAddress?.country
              } ` || inputRef.current.value,
          }));
        }
      });
    }
  }, [inputRef.current]);

  return (
    <Modal
      className="description-body"
      size="lg"
      show={openprofilecreate}
      centered
      onHide={handleClose}
    >
      {loader && <Loadericon />}
      <Modal.Body>
        <>
          <div className="form-group mb-4">
            <label>Restaurant Name</label>
            <input
              type="text"
              value={formdata?.restaurant_name}
              className="custom-input"
              onChange={(e) => {
                handlevalue({
                  ...formdata,
                  restaurant_name: e.target.value,
                });
              }}
            />
          </div>
          <div className="form-group mb-4">
            <label>Website</label>
            <input
              type="text"
              value={formdata?.restaurant_website}
              className="custom-input"
              onChange={(e) => {
                handlevalue({
                  ...formdata,
                  restaurant_website: e.target.value,
                });
              }}
            />
          </div>
          <div className="form-group mb-4">
            <label>Address</label>
            <input
              ref={inputRef}
              type="text"
              value={formdata?.restaurant_address}
              className="custom-input"
              onChange={(e) => {
                handlevalue({
                  ...formdata,
                  restaurant_address: e.target.value,
                });
              }}
            />
            {!zipcode && (
              <div className="col-md-6 pt-3">
                <input
                  type="number"
                  placeholder="ZIP Code"
                  className="form-control custom-input"
                  onBlur={handlezipcode}
                />
              </div>
            )}
          </div>

          <button
            className={`byteready-btn byteready-primary ${
              formdata?.restaurant_name &&
              formdata?.restaurant_website &&
              formdata?.restaurant_address
                ? ""
                : "disabled"
            }`}
            style={{
              width: "100%",
            }}
            onClick={handleSubmit}
          >
            Save
          </button>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileSetup;
