import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Stack,
  Button,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import "./FirstTimeViewers.css";
import firstTimeIcon from "../../../assets/SVG/ph_eye-fill.svg";
import engagedIcon from "../../../assets/SVG/ph_eye-fill2.svg";
import firstConsumersIcon from "../../../assets/SVG/solar_user-bold.svg";
import dinersIcon from "../../../assets/SVG/solar_dollar-bold.svg";
import CardBottom from "../components/CardBottom";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import upArrow from "../../../assets/SVG/material-symbols_arrow-right-rounded_green.svg";
import "react-circular-progressbar/dist/styles.css";
import { DonutChart } from "react-circle-chart";
import { firsttimeViewerAPI, setTargetAPI } from "../../../apis/auth";

const itemsData = [
  {
    value: 30,
    label: "Total",
  },
  {
    value: 20,
    label: "First Time Viewers",
  },
  {
    value: 15,
    label: "Engaged Viewers",
  },
  {
    value: 35,
    label: "First Time Consumers",
  },
  {
    value: 15,
    label: "Freq Diners",
  },
];

const FirstTimeBox = ({
  name,
  loader,
  tag,
  data,
  initialRender,
  icon,
  total,
}) => {
  const percentage = 10;
  const [fisrttimeViewer, setFirstTimeViewer] = useState(data);
  const [maxViewer, setMaxViewer] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [targetViewer, setTargetViewer] = useState(0);
  const [targetPrice, setTargetPrice] = useState(0);
  const [currentViewer, setCurrentViewer] = useState();
  const [currentPrice, setCurrentPrice] = useState();
  const [currentpercentage, setCurrentpercetage] = useState();
  const [targetpercentage, setTagetpercentage] = useState();
  const [totalprice, setTotalPrice] = useState({
    totalprice: "",
    firsttimeprice: "",
    enagageprice: "",
    firsttimeconsumerprice: "",
    fredinerpprice: "",
  });
  const handleSliderChange = (event, newValue) => {
    let baseprice = parseFloat(fisrttimeViewer?.base_price_in_usd || 0);
    console.log(newValue, baseprice);
    let trgprice = (parseFloat(newValue) * baseprice).toFixed(2);
    setTargetViewer(newValue);
    setTargetPrice(trgprice);
  };
  const settargetprice = () => {
    let payload = {
      profile_id: "6539fe4fa0eb1873290f9f15",
      campaign_id: "6539fee89933281b631a6ebd",
      viewer_type: tag,
      viewer_count: targetViewer,
    };
    setTargetAPI(payload)
      .then((res) => {
        if (res.status == "success") {
          initialRender();
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  useEffect(() => {
    if (fisrttimeViewer) {
      let baseprice = parseFloat(fisrttimeViewer.base_price_in_usd);
      let mxprice = (
        parseFloat(fisrttimeViewer.max_viewers) * baseprice
      ).toFixed(2);
      let trgprice = 0;
      if (name === "Total Spend") {
        setAllviewervalue(totalviewer);
        trgprice = fisrttimeViewer?.all
          ? (parseFloat(fisrttimeViewer.all) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(fisrttimeViewer.all);
      } else if (name === "First Time Viewers") {
        let currentp = (
          (fisrttimeViewer["first-time-viewers"] /
            fisrttimeViewer.current_viewers) *
          100
        ).toFixed(2);
        trgprice = fisrttimeViewer["first-time-viewers"]
          ? (
              parseFloat(fisrttimeViewer["first-time-viewers"]) * baseprice
            ).toFixed(2)
          : 0.0;
        setCurrentViewer(fisrttimeViewer["first-time-viewers"]);
        setCurrentpercetage(currentp);
      } else if (name === "Engaged Viewers") {
        trgprice = fisrttimeViewer["engaged-viewers"]
          ? (
              parseFloat(fisrttimeViewer["engaged-viewers"]) * baseprice
            ).toFixed(2)
          : 0.0;
        setCurrentViewer(fisrttimeViewer["engaged-viewers"]);
      } else if (name === "First Time Consumers") {
        trgprice = fisrttimeViewer["first-time-customers"]
          ? (
              parseFloat(fisrttimeViewer["first-time-customers"]) * baseprice
            ).toFixed(2)
          : 0.0;
        setCurrentViewer(fisrttimeViewer["first-time-customers"]);
      } else {
        trgprice = fisrttimeViewer["freq-diners"]
          ? (parseFloat(fisrttimeViewer["freq-diners"]) * baseprice).toFixed(2)
          : 0.0;
        setCurrentViewer(fisrttimeViewer["freq-diners"]);
      }
      let targetper = (
        (fisrttimeViewer.current_viewers / fisrttimeViewer.max_viewers) *
        100
      ).toFixed(2);
      if (total) {
        setTotalPrice({
          totalprice: (baseprice * total.total).toFixed(2) || "00",
          firsttimeprice: (baseprice * total.firstviewer).toFixed(2) || "00",
          enagageprice: (baseprice * total.engagedviewer).toFixed(2) || "00",
          firsttimeconsumerprice:
            (baseprice * total.firsttimeconsumer).toFixed(2) || "00",
          fredinerpprice: (baseprice * total.freqdiner).toFixed(2) || "00",
        });
      }
      setTagetpercentage(targetper);
      setMaxViewer(fisrttimeViewer.max_viewers);
      setCurrentPrice(trgprice);
      setTargetViewer(fisrttimeViewer.current_viewers);
      setTargetPrice(fisrttimeViewer.current_spend.toFixed(2));
      setMaxPrice(mxprice);
    }
  }, [fisrttimeViewer]);

  return (
    <>
      {loader ? (
        <div
          style={{
            width: "100%",
            height: "100px",
            position: "relative",
          }}
        >
          <CircularProgress
            sx={{
              color: "#eb2426",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      ) : (
        <div className="p-2">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="first-viewers-container box-shadow-violet-ftv first-viewers-cards pad-ft">
                <Stack direction="row" spacing={2}>
                  {/* <div className="view-circle-ftv"> */}
                  <img className="view-circle-ftv" src={icon} alt="error" />
                  {/* </div> */}
                  <Typography
                    variant="p"
                    className="mar-left-ft text-ftv text-start align "
                  >
                    {name}
                  </Typography>
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <div className="circular-progress-bar">
                      <CircularProgressbarWithChildren
                        value={currentpercentage || ""}
                        strokeWidth={12}
                        styles={buildStyles({
                          pathColor: "#29CA1B",
                          trailColor: "#262626",
                          strokeLinecap: "butt",
                        })}
                      >
                        <CircularProgressbar
                          value={targetpercentage}
                          strokeWidth={12}
                          styles={buildStyles({
                            pathColor: "#4E0AA5",
                            trailColor: "transparent",
                            strokeLinecap: "butt",
                          })}
                        />
                      </CircularProgressbarWithChildren>
                      <div className="progress-bar-child">
                        <img
                          width="20px"
                          height="20px"
                          src={upArrow}
                          alt="error"
                        />
                        <div className="progress-bar-text">
                          {currentpercentage || "00"}%
                        </div>
                        <div className="progress-bar-text text-green">
                          {targetpercentage || "00"}%
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
                    <div className="total-no py-2">
                      <Typography variant="p" className="text2-ftv">
                        Total No.
                      </Typography>
                      <Typography variant="p" className="text3-ftv">
                        {currentViewer || "00"}
                      </Typography>
                      <Typography variant="p" className="text4-ftv">
                        {targetViewer || "00"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
                    <div className="total-no py-2">
                      <Typography variant="p" className="text2-ftv">
                        Spend
                      </Typography>
                      <Typography variant="p" className="text3-ftv">
                        ${currentPrice || "0.00"}
                      </Typography>
                      <Typography variant="p" className="text4-ftv">
                        ${targetPrice || "0.00"}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <div className="d-flex flex-column mar-top-ft">
                      <Typography variant="p" className="text5-ftv text-end">
                        Max
                      </Typography>
                      <Typography
                        variant="p"
                        className="text5-ftv text-white text-end"
                      >
                        {maxViewer || "00"}
                      </Typography>
                      <div className="slider-box">
                        <Slider
                          sx={{ color: "#eb2426" }}
                          value={targetViewer}
                          max={maxViewer}
                          min={0}
                          onChange={handleSliderChange}
                        />
                        <div className="slider-circle"></div>
                      </div>
                      <Typography
                        variant="p"
                        className="text5-ftv text-white text-end"
                      >
                        ${maxPrice || "0.00"}
                      </Typography>
                      <Button
                        sx={{
                          width: "100%",
                          margin: "10px 0",
                          borderRadius: "50px",
                          // opacity: 0.3,
                          background: "#eb2426",
                          "&:hover": {
                            background: "#eb2426",
                          },
                          "@media (max-width: 1280px)": {
                            width: "100%",
                            height: "25px",
                          },
                        }}
                        variant="contained"
                        onClick={settargetprice}
                      >
                        Boost
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="donut-chart">
                    <DonutChart
                      sx={{ position: "relative" }}
                      totalTextColor="#fff"
                      totalFontSize="25px"
                      showTotal={false}
                      trackWidth="sm"
                      items={itemsData}
                    />
                    <div className="donut-text-box">
                      <Typography variant="p" className="donut-text1-ftv">
                        ${totalprice.totalprice}
                      </Typography>
                      <Typography variant="p" className="donut-text2-ftv">
                        Total Spent
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <ul className="ul-counttext">
                    <li className="bg-price1">
                      <div className="point1 mx-1"></div>
                      <img
                        width="20px"
                        height="20px"
                        src={engagedIcon}
                        alt="error"
                      />
                      <Typography variant="p" className="text6-ftv">
                        ${totalprice.enagageprice}
                      </Typography>
                    </li>
                    <li className="bg-price2">
                      <div className="point2 mx-1"></div>
                      <img
                        width="20px"
                        height="20px"
                        src={firstTimeIcon}
                        alt="error"
                      />
                      <Typography variant="p" className="text6-ftv">
                        ${totalprice.firsttimeprice}
                      </Typography>
                    </li>
                    <li className="bg-price3">
                      <div className="point3 mx-1"></div>
                      <img
                        width="20px"
                        height="20px"
                        src={firstConsumersIcon}
                        alt="error"
                      />
                      <Typography variant="p" className="text6-ftv">
                        ${totalprice.firsttimeconsumerprice}
                      </Typography>
                    </li>
                    <li className="bg-price4">
                      <div className="point4 mx-1"></div>
                      <img
                        width="20px"
                        height="20px"
                        src={dinersIcon}
                        alt="error"
                      />
                      <Typography variant="p" className="text6-ftv">
                        ${totalprice.fredinerpprice}
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FirstTimeBox;
