import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Mypaidmedia from "./Mypaidmedia";
import GetStartedPaymentPage from "../../../pages/PaymentPage/GetStartedPaymentPage";
import Trialpayemnt from "../../../pages/PaymentPage/Trialpayemnt";

const Mypaidmediamain = () => {
  const subscribestatus = useSelector(
    (state) => state.main?.store?.users.subscribe_status
  );
  return (
    <>
      <Mypaidmedia />
      {/* {subscribestatus == "free" || subscribestatus == "paid" ? (
        <></>
      ) : (
        <>
          {subscribestatus == "expire" ? (
            <>
              <GetStartedPaymentPage price={399} product={"unified"} />
            </>
          ) : (
            <>
              <Trialpayemnt price={399} product={"unified"} />
            </>
          )}
        </>
      )} */}
    </>
  );
};

export default Mypaidmediamain;
