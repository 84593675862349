import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Customerjourney from "../components/mysocialmedia/Customerjourney";
import Socialmediamain from "../components/mysocialmedia/Socialmediamain";
import Mypaidmediamain from "../components/mysocialmedia/Paidmedia/Mypaidmediamain";

const Mysavedsocialmedia = () => {
  const [activetab, setActivetab] = useState();
  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  useEffect(() => {
    if (currentsubmenu) {
      setActivetab(currentsubmenu);
    }
  }, [currentsubmenu]);
  return (
    <div className="full-width headerpadding">
      {activetab === "mysocialmedia" && <Socialmediamain />}
      {activetab === "customerjourney" && <Customerjourney />}
      {activetab === "mypaidaids" && <Mypaidmediamain />}
    </div>
  );
};

export default Mysavedsocialmedia;
