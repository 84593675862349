import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import { getmainTemplateAPI } from "../../../apis/auth";
import GlobalAlert from "../../Common/GlobalAlert";
import { useNavigate } from "react-router-dom";
const Mystory = ({ unified, submenu }) => {
  const [currentmenu, setCurrentmenu] = useState();
  const eventTitle = useSelector((state) => state?.main?.store?.eventTitle);
  const users = useSelector((state) => state?.main?.store?.users);
  const selectedid = useSelector(
    (state) => state?.main?.store?.selectedevent?.id
  );
  const navigate = useNavigate();
  const currentcode = useSelector((state) => state.main.store?.currentsubmenu);
  const dispatch = useDispatch();
  let menuname = [];
  if (!unified) {
    menuname = [
      {
        name: "Create My SM Calendar",
        key: "createmysmcalender",
      },
    ];
  } else {
    menuname = [
      {
        name: "Brand Story",
        key: "createmysmcalender",
      },
      {
        name: "SM",
        key: "smcalender",
      },
      {
        name: "Campaigns",
        key: "createmypaidads",
      },
    ];
  }

  const handleactivemenu = (item) => {
    setCurrentmenu(item);
  };

  useEffect(() => {
    if (currentmenu) {
      dispatch(storeInLocalStore({ currentsubmenu: currentmenu }));
    }
  }, [currentmenu]);
  useEffect(() => {
    if (currentcode) {
      let tempcode = menuname.map((item) => item.key);
      if (tempcode.includes(currentcode)) {
        setCurrentmenu(currentcode);
      } else {
        setCurrentmenu("createmysmcalender");
      }
    } else {
      setCurrentmenu("createmysmcalender");
    }
  }, [currentcode]);
  const getMainTemplatedata = () => {
    getmainTemplateAPI()
      .then((res) => {
        let tempData = res.template.map((item) => ({
          id: item._id,
          imageUrl: item.image_url_for_card,
          text: item.title,
          description: item.description,
        }));
        if (users?.theme || selectedid) {
          let newdata = tempData.filter(
            (item) => item.id == users?.theme || selectedid
          );
          dispatch(storeInLocalStore({ eventTitle: newdata?.[0] }));
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  useEffect(() => {
    getMainTemplatedata();
  }, []);
  return (
    <>
      <div>
        <div className="header-down">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="header-item two">
                  <ul className="submenu">
                    {menuname.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            item.key === currentmenu ? "active" : ""
                          }`}
                          onClick={() => {
                            dispatch(
                              storeInLocalStore({
                                currentsubmenu: item.key,
                              })
                            );
                          }}
                        >
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mystory;
