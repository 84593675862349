import { Box, Typography } from "@mui/material";
import questionMarkIcon from "../../assets/SVG/ri_question-fill.svg";
import arrowIcon from "../../assets/SVG/icon-park-solid_down-two.svg";


const style = {
  container: {
    width: "92px",
    height: "88px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    color: "#FFF",
    fontFamily: "SFProText-Regular",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
  },
  questionMarkStyle: { position: "absolute", top: "10%", right: "10%" },
  arrowStyle: {
    position: "absolute",
    top: "40%",
    right: "-10%",
    zIndex: 2,
  },
};

const FirstTimeViewCard = ({ handleViewers, data }) => {
  return (
    <>
      <Box onClick={() => handleViewers(data?.title)} sx={style.container}>
        <img
          style={style.questionMarkStyle}
          width="16px"
          height="16px"
          src={questionMarkIcon}
          alt="error"
        />
        <img width="40px" height="40px" src={data?.imgUrl} alt="error" />
        <Typography sx={style.text}>{data?.title}</Typography>
        {data?.arrow && (
          <img style={style.arrowStyle} src={arrowIcon} alt="error" />
        )}
      </Box>
    </>
  );
};

export default FirstTimeViewCard;
