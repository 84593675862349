import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import Subheader from "./partial/Subheader";

const Insightsubmenu = ({ unified, submenu }) => {
  const currentcode = useSelector((state) => state.main.store?.currentsubmenu);
  const [currentmenu, setCurrentmenu] = useState();
  let menulist = [];
  if (unified) {
    menulist = [
      {
        name: "Corporate",
        key: "overview",
      },
      {
        name: "Operations",
        key: "firsttimeviewer",
      },
      {
        name: "Location",
        key: "engagedviewer",
      },
      // {
      //   name: "First Time Consumers",
      //   key: "firsttimeconsumer",
      // },
      // {
      //   name: "Freq Diners",
      //   key: "freqdinner",
      // },
    ];
  } else {
    menulist = [
      {
        name: "Overview",
        key: "scribeoverview",
      },
    ];
  }

  const dispatch = useDispatch();
  const handleactivemenu = (item) => {
    setCurrentmenu(item);
  };
  useEffect(() => {
    if (currentmenu) {
      dispatch(storeInLocalStore({ currentsubmenu: currentmenu }));
    }
  }, [currentmenu]);
  useEffect(() => {
    if (currentcode) {
      let tempcode = menulist.map((item) => item.key);
      if (tempcode.includes(currentcode)) {
        setCurrentmenu(currentcode);
      } else {
        setCurrentmenu(unified ? "overview" : "scribeoverview");
      }
    } else {
      setCurrentmenu(unified ? "overview" : "scribeoverview");
    }
  }, [currentcode]);

  return (
    <>
      <Subheader
        menuname={menulist}
        currentmenu={currentmenu}
        handleactivemenu={handleactivemenu}
      />
    </>
  );
};

export default Insightsubmenu;
