import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./../signupstyle.css";
import moment from "moment";
import {
  createProfileAPI,
  loginAPI,
  updateResturantDataAPI,
} from "../../../apis/auth/index.jsx";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import GlobalAlert from "../../../components/Common/GlobalAlert.jsx";
import verticleline from "../../../assets/images/header/vertical-line.png";
import eyeopen from "../../../assets/images/header/eye-open.png";
import eyeclose from "../../../assets/images/header/eye-close.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  fetchprofile,
  handleAddressglobal,
} from "../../../CustomHook/addressUtills.js";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice.jsx";

const Profilesetup = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"],
  };
  const autoCompleteRef = useRef();
  const [alertMessage, setAlertmessage] = useState();
  const profileData = useSelector((state) => state.main?.store?.profile_data);
  const locationdata = useSelector((state) => state.main?.store?.location_data);
  const lastcomingpage = useSelector((state) => state.main?.store?.lastpageurl);
  const restaurant_nameRef = useRef();
  const locationRef = useRef();
  const websiteurlRef = useRef();
  const zipcodeRef = useRef();
  const marketingRef = useRef();

  const history = useNavigate();
  const [message, setMessage] = useState();
  const [formData, setFormData] = useState({
    restaurant_name: "",
    location: "",
    website_url: "",
    zipcode: "",
    marketing_area: "",
  });
  const [fieldalertmessage, setFieldalertmessage] = useState({
    restaurant_name: "",
    location: "",
    website: "",
    zipcode: "",
    marketingarean: "",
    donotmatch: "",
  });

  const validationdata = () => {
    if (formData.restaurant_name.trim() === "") {
      setFieldalertmessage({
        ...fieldalertmessage,
        restaurant_name: "Field is Required.",
      });
      restaurant_nameRef.current.focus();
      restaurant_nameRef.current.scrollIntoView();
      return false;
    } else if (formData.location.trim() === "") {
      setFieldalertmessage({
        ...fieldalertmessage,
        location: "Field is Required.",
      });
      locationRef.current.focus();
      locationRef.current.scrollIntoView();
      return false;
    } else if (formData.website_url.trim() === "") {
      setFieldalertmessage({
        ...fieldalertmessage,
        website: "Field is Required.",
      });
      websiteurlRef.current.focus();
      websiteurlRef.current.scrollIntoView();
      return false;
    } else if (formData.zipcode.trim() === "") {
      setFieldalertmessage({
        ...fieldalertmessage,
        zipcode: "Field is Required.",
      });
      zipcodeRef.current.focus();
      zipcodeRef.current.scrollIntoView();
      return false;
    } else if (formData.marketing_area === "") {
      setFieldalertmessage({
        ...fieldalertmessage,
        marketingarean: "Field is Required.",
      });
      marketingRef.current.focus();
      marketingRef.current.scrollIntoView();
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let validate = validationdata();
    if (validate) {
      if (!profileData?.profile_id) {
        let payload = {
          resturant_name: formData?.restaurant_name,
          resturant_address: formData?.location,
          resturant_website: formData?.website_url,
          delivery_zip_code: formData?.zipcode,
          area_of_marketing: formData?.marketing_area,
          crk_id: Clerk?.user?.id,
          is_unified_web: true,
        };
        createProfileAPI(payload, true)
          .then((res) => {
            if (res?.user_profile?.profile_id) {
              loginAPI()
                .then((res) => {
                  if (res.status == "success") {
                    localStorage.setItem("token", res.token);
                    setTimeout(() => {
                      fetchprofile({
                        profile_id: res.user_profiles.profile_id,
                      }).then((result) => {
                        dispatch(
                          storeInLocalStore({
                            profile_data: result?.dispachabledata?.profile_data,
                          })
                        );
                        dispatch(
                          storeInLocalStore({
                            users: result?.dispachabledata?.users,
                          })
                        );
                        setMessage("Profile is Created.");
                      });
                    }, 500);
                  }
                })
                .catch((rej) => {
                  console.log(rej);
                });
            } else {
              setAlertmessage("Something went worng");
            }
          })
          .catch((err) => {
            console.log("Error", err.response);
            setAlertmessage(
              err?.response?.data?.detail || "Something went worng"
            );
          });
      } else {
        let payload = {
          profile_id: profileData?.profile_id,
          resturant_name: formData?.restaurant_name,
          resturant_address: formData?.location,
          resturant_website: formData?.website_url,
          delivery_zip_code: formData?.zipcode,
          area_of_marketing: formData?.marketing_area,
          crk_id: Clerk?.user?.id,
          is_unified_web: true,
        };
        updateResturantDataAPI(payload, true)
          .then((res) => {
            if (res?.user_profile?.profile_id) {
              loginAPI()
                .then((res) => {
                  if (res.status == "success") {
                    localStorage.setItem("token", res.token);
                    setTimeout(() => {
                      fetchprofile({
                        profile_id: res.user_profiles.profile_id,
                      }).then((result) => {
                        dispatch(
                          storeInLocalStore({
                            profile_data: result?.dispachabledata?.profile_data,
                          })
                        );
                        dispatch(
                          storeInLocalStore({
                            users: result?.dispachabledata?.users,
                          })
                        );
                        setMessage("Profile is Created.");
                      });
                    }, 500);
                  }
                })
                .catch((rej) => {
                  console.log(rej);
                });
            } else {
              setAlertmessage("Something went worng");
            }
          })
          .catch((err) => {
            console.log("Error", err.response);
            setAlertmessage(
              err?.response?.data?.detail || "Something went worng"
            );
          });
      }
    }

    setTimeout(() => {
      setFieldalertmessage({
        restaurant_name: false,
        location: false,
        website: false,
        zipcode: false,
        marketingarean: false,
        donotmatch: false,
      });
    }, 3000);
  };
  const handleClose = () => {
    if (alertMessage) {
      setAlertmessage("");
    } else {
      setMessage("");
      history("/locations");
    }
  };
  const movetosignup = () => {
    history("/login");
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      locationRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      if (place) {
        let confirmAddress = handleAddressglobal(place);
        setFormData((formData) => ({
          ...formData,
          location:
            `${confirmAddress?.addressLine1} ${confirmAddress?.city} ${confirmAddress?.zipCode}, ${confirmAddress?.country} ` ||
            locationRef.current.value,
        }));
      }
    });
  }, [locationRef.current]);
  useEffect(() => {
    setFormData({
      ...formData,
      restaurant_name: profileData?.resturant_name || "",
      location: locationdata?.resturant_address || "",
      website_url: profileData?.website || "",
    });
  }, []);
  return (
    <>
      <div className="full-width headerpadding signup-page">
        <div className="content-padding-both">
          <div className="py-5">
            <div className="row align-items-top">
              <div className="col-md-6">
                <div className="title-wrap">
                  <div className="d-flex justify-content-center">
                    <h1 className="title extra-font">
                      SAVE YOUR <br />
                      PROFILE DETAILS
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                  <div className="row position-relative">
                    <div className="col-md-12">
                      <div className="content-padding-right">
                        <div className="form-group">
                          <label>Restaurant Name</label>
                          <input
                            type={"text"}
                            ref={restaurant_nameRef}
                            className="custom-input"
                            value={formData.restaurant_name}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                restaurant_name: e.target.value,
                              });
                            }}
                          />
                          {fieldalertmessage.restaurant_name && (
                            <span className="alerm-maeesage">
                              {fieldalertmessage.restaurant_name}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Location</label>
                          <input
                            type={"text"}
                            ref={locationRef}
                            className="custom-input"
                            value={formData?.location}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                location: e.target.value,
                              });
                            }}
                          />
                          {fieldalertmessage.location && (
                            <span className="alerm-maeesage">
                              {fieldalertmessage.location}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Website URL</label>
                          <input
                            type={"text"}
                            ref={websiteurlRef}
                            className="custom-input"
                            value={formData?.website_url}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                website_url: e.target.value,
                              });
                            }}
                          />
                          {fieldalertmessage.website && (
                            <span className="alerm-maeesage">
                              {fieldalertmessage.website}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Delivery Area Zip Code</label>
                          <input
                            type={"text"}
                            ref={zipcodeRef}
                            className="custom-input"
                            value={formData?.zipcode}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                zipcode: e.target.value,
                              });
                            }}
                          />
                          {fieldalertmessage.zipcode && (
                            <span className="alerm-maeesage">
                              {fieldalertmessage.zipcode}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Area of Marketing (Miles)</label>
                          <input
                            type={"number"}
                            ref={marketingRef}
                            className="custom-input"
                            value={formData?.marketing_area}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                marketing_area: e.target.value,
                              });
                            }}
                          />
                          {fieldalertmessage.marketingarean && (
                            <span className="alerm-maeesage">
                              {fieldalertmessage.marketingarean}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-3">
                    <div className="col-md-12 text-center">
                      <button
                        type="submit"
                        className="byteready-btn byteready-primary signupbtn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {(message || alertMessage) && (
          <GlobalAlert
            message={message || alertMessage}
            handleClose={handleClose}
          />
        )}
      </div>
    </>
  );
};

export default Profilesetup;
