import React, { useState } from "react";
import style from "./SmsStyle";
import { Box, Typography } from "@mui/material";
import PhoneImage from "../../../assets/images/iPhone_14_Pro.png";
import VerticalContainer from "../../../assets/images/vertical_container.png";
import FrontCamera from "../../../assets/SVG/Front_Camera.svg";
import circleIcon from "../../../assets/SVG/gray-circle.svg";
import EditButton from "../../Common/EditButton";
import {
  storeInLocalStore,
  selectedPlatform,
} from "../../../redux/reducers/mainSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./smsstyle.css";

const InstaView = ({ data, loader, operation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEdit = (data) => {
    let tempdata = { ...data?.alldata, selectedplatform: "sms" };
    dispatch(storeInLocalStore({ ediatablepost: tempdata }));
    dispatch(selectedPlatform("sms"));
    navigate("/edit-posts");
  };
  return (
    <>
      <div className="sms-container">
        <div className="sms-header">
          <div className="header-container">
            <div className="image">
              <img src={circleIcon} className="icon" />
            </div>
            <div className="skeleton">
              <hr className="lineone" />
              <hr className="linetwo" />
            </div>
          </div>
        </div>
        <div className="dummychats"></div>
        <div className="smschat">
          <p className="text">{data?.description}</p>
        </div>
        {!data?.alldata?.is_posted && (
          <div className="editbtn">
            <EditButton
              onClick={() => {
                handleEdit(data);
              }}
              style={{ margin: "15px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InstaView;
