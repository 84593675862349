import React, { useEffect, useState, useRef } from "react";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Box, Stack, Typography } from "@mui/material";
import eventCardImage1 from "../../../assets/images/events-1.png";
import style from "./BodyStyle.js";
import EventCard from "../../Common/EventsCard/EventCard.jsx";
import eventCardImage2 from "../../../assets/images/events-2.png";
import eventCardImage3 from "../../../assets/images/events-3.png";
import eventCardImage4 from "../../../assets/images/events-4.png";
import eventCardImage5 from "../../../assets/images/events-5.png";
import offerImage1 from "../../../assets/images/offer_image1.png";
import offerImage2 from "../../../assets/images/offer_image2.png";
import offerImage3 from "../../../assets/images/offer_image3.png";
import offerImage4 from "../../../assets/images/offer_image4.png";
import offerImage5 from "../../../assets/images/offer_image5.png";
import rewardsImage1 from "../../../assets/images/rewards_image1.png";
import rewardsImage2 from "../../../assets/images/rewards_image2.png";
import rewardsImage3 from "../../../assets/images/rewards_image3.png";
import rewardsImage4 from "../../../assets/images/rewards_image4.png";
import rewardsImage5 from "../../../assets/images/rewards_image5.png";
import { useDispatch, useSelector } from "react-redux";
import EventPopUp from "../EventPopOver/EventPopUp.jsx";

const eventInitialData = [
    {
        imgUrl: eventCardImage1,
        text: "Barbeque Festival",
        editBtn: false,
        isBookMark: false,
    },
    {
        imgUrl: eventCardImage2,
        text: "Honey and Berry",
        editBtn: false,
        isBookMark: false,
    },
    {
        imgUrl: eventCardImage3,
        text: "Watermelon Festival",
        editBtn: false,
        isBookMark: false,
    },
    {
        imgUrl: eventCardImage4,
        text: "Pizza Party",
        editBtn: false,
        isBookMark: false,
    },
    {
        imgUrl: eventCardImage5,
        text: "Ice-Cream Fest",
        editBtn: false,
        isBookMark: false,
    },
    {
        imgUrl: eventCardImage4,
        text: "Pasta Day",
        editBtn: false,
        isBookMark: false,
    },
];

const offersInitialData = [
    {
        imgUrl: offerImage1,
        text: "get $X off your order if it’s over $X.",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: offerImage2,
        text: "buy  X  {item}, get X  free!",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: offerImage3,
        text: "you will get X% off orders over $X!",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: offerImage4,
        text: "buy {item} and get a free {item}!",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: offerImage5,
        text: "Join us {time} pm, featuring $X drinks and more!",
        editBtn: true,
        isBookMark: false,
    },
];

const rewardsInitialData = [
    {
        imgUrl: rewardsImage1,
        text: "Free GLASS OF WINE WITH DINNER FOR 2",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: rewardsImage2,
        text: "FREE DESSERT WITH EVERY FAMILY DINNER",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: rewardsImage3,
        text: "Where happy hours are the happiest. Join us {time} pm, featuring $X drinks and more!",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: rewardsImage4,
        text: "Give the gift of great taste this Christmas! For every $X spent, get $X off!",
        editBtn: true,
        isBookMark: false,
    },
    {
        imgUrl: rewardsImage5,
        text: "Join us {time} pm, featuring $X drinks and more!",
        editBtn: true,
        isBookMark: false,
    },
];

const EventBody = ({ templateData, handletabchange, eventpage, type }) => {
    const [open, setOpen] = useState(false);
    const [tab, setTabs] = useState(type);
    const campaignData = useSelector((state) => state?.main?.store?.campaignUnit)
    const campaignId = useSelector((state) => state?.main?.store?.campaignId)
    let lastStep = campaignData?.[campaignData?.length - 1]?.tab
    const [eventData, setEventData] = useState(eventInitialData);
    const [offersData, setOffersData] = useState(offersInitialData);
    const [rewardsData, setRewardsData] = useState(rewardsInitialData);
    const handlenextRef = useRef(null);
    const profile_id = useSelector(
        (state) => state?.main?.store?.profile_data?.profile_id
    );
    const [popoverData, setPopoverData] = useState();
    const [currentImage, setCurrentImage] = useState();
    useEffect(() => {
        if (tab === 'events') {
            setCurrentImage(eventInitialData)
        } else if (tab === 'offers') {
            setCurrentImage(offersInitialData)
        } else {
            setCurrentImage(rewardsInitialData)
        }
    }, [tab])
    useEffect(() => {
        if(campaignId){
            setTabs("events")
        }else{
            if (lastStep == 'events') {
                setTabs("offers")
            } else if (lastStep == 'offers') {
                setTabs("rewards")
            } else if (lastStep == 'rewards') {
                setTabs("rewards")
            }
        }
    }, [lastStep,campaignId])
    useEffect(()=>{
        setTabs(type)
    },[type])
    const handleEventCounter = (index) => {
        const newData = eventData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    isBookMark: !item?.isBookMark,
                };
            } else {
                return item;
            }
        });
        setEventData(newData);
    };

    const handleOfferCounter = (index) => {
        const newData = offersData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    isBookMark: !item?.isBookMark,
                };
            } else {
                return item;
            }
        });
        setOffersData(newData);
    };

    const handleRewardsCounter = (index) => {
        const newData = rewardsData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    isBookMark: !item?.isBookMark,
                };
            } else {
                return item;
            }
        });
        setRewardsData(newData);
    };
    const handlePopover = (element) => {
        setOpen(true);
        setPopoverData(element);
    };
    const handlePopoverClose = () => {
        setOpen(false);
        setPopoverData("");
    };

    useEffect(() => {
        if (tab === "events") {
            setCurrentImage(eventData);
        } else if (tab === "offers") {
            setCurrentImage(offersData);
        } else if (tab === "rewards") {
            setCurrentImage(rewardsData);
        }
    }, [tab, eventData, offersData, rewardsData]);

    useEffect(() => {
        if (templateData) {
            const eventData = templateData
                .filter((item) => item.template_category == "events")
                .map((item) => ({
                    id: item.id,
                    imgUrl: item.content?.image_url,
                    text: item?.template_title,
                    editBtn: false,
                    contentData: item.content,
                    isBookMark: false,
                }));
            const rewardData = templateData
                .filter((item) => item.template_category == "rewards")
                .map((item) => ({
                    id: item.id,
                    imgUrl: item.content?.image_url,
                    text: item?.template_title,
                    editBtn: true,
                    contentData: item.content,
                    isBookMark: false,
                }));
            const offersData = templateData
                .filter((item) => item.template_category == "offers")
                .map((item) => ({
                    id: item.id,
                    imgUrl: item.content?.image_url,
                    text: item?.template_title,
                    editBtn: true,
                    contentData: item.content,
                    isBookMark: false,
                }));
            setEventData(eventData);
            setOffersData(offersData);
            setRewardsData(rewardData);
        } else {
            setEventData(eventInitialData);
            setOffersData(offersInitialData);
            setRewardsData(rewardsInitialData);
        }
    }, [templateData]);

    return (
        <>
            <Box sx={style.mainContainer}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        marginBottom: "20px",
                        padding: "10px 0px",
                    }}
                >
                    <Stack direction="row" sx={{
                        alignItems:'center'
                    }} spacing={2}>
                        <Typography
                            // onClick={() => setTabs("events")}
                            sx={{
                                ...style.text,
                                opacity: tab !== "events" && "0.30000001192092896",
                            }}
                        >
                            events
                        </Typography>
                        <Brightness1Icon sx={style.iconStyle} />
                        <Typography
                            sx={{
                                ...style.text,
                                opacity: tab !== "offers" && "0.30000001192092896",
                            }}
                        >
                            Offers
                        </Typography>
                        <Brightness1Icon sx={style.iconStyle} />
                        <Typography
                            sx={{
                                ...style.text,
                                opacity: tab !== "rewards" && "0.30000001192092896",
                            }}
                        >
                            Rewards
                        </Typography>
                    </Stack>
                </Stack>
                {tab === "events" && (
                    <EventCard
                        ref={handlenextRef}
                        handleCounter={handleEventCounter}
                        data={currentImage}
                        handlePopover={handlePopover}
                    />
                )}
                {tab === "offers" && (
                    <EventCard
                        ref={handlenextRef}
                        handleCounter={handleOfferCounter}
                        data={currentImage}
                        handlePopover={handlePopover}
                    />
                )}
                {tab === "rewards" && (
                    <EventCard
                        ref={handlenextRef}
                        handleCounter={handleRewardsCounter}
                        data={currentImage}
                        handlePopover={handlePopover}
                    />
                )}
                <EventPopUp
                    data={popoverData}
                    open={open}
                    handleClose={handlePopoverClose}
                    tab={tab}
                    setTabs={setTabs}
                    handletabchange={handletabchange}
                    profile_id={profile_id}
                    eventpage={eventpage}
                />
            </Box>
        </>
    );
};

export default EventBody;
