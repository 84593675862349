import React, { useState } from "react";
import "./../../../assets/css/common/commonComponent.css";
import FacebookIcon from "../../../assets/SVG/fbIcon.svg";
import ActiveFacebookIcon from "../../../assets/SVG/activeFbicon.svg";
import InstgramIconactive from "../../../assets/SVG/IstaIcon.svg";
import InstagramNormal from "../../../assets/SVG/IstaIconnormal.svg";

const MediaSwitchButton = ({ mobilePhone, designSwitch, customStyle }) => {
  const [isActive, setIsActive] = useState(1);

  const toggleSwitch = () => {
    if (isActive === 1) {
      setIsActive(2);
      designSwitch("fb");
    } else {
      setIsActive(1);
      designSwitch("insta");
    }
  };

  return (
    <div className="icon-switcher">
      <img
        onClick={toggleSwitch}
        style={{ fill: isActive === 1 ? "#eb2426" : "#FCFCFC" }}
        src={isActive === 1 ? InstgramIconactive : InstagramNormal}
        className={`social-icons ${isActive === 1 ? "active" : ""}`}
        alt="InstgramIcon"
      />

      <img
        onClick={toggleSwitch}
        style={{ fill: isActive === 2 ? "#eb2426" : "#FCFCFC" }}
        src={isActive === 2 ? ActiveFacebookIcon : FacebookIcon}
        className={`social-icons ${isActive === 2 ? "active" : ""}`}
        alt="FacebookIcon"
      />
    </div>
  );
};

export default MediaSwitchButton;
