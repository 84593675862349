const style = {
    mainWrapper: {
        position: 'relative',
        width: "max-content",
        backgroundSize: "contain",
        height: "570px",
        width: "279px", 
        margin: "0px 8px",
    },
    fbcontainer: {
        position: "absolute",
        top: "2%",
        height: "590px",
        width: "260px"
    },
    post: {
        height: '257px',
        width: '250px',
        position: 'absolute',
        left: '5%',
        top: '37%',
        backgroundSize: "cover",
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',
    },
    postHeading: {
        position: 'absolute',
        top: '25.8%',
        left: "5%",
        padding: "10px 15px",
        width: '250px',
        background: '#fff'
    },
    postHeadingImage: {
        width: "27px",
        marginRight: "10px"
    },
    postImage: {
        position: "absolute",
        width: "319px",
        height: "300px",
        objectFit: "cover",
        top: '40%',
        left: "4.5%"
    },
    postHeadingText: {
        textAlign:"center",
        position: "absolute",
        color: "#fff",
        fontSize: "25px",
        fontWeight: "900",
        fontFamily: "WhyteInktrap-Black",
        width:"100%"
    }
}
export default style