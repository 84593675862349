import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./Campaignstyle";
import RightArrow from "../../components/Common/RightArrow/RightArrow";
import displayImage from "../../assets/images/IpadDisplayImage.png";
import ipadFrame from "../../assets/images/IpadImage.png";
import CustomButton from "../../components/Common/CustomButton/CustomButton.jsx";
import { useNavigate } from "react-router-dom";
import ImageCards from "../../components/Common/ImageCards";
import { useDispatch, useSelector } from "react-redux";
import CreatedButton from "../../components/Events/EventsImage/CreatedButton";
import {
  settogodownposition,
  setoriginscroller,
} from "../../redux/reducers/mainSlice";

const SavedCampaign = ({ eventpage, paymentDone }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const compaignData = useSelector((state) => state.main.store.campaignUnit);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const firstName = eventpage ? eventpage.split(" ") : "";
  const handleSave = () => {
    setSubmitted(true);
    navigate("/saved-campaign");
  };

  const handlePrevious = () => {
    if (index > 0 && compaignData.length > 3) {
      let _index = index - 1;
      let filterData = compaignData?.filter(
        (element, i) => i >= _index && i < _index + 3
      );
      setData(filterData);
      setIndex(_index);
    }
  };

  const handleNext = () => {
    if (
      index < data.length &&
      compaignData.length > 3 &&
      index + 3 < compaignData.length
    ) {
      let filterData = compaignData?.filter(
        (element, i) => i > index && i <= index + 3
      );
      setData(filterData);
      setIndex(index + 1);
    }
  };

  const handleCreateCalendar = () => {
    dispatch(setoriginscroller(true));
    navigate("/");
  };
  const browsertopic = () => {
    dispatch(settogodownposition(true));
    navigate("/");
  };
  useEffect(() => {
    let _data = compaignData.filter((element, i) => i < 3);
    setData(_data);
  }, [compaignData]);

  return (
    <>
      <Box
        sx={{
          ...style.container,
          background: paymentDone && "#111",
          paddingTop: paymentDone && "0px",
        }}
      >
        <Box sx={style.mainContainer}>
          {!paymentDone && (
            <Stack sx={style.textContainer} direction="row" spacing={2}>
              <Typography sx={style.text}>Viewers</Typography>
              <RightArrow />
              <Typography
                sx={{ ...style.text, opacity: "0.30000001192092896" }}
              >
                Visitors
              </Typography>
              <RightArrow sx={{ opacity: "0.30000001192092896" }} />
              <Typography
                sx={{ ...style.text, opacity: "0.30000001192092896" }}
              >
                1st Time Customers
              </Typography>
              <RightArrow sx={{ opacity: "0.30000001192092896" }} />
              <Typography
                sx={{ ...style.text, opacity: "0.30000001192092896" }}
              >
                Reg Customers
              </Typography>
            </Stack>
          )}
          <Box
            sx={{
              ...style.circleContainer,
              backgroundImage: !paymentDone && `url(${displayImage})`,
              background: paymentDone && "#111",
              height: "90vh",
            }}
          ></Box>
          {!paymentDone && (
            <Box sx={style.btnBox}>
              <CreatedButton />
            </Box>
          )}
          <Box sx={style.ipadBox}>
            <Box
              sx={{ ...style.ipadFrame, backgroundImage: `url(${ipadFrame})` }}
            >
              <Box
                sx={{
                  ...style.imgStyle,
                  backgroundImage: `url(${displayImage})`,
                }}
              >
                {!paymentDone && (
                  <Stack direction="column" spacing={2}>
                    <CustomButton
                      onClick={handleCreateCalendar}
                      sx={{ width: "100%" }}
                      label="Create Calendar"
                    />
                    <CustomButton
                      sx={style.browseBtn}
                      label="Browse Other Topics"
                      onClick={browsertopic}
                    />
                  </Stack>
                )}
                <Box
                  sx={{
                    width: "100%",
                    height: "52vh",
                    top: "48%",
                    left: "0%",
                    position: "absolute",
                  }}
                >
                  {compaignData && (
                    <ImageCards
                      data={data}
                      handlePrevious={handlePrevious}
                      handleNext={handleNext}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Typography sx={style.textCover}>
              {firstName.length > 0 ? (
                <>
                  {firstName[0]} <br /> {firstName.slice(1).join(" ")}
                </>
              ) : (
                <>
                  AUTUMN <br /> Season
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SavedCampaign;
