import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({ text, spacing, custommargin, handleChange }) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#eb2426",
        borderRadius: "50px",
        display: "block",
        padding: `${spacing ? spacing : "0px"}`,
        margin: `${custommargin ? custommargin : "0px"}`,
        justifyContent: "center",
        alignItems: "center",
        background: "#eb2426",
        textTransform: "none",
        "&:hover": {
          background: "#eb2426",
        },
      }}
      onClick={() => {
        handleChange();
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
