import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./PopOverStyle";
import commonStyle from "../../../assets/style/commonStyle";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import CustomTimePicker from "../../Common/CustomTimePicker";

export default function Collectiveinput({
  obj,
  handleValue,
  inputValue,
  currentDate,
  time,
}) {
  const [dvalue, setDvalue] = useState();
  const currentData = new Date().toJSON().slice(0, 10);
  const handleInputChange = (e) => {
    if (obj.field_type == "date") {
      const value = e.target.value;
      let tempVal = moment(value).format("DD/MM/YYYY");
      handleValue(obj.field_name, tempVal);
    } else if (obj.field_type == "time") {
      const value = e;
      handleValue(obj.field_name, value);
    } else if (obj.field_name.toLowerCase().includes("price")) {
      const value = e;
      let tempVal = `$` + value;
      handleValue(obj.field_name, tempVal);
    } else {
      const value = e.target.value;
      handleValue(obj.field_name, value);
    }
  };
  useEffect(() => {
    if (obj?.field_name?.toLowerCase().includes("price")) {
      let pricewithsoller = inputValue[obj.field_name]?.replace("$", "");
      setDvalue(pricewithsoller);
    } else if (obj.field_type == "date") {
      let tempVal = moment(inputValue[obj.field_name], "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      setDvalue(tempVal);
    } else {
      setDvalue(inputValue[obj.field_name]);
    }
  }, [inputValue]);

  if (obj.field_type == "date") {
    return (
      <Box className="datebox">
        <label
          style={{ ...commonStyle, marginTop: "20px", display: "block" }}
          htmlFor={obj.field_name}
        >
          {obj.field_name}
        </label>
        <input
          id={obj.field_name}
          type={obj.field_type}
          className="events date"
          value={dvalue}
          placeholder={obj.field_name}
          min={currentData}
          onChange={handleInputChange}
        />
      </Box>
    );
  } else if (obj.field_type == "time") {
    return (
      <Box className="datebox">
        <label style={commonStyle} htmlFor={obj.field_name}>
          {obj.field_name}
        </label>
        <CustomTimePicker
          defaultTime={dvalue}
          onTimeChange={handleInputChange}
          placeholder={obj.field_name}
        />
      </Box>
    );
  } else if (obj?.field_name?.toLowerCase().includes("price")) {
    return (
      <>
        <Box>
          <label style={commonStyle} htmlFor="firstPrice">
            {obj.field_name}
          </label>
          <CurrencyInput
            id="input-example"
            name="input-name"
            placeholder={obj.field_name}
            prefix="$"
            className="events"
            value={isNaN(dvalue) ? "" : dvalue}
            decimalsLimit={2}
            allowNegativeValue={false}
            onValueChange={(value) => handleInputChange(value)}
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box>
          <label style={commonStyle} htmlFor="firstPrice">
            {obj.field_name}
          </label>
          <input
            id="firstPrice"
            type={obj.field_type}
            className="events"
            defaultValue={dvalue}
            placeholder={obj.field_name}
            onChange={handleInputChange}
          />
        </Box>
      </>
    );
  }
}
