import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";

import style from "./Calendar.js";
import Arrow from "../../assets/SVG/arrow.svg";
import SwitchButton from "../../components/Common/SwitchButton/SwitchButton.jsx";
import SocialMediaCards from "../../components/Common/SocialMediaCards/SocialMediaCards.jsx";
import FacebookMediaCard from "../../components/Common/SocialMediaCards/FacebookMediaCard.jsx";
import Footer from "../../components/Home/Footer/Footer.jsx";
import TextSwitchButton from "../../components/Common/TextSwitchButton/TextSwitchButton.jsx";
import moment from "moment";
import { getAllCaptionpost, addtempPost } from "../../apis/auth/index.jsx";
import Signup from "./Signup/Signup.jsx";
import PostConfirm from "./Signup/PostConfirm.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorate } from "./../../apis/helper/getLocalstore.js";
import PopupShow from "./Signup/PopupShow.jsx";
import GlobalLoader from "../../components/Common/GlobalLoader/GlobalLoader.jsx";
import { storeInLocalStore } from "../../redux/reducers/mainSlice.jsx";
import MediaSwitchButton from "../../components/Common/MediaSwitch/MediaSwitchButton.jsx";

const Calendar = () => {
  const cardData = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state?.main?.store?.profile_data);
  const locationstoreData = useSelector(
    (state) => state?.main?.store?.location_data
  );
  const profile_id = profileData?.profile_id;
  const wesiturl = locationstoreData?.resturant_website;
  const resName = profileData?.resturant_name;
  const [monthalydata, setMonthalydata] = useState(cardData);
  const [designSwicher, setDesingswitcher] = useState("insta");
  const [currentDate, setCurrentDate] = useState();
  const tempCurrentDate = new Date();
  const [signupModel, setSignupModel] = useState(false);
  const [modelData, setModelData] = useState("");
  const [loader, setLoader] = useState(false);
  // get Post Data
  const fetchData = async () => {
    setLoader(true);
    getAllCaptionpost({
      profile_id: profile_id,
      number_of_day: 30,
    })
      .then((res) => {
        if (res.status == "success") {
          setLoader(false);
          console.log(res.captions);
          setMonthalydata(res.captions);
          setCurrentDate(res?.captions[0]?.date);
          let temporigin = {
            date: res?.captions[0]?.date,
            output_image: res?.captions[0]?.prompt_output_img_url,
            output_text: res?.captions[0]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ origin_output: temporigin }));
          let templocation = {
            date: res?.captions[1]?.date,
            output_image: res?.captions[1]?.prompt_output_img_url,
            output_text: res?.captions[1]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ location_output: templocation }));
          let tempcuisine = {
            date: res?.captions[2]?.date,
            output_image: res?.captions[2]?.prompt_output_img_url,
            output_text: res?.captions[2]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ cuisine_output: tempcuisine }));
        } else {
          setMonthalydata(cardData);
          setCurrentDate(tempCurrentDate);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
        setMonthalydata(cardData);
        setCurrentDate(tempCurrentDate);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (profileData === null) {
      navigate("/");
    } else {
      fetchData();
    }
  }, []);

  //Date Change
  let startDate = moment(currentDate);
  const weekdays = moment.weekdaysShort();
  const currentDayOfWeek = moment(currentDate).day();
  const rotatedWeekdays = [
    ...weekdays.slice(currentDayOfWeek),
    ...weekdays.slice(0, currentDayOfWeek),
  ];
  // Social Media Switcher
  const designSwitch = (data) => {
    console.log(data);
    setDesingswitcher(data);
  };

  const handleSave = () => {
    addtempPost({
      date: currentDate,
      profile_id: profile_id,
    }).then((res) => {
      if (res.status == "success") {
        setSignupModel(true);
      }
    });
  };
  const handleSignupmodel = () => {
    setSignupModel(false);
  };
  const handlePopup = (data) => {
    // console.log(data);
    setModelData(data);
  };
  const clodeModel = (data) => {
    if ((data = "reload")) {
      setModelData("");
      fetchData();
    } else {
      setModelData("");
    }
  };

  return (
    <>
      {loader ? (
        <GlobalLoader open={loader} />
      ) : (
        <>
          <Box sx={style.mainContainer}>
            <Box className="customcontainerglobal">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sx={{
                    padding: "20px 0px",
                  }}
                >
                  <Stack direction="row">
                    <Typography sx={style.text}>Summer Seasons</Typography>
                    <img src={Arrow} alt="Arrow1" />
                    <Typography sx={style.text}>My Story</Typography>
                    <img src={Arrow} alt="Arrow2" />
                    <Typography sx={style.text}>My Media</Typography>
                    <img style={{ opacity: "0.2" }} src={Arrow} alt="Arrow2" />
                    <Typography sx={{ ...style.text, opacity: "0.2" }}>
                      My Customers
                    </Typography>
                    <img style={{ opacity: "0.2" }} src={Arrow} alt="Arrow2" />
                    <Typography sx={{ ...style.text, opacity: "0.2" }}>
                      Actionable Data
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography sx={{ ...style.text, padding: "20px 0px" }}>
                    Choose The Platform
                  </Typography>
                  <MediaSwitchButton designSwitch={designSwitch} />
                  <Typography sx={{ ...style.text, padding: "20px 0px" }}>
                    Choose The Context
                  </Typography>
                  <TextSwitchButton />
                  <Box>
                    <Typography sx={style.text2}>My Social media</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Box sx={style.cardBox}>
                    <TableContainer>
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow
                            sx={{
                              borderBottom: "0px",
                            }}
                          >
                            {rotatedWeekdays.map((day) => (
                              <TableCell key={day} align="center">
                                <Typography sx={style.dayText} variant="h5">
                                  {day.charAt(0)}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {monthalydata &&
                            monthalydata
                              .reduce((weeks, day, index) => {
                                startDate =
                                  index === 0
                                    ? startDate.add(0, "days")
                                    : startDate.add(1, "days");
                                const weekIndex = Math.floor(index / 7);
                                if (!weeks[weekIndex]) {
                                  weeks[weekIndex] = [];
                                }
                                weeks[weekIndex].push(
                                  <TableCell
                                    key={index}
                                    align="center"
                                    sx={{
                                      padding: "2px",
                                      margin: "auto",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handlePopup(day)}
                                  >
                                    {designSwicher == "insta" ? (
                                      <>
                                        <SocialMediaCards
                                          dataperday={day}
                                          url={wesiturl}
                                          name={resName}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <FacebookMediaCard
                                          dataperday={day}
                                          url={wesiturl}
                                          name={resName}
                                        />
                                      </>
                                    )}

                                    <Typography
                                      sx={style.dateText}
                                      variant="h5"
                                    >{`${startDate.format(
                                      "MM/DD"
                                    )}`}</Typography>
                                  </TableCell>
                                );
                                return weeks;
                              }, [])
                              .map((week, index) => (
                                <TableRow key={index}>{week}</TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Button
                align="left"
                alignItems="flex-end"
                justifyContent="flex-end"
                sx={style.saveButton}
                variant="contained"
                onClick={handleSave}
              >
                Save the Content
              </Button>
            </Box>
          </Box>
        
          {/* <Signup open={signupModel} handleClose={handleSignupmodel} /> */}
          <PostConfirm open={signupModel} handleClose={handleSignupmodel} />
          <PopupShow
            open={modelData}
            wesiturl={wesiturl}
            resName={resName}
            handleClose={clodeModel}
            resData={profileData}
          />
        </>
      )}
    </>
  );
};

export default Calendar;
