import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Jan", uv: 65000, pv: 30000, thirdLine: 45000, fourthLine: 35000 },
  { name: "Feb", uv: 59000, pv: 20, thirdLine: 55, fourthLine: 45 },
  { name: "Mar", uv: 40000, pv: 60, thirdLine: 65, fourthLine: 55 },
  { name: "Apr", uv: 51000, pv: 70000, thirdLine: 65000, fourthLine: 65 },
  { name: "May", uv: 56000, pv: 40, thirdLine: 55000, fourthLine: 75 },
  { name: "Jun", uv: 56000, pv: 40000, thirdLine: 95, fourthLine: 55 },
];

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
      {payload.value}
    </text>
  </g>
);

const CustomCharts = () => {
  const yAxisTicks = [0, 10000, 20000, 30000, 40000, 50000, 60000, 70000];

  return (
    <>
      <ResponsiveContainer aspect={1.2}>
        <LineChart width={450} height={300} data={data}>
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis
            axisLine={false}
            tickLine={false}
            domain={[0, 70000]}
            ticks={yAxisTicks}
            tick={CustomYAxisTick}
          />
          <CartesianGrid stroke="#ccc" />
          <Line type="monotone" dataKey="uv" stroke="#8884d8" />
          <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
          <Line type="monotone" dataKey="thirdLine" stroke="#ff0000" />
          <Line type="monotone" dataKey="fourthLine" stroke="#1DE8DB" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default CustomCharts;
