import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import style from "./EventPopUpStyle";
import close from "../../../assets/SVG/close.svg";
import resetImage from "../../../assets/SVG/ic_round-refresh.svg";
import uploadImageNew from "../../../assets/images/upload-underline.png";
import sendIcon from "../../../assets/images/Send.png";
import CustomButton from "../../Common/CustomButton/CustomButton";
import ai from "../../../assets/images/AI.png";
import PickDate from "../../commoncomponents/PickDate/PickDate";
import UploadBtn from "../../Common/UploadBtn";
import {
  createAddUnitAPI,
  createCustomThemeAPI,
  saveSinglecompainAPI,
  unitImageuploadAPI,
  uploadImageAddUnitAPI,
} from "../../../apis/auth";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useCompaignData } from "../../../Context/CampaignContext";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import Loadericon from "../../Common/Loadericon";
import ProfileAlert from "../../Events/PopOver/ProfileAlert";
import { Modal } from "react-bootstrap";

function EventPopUp({
  open,
  handleClose,
  tab,
  setTabs,
  handletabchange,
  data,
  profile_id,
  eventpage,
}) {
  const [uploadImage, setUploadImage] = useState(data?.imgUrl);
  const [disableTextArea, setDisabledTextarea] = useState(true);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);
  const [imageFile, setImageFile] = useState();
  const [loader, setLoader] = useState(false);
  const [dateAndTime, setDateAndTime] = useState();
  const [aiText, setAiText] = useState(false);
  const [applied, setApplied] = useState(false);
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [userThaughts, setUserThaughts] = useState(false);
  const [aiLoader, setAiLoader] = useState(false);
  const [compaignData, setCompaignData] = useCompaignData();
  const dispatch = useDispatch();
  const [textareaElement, setTextareaElement] = useState(null);
  const [profileAlert, setProfileAlert] = useState(false);
  const getCaompainData = useSelector(
    (state) => state?.main?.store?.campaignUnit
  );
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1);
    setOtp(newOtp);
    if (event.target.value && otpInputs.current[index + 1]) {
      otpInputs.current[index + 1].focus();
    }
  };
  const concatenatedString = otp.map((item) => String(item)).join("");
  const handleBackspace = (index, event) => {
    const value = event.target.value;
    if (value === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = ""; // Clear the previous digit
      setOtp(newOtp);

      // Move to the previous input field
      if (otpInputs.current[index - 1]) {
        otpInputs.current[index - 1].focus();
      }
    }
  };
  const handleTabChange = () => {
    if (tab == "events") {
      setTabs("offers");
      handletabchange(1);
      handleClose();
    }
    if (tab == "offers") {
      setTabs("rewards");
      handletabchange(2);
      handleClose();
    }
    if (tab == "rewards") {
      handleClose();
    }
  };
  useEffect(() => {
    setUploadImage(data?.imgUrl);
    setTitle(data?.text);
    setDesc(data?.contentData?.description);
  }, [data]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
    const modifiedFile = new File([file], newFileName, { type: file.type });
    setImageFile(modifiedFile);
    setUploadImage(URL.createObjectURL(modifiedFile));
  };
  const handleImageUploadBackend = (data) => {
    let payload = {
      image: imageFile,
      profile_id: profile_id,
      addunitId: data,
    };
    console.log(payload, "PAYLOAD1!");
    uploadImageAddUnitAPI(payload)
      .then((res) => {
        console.log(res, "RESULT");
      })
      .catch((error) => {
        console.log("Error ", error);
      });
  };
  const handleGetDateAndTime = (data) => {
    setDateAndTime(data);
  };
  const handleCreateSuggestion = () => {
    if (!profile_id) {
      alert("profile id is required");
    } else {
      setLoader(true);
      let payload = {
        profile_id: profile_id,
        child_temp_id: data?.id,
        input_text: JSON.stringify(
          tab == "events"
            ? dateAndTime
              ? {
                  ...dateAndTime,
                  "User Thoughts": userThaughts ? userThaughts : "",
                }
              : {
                  "Start Date": data?.contentData?.fields[0]?.placeholder,
                  "End Date": data?.contentData?.fields[2]?.placeholder,
                  "Start Time": data?.contentData?.fields[1]?.placeholder,
                  "End Time": data?.contentData?.fields[3]?.placeholder,
                  "User Thoughts": userThaughts ? userThaughts : "",
                }
            : {
                promocode: concatenatedString,
                "Offer Text": data?.text,
                "User Thoughts": userThaughts ? userThaughts : "",
              }
        ),
        title: title,
        description: desc,
        image_url: uploadImage,
      };
      createAddUnitAPI(payload)
        .then((res) => {
          if (res.status == "success") {
            setLoader(false);
            setAiText(res?.data?.description);
            setApplied(false);
          } else {
            setLoader(false);
            alert(res.message);
          }
        })
        .catch((res) => {
          alert(res.data);
        });
    }
  };
  function removeEmojis(text) {
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
  }
  const handleSaveSuggestion = () => {
    setLoader(true);
    if (!profile_id) {
      alert("profile id is required");
    } else {
      let payload = {
        bodyPayload: {
          profile_id: profile_id,
          child_temp_id: data?.id,
          input_text: JSON.stringify(
            tab == "events"
              ? dateAndTime
                ? { ...dateAndTime, "User Thoughts": aiText ? aiText : "" }
                : {
                    "Start Date": data?.contentData?.fields[0]?.placeholder,
                    "End Date": data?.contentData?.fields[2]?.placeholder,
                    "Start Time": data?.contentData?.fields[1]?.placeholder,
                    "End Time": data?.contentData?.fields[3]?.placeholder,
                    "User Thoughts": aiText ? aiText : "" ,
                  }
              : {
                  promocode: concatenatedString,
                  "Offer Text": data?.text,
                  "User Thoughts": aiText ? aiText : "" ,
                }
          ),
          title: title,
          description: removeEmojis(desc),
          image_url: uploadImage,
        },
        urlPayload: {
          update: false,
          unitid: data?.id,
        },
      };
      saveSinglecompainAPI(payload).then((res) => {
        if (res.status == "success") {
          if (imageFile) {
            handleImageUploadBackend(res.addunit.id);
          }
          const _data = {
            id: res.addunit.id,
            imgUrl: uploadImage,
            text: title,
            description: desc,
            date: new Date(),
            time: new Date().getTime(),
            tab: tab,
          };
          console.log(tab, _data, "TAB");
          if (compaignData) {
            setCompaignData([...compaignData, _data]);
          }
          setOtp(["", "", "", "", "", ""]);
          setImageFile("");
          let tempval = [];
          if (getCaompainData?.length > 0) {
            tempval = [...getCaompainData, _data];
          } else {
            tempval = [_data];
          }
          dispatch(storeInLocalStore({ campaignUnit: tempval }));
          handleTabChange();
          setLoader(false);
          setAiText(false);
          setApplied(false);
        } else {
          setLoader(false);
          alert(res.message);
        }
      });
    }
  };
  const applyTheme = () => {
    setApplied(true);
    setDisabledTextarea(true);
    setDesc(aiText);
  };
  const dateRange = { startDate: new Date(), endDate: new Date() };
  useEffect(() => {
    const textarea = document.getElementById("autoHeightTextarea");
    if (textarea) {
      setTextareaElement(textarea);
    }
  }, []);
  function adjustTextareaHeight(textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
  useEffect(() => {
    if (textareaElement) {
      adjustTextareaHeight(textareaElement);
    }
  }, [textareaElement, aiText]);
  function isArrayFilledWithEmptyStrings(arr) {
    return arr.some((item) => item === "");
  }

  return (
    <Modal
      show={open}
      onClose={() => {
        handleClose(), setAiText(false), setApplied(false);
      }}
      size="lg"
      centered
      className="description-body"
    >
      <Modal.Body>
        {loader && <Loadericon />}
        <Box
          align="right"
          sx={{
            marginTop: "0px",
            display: "flex",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ ...style.headingPopup, width: "95%" }}
            color={"#fff"}
          >
            {data?.text}
          </Typography>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClose(), setAiText(false), setApplied(false);
            }}
            alt="imgError"
            src={close}
            width="5%"
          />
        </Box>
        <div className="row align-items-start" style={style.mainContent}>
          <div className="col-md-3">
            <img src={uploadImage} style={{ ...style.imgContainer }} />
            <UploadBtn showText={true} handleChange={handleImageUpload} />
          </div>
          <div className="col-md-9">
            {tab == "events" && (
              <PickDate
                selectedDateRange={dateRange}
                data={data}
                handleGetDateAndTime={handleGetDateAndTime}
              />
            )}
            {(tab == "offers" || tab == "rewards") && (
              <>
                <small style={{ marginLeft: "15px", fontSize: "12px" }}>
                  Promocode
                </small>
                <div style={{ marginLeft: "15px", marginBottom: "10px" }}>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (otpInputs.current[index] = el)} // Store input refs
                      value={digit}
                      onChange={(e) => handleInputChange(index, e)}
                      onKeyDown={(e) =>
                        e.key === "Backspace" && handleBackspace(index, e)
                      }
                      variant="outlined"
                      type="text"
                      inputProps={{
                        maxLength: 1,
                        style: {
                          textAlign: "center",
                          width: "1rem",
                          borderBottom: "1px solid #fff",
                          color: "#fff",
                          borderRadius: "0px",
                          marginLeft: "5px",
                          padding: "2px",
                        },
                      }}
                      margin="dense"
                    />
                  ))}
                </div>
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  defaultValue={title}
                  style={{
                    marginLeft: "15px",
                    marginBottom: "10px",
                    fontSize: "13px",
                  }}
                />
              </>
            )}
            <div style={{ marginLeft: "15px", marginTop: "10px" }}>
              <Box sx={style.blackEventPopUpBox}>
                <Typography sx={style.boxHeading}>Description</Typography>
                <Typography sx={style.boxContent}>{desc}</Typography>
              </Box>
              <hr />
              {aiText && !applied && (
                <>
                  <Box sx={style.orangeEventPopUpBox}>
                    <Typography sx={style.boxHeading}>Description</Typography>
                    <Typography sx={style.boxContent}></Typography>
                    <textarea
                      ref={setTextareaElement}
                      id="autoHeightTextarea"
                      onChange={(e) => setAiText(e.target.value)}
                      disabled={disableTextArea}
                      style={{
                        ...style.pseudoPopUpInput,
                        fontSize: "16px",
                        border: disableTextArea ? "none" : "1px solid #000",
                        outline: disableTextArea ? "none" : "1px solid #fff",
                      }}
                      value={aiText}
                    />
                    <img src={ai} style={style.aiLogo} />
                  </Box>
                  <Box className="d-flex justify-content-between">
                    <div className="d-flex">
                      <button
                        onClick={() => setDisabledTextarea(false)}
                        style={style.btnBox}
                      >
                        <img src={uploadImageNew} />
                      </button>
                      <button
                        onClick={() => handleCreateSuggestion()}
                        style={style.btnBox}
                      >
                        <img src={resetImage} />
                      </button>
                    </div>
                    <Button
                      style={style.borderButton}
                      onClick={() => applyTheme()}
                    >
                      Apply
                    </Button>
                  </Box>
                </>
              )}
              <Box mt={1}>
                <Typography sx={{ ...style.boxHeading, mb: 1 }}>
                  AI Suggestion
                </Typography>
                <Box className="d-flex" sx={style.pseudoInput}>
                  <input
                    onChange={(e) => setUserThaughts(e.target.value)}
                    style={style.popUpInput}
                    placeholder="Type your thoughts"
                  />
                  <button
                    disabled={!userThaughts}
                    style={style.transparentButton}
                    onClick={() => handleCreateSuggestion()}
                  >
                    <img src={sendIcon} />
                  </button>
                </Box>
              </Box>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <CustomButton
            sx={{
              width: "20%",
              fontFamily: "SF Pro Display",
              fontWeight: "900",
              backgroundColor:
                (tab == "offers" || tab == "rewards") &&
                isArrayFilledWithEmptyStrings(otp)
                  ? "#7c7c7c"
                  : "#eb2426",
            }}
            label="Save"
            disabling={
              (tab == "offers" || tab == "rewards") &&
              isArrayFilledWithEmptyStrings(otp)
            }
            onClick={() => handleSaveSuggestion()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EventPopUp;
