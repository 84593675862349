import React from "react";
import viewerIcon from "../../../assets/images/Vector_viewers.png";
import { Grid, Stack, Typography } from "@mui/material";
import segmentIcon from "../../../assets/SVG/Group_48096207.svg";
import spendArrowIcon from "../../../assets/SVG/fontisto_arrow-left-l.svg";
import viewersIcon from "../../../assets/SVG/Frame.svg";
import costIcon from "../../../assets/SVG/noun-tag-191676 (1) 1.svg";
import trendUp from "../../../assets/SVG/Group_1.svg";
import trendDown from "../../../assets/SVG/Group_2.svg";
import Accordin from "./Accordin";
import CustomCharts from "./../components/CustomCharts";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Form from "react-bootstrap/Form";

const Years = [
  2000, 2001, 2002, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
];

const data = [
  {
    segment: "Man(45-65)",
    spend: "$75.00",
    viewers: 83,
    cost_per: "$0,1",
    now: 60,
  },
  {
    segment: "Women (18-35)",
    spend: "$160.00",
    viewers: 206,
    cost_per: "$0,3",
    now: 70,
  },
  {
    segment: "Women (18-35)",
    spend: "$25.00",
    viewers: 45,
    cost_per: "$1,09",
    now: 80,
  },
  {
    segment: "Families",
    spend: "$5.00",
    viewers: 3,
    cost_per: "$0,04",
    now: 90,
  },
  {
    segment: "Students",
    spend: "$5.00",
    viewers: 3,
    cost_per: "$0,02",
    now: 40,
  },
  {
    segment: "High Value Customers",
    spend: "$8.00",
    viewers: 12,
    cost_per: "$0,02",
    now: 30,
  },
];

const FirstTimeViewers = () => {
  return (
    <>
      <div className="chart-container">
        {/* <div className="d-flex align-items-center justify-content-between"> */}
        <Stack
          className="w-100"
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="p" className="text5">
            Charts
          </Typography>
          <div>
            <Form.Select
              className="select-total"
              aria-label="Default select example"
            >
              {/* <option>--select--</option> */}
              {Years?.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </div>
        </Stack>

        {/* </div> */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div className="chart-card">
              <Stack
                className="py-4"
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="point-ftv bg-violet"></div>
                  <Typography variant="p" className="text">
                    First Time Viewers
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="point-ftv bg-blue"></div>
                  <Typography variant="p" className="text">
                    Engaged Viewers
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="point-ftv bg-navyblue"></div>
                  <Typography variant="p" className="text">
                    First Time Consumers
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="point bg-lightgrey"></div>
                  <Typography variant="p" className="text">
                    Freq Diners
                  </Typography>
                </Stack>
              </Stack>
              <CustomCharts />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="chart-card">
              <Stack
                className="py-4 justify-content-end"
                direction="row"
                spacing={2}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="point-ftv bg-orange"></div>
                  <Typography variant="p" className="text">
                    Spend
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div className="point-ftv bg-green"></div>
                  <Typography variant="p" className="text">
                    Revenues
                  </Typography>
                </Stack>
              </Stack>
              <CustomCharts />
            </div>
          </Grid>
        </Grid>
      </div>
      <table style={{ width: "100%" }}>
        <tr className="accordin-container accordin-container-align">
          <th>
            <Stack direction="row" spacing={2}>
              <img src={segmentIcon} alt="error" />
              <Typography variant="p" className="text5 align">
                Segments
              </Typography>
            </Stack>
          </th>
          <th>
            <Stack direction="row" spacing={2}>
              <img src={spendArrowIcon} alt="error" />
              <Typography className="text5 align">Spend</Typography>
            </Stack>
          </th>
          <th>
            <Stack direction="row" spacing={2}>
              <img src={viewersIcon} alt="error" />
              <Typography variant="p" className="text5 align">
                Viewers
              </Typography>
            </Stack>
          </th>
          <th>
            <Stack direction="row" spacing={2}>
              <img src={costIcon} alt="error" />
              <Typography variant="p" className="text5 align">
                Cost Per
              </Typography>
            </Stack>
          </th>
        </tr>
        <tr className="accordin-container accordin-container-align">
          <th>
            <Typography variant="p" className="text5-ftv align">
              Total
            </Typography>
          </th>
          <th>
            <Typography variant="p" className="text5-ftv align">
              4.24399e-314
            </Typography>
          </th>
          <th>
            <Stack direction="row" spacing={2}>
              <Typography className="text5-ftv align">375</Typography>
              <img src={trendUp} alt="error" />
            </Stack>
          </th>
          <th>
            <Typography variant="p" className="text5-ftv align">
              $0,80
            </Typography>
          </th>
        </tr>

        {/* <div className="accordin-container accordin-container-align">
        <Stack direction="row" spacing={2}>
          <img src={segmentIcon} alt="error" />
          <Typography className="text5 align">Segments</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <img src={spendArrowIcon} alt="error" />
          <Typography className="text5 align">Spend</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <img src={viewersIcon} alt="error" />
          <Typography className="text5 align">Viewers</Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <img src={costIcon} alt="error" />
          <Typography className="text5 align">Cost Per</Typography>
        </Stack>
      </div> */}
        {/* <div className="accordin-container accordin-container-align">
        <Typography className="text5 align">Total</Typography>
        <Typography className="text5 align">4.24399e-314</Typography>
        <Stack direction="row" spacing={2}>
          <Typography className="text5 align">375</Typography>
          <img src={trendUp} alt="error" />
        </Stack>
        <Typography className="text5 align">$0,80</Typography>
      </div> */}
        <Accordin data={data} />
      </table>
    </>
  );
};

export default FirstTimeViewers;
