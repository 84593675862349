import React, { useEffect, useState } from "react";
import Firsttimeviewer from "../../components/mycustomer/Firsttimeviewer";
import Engagementviewer from "../../components/mycustomer/Engagementviewer";
import Firsttimeconsumer from "../../components/mycustomer/Firsttimeconsumer";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import Freqdinner from "../../components/mycustomer/Freqdinner";
import NumberofViewer from "../../components/mycustomer/NumberofViewer";
import Customerdata from "./partials/Customerdata";
import Persnalattributes from "./partials/Persnalattributes";
import Campaigns from "./partials/Campaigns";

const Mycustomer = () => {
  const lastpageurl = useSelector((state) => state.main.store?.lastpageurl);
  const is_unified_web = useSelector(
    (state) => state.main?.store?.users?.is_unified_web
  );
  const [activetab, setActivetab] = useState(
    is_unified_web ? "firsttimeviewercus" : "scribenumber"
  );

  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  useEffect(() => {
    if (currentsubmenu) {
      setActivetab(currentsubmenu);
    }
  }, [currentsubmenu]);
  return (
    <>
      <div className="headerpadding">
        <div className="full-width">
          {activetab === "firsttimeviewercus" && <Customerdata />}
          {activetab === "engagedviewercus" && <Persnalattributes />}
          {activetab === "firsttimeconsumercus" && <Campaigns />}
          {/* {activetab === "freqdinnercus" && <Freqdinner />}
          {activetab === "scribenumber" && <NumberofViewer />} */}
        </div>
      </div>
    </>
  );
};

export default Mycustomer;
