import React from "react";
import cardtrial from "../../../assets/images/info/image3.png";

const Cardthree = ({ handleFocus }) => {
  return (
    <>
      <div className="suggestion-card-container">
        <div className="suggetion-inner-container">
          <div className="image-container">
            <img src={cardtrial} className="w-100" />
          </div>
          <div className="content-container">
            <h6 className="sugesstion-class">
              <span className="text-white">
                If your location offers al fresco dining, rooftop views, or
                perhaps a serene garden setting,
              </span>{" "}
              highlight these features prominently.{" "}
            </h6>
            <div className="btn-container">
              <span
                onClick={() => {
                  handleFocus("skip");
                }}
                className="skip-btn"
              >
                Skip
              </span>
              <button
                onClick={() => {
                  handleFocus(4);
                }}
                className="byteready-btn byteready-primary"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cardthree;
