import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

const Sliders = ({ allads, sliderRef }) => {
  // const sliderRef = useRef(null);
  // const handleNext = () => {
  //   sliderRef.current.swiper.slideNext();
  // };
  return (
    <div className="right-card">
      <Swiper
        ref={sliderRef}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        navigation={false}
      >
        {allads.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="card custom-card">
              <img src={item.image} className="w-100" />
              <div className="content-area">
                <p className="font-small">{item.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Sliders;
