import React, { useEffect, useState } from "react";
import "./editpoststyle.css";
import bgimg from "../../../../assets/images/create-calender/right-img.png";
import backbtn from "../../../../assets/images/mycustomer/back-btn.png";
import MobileWrapper from "../../../Common/MobileView/MobileWrapper";
import deletebtn from "../../../../assets/images/create-calender/delete-btn.png";
import dummypostimg from "../../../../assets/images/create-calender/dummy-image.png";
import Deletepopup from "./Deletepopup";
import Loadericon from "../../../Common/Loadericon";
import { deletesocialmediaPostAPI } from "../../../../apis/auth";
import { useSelector } from "react-redux";

const Editpost = ({
  data,
  handleback,
  handleChanges,
  handlesave,
  generatecaption,
  handleImageupload,
  loader,
}) => {
  const [opendelete, setOpendelete] = useState("");
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const deleteopenfunc = (data) => {
    setOpendelete(data);
  };
  const handlecanel = () => {
    setOpendelete("");
  };
  const handledelete = () => {
    let payload = {
      profile_id: profile_info?.profile_id,
      caption_id: opendelete?.id,
    };
    console.log(payload);
    deletesocialmediaPostAPI(payload)
      .then((res) => {
        if (res.status) {
          handleback();
          setOpendelete("");
        } else {
          handleback();
          setOpendelete("");
        }
      })
      .catch((rej) => {
        console.log(rej);
        setOpendelete("");
      });
  };
  return (
    <>
      <div className="row edit-post-main position-relative">
        {loader && <Loadericon />}
        <div className="col-md-6 ">
          <div className="py-5 backbtn-container">
            <img onClick={handleback} src={backbtn} />
          </div>
          <div className="content-padding-left">
            <div className="py-5">
              <h1 className="white-subtitle">Edit</h1>
              <p className="editpost-description">Description</p>
              <textarea
                className="description-textarea"
                onChange={(e) => {
                  handleChanges(e.target.value);
                }}
              >
                {data?.cuisine_story}
              </textarea>
              <div className="py-1">
                <button
                  onClick={handlesave}
                  className="byteready-btn byteready-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="post-right position-relative">
            <img src={bgimg} className="w-100 editpost-bg" />
            <div className="post-mobile-design">
              <div className="spacing-post">
                <MobileWrapper
                  alldata={data}
                  outputImage={""}
                  outputtext={""}
                  handleImageupload={handleImageupload}
                  generatecaption={generatecaption}
                />
                <div className="deletebtn-content">
                  <img
                    src={deletebtn}
                    onClick={deleteopenfunc}
                    className="deletebtn-container"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Deletepopup
        open={opendelete}
        handlecanel={handlecanel}
        handledelete={handledelete}
      />
    </>
  );
};

export default Editpost;
