import React from "react";
import "./Googleads.css";
import circleIcon from "../../../assets/images/circleInstaImg.png";
import EditButton from "../../Common/EditButton";
import {
  storeInLocalStore,
  selectedPlatform,
} from "../../../redux/reducers/mainSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GoogleView = ({ data, iconurl }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleEdit = (data) => {
    let tempdata = { ...data?.alldata, selectedplatform: "google ad" };
    dispatch(storeInLocalStore({ ediatablepost: tempdata }));
    dispatch(selectedPlatform("google"));
    navigate("/edit-posts");
  };
  return (
    <>
      <div className="googleads-container">
        <div
          className="google-post"
          style={{
            backgroundImage: `url(${data?.imgUrl})`,
          }}
        >
          {!data?.alldata?.is_posted && (
            <div className="editbtn">
              <EditButton
                onClick={() => {
                  handleEdit(data);
                }}
                style={{ margin: "15px" }}
              />
            </div>
          )}
          <p className="event-title"> {data.event}</p>
          {/* <img src={iconurl || circleIcon} className="logoicon" alt="circleError" /> */}
        </div>
        <div className="description">{data.description}</div>
      </div>
    </>
  );
};

export default GoogleView;
