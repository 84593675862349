import React, { useState, useEffect } from "react";
import ReviewsMain from "../../components/Reviewsmain/ReviewsMain";
import Savedpost from "../../components/Reviewsmain/Savedpost";
import Unifiedreferencebanner from "../../components/Reviewsmain/Unifiedreferencebanner";
import Trialended from "../../components/Reviewsmain/Trialended";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DashboardLoader from "../../components/Common/DashboardLoader";
import {
  getAllCaptionpost,
  getAllNotificationAPI,
  getdetailedpostAPI,
  commentreplyAPI,
  commentLikeAPI,
  deletesocialmediaPostAPI,
  commentReadAPI,
} from "../../apis/auth";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import Loadericon from "../../components/Common/Loadericon";
import Deletepopup from "../../components/CreateCalender/monthlycalender/Editpost/Deletepopup";
const cardData = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const Socialmediapost = () => {
  const users = useSelector((state) => state.main?.store?.users);
  const dispatch = useDispatch();
  const [savepostloader, setSavepostloader] = useState(false);
  const [savepostsecondloader, setSavepostsecondloader] = useState(false);
  const [reviewmainloader, setReviewmainloader] = useState(false);
  const [reviewsecondloader, setReviewsecondloader] = useState(false);
  const newdate = moment().format("YYYY-MM-DD");
  const tempCurrentDate = moment().format("YYYY-MM-DD");
  const profile_info = useSelector((state) => state.main.store?.profile_data);
  const [monthData, setMonthdata] = useState(cardData);
  const [currentdesign, setCurrentdesign] = useState("insta");
  const [currentdate, setCurrentDate] = useState(tempCurrentDate);
  const [todayspost, settopdayspost] = useState();
  const [todayspostnew, settopdayspostnew] = useState();
  const [isActive, setIsActive] = useState("all");
  const [socialmediaView, setSocialmediaView] = useState("all");
  const [allnotificationConstant, setAllNotificationConstant] = useState([]);
  const [allnotification, setAllNotificationlist] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentcaptionid, setCurrentcaptionid] = useState();
  const [captionid, setCaptionid] = useState({
    id: "",
    platform: "",
    mgsid: "",
  });
  const [commentdata, setCommentData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState();
  const [searchtext, setSearchtext] = useState("");
  const [detailedpost, setDetailedpost] = useState();
  const [mindates, setMindate] = useState();
  const [maxDates, setMaxdates] = useState();
  const [opendelete, setOpendelete] = useState("");
  const [reviewpostComment, setReviewpostcomment] = useState([]);
  const changedesign = (data) => {
    setCurrentdesign(data);
  };
  const fetchData = async () => {
    setSavepostloader(true);
    getAllCaptionpost({
      profile_id: profile_info?.profile_id,
      number_of_day: 30,
    })
      .then((res) => {
        if (res.status == "success") {
          setSavepostloader(false);
          setMindate(res.captions[0].date);
          setMaxdates(res.captions[res.captions.length - 1].date);
          setMonthdata(res.captions);
          let temporigin = {
            date: res?.captions[0]?.date,
            output_image: res?.captions[0]?.prompt_output_img_url,
            output_text: res?.captions[0]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ origin_output: temporigin }));
          let templocation = {
            date: res?.captions[1]?.date,
            output_image: res?.captions[1]?.prompt_output_img_url,
            output_text: res?.captions[1]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ location_output: templocation }));
          let tempcuisine = {
            date: res?.captions[2]?.date,
            output_image: res?.captions[2]?.prompt_output_img_url,
            output_text: res?.captions[2]?.prompt_output_text,
          };
          dispatch(storeInLocalStore({ cuisine_output: tempcuisine }));
        } else {
          setMonthdata(cardData);
          setSavepostloader(false);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
        setMonthdata(cardData);
        setSavepostloader(false);
      });
  };
  const handledatechange = (e) => {
    setCurrentDate(e.target.value);
  };
  const handledeletemain = () => {
    let payload = {
      profile_id: profile_info?.profile_id,
      caption_id: opendelete?.id,
    };
    console.log(payload);
    deletesocialmediaPostAPI(payload)
      .then((res) => {
        if (res.status) {
          fetchData();
          setOpendelete("");
        } else {
          fetchData();
          setOpendelete("");
        }
      })
      .catch((rej) => {
        console.log(rej);
        setOpendelete("");
        fetchData();
      });
  };
  const handledelete = (data) => {
    setOpendelete(data);
  };
  const handledeletecancle = () => {
    setOpendelete("");
  };
  // Review Part
  const handleActiveSocialMedia = (params) => {
    setIsActive(params);
    setAllNotificationlist(allnotificationConstant);
  };
  const handleNotificationClick = (params) => {
    const index = allnotification.findIndex(
      (item) => item.msgid === params.msgid
    );
    setCurrentIndex(index);
    setCaptionid({
      id: params.caption_id || "",
      platform: params.platform,
      mgsid: params.msgid,
    });
    setCurrentcaptionid(params.msgid);
    setSocialmediaView(params);
  };

  const socialMediaCards = allnotification.filter((card) => {
    if (isActive === "all") {
      return true;
    } else {
      return card.platform === isActive;
    }
  });
  const getAllnotificationlist = () => {
    setReviewmainloader(true);
    getAllNotificationAPI({
      profile_id: profile_info?.profile_id,
      platform: isActive,
      isall: true,
      page: 1,
    })
      .then((res) => {
        if (res.status) {
          if (res?.comments.length > 0) {
            setCaptionid({
              id: res.comments[0].caption_id,
              platform: res.comments[0].platform,
            });
            setCurrentcaptionid(res.comments[0].msgid);
            setAllNotificationlist(res.comments);
            setAllNotificationConstant(res.comments);
            setCurrentIndex(0);
            setSearchtext("");
            setReviewmainloader(false);
          } else {
            setCaptionid("");
            setDetailedpost("");
            setCurrentcaptionid("");
            setAllNotificationlist([]);
            setAllNotificationConstant([]);
            setReviewpostcomment([]);
            setCurrentIndex(0);
            setSearchtext("");
            setReviewmainloader(false);
          }
        } else {
          setCaptionid("");
          setDetailedpost("");
          setCurrentcaptionid("");
          setAllNotificationlist([]);
          setAllNotificationConstant([]);
          setReviewpostcomment([]);
          setCurrentIndex(0);
          setSearchtext("");
          setReviewmainloader(false);
        }
      })
      .catch((rej) => {
        console.log(rej);
        setReviewmainloader(false);
      });
  };
  const handleCurrentPost = (captionid) => {
    setLoader(true);
    getdetailedpostAPI({
      profile_id: profile_info?.profile_id,
      caption_id: captionid.id,
      platform: captionid.platform,
    })
      .then((res) => {
        if (res.status) {
          setDetailedpost(res.caption_with_matrics);
          getAllNotificationAPI({
            profile_id: profile_info?.profile_id,
            platform: captionid.platform,
            isall: false,
            page: 1,
            caption_id: captionid.id,
          })
            .then((res) => {
              if (res.status) {
                if (res.status) {
                  setReviewpostcomment(res.comments);
                  setCaptionid({
                    id: "",
                    platform: "",
                  });
                  setLoader(false);
                }
              } else {
                setLoader(false);
              }
            })
            .catch((rej) => {
              console.log(rej);
              setLoader(false);
            });
        } else {
          setDetailedpost("");
          setLoader(false);
        }
      })
      .catch((rej) => {
        console.log(rej);
        setDetailedpost("");
        setLoader(false);
      });
  };
  useEffect(() => {
    setSocialmediaView(isActive);
  }, [isActive]);
  useEffect(() => {
    getAllnotificationlist();
  }, [isActive]);
  useEffect(() => {
    if (captionid.id) {
      handleCurrentPost(captionid);
    }
  }, [captionid]);
  const addreply = (payload) => {
    setLoader(true);
    commentreplyAPI(payload)
      .then((res) => {
        setCaptionid({
          id: payload.caption_id,
          platform: payload.platform,
        });
        setLoader(false);
        fetchData();
        getAllnotificationlist();
      })
      .catch((rej) => {
        setLoader(false);
        console.log(rej);
      });
  };
  const likeanddislikefunc = (likedata) => {
    let payload = {
      profile_id: profile_info?.profile_id,
      caption_id: likedata?.caption_id,
      msgid: likedata?.msgid,
      like: likedata.is_liked ? 0 : 1,
    };

    setLoader(true);
    commentLikeAPI(payload)
      .then((res) => {
        if (res.status) {
          setCaptionid({
            id: likedata.caption_id,
            platform: likedata.platform,
          });
          setLoader(false);
          fetchData();
          getAllnotificationlist();
        }
      })
      .catch((rej) => {
        setLoader(false);
        console.log(rej);
      });
  };
  const handlesearch = () => {
    if (searchtext) {
      const results = allnotificationConstant.filter((item) => {
        return item.name.toLowerCase().includes(searchtext.toLowerCase());
      });

      if (results.length > 0) {
        setCaptionid({
          id: results[0].caption_id,
          platform: results[0].platform,
        });
        setCurrentcaptionid(results[0].msgid);
        setAllNotificationlist(results);
        setCurrentIndex(0);
      } else {
        setAllNotificationlist([]);
      }
    } else {
      setAllNotificationlist(allnotificationConstant);
    }
  };
  const handleprevious = () => {
    const index = allnotification.findIndex(
      (item) => item.msgid === currentcaptionid
    );
    setCurrentIndex(index - 1);
    if (index > 0) {
      setCurrentcaptionid(allnotification[index - 1].msgid);
      setCaptionid({
        id: allnotification[index - 1].caption_id,
        platform: allnotification[index - 1].platform,
      });
    }
  };
  const handlenext = () => {
    const index = allnotification.findIndex(
      (item) => item.msgid === currentcaptionid
    );
    setCurrentIndex(index + 1);
    if (allnotification.length > index) {
      setCurrentcaptionid(allnotification[index + 1].msgid);
      setCaptionid({
        id: allnotification[index + 1].caption_id,
        platform: allnotification[index + 1].platform,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (monthData.length > 0) {
      let currentDatepost = monthData.filter(
        (item) => item.date == currentdate
      )[0];
      settopdayspost(currentDatepost);
    }
  }, [currentdate, monthData]);
  useEffect(() => {
    if (todayspost?.id) {
      // setCaptionsavedid();
      let data = {
        id: todayspost?.id,
        platform: currentdesign,
      };
      handletodayspost(data);
    }
  }, [todayspost, currentdesign]);
  const handletodayspost = (data) => {
    setLoader(true);
    getdetailedpostAPI({
      profile_id: profile_info?.profile_id,
      caption_id: data?.id,
      platform: data?.platform,
    })
      .then((res) => {
        if (res.status) {
          settopdayspostnew(res.caption_with_matrics);
          getAllNotificationAPI({
            profile_id: profile_info?.profile_id,
            platform: data?.platform,
            isall: false,
            page: 1,
            caption_id: data?.id,
          })
            .then((res) => {
              if (res.status) {
                if (res.status) {
                  setCommentData(res.comments);
                  setLoader(false);
                } else {
                  setLoader(false);
                }
              } else {
                setLoader(false);
              }
            })
            .catch((rej) => {
              console.log(rej);
              setLoader(false);
            });
        } else {
          settopdayspostnew(todayspost);
          setLoader(false);
        }
      })
      .catch((rej) => {
        console.log(rej);
        settopdayspostnew(todayspost);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (captionid?.mgsid) {
      commentReadAPI({
        profile_id: profile_info?.profile_id,
        caption_id: captionid?.id,
        msgid: captionid?.mgsid,
        platform: captionid?.platform,
      })
        .then((res) => {})
        .catch((rej) => {});
    }
  }, [captionid?.mgsid]);
  return (
    <>
      <div>
        {savepostloader ||
        savepostsecondloader ||
        reviewmainloader ||
        reviewsecondloader ? (
          <DashboardLoader />
        ) : (
          <>
            <Savedpost
              changedesign={changedesign}
              currentdesign={currentdesign}
              currentdate={currentdate}
              handledatechange={handledatechange}
              todayspost={todayspostnew}
              monthData={monthData}
              handledelete={handledelete}
              trialactive={users?.is_trial || false}
              setCurrentDate={setCurrentDate}
              commentdata={commentdata}
              mindates={mindates}
              maxDates={maxDates}
              likeanddislikefunc={likeanddislikefunc}
              addreply={addreply}
            />
            <ReviewsMain
              isActive={isActive}
              handleActiveSocialMedia={handleActiveSocialMedia}
              searchtext={searchtext}
              handlesearch={handlesearch}
              socialMediaCards={socialMediaCards}
              socialmediaView={socialmediaView}
              detailedpost={detailedpost}
              addreply={addreply}
              handleprevious={handleprevious}
              handlenext={handlenext}
              currentIndex={currentIndex}
              allnotification={allnotification}
              likeanddislikefunc={likeanddislikefunc}
              currentcaptionid={currentcaptionid}
              loader={loader}
              setSearchtext={setSearchtext}
              handleNotificationClick={handleNotificationClick}
              reviewpostComment={reviewpostComment}
            />
            {/* {(!users?.is_unified_web || users?.subscribe_status !== "paid") && (
              <Unifiedreferencebanner />
            )} */}
          </>
        )}
        <Deletepopup
          open={opendelete}
          handlecanel={handledeletecancle}
          handledelete={handledeletemain}
        />
      </div>
    </>
  );
};

export default Socialmediapost;
