import React from "react";
import socialbanner from "../../../assets/images/home/customer.png";
const Mycustomer = () => {
  return (
    <div className="full-width mycustomer-container py-5">
      <div className="row align-items-center">
        <div className="col-md-6 zindex">
          <div className="image-padding-left">
            <img src={socialbanner} className="w-100" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-padding-right">
            <h1 className="title">
              My <br /> <span className="bold-inner">Customer</span>
            </h1>
          </div>
          <div className="custom-bg overflow-shadow margin-left-shadow">
            <div className="actual-content-area margin-left-shadow-half">
              <div className="content-padding-right">
                <p>
                  The Story Craft system operates on the power of real-time
                  customer data. Witness the system in action as it identifies
                  customers across diverse segments, seamlessly converting
                  viewers into buyers and buyers into loyal, repeat customers.
                </p>
                <p>
                  The system not only simplifies the creation, publication, and
                  management of your brand's stories but also provides a clear
                  and comprehensive view of your entire customer engagement
                  journey : who they are, where they are coming from, the cost
                  associated with acquiring each customer, and the lifetime
                  value of a customer.
                </p>
                <p>
                  This is the goldmine within your business, empowering you to
                  make informed decisions, cultivate exceptional customer
                  experiences, and drive growth like never before
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mycustomer;
