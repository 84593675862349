import React, { useEffect } from "react";
import "./paymentstyle.css";
import macimage from "../../assets/images/Payment/mac-payment.png";
import { Link, useNavigate } from "react-router-dom";
import { fetchProfileAPI } from "../../apis/auth";
import { useDispatch, useSelector } from "react-redux";
import { fetchprofile } from "../../CustomHook/addressUtills";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
const Paymentdone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile_data = useSelector((state) => state.main.store?.profile_data);
  const users = useSelector((state) => state.main.store?.users?.id);
  useEffect(() => {
    let payload = {};
    if (profile_data?.profile_id) {
      payload = { profile_id: profile_data?.profile_id };
    } else {
      payload = { user_id: users };
    }
    fetchprofile(payload)
      .then((res) => {
        if (res) {
          dispatch(
            storeInLocalStore({
              profile_data: res?.dispachabledata?.profile_data,
            })
          );
          dispatch(
            storeInLocalStore({
              users: res?.dispachabledata?.users,
            })
          );
          setTimeout(() => {
            navigate("/");
          }, 7000);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  }, []);
  return (
    <div className="full-width headerpadding boost-congratulation ">
      <div className="content-padding-both py-5">
        <div className="row py-5">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <div className="card custom-card">
                <div className="image-container">
                  <img src={macimage} className="w-100" />
                </div>
                <h3 className="congratulation-text">Congratulations!</h3>
                <p className="congratulation-message">Your Payment is done!</p>
                <div className="image-container">
                  <Link to={"/"} className="byteready-btn byteready-primary">
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymentdone;
