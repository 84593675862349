import React, { useEffect, useState } from "react";
import { SignUp } from "@clerk/clerk-react";

const Newsignup = () => {
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("?after_sign_up_url")) {
      const updatedUrl = currentUrl.split("#/")[0].split("?")[0];
      window.history.replaceState(null, null, updatedUrl);
    }
  }, []);
  return (
    <>
      <div className="full-width headerpadding loging signup-page">
        <div className="content-padding-both ">
          <div className="py-5">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title-wrap">
                  <div className="">
                    <h1 className="title extra-font">
                      Create your <br />
                      account
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                      <SignUp
                        signInUrl="/login"
                        path="/signup"
                        afterSignUpUrl={"/redirecting"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsignup;
