import { Button } from "@mui/material";
import React from "react";
import style from "./CustomButtonStyle.js";

const CustomButton = ({ sx, onClick, label, disabling}) => {
  return (
    <>
      <Button
        sx={{ ...style.btn, ...sx}}
        onClick={onClick}
        className={disabling ? `disabled` : ""}
      >
        {label}
      </Button>
    </>
  );
};

export default CustomButton;
