import React, { useState } from "react";
import { Box } from "@mui/material";
import style from "./SwitchButton.js";
import FacebookIcon from "../../../assets/SVG/fbIcon.svg";
import ActiveFacebookIcon from "../../../assets/SVG/activeFbicon.svg";
import InstgramIconactive from "../../../assets/SVG/IstaIcon.svg";
import InstagramNormal from "../../../assets/SVG/IstaIconnormal.svg";

const SwitchButton = ({ mobilePhone, designSwitch, customStyle }) => {
  const [isActive, setIsActive] = useState(1);

  const toggleSwitch = () => {
    if (isActive === 1) {
      setIsActive(2);
      designSwitch("fb");
    } else {
      setIsActive(1);
      designSwitch("insta");
    }
  };

  return (
    <Box
      sx={
        mobilePhone
          ? style.mainContainerMob
          : { ...style.mainContainer, ...customStyle }
      }
    >
      <Box
        onClick={toggleSwitch}
        sx={
          mobilePhone
            ? isActive === 1
              ? style.activeCircleMob
              : style.circleMob
            : isActive === 1
            ? style.activeCircle
            : style.circle
        }
      >
        <img
          style={{ fill: isActive === 1 ? "#eb2426" : "#FCFCFC" }}
          src={
            mobilePhone
              ? isActive === 1
                ? InstagramNormal
                : InstgramIconactive
              : isActive === 1
              ? InstgramIconactive
              : InstagramNormal
          }
          alt="InstgramIcon"
        />
      </Box>
      <Box
        onClick={toggleSwitch}
        sx={
          mobilePhone
            ? isActive === 2
              ? style.activeCircleMob
              : style.circleMob
            : isActive === 2
            ? style.activeCircle
            : style.circle
        }
      >
        <img
          style={{ fill: isActive === 2 ? "#eb2426" : "#FCFCFC" }}
          src={
            mobilePhone
              ? isActive === 2
                ? FacebookIcon
                : ActiveFacebookIcon
              : isActive === 2
              ? ActiveFacebookIcon
              : FacebookIcon
          }
          alt="FacebookIcon"
        />
      </Box>
    </Box>
  );
};

export default SwitchButton;
