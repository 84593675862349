import React from "react";
import scribeimage from "../../../assets/images/home/scribe-automated.png";

const Automated = () => {
  return (
    <>
      <div className="full-width py-5">
        <div className="row align-items-center">
          <div className="col-md-6 zindex">
            <div className="image-padding-left">
              <img src={scribeimage} className="w-100" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-padding-right">
              <h1 className="title px-1">
                Automated <span className="bold-inner">Posting</span>
              </h1>
            </div>
            <div className="cutom-bg overflow-shadow margin-left-shadow">
              <div className="actual-content-area margin-left-shadow-half">
              <div className="content-padding-right">
                <p>
                  Our platform automates daily postings to your social media
                  channels, ensuring a consistent and engaging online presence.
                </p>
              </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Automated;
