import React, { useEffect, useState } from "react";
import Main from "../components/CreateCalender/Main";
import Tabview from "../components/CreateCalender/Tabview";
import { useSelector } from "react-redux";
import NewEvents from "../components/NewEvents/NewEvents";

const Createcalender = () => {
  const [activetab, setActivetab] = useState();
  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  useEffect(() => {
    console.log(currentsubmenu);
    if (currentsubmenu) {
      setActivetab(currentsubmenu);
    }
  }, [currentsubmenu]);
  return (
    <>
      {activetab == "createmysmcalender" && <Tabview />}
      {activetab == "createmypaidads" && <NewEvents />}
    </>
  );
};

export default Createcalender;
