const style = {
    mainWrapper: {
        position: 'relative',
        width: "max-content",
        backgroundSize: "contain",
        height: "570px",
        width: "279px",
        margin:'0px 8px'
    },
    googleHeading: {
        background: '#fff',
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: "40%",
        left: '5%',
        padding: "10px 0px",
        width: "252px"
    },
    post: {
        height: '200px',
        width: '252px',
        position: 'absolute',
        left: '5%',
        top: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    postHeadingText: {
        textAlign: "center",
        position: "absolute",
        color: "#fff",
        fontSize: "25px",
        fontWeight: "900",
        fontFamily: "WhyteInktrap-Black",
        width: "100%"
    }
}
export default style