import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./EventCards.css";
import { Box, Typography } from "@mui/material";
import bookMarkImg from "../../../assets/SVG/bookmark.svg";
import selectBookMarkImg from "../../../assets/SVG/Select_bookmark.svg";
import bookmarkExtIcon from "../../../assets/SVG/bookmark_ext.svg";
import editImg from "../../../assets/SVG/edit.svg";

const EventCard = forwardRef(function EventCard(
  { handleCounter, data, handlePopover },
  ref
) {
  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(0);
  useImperativeHandle(
    ref,
    () => {
      return {
        goToNext() {
          const remainingItems = items.length - currentIndex - itemsToShow;
          if (remainingItems > 0) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
          }
        },
        goToPrevious() {
          if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
          }
        },
      };
    },
    []
  );
  const goToNext = () => {
    const remainingItems = items.length - currentIndex - itemsToShow;
    if (remainingItems > 0) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
    }
  };

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const updateItemsToShow = () => {
    if (window.innerWidth <= 480) {
      setItemsToShow(1);
    } else if (window.innerWidth <= 768) {
      setItemsToShow(3);
    } else {
      setItemsToShow(5);
    }
  };

  useEffect(() => {
    updateItemsToShow();
    window.addEventListener("resize", updateItemsToShow);
    return () => {
      window.removeEventListener("resize", updateItemsToShow);
    };
  }, []);
  useEffect(() => {
    setItems(data);
  }, [data]);

  return (
    <div className="event-container">
      <button className="prevButton d-none" onClick={goToPrevious}>
        Previous
      </button>
      <button className="nextButton d-none" onClick={goToNext}>
        Next
      </button>
      <div className="eventmain">
        {items &&
          items.length > 0 &&
          items
            .slice(currentIndex, currentIndex + itemsToShow)
            .map((element, i) => (
              <div className="event" key={i + 1}>
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${element?.imgUrl})`,
                    }}
                    className={`image-container ${i % 2 !== 0 && "margin-top"}`}
                  >
                    {/* <img
                      className="bookmarkcontainer"
                      src={
                        element?.isBookMark ? bookMarkImg : selectBookMarkImg
                      }
                      onClick={(e) => {
                        handleCounter(i);
                        e.stopPropagation();
                      }}
                    /> */}
                    <img
                      onClick={() => {
                        handlePopover(element);
                      }}
                      className="editbutton"
                      src={editImg}
                    />
                  </div>
                  <p className="title">{element?.text}</p>
                  {/* {element.editBtn && (
                    <p
                      className="edit-text"
                      onClick={() => handlePopover(element)}
                    >
                      Edit Text
                    </p>
                  )} */}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
});

export default EventCard;
