import React, { useEffect, useState } from "react";
import "./Reviewstyle.css";
import Loadericon from "../Common/Loadericon";
import freetrial from "../../assets/images/header/trial-banner.png";
import Socialmediaswitch from "../commoncomponents/Socialmediaswitch";
import Calenderdatepickermain from "../commoncomponents/Calenderdatepickermain";
import Facebook from "../../components/commoncomponents/socialview/Facebook";
import Instagram from "../../components/commoncomponents/socialview/Instagram";
import deletebtn from "../../assets/images/create-calender/delete-btn.png";
import Singlepost from "./singlepost/Singlepost";
import { getAllCaptionpost, getdetailedpostAPI } from "../../apis/auth";
import { storeInLocalStore } from "../../redux/reducers/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Savedpost = ({
  changedesign,
  currentdesign,
  currentdate,
  handledatechange,
  todayspost,
  monthData,
  handledelete,
  trialactive,
  setCurrentDate,
  commentdata,
  mindates,
  maxDates,
  addreply,
  likeanddislikefunc,
}) => {
  const today_date = moment().format("YYYY-MM-DD");
  return (
    <>
      <div className="saved-post py-3 postion-relative">
        <div className="row  py-3 heading-background">
          <div className="col-md-12">
            <div className="content-padding-both">
              <div className="position-relative">
                <h1 className="title bold-inner">My Social Media</h1>
                {trialactive && (
                  <img src={freetrial} className="trail-banner" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-md-12">
            <div className="content-padding-both">
              <div className="d-flex justify-content-between align-items-center">
                <div className="item">
                  <Socialmediaswitch
                    handlechange={changedesign}
                    activedesign={currentdesign}
                  />
                </div>
                <div className="item">
                  <Calenderdatepickermain
                    handlechange={changedesign}
                    activedesign={currentdesign}
                    currentdate={currentdate}
                    handledatechange={handledatechange}
                    maxDates={maxDates}
                    mindates={mindates}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3 review-bg">
          <div className="content-padding-both">
            <div className="row">
              <div className="col-md-6">
                <div className="content-container">
                  <h2 className="title">Today’s posts</h2>
                  <div className="view-container">
                    <div className="todays-post">
                      <Singlepost
                        data={todayspost}
                        commentdata={commentdata}
                        likeanddislikefunc={likeanddislikefunc}
                        addreply={addreply}
                        currentdesign={currentdesign}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-container">
                  <h2 className="title">Upcoming posts</h2>
                  <div className="view-container">
                    <div className="all-posts">
                      {monthData.length > 0 &&
                        monthData.map((item, index) => {
                          let postdate = moment(item.date).format("MM/DD");
                          return (
                            <div
                              className="item"
                              key={index}
                              onClick={() => {
                                setCurrentDate(item?.date);
                              }}
                            >
                              <div className="post-container">
                                <div
                                  className={`post-inner ${
                                    item.date == currentdate ? "active" : ""
                                  }`}
                                >
                                  <div className="post-card">
                                    {currentdesign == "insta" ? (
                                      <>
                                        <Instagram data={item} />
                                      </>
                                    ) : (
                                      <>
                                        <Facebook data={item} />
                                      </>
                                    )}
                                    {!item?.posted && (
                                      <div className="editpost">
                                        <img
                                          onClick={(e) => {
                                            handledelete(item);
                                            e.stopPropagation();
                                          }}
                                          src={deletebtn}
                                          className="edit-btn"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <p className="post-date">{postdate}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Savedpost;
