import React from "react";
import yourstory from "../../../assets/images/home/Your-Story-Amplified.png";
const Yourstory = () => {
  return (
    <div className="full-width py-5">
      <div className="row align-items-center">
        <div className="col-md-6 zindex">
          <div className="image-padding-left">
            <img src={yourstory} className="w-100" />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="content-padding-right">
            <h1 className="title">
              Your Story, <br />
              <span className="bold-inner">Amplified</span>
            </h1>
            <h3 className="h3-title-while">
              AI-Powered Narratives that Resonate
            </h3>
          </div>
          <div className="custom-bg overflow-shadow margin-left-shadow">
            <div className="actual-content-area margin-left-shadow-half">
              <div className="content-padding-right">
                <p className="text-design">
                Let AI unleash the power of your brand's story. Craft captivating narratives that authentically reflect your unique identity and resonate with your audience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yourstory;
