import React from "react";

const Subheader = ({ menuname, currentmenu, handleactivemenu }) => {
  return (
    <div>
      <div className="header-down">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-item two">
                <ul className="submenu">
                  {menuname.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`${
                          item.key === currentmenu ? "active" : ""
                        }`}
                        onClick={() => {
                          handleactivemenu(item.key);
                        }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subheader;
