const baseApiUrl = "https://stagingapp.byteready.co";

const ALL_API_CONST = {
  auth: {
    login: baseApiUrl + "/api/v3/users/login",
    sendopturl: baseApiUrl + "/api/v3/users/send/otp",
    register: baseApiUrl + "/api/v3/users/register",
    otpverify: baseApiUrl + "/api/v3/users/verify",
    cuisine: baseApiUrl + "/api/v3/init/cuisine",
    createProfile: baseApiUrl + "/api/v3/users/profile/create",
    fetchprofileurl: baseApiUrl + "/api/v3/users/profile",
    forgetpasswordurl: baseApiUrl + "/api/v3/users/forgot/password",
    captions: baseApiUrl + "/api/v3/captions/generate",
    update: baseApiUrl + "/api/v1/captions/update",
    getallcaption: baseApiUrl + "/api/v3/captions/get_caption",
    uploadDish: baseApiUrl + "/api/v3/captions/upload/dish/menu",
    addDish: baseApiUrl + "/api/v3/captions/generate/added/dish/menu",
    tempPostdate: baseApiUrl + "/api/v3/captions/temp/post",
    getDishes: baseApiUrl + "/api/v3/captions/dish/menu?profile_id=",
    imageupload: baseApiUrl + "/api/v3/captions/upload/image?profile_id=",
    updateResturant: baseApiUrl + "/api/v3/users/profile/update",
    signUp: baseApiUrl + "/api/v3/users/register",
    updateCaption: baseApiUrl + "/api/v3/captions/update",
    homepagemaintemplate: baseApiUrl + "/api/v3/templates/main",
    childeventtemplate: baseApiUrl + "/api/v3/templates/child?mainId=",
    createAddunit: baseApiUrl + "/api/v3/campaign/addunit/create",
    updateAddunit: baseApiUrl + "/api/v3/campaign/addunit/update",
    savesinglecompain: baseApiUrl + "/api/v3/campaign/addunit/save?",
    uploadimageaddunit: baseApiUrl + "/api/v3/campaign/addunit/image/upload?",
    getsavedunit: baseApiUrl + "/api/v3/campaign/addunit?profile_id=",
    unitimageupload: baseApiUrl + "/api/v3/campaign/upload/addunit/image?",
    createcampaign: baseApiUrl + "/api/v3/campaign/manage/create",
    updatecampaign: baseApiUrl + "/api/v3/campaign/manage/update?",
    confirmcampaign: baseApiUrl + "/api/v3/campaign/manage/confirm?",
    getallcampaign: baseApiUrl + "/api/v3/campaign/all?",
    getsavedcampbyid: baseApiUrl + "/api/v3/campaign",
    updatesingleunit: baseApiUrl + "/api/v3/campaign/update/addunit",
    generateunitimage:
      baseApiUrl + "/api/v3/campaign/generate/addunit/description?",
    updatesocialmediabyname:
      baseApiUrl + "/api/v3/campaign/update/addunit/caption?",
    generateplatformDescription:
      baseApiUrl + "/api/v3/campaign/generate/addunit/caption?",
    deleteaddunit: baseApiUrl + "/api/v3/campaign/addunit?",
    varificationurl: baseApiUrl + "/api/v3/users/verify",
    confirmcalender: baseApiUrl + "/api/v3/campaign/confirm",

    firsttimeviewerurl:
      baseApiUrl + "/api/v3/analytics/info/first-time-viewers",
    engagedviewerurl: baseApiUrl + "/api/v3/analytics/info/engaged-viewers",
    consumerviewerurl:
      baseApiUrl + "/api/v3/analytics/info/first-time-customers",
    freqdinerurl: baseApiUrl + "/api/v3/analytics/info/freq-diners",
    totalurl: baseApiUrl + "/api/v3/analytics/info/all",
    targetsettingurl: baseApiUrl + "/api/v3/campaign/set/target",
    campaignpublish: baseApiUrl + "/api/v3/campaign/ads/publish",
    maxviewersurl: baseApiUrl + "/api/v3/campaign/manage/max-viewers/count?",
    getallnotificationurl: baseApiUrl + "/api/v3/captions/post/comments",
    getdetailsposturl: baseApiUrl + "/api/v3/captions/post/details",
    replycommneturl: baseApiUrl + "/api/v3/captions/post/reply",
    commentlikeAPI: baseApiUrl + "/api/v3/captions/fb/post/like",
    getspendsinfo: baseApiUrl + "/api/v3/analytics/spends/info",
    getspendhistoryurl: baseApiUrl + "/api/v3/analytics/spends/history",
    getfirsttimeviewergraphurl:
      baseApiUrl + "/api/v3/analytics/my-customers/first-time-viewers",
    getengagedviewergraphurl:
      baseApiUrl + "/api/v3/analytics/my-customers/engaged-viewers",
    getfirsttimeconsumergraphurl:
      baseApiUrl + "/api/v3/analytics/my-customers/first-time-customers",
    getfreqdinerurl: baseApiUrl + "/api/v3/analytics/my-customers/freq-diners",
    getallviewerdatachart: baseApiUrl + "/api/v3/analytics/metrics/all",
    getfirsttimeviewerchart:
      baseApiUrl + "/api/v3/analytics/metrics/first-time-viewers",
    getengagedviewerchart:
      baseApiUrl + "/api/v3/analytics/metrics/engaged-viewers",
    getfirsttimeconsumerurl:
      baseApiUrl + "/api/v3/analytics/metrics/first-time-customers",
    getfreqdinnercharturl: baseApiUrl + "/api/v3/analytics/metrics/freq-diners",
    getallrevenuecharturl:
      baseApiUrl + "/api/v3/analytics/metrics/spend-revenue/all",
    getcustomerdetailsurl: baseApiUrl + "/api/v3/analytics/customer/detail",
    getfirstimeviewerspendrevenueurl:
      baseApiUrl + "/api/v3/analytics/metrics/spend-revenue/first-time-viewers",
    getengagedviewerrevenueurl:
      baseApiUrl + "/api/v3/analytics/metrics/spend-revenue/engaged-viewers",
    getfirsttimeconsumerrevenueurl:
      baseApiUrl +
      "/api/v3/analytics/metrics/spend-revenue/first-time-customers",
    getfreqdinerrevenueurl:
      baseApiUrl + "/api/v3/analytics/metrics/spend-revenue/freq-diners",
    firstviewerinfourl:
      baseApiUrl + "/api/v3/analytics/info/first-time-viewers",
    engagedviewerinfourl: baseApiUrl + "/api/v3/analytics/info/engaged-viewers",
    firsttimeconsumerurl:
      baseApiUrl + "/api/v3/analytics/info/first-time-customers",
    freqdinnerinfourl: baseApiUrl + "/api/v3/analytics/info/freq-diners",
    confirmsmpostcalendar: baseApiUrl + "/api/v3/captions/confirm",
    postagree: baseApiUrl + "/api/v3/captions/posting/start",
    authtokenforfbsm: baseApiUrl + "/api/v3/users/settings/fb/authtoken",
    scribeanalyticsurl: baseApiUrl + "/api/v3/analytics/scribe/viewers/info",
    scribeanalyticscharturl:
      baseApiUrl + "/api/v3/analytics/scribe/viewers/chart",
    scribecardinfourl:
      baseApiUrl + "/api/v3/analytics/scribe/viewers/card/info",
    deletesocialmediapost: baseApiUrl + "/api/v3/captions/delete",
    commentReadurl: baseApiUrl + "/api/v3/captions/post/comment/read",
    createCustomTheme: baseApiUrl + "/api/v3/templates/create/theme",
    saveCustomTheme: baseApiUrl + "/api/v3/templates/save/theme",
    uploadImageCustomTheme:
      baseApiUrl + "/api/v3/templates/upload/theme/image?",
    lastfivedataurl: baseApiUrl + "/api/v3/captions/last/history",
    changepasswordurl: baseApiUrl + "/api/v3/users/change/password",
    getcampaignbyevent: baseApiUrl + "/api/v3/campaign/manage?",
    yelpsettingurl: baseApiUrl + "/api/v3/users/settings/yelp",
    getallcampaign: baseApiUrl + "/api/v3/campaign/manage/all?",
    subscriptionpaymentapiURL:
      baseApiUrl + "/api/v3/users/subscribtion/checkout-subscription-session",
    managesubscriptioUrl:
      baseApiUrl + "/api/v3/users/subscribtion/create-portal",
    settargeturl: baseApiUrl + "/api/v3/campaign/manage/set/target?is_all=",
    boostpaymentUrl:
      baseApiUrl + "/api/v3/users/subscribtion/checkout-payment-session",
    getsavedtheme: baseApiUrl + "/api/v3/templates/get/theme",
    paymenthistory: baseApiUrl + "/api/v3/users/subscribtion/payment/history",
    locationsaddcsv: baseApiUrl + "/api/v3/users/multi/location/user/csv",
    locationsadd: baseApiUrl + "/api/v3/users/multi/location/user",
    locationsdata: baseApiUrl + "/api/v3/users/multi/location/user/fetch",
    thoughtspottoken: baseApiUrl + "/api/v3/users/thoughtsport/token",
    createcustomthemeURL: baseApiUrl + "/api/v3/templates/main/create",
    usercustomthemeimgupload: baseApiUrl + "/api/v3/templates/upload/image",
  },
};

export default ALL_API_CONST;
