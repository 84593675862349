import React, { useEffect, useRef, useState } from 'react'
import './PickDate.css'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Button } from '@mui/material';
import calender from '../../../assets/images/calender.png'
import CustomButton from '../../Common/CustomButton/CustomButton';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { Modal, ModalBody } from 'react-bootstrap';

function PickDate({ selectedDateRange, data, handleGetDateAndTime, open }) {
    console.log(data, 'DATA')
    const style = {
        borderButton: {
            borderRadius: '30px',
            padding: '0px 32px',
            border: '1px solid #eb2426',
            color: 'white',
            marginRight: "10px"
        },
        popUpInput: {
            border: 'none',
            background: 'transparent',
            width: '85%',
            outline: 'none'
        },
        pseudoInput: {
            background: "#000",
            borderRadius: "50px",
            border: '1px solid #8e8e8e',
            width: "55%",
            marginLeft: '15px',
        },
        transparentButton: {
            background: 'transparent',
            border: "none",
            outline: "none",
            display:'flex',
            alignItems:"center",
            justifyContent:"center"
        },
    }
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [active, setActive] = useState(false)
    const [startTime, setStartTime] = useState(selectedDateRange?.startTime)
    const [endTime, setEndTime] = useState(selectedDateRange?.endTime)
    console.log(startTime)
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectionRange, setSelectionRange] = useState(
        { ...selectedDateRange, key: 'selection' }
    );
    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
    }
    console.log(selectedDateRange, 'range')
    return (
        <>
            <Box className='d-flex' sx={style.pseudoInput}>
                <input value={`${moment(selectionRange?.startDate).format('DD/MM/YYYY')}-${moment(selectionRange?.endDate).format('DD/MM/YYYY')}`} style={style.popUpInput} placeholder='Pick date' />
                <button onClick={() => setActive(!active)} style={style.transparentButton}>
                    <img src={calender} />
                </button>
            </Box>
            <Modal
                show={active}
                onHide={() => { handleGetDateAndTime({ 'Start Time': startTime, 'Start Date': moment(selectionRange?.startDate).format('DD/MM/YYYY'), 'End Time': endTime, 'End Date': moment(selectionRange?.endDate).format('DD/MM/YYYY') }); setActive(false) }}
                onClose={() => { handleGetDateAndTime({ 'Start Time': startTime, 'Start Date': moment(selectionRange?.startDate).format('DD/MM/YYYY'), 'End Time': endTime, 'End Date': moment(selectionRange?.endDate).format('DD/MM/YYYY') }); setActive(false) }}
                size='sm'
                className='dateTimepicker-body'
            >
                <ModalBody>
                    <div className={`customDatePicker ${active == true ? 'active' : ''}`}>
                        <DateRangePicker
                            showSelectionPreview={true}
                            showPreview={false}
                            months={1}
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                        />
                        <div className='TimePicker'>
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    {/* <input className='custom-radio' type='radio' checked style={{ width: "20px", color: "#eb2426" }} /> */}
                                    <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "transparent", border: '2px solid #eb2426' }}>
                                        <div style={{ margin: '4px', width: "18px", height: "18px", borderRadius: "50%", background: "#eb2426", border: '1px solid #eb2426' }}></div>
                                    </div>
                                    <label style={{ marginLeft: "10px" }}>Pick Time</label>
                                </div>
                                {/* <Button onClick={() => { handleGetDateAndTime({ 'Start Time': startTime, 'Start Date': moment(selectionRange?.startDate).format('DD/MM/YYYY'), 'End Time': endTime, 'End Date': moment(selectionRange?.endDate).format('DD/MM/YYYY') }); setActive(false) }} style={style.borderButton}>Apply</Button> */}
                            </div>
                            <div style={{ display: 'flex', alignItems: "center", marginTop: '10px' }}>
                                <AccessTimeIcon />
                                <p style={{ marginLeft: '5px', fontSize: '13px' }}>Start Time</p>
                                <input onChange={(e) => setStartTime(e.target.value)} value={startTime} type='time' style={{ width: '150px', marginLeft: "15px", borderRadius: '10px' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: "center", marginTop: '10px' }}>
                                <AccessTimeIcon />
                                <p style={{ marginLeft: '5px', fontSize: '13px' }}>End Time</p>
                                <input onChange={(e) => setEndTime(e.target.value)} value={endTime} type='time' style={{ width: '150px', marginLeft: "20px", borderRadius: '10px' }} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default PickDate