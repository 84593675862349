import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import avatarIMg from "../../assets/images/vertical_container.png";
import OptionIcon from "./OptionIcon";
import instaLike from "../../assets/SVG/black-insta-like.svg";
import instaComment from "../../assets/SVG/black-insta-comment.svg";
import instaShare from "../../assets/SVG/black-insta-share.svg";
import instaBookmark from "../../assets/SVG/black-insta-bookmark.svg";

const style = {
  container: {
    margin: "20px",
    width: "20%",
    height: "58vh",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4%",
  },
  headerText: {
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  alignLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};

const InstaCard = ({ image, customStyle }) => {

  return (
    <>
      <div style={{ ...style.container, ...customStyle }}>
        <Box sx={style.header}>
          <Stack direction="row" spacing={1}>
            <img width="25px" height="25px" src={avatarIMg} alt="error" />
            <Typography sx={{ ...style.headerText, ...style.alignLayout }}>
              ilmelograno
            </Typography>
          </Stack>
          <OptionIcon style={{ paddingLeft: "45%" }} />
        </Box>
        <img width="100%" src={image} alt="error" />
        <Stack
          sx={{ padding: "4%" }}
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            <img src={instaLike} alt="errorLike" />
            <img src={instaComment} alt="errorLike" />
            <img src={instaShare} alt="errorLike" />
          </Stack>
          <img src={instaBookmark} alt="errorBookmark" />
        </Stack>
        <Typography
          sx={{ ...style.headerText, fontWeight: 400, padding: "4%" }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </Typography>
      </div>
    </>
  );
};

export default InstaCard;
