const style = {
  mainContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "80vh",
    overflowY: "scroll",
    bgcolor: "#151515",
    margin: "auto",
    border: "2px solid #151515",
    boxShadow: "0px 8px 25.596452713012695px 0px rgba(0, 0, 0, 0.10)",
    // p: 5,
    padding: "50px",
    // textAlign: "center",
    color: "#FCFCFC",
  },
  imgContainer: {
    width: "250px",
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    borderRadius: "10px",
    margin: "auto",
  },
  editBtn: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
  },
  descBox: {
    marginTop: "20px",
    display: "flex",
    width: "100%",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "20px",
    background: "#181818",
  },
  titleBox: {
    width: "100%",
    padding: "5px 16px",
    borderRadius: "20px",
    background: "#181818",
  },
  title: {
    color: "#FFF",
    fontFamily: "SFProText-Regular",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  // profile Alert
  profileAlert: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",

    overflowY: "scroll",
    bgcolor: "#151515",
    margin: "auto",
    border: "2px solid #151515",
    boxShadow: "0px 8px 25.596452713012695px 0px rgba(0, 0, 0, 0.10)",
    // p: 5,
    padding: "50px",
    // textAlign: "center",
    color: "#FCFCFC",
  },
};

export default style;
