import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import InstaView from "../../../components/Common/InstaView/InstaView";
import FacebookView from "../../../components/Common/FacebookView/FacebookView";
import GoogleView from "../../../components/Common/GoogleView/GoogleView";
import instaIcon from "../../../assets/SVG/gray_insta_icon.svg";
import fbIcon from "../../../assets/SVG/gray_fb_icon.svg";
import googleIcon from "../../../assets/SVG/gray_google_icon.svg";
import img2 from "../../../assets/images/mediaInstaimg2.png";
import style from "../viewersStyle"

const instaData = {
  imgUrl: img2,
  event: "Summer Season",
  offers: "$50 for 2",
};

const MobView = ({ loader }) => {
  return (
    <>
      <Grid sx={style.mobViewContainer} container spacing={4}>
        <Grid sx={style.mobViewStyle} item xs={12} md={4}>
          <Stack sx={{ padding: "5%" }} direction="row" spacing={1}>
            <img width="24px" height="24px" src={instaIcon} alt="error" />
            <Typography sx={style.mobHeaderText}>Instagram</Typography>
          </Stack>
          <InstaView data={instaData} loader={loader} />
        </Grid>
        <Grid sx={style.mobViewStyle} item xs={12} md={4}>
          <Stack sx={{ padding: "5%" }} direction="row" spacing={1}>
            <img width="24px" height="24px" src={fbIcon} alt="error" />
            <Typography sx={style.mobHeaderText}>Facebook</Typography>
          </Stack>
          <FacebookView data={instaData} loader={loader} />
        </Grid>
        <Grid sx={style.mobViewStyle} item xs={12} md={4}>
          <Stack
            sx={{ padding: "5%" }}
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <img width="24px" height="24px" src={googleIcon} alt="error" />
            <Typography sx={style.mobHeaderText}>Google</Typography>
          </Stack>
          <GoogleView data={instaData} loader={loader} />
        </Grid>
      </Grid>
    </>
  );
};

export default MobView;
