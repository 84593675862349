import React from "react";
import { Box, Typography } from "@mui/material";
import "./../../assets/css/common/commonComponent.css";
import FacebookIcon from "../../assets/SVG/fbIcon.svg";
import ActiveFacebookIcon from "../../assets/SVG/activeFbicon.svg";
import InstgramIconactive from "../../assets/SVG/IstaIcon.svg";
import InstagramNormal from "../../assets/SVG/IstaIconnormal.svg";
import GoogleNormal from "../../assets/SVG/googleIcon.svg";
import email from "../../assets/SVG/email.svg";
import emailActive from "../../assets/SVG/email_active.svg";
import style from "../Common/SocialMediaSwitch/SocialMediaSwitchStyle";
import googleActive from "../../assets/SVG/google_active.svg";

const styling = {
  text: {
    color: "#C4C4C4",
    textAlign: "center",
    fontFamily: "SF Pro Display",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
};

const SocialMediaSwitch = ({
  isActive,
  handleActiveSocialMedia,
  customStyle,
}) => {
  return (
    <div className="icon-switcher">
      <img
        onClick={() => handleActiveSocialMedia("insta")}
        style={{ fill: isActive === "insta" ? "#eb2426" : "#FCFCFC" }}
        src={isActive === "insta" ? InstgramIconactive : InstagramNormal}
        className={`social-icons ${isActive === "insta" ? "active" : ""}`}
        alt="InstgramIcon"
      />
      <img
        onClick={() => handleActiveSocialMedia("fb")}
        style={{ fill: isActive === "fb" ? "#eb2426" : "#FCFCFC" }}
        src={isActive === "fb" ? ActiveFacebookIcon : FacebookIcon}
        className={`social-icons ${isActive === "fb" ? "active" : ""}`}
        alt="FacebookIcon"
      />
      <img
        onClick={() => handleActiveSocialMedia("google")}
        style={{ fill: isActive === "google" ? "#eb2426" : "#FCFCFC" }}
        src={isActive === "google" ? googleActive : GoogleNormal}
        className={`social-icons ${isActive === "google" ? "active" : ""}`}
        alt="GoogleIcon"
      />
      <img
        onClick={() => handleActiveSocialMedia("email")}
        style={{ fill: isActive === "email" ? "#eb2426" : "#FCFCFC" }}
        src={isActive === "email" ? emailActive : email}
        className={`social-icons ${isActive === "email" ? "active" : ""}`}
        alt="emailIcon"
      />
      <div
        onClick={() => handleActiveSocialMedia("sms")}
        className={`sms-text-container ${isActive === "sms" ? "active" : ""}`}
      >
        <span
          style={{
            color: isActive === "sms" ? "#eb2426" : "#C4C4C4",
          }}
          className="text"
        >
          sms
        </span>
      </div>
    </div>
  );
};

export default SocialMediaSwitch;
