import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  setmediatabswitch,
  storeInLocalStore,
} from "../../redux/reducers/mainSlice";
import { getallcampaignAPI } from "../../apis/auth";

const style = {
  mainContainer: {
    backgroundColor: "transprant",
    width: "fit-content",
    height: "50px",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    padding: "2px",
    cursor: "pointer",
    border: "2px solid #FCFCFC",
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    width: "140px",
    borderRadius: "60px",
    backgroundColor: "transparent",
    transition: "transform 0.3s",
  },
  text: {
    color: "#FCFCFC",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "SFProText-Regular",
    fontWeight: 900,
  },
};

const MediaTextSwitch = ({ mymedia, customStyle }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(1);
  const campaignsLists = useSelector(
    (state) => state.main?.store?.campaignsList
  );
  const profile_id = useSelector(
    (state) => state.main.store.profile_data?.profile_id
  );
  const [campaignList, setCampaignList] = useState([]);
  const tabData = useSelector((state) => state.main.setmediatabswitch);
  const [currentTab, setCuurentTab] = useState(tabData);
  const toggleSwitch = (params) => {
    dispatch(storeInLocalStore({ selectedcampaign: params }));
    dispatch(setmediatabswitch(params));
    setCuurentTab(params);
    setIsActive(params);
  };

  useEffect(() => {
    if (campaignsLists) {
      setCampaignList(campaignsLists);
      if (tabData == "Evergreen") {
        if (mymedia) {
          setCuurentTab(campaignsLists[0]?.name);
          dispatch(
            storeInLocalStore({ selectedcampaign: campaignsLists[0]?.name })
          );
        }
      } else if (tabData) {
        setCuurentTab(tabData);
      } else {
        if (mymedia) {
          if (campaignsLists) {
            setCuurentTab(campaignsLists[0]?.name);
            dispatch(
              storeInLocalStore({ selectedcampaign: campaignsLists[0]?.name })
            );
          }
        }
      }
    }
  }, [campaignsLists]);
  return (
    <>
      <Box sx={{ ...style.mainContainer, ...customStyle }}>
        {campaignList &&
          campaignList.length > 0 &&
          campaignList.map((item) => {
            return (
              <>
                <Box
                  onClick={(e) => toggleSwitch(item.name)}
                  sx={{
                    ...style.circle,
                    backgroundColor:
                      mymedia && currentTab === item.name ? "#fff": 'inherit',
                  }}
                  key={item.id}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      ...style.text,
                      color:
                        mymedia && currentTab === item.name
                          ? "#eb2426"
                          : "#FCFCFC",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </>
            );
          })}
      </Box>
    </>
  );
};

export default MediaTextSwitch;
