import React from "react";
import aienable from "../../../assets/images/create-calender/ai-enable.png";
import aidisasble from "../../../assets/images/create-calender/ai-disable.png";
import "./Aisavebutton.css";

const AIsavebutton = ({
  disable,
  savebtndisable,
  generatecaption,
  saveCaption,
  savebtncontent,
}) => {
  return (
    <>
      <div className="d-flex justify-content-end calender-button-container">
        <button
          onClick={() => {
            generatecaption("both");
          }}
          className={`byteready-btn byteready-outlinne ${
            disable ? "disabled" : ""
          }`}
        >
          <img src={disable ? aidisasble : aienable} /> Try AI
        </button>
        <button
          onClick={() => {
            saveCaption();
          }}
          className={`byteready-btn byteready-primary ${
            savebtndisable ? "disabled" : ""
          }`}
        >
          {savebtncontent || "Save Created Post"}
        </button>
      </div>
    </>
  );
};

export default AIsavebutton;
