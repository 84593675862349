import { Box, Typography } from "@mui/material";
import React from "react";
import style from "./PaymentStyle";
import payment from "../../assets/images/Payment/payment.png";
import check from "../../assets/images/Payment/check.png";
import facebook from "../../assets/images/Payment/paymentIcon-4.png";
import instagram from "../../assets/images/Payment/paymentIcon-3.png";
import google from "../../assets/images/Payment/paymentIcon-1.png";
import gmail from "../../assets/images/Payment/paymentIcon-2.png";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/Common/CustomButton/CustomButton";
import { useSelector } from "react-redux";
import { subscribepaymentAPI } from "../../apis/auth";

function Trialpayemnt() {
  const navigate = useNavigate();
  const user_id = useSelector((state) => state.main.store?.users?.id);
  const paymentfunc = () => {
    navigate("/create-calender");
  };

  return (
    <>
      <Box style={style.container}>
        <Typography style={style.styleHeading}>
          You can start Your free trial.
        </Typography>
        <Typography style={style.styleSmallHeading}>
          Connect with customers on the most popular social platforms on the
          planet. No marketing and design skills required. Connect with
          customers on the most popular social platforms on the planet. No
          marketing and design skills required.{" "}
        </Typography>
        <Box className="d-flex justify-content-center mt-5">
          <img
            src={payment}
            style={{
              textAlign: "center",
              width: "80%",
              position: "relative",
              zIndex: "99",
            }}
          />
        </Box>
        <Box style={style.smallContainer}>
          <Typography
            style={{
              ...style.styleHeading,
              fontSize: "26px",
              marginTop: "20px",
            }}
          >
            Premier Program
          </Typography>
          <Typography
            style={{
              ...style.styleSmallHeading,
              fontSize: "14px",
              textTransform: "none",
              color: "#BCBCBC",
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Typography>
          <Box mt={3}>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex">
                <img src={check} width={"20px"} />
                <Typography ml={1} fontSize={"13px"}>
                  SM Posts
                </Typography>
              </Box>
              <Box className="d-flex">
                <img
                  src={instagram}
                  width={"18px"}
                  style={{ display: "block", margin: "0px 5px" }}
                />
                <img
                  src={facebook}
                  width={"18px"}
                  style={{ display: "block", margin: "0px 5px" }}
                />
              </Box>
            </Box>
            <Box className="d-flex justify-content-between align-items-center mt-4">
              <Box className="d-flex">
                <img src={check} width={"20px"} />
                <Typography ml={1} fontSize={"13px"}>
                  Ads
                </Typography>
              </Box>
              <Box className="d-flex">
                <img
                  src={instagram}
                  width={"18px"}
                  style={{ display: "block", margin: "0px 5px" }}
                />
                <img
                  src={facebook}
                  width={"18px"}
                  style={{ display: "block", margin: "0px 5px" }}
                />
                <img
                  src={google}
                  width={"18px"}
                  style={{ display: "block", margin: "0px 5px" }}
                />
                <img
                  src={gmail}
                  width={"18px"}
                  style={{ display: "block", margin: "0px 5px" }}
                />
              </Box>
            </Box>
            <Box className="d-flex justify-content-between align-items-center mt-4">
              <Box className="d-flex">
                <img src={check} width={"20px"} />
                <Typography ml={1} fontSize={"13px"}>
                  Increase Number of Customers
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={6} className="d-flex justify-content-between">
            <Typography>
              <span style={style.price}>$399</span>
              <br />
              <span style={{ fontSize: "14px" }}>One Month</span>
            </Typography>
            <CustomButton
              label={"GET STARTED"}
              sx={{
                padding: "8px 40px",
                cursor: "pointer",
                fontFamily: "helvetica-font",
                fontSize: "15px",
                textTransform: "uppercase",
              }}
              onClick={paymentfunc}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Trialpayemnt;
