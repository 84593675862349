import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import point from "../../assets/SVG/Ellipse.svg";
import bookMarkImg from "../../assets/SVG/bookmark.svg";
import selectBookMarkImg from "../../assets/SVG/Select_bookmark.svg";
import bookmarkExtIcon from "../../assets/SVG/bookmark_ext.svg";
import "./../../assets/css/ComponentCSS/bookmark.css";

const textStyle = {
  color: "#FCFCC",
  fontFamily: "SFProText-Regular",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 800,
  lineHeight: "normal",
  // textAlign: "center",
};

const BookMark = ({ style, count, handleSelect, isBookMark }) => {
  return (
    <>
      <div className="bookontainer">
        <img
          className="bookmarkImage"
          onClick={handleSelect}
          src={isBookMark ? bookMarkImg : selectBookMarkImg}
        />
        {count > 0 && (
          <div className="numbercontainer">
            <div className="numberunder">
              <span className="numbercount">{count}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookMark;
