import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./MyMediaStyle";
import "./mymedia.css";
import InstaView from "../../Common/InstaView/InstaView";
import FacebookView from "../../Common/FacebookView/FacebookView";
import GoogleView from "../../Common/GoogleView/GoogleView";
import SmsView from "../../Common/SmsView/SmsView";
import EmailView from "../../Common/EmailView/EmailView";
import img1 from "../../../assets/images/mediaInstaimg1.png";
import img2 from "../../../assets/images/mediaInstaimg2.png";
import img3 from "../../../assets/images/mediaInstaimg3.png";
import img4 from "../../../assets/images/mediaInstaimg4.png";
import MediaTextSwitch from "../../Common/MediaTextSwitch";
import CustomButton from "../../Common/CustomButton/CustomButton";
import SocialMediaSwitch from "../../Common/MyMediaSwitch";
import { useDispatch, useSelector } from "react-redux";

import {
  getallcampaignAPI,
  confirmcalenderAPI,
  getallsavedunitAPI,
  getcampaignbyidAPI,
  publishcampaignAPI,
  getmaxViewerAPI,
} from "../../../apis/auth";
import {
  setmediatabswitch,
  storeInLocalStore,
  selectedPlatform,
  settogodownposition,
} from "../../../redux/reducers/mainSlice";
import GlobalLoader from "../../Common/GlobalLoader/GlobalLoader";
import PhoneWrapper from "../../Common/PhoneWrapper/PhoneWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalAlert from "../../Common/GlobalAlert";
import { Button } from "react-bootstrap";
import IncreaseViewers from "../../Viewers/IncreaseViewers/IncreaseViewers";
import Reach from "../../Viewers/Reach/Reach";

const instaViewData = [
  {
    imgUrl: img2,
    event: "Summer Season",
    offers: "$50 for 2",
  },
  {
    imgUrl: img3,
    event: "Special dishes",
    offers: "$50 for 2",
  },
  {
    imgUrl: img4,
    event: "Sunday brunch",
    offers: "$50 for 2",
  },
  {
    imgUrl: img1,
    event: "dinner for 2/1",
    offers: "$50 for 2",
  },
];

const smsViewData = [
  {
    imgUrl: img2,
    event: "Summer Season",
    offers: "$50 for 2",
  },
  {
    imgUrl: img3,
    event: "Special dishes",
    offers: "$50 for 2",
  },
];

const EditMyMedia = () => {
  const history = useNavigate();
  const [isSocialActive, setIsSocialActive] = useState("insta");
  const [loader, setLoader] = useState(false);
  const [creatingcalender, setCreatingCalender] = useState(false);
  const [loginmessage, setLogingMessage] = useState("");
  const [savedcampaignData, setCampaignData] = useState([]);
  const [selectedCampaignname, setSelectedCampaignName] = useState("");
  const [targetamountcustom, setTargetamountcustom] = useState({
    viewer: 0,
    price: 0,
  });
  const [publishloader, setPublishloader] = useState(false);
  const campaignsLists = useSelector(
    (state) => state.main.store?.campaignsList
  );
  const moveto = useSelector((state) => state.main.store?.moveto);
  const userId = useSelector((state) => state.main.store?.userInfo?.user_id);
  const [instagramData, setInstagramData] = useState([]);
  const [facebookData, setFacebookData] = useState([]);
  const [gmailData, setGmailData] = useState([]);
  const [googleadData, setGoogleAddData] = useState([]);
  const [smsData, setSMSData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const [confirmbuttonFlag, setConfirmClanderFlag] = useState();
  const store = useSelector((state) => state.main.store);
  const rest_name = useSelector(
    (state) => state.main.store?.profile_data?.resturant_name
  );
  const iconsurl = useSelector(
    (state) => state.main.store?.profile_data?.website
  );
  const selectedPlatformdata = useSelector(
    (state) => state.main.selectedPlatform
  );
  const dispatch = useDispatch();
  const tabData = useSelector((state) => state.main.setmediatabswitch);
  const { pathname } = useLocation();
  const [maxviewersData, setMaxviewersData] = useState({});
  const handleActiveSocialMedia = (params) => {
    setIsSocialActive(params);
    dispatch(selectedPlatform(params));
  };
  const getviewerlist = () => {
    console.log("load");
    let data = {
      profile_id: profile_id,
    };
    getmaxViewerAPI(data)
      .then((res) => {
        if (res.status == "success") {
          setMaxviewersData(res.data);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const getCapData = (tempDatass) => {
    setIsLoading(true);
    let data = {
      profile_id: profile_id,
      campaign_id: tempDatass,
    };
    getcampaignbyidAPI(data)
      .then((res) => {
        if (res.status == "success") {
          let tempData = res.campaign.add_unit_ids.map((item) => {
            let tempDes = item.other_platforms
              .filter((item) => item.platform == "instagram")
              .map((item) => item.caption)[0];
            return {
              id: item.id,
              imgUrl: item.image_url,
              event: item.title,
              offers: item.input_text,
              alldata: item,
              platform: "instagram",
              description: tempDes,
            };
          });
          let facebookData = res.campaign.add_unit_ids.map((item) => {
            let tempDes = item.other_platforms
              .filter((item) => item.platform == "facebook")
              .map((item) => item.caption)[0];
            return {
              id: item.id,
              imgUrl: item.image_url,
              event: item.title,
              offers: item.input_text,
              alldata: item,
              platform: "facebook",
              description: tempDes,
            };
          });
          let googleadds = res.campaign.add_unit_ids.map((item) => {
            let tempDes = item.other_platforms
              .filter((item) => item.platform == "google ad")
              .map((item) => item.caption)[0];
            return {
              id: item.id,
              imgUrl: item.image_url,
              event: item.title,
              offers: item.input_text,
              alldata: item,
              platform: "google ad",
              description: tempDes,
            };
          });
          let tempsms = res.campaign.add_unit_ids.map((item) => {
            let tempDes = item.other_platforms
              .filter((item) => item.platform == "sms")
              .map((item) => item.caption)[0];
            return {
              id: item.id,
              imgUrl: item.image_url,
              event: item.title,
              offers: item.input_text,
              alldata: item,
              platform: "sms",
              description: tempDes,
            };
          });
          let tempemail = res.campaign.add_unit_ids.map((item) => {
            let tempDes = item.other_platforms
              .filter((item) => item.platform == "email")
              .map((item) => item.caption)[0];
            let lines = tempDes.split("\n");
            let filteredLines = lines.filter(
              (line) => !line.trim().startsWith("Subject:")
            );
            let filteredEmailText = filteredLines
              .join("\n")
              ?.replace("Body:", "");
            return {
              id: item.id,
              imgUrl: item.image_url,
              event: item.title,
              offers: item.input_text,
              alldata: item,
              platform: "email",
              description: filteredEmailText,
            };
          });
          setInstagramData(tempData);
          setFacebookData(facebookData);
          setGoogleAddData(googleadds);
          setSMSData(tempsms);
          setGmailData(tempemail);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCampaign = () => {
    let data = {
      profile_id: profile_id,
      unit_id: 0,
    };
    getallcampaignAPI(data).then((res) => {
      let tempData = res.campaign.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      dispatch(storeInLocalStore({ campaignsList: tempData }));
    });
  };
  useEffect(() => {
    if (campaignsLists) {
      let tempDatass = "";
      if (tabData == "Evergreen") {
        tempDatass = campaignsLists[0]?.id;
        dispatch(setmediatabswitch(campaignsLists[0]?.name));
        dispatch(
          storeInLocalStore({ selectedcampaign: campaignsLists[0]?.name })
        );
      } else if (tabData) {
        tempDatass = campaignsLists.filter((item) => item.name == tabData)[0]
          ?.id;
      } else {
        tempDatass = campaignsLists[0]?.id;
        dispatch(setmediatabswitch(campaignsLists[0]?.name));
        dispatch(
          storeInLocalStore({ selectedcampaign: campaignsLists[0]?.name })
        );
      }
      getCapData(tempDatass);
      setSelectedCampaignName(tempDatass);
    }
  }, [tabData, campaignsLists]);
  useEffect(() => {
    if (selectedPlatformdata) {
      handleActiveSocialMedia(selectedPlatformdata);
    }
  }, [selectedPlatformdata]);
  const handleConfirmCalener = () => {
    if (userId) {
      setCreatingCalender(true);
      let payload = {
        profile_id: profile_id,
        campaign_id: selectedCampaignname,
      };
      confirmcalenderAPI(payload)
        .then((res) => {
          setCreatingCalender(false);
          if (res.status == "success") {
            setLogingMessage("Campaign has been created.");
            dispatch(storeInLocalStore({ moveto: "" }));
          }
        })
        .catch((res) => {
          console.log(res);
          setCreatingCalender(false);
        });
    } else {
      dispatch(storeInLocalStore({ useravailable: false }));
      dispatch(storeInLocalStore({ moveto: pathname }));
      setLogingMessage("Please Signup or signin to store the calender.");
    }
  };
  const gettargetvalue = (data) => {
    setTargetamountcustom({
      viewer: data.newValue,
      price: data.crntval,
    });
  };
  const publishcampaign = (data) => {
    let payload = {
      profile_id: profile_id,
      camp_id: selectedCampaignname,
      amount: parseInt(data.targetamount),
      start_date: data.startdate,
      end_date: data.enddate,
      platform: "all",
    };
    setPublishloader(true);
    publishcampaignAPI(payload)
      .then((res) => {
        setCreatingCalender(false);
        if (res.status == "success") {
          setLogingMessage(
            "campagin has been posted on all the connected channels."
          );
          setPublishloader(false);
        }
      })
      .catch((res) => {
        console.log(res);
        setLogingMessage("This caption is not allowed. ");
        setCreatingCalender(false);
        setPublishloader(false);
      });
  };
  const handleClose = () => {
    setLogingMessage("");
    if (!userId) {
      history("/login");
    } else {
      getCapData(selectedCampaignname);
    }
  };
  const handleCreateCalendar = () => {
    dispatch(settogodownposition(true));
    history("/");
  };
  useEffect(() => {
    getAllCampaign();
    getviewerlist();
  }, []);
  useEffect(() => {
    if (instagramData || facebookData || gmailData || smsData || googleadData) {
      if (isSocialActive == "insta") {
        let arr = instagramData.map((item) => item.alldata.is_posted);

        if (arr.includes(false)) {
          setConfirmClanderFlag(false);
        } else {
          setConfirmClanderFlag(true);
        }
      }
      if (isSocialActive == "fb") {
        if (
          facebookData.map((item) => item.alldata.is_posted).includes(false)
        ) {
          setConfirmClanderFlag(false);
        } else {
          setConfirmClanderFlag(true);
        }
      }
      if (isSocialActive == "google") {
        if (
          googleadData.map((item) => item.alldata.is_posted).includes(false)
        ) {
          setConfirmClanderFlag(false);
        } else {
          setConfirmClanderFlag(true);
        }
      }
      if (isSocialActive == "email") {
        if (gmailData.map((item) => item.alldata.is_posted).includes(false)) {
          setConfirmClanderFlag(false);
        } else {
          setConfirmClanderFlag(true);
        }
      }
      if (isSocialActive == "sms") {
        if (smsData.map((item) => item.alldata.is_posted).includes(false)) {
          setConfirmClanderFlag(false);
        } else {
          setConfirmClanderFlag(true);
        }
      }
    }
  }, [instagramData, facebookData, gmailData, smsData, googleadData]);
  useEffect(() => {
    if (confirmbuttonFlag == false) {
      if (campaignsLists) {
        if (moveto == "/media") {
          handleConfirmCalener();
        }
      }
    }
  }, [campaignsLists, confirmbuttonFlag]);
  return (
    <>
      <div className="mymediamain">
        {selectedCampaignname !== undefined ? (
          <>
            <div className="headerContainer">
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={12} md={12}>
                  <p className="headText">Select The Post You Want To Edit</p>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={12} md={4}>
                      <p className="title">Choose The Platform</p>
                      <SocialMediaSwitch
                        handleActiveSocialMedia={handleActiveSocialMedia}
                        isActive={isSocialActive}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <p className="title">Choose From Your Campaigns</p>
                      <MediaTextSwitch mymedia={true} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="text-end">
                    {confirmbuttonFlag == false && (
                      <>
                        {creatingcalender ? (
                          <>
                            <Button
                              style={{
                                background: "#eb2426",
                                height: "51px",
                                padding: "16px 32px 16px 32px",
                                borderRadius: "50px",
                                fontSize: "16px",
                                border: "none",
                                width: "fit-content",
                                alignItems: "right",
                              }}
                              disabled
                            >
                              <span>Confirm Calendar</span>
                              <span>
                                <CircularProgress
                                  className="savingLoader"
                                  sx={{
                                    color: "#fff",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              </span>
                            </Button>
                          </>
                        ) : (
                          <>
                            <CustomButton
                              sx={{ width: "fit-content" }}
                              label="Confirm Calendar"
                              onClick={handleConfirmCalener}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            {isLoading ? (
              <>
                <Grid sx={style.mobContainer} container spacing={2}>
                  <GlobalLoader text={"Getting Media..."} open={isLoading} />
                </Grid>
              </>
            ) : (
              <>
                {isSocialActive === "insta" && (
                  <Grid sx={style.mobContainer} container spacing={2}>
                    {instagramData?.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Grid key={index + 1} item xs={12} md={3}>
                            <Box
                              sx={{
                                margin: "auto",
                                textAlign: "center",
                                color: "#eb2426",
                                fontSize: "14px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              {data?.alldata?.is_posted ? "Posted" : "Pending"}
                            </Box>
                            <PhoneWrapper
                              template="common"
                              name={rest_name}
                              iconurl={
                                iconsurl
                                  ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                  : ""
                              }
                            >
                              <InstaView
                                restaurantName={rest_name}
                                operation="edit"
                                data={data}
                                loader={loader}
                                iconurl={
                                  iconsurl
                                    ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                    : ""
                                }
                              />
                            </PhoneWrapper>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                )}
                {isSocialActive === "fb" && (
                  <Grid sx={style.mobContainer} container spacing={2}>
                    {facebookData?.map((data, index) => {
                      return (
                        <Grid key={index + 1} item xs={12} md={3}>
                          <Box
                            sx={{
                              margin: "auto",
                              textAlign: "center",
                              color: "#eb2426",
                              fontSize: "14px",
                              fontWeight: "600",
                              marginBottom: "10px",
                            }}
                          >
                            {data?.alldata?.is_posted ? "Posted" : "Pending"}
                          </Box>
                          <PhoneWrapper
                            template="common"
                            name={rest_name}
                            iconurl={
                              iconsurl
                                ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                : ""
                            }
                          >
                            <FacebookView
                              restaurantName={rest_name}
                              operation="edit"
                              data={data}
                              loader={loader}
                            />
                          </PhoneWrapper>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {isSocialActive === "google" && (
                  <Grid sx={style.mobContainer} container spacing={2}>
                    {googleadData?.map((data, index) => {
                      return (
                        <Grid key={index + 1} item xs={12} md={3}>
                          <Box
                            sx={{
                              margin: "auto",
                              textAlign: "center",
                              color: "#eb2426",
                              fontSize: "14px",
                              fontWeight: "600",
                              marginBottom: "10px",
                            }}
                          >
                            {data?.alldata?.is_posted ? "Posted" : "Pending"}
                          </Box>
                          <PhoneWrapper
                            template="googleads"
                            name={rest_name}
                            iconurl={
                              iconsurl
                                ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                : ""
                            }
                          >
                            <GoogleView
                              restaurantName={rest_name}
                              operation="edit"
                              data={data}
                              loader={loader}
                              iconurl={
                                iconsurl
                                  ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                  : ""
                              }
                            />
                          </PhoneWrapper>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {isSocialActive === "email" && (
                  <Grid sx={style.mobContainer} container spacing={2}>
                    {gmailData?.map((data, index) => {
                      return (
                        <Grid key={index + 1} item xs={12} md={5}>
                          <Box
                            sx={{
                              margin: "auto",
                              textAlign: "center",
                              color: "#eb2426",
                              fontSize: "14px",
                              fontWeight: "600",
                              marginBottom: "10px",
                            }}
                          >
                            {data?.alldata?.is_posted ? "Posted" : "Pending"}
                          </Box>
                          <EmailView
                            restaurantName={rest_name}
                            operation="edit"
                            data={data}
                            loader={loader}
                            iconurl={
                              iconsurl
                                ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                : ""
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {isSocialActive === "sms" && (
                  <Grid sx={style.mobContainer} container spacing={2}>
                    {smsData?.map((data, index) => {
                      return (
                        <>
                          <Grid
                            key={index + 1}
                            item
                            xs={12}
                            md={3}
                            justifyContent="flex-end"
                          >
                            <Box
                              sx={{
                                margin: "auto",
                                textAlign: "center",
                                color: "#eb2426",
                                fontSize: "14px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              {data?.alldata?.is_posted ? "Posted" : "Pending"}
                            </Box>
                            <PhoneWrapper
                              template="sms"
                              name={rest_name}
                              iconurl={
                                iconsurl
                                  ? `https://s2.googleusercontent.com/s2/favicons?domain=${iconsurl}`
                                  : ""
                              }
                            >
                              <SmsView
                                restaurantName={rest_name}
                                operation="edit"
                                data={data}
                                loader={loader}
                              />
                            </PhoneWrapper>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Box>
              <Typography sx={style.boldText} variant="h4">
                Campaign is Not Created.
              </Typography>
              <Button
                style={{
                  margin: "50px auto",
                  backgroundColor: "#eb2426",
                  borderRadius: "50px",
                  display: "block",
                  padding: "16px 50px",
                  border: "none",
                  textAlign: "center",
                  "&:hover": {
                    background: "#eb2426",
                    border: "none",
                  },
                }}
                onClick={handleCreateCalendar}
              >
                Create Campaign
              </Button>
            </Box>
          </>
        )}
        {confirmbuttonFlag == false && (
          <>
            <div className="row align-items-stretch  pt-2">
              <div className="col-md-6">
                <IncreaseViewers
                  maxviewersData={maxviewersData}
                  publishcampaign={publishcampaign}
                  gettargetvalue={gettargetvalue}
                  publishloader={publishloader}
                />
              </div>
              <div className="col-md-6 customhalfbg">
                <Reach
                  maxviewersData={maxviewersData}
                  currentViewer={targetamountcustom}
                />
              </div>
            </div>
          </>
        )}
        {loginmessage && (
          <GlobalAlert message={loginmessage} handleClose={handleClose} />
        )}
      </div>
    </>
  );
};

export default EditMyMedia;
