import React from "react";
import style from "../ImageSlider";
import { Box, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const SocialHeader = ({ VerticalContainer, resturantNameValue }) => {
  return (
    <>
      <Box sx={style.instagramHeader}>
        <Box sx={style.firstInstaCont}>
          <img
            width="25px"
            height="25px"
            src={VerticalContainer}
            alt="VerticalContainer"
          />
          <Box sx={style.instaHeaderText}>
            <Typography
              variant="p"
              sx={{ fontSize: "8px", fontWeight: "bold" }}
            >
              {resturantNameValue || "Resturant Name"}
            </Typography>
            <Typography variant="p" sx={{ fontSize: "8px" }}>
              Sponsored
            </Typography>
          </Box>
        </Box>
        <Box sx={style.settingContainer}>
          <MoreHorizIcon />
        </Box>
      </Box>
    </>
  );
};

export default SocialHeader;
