import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Slidertop = ({
  title,
  bgcode,
  description,
  handleNextAd,
  handlePreviousAd,
}) => {
  return (
    <div className="slider-top">
      <div className={`card custom-card ${bgcode}`}>
        <div className="left-content">
          <p>{title}</p>
          <p className="font-small">{description}</p>
        </div>
        <div className="right-content">
          <div className="btn-area">
            <KeyboardArrowLeftIcon
              onClick={handlePreviousAd}
              className={`icon ${bgcode}-color`}
            />
            <ChevronRightIcon
              onClick={handleNextAd}
              className={`icon ${bgcode}-color`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidertop;
