import React, { useState } from "react";
import iIcon from "../../assets/SVG/typcn_info-large.svg";
import dropdownIcon from "../../assets/SVG/material-symbols_arrow-right-rounded.svg";

const CustomSelect = ({ handleSelected, selectedOption, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className="custom-select">
        <div className="selected-item">
          <img src={iIcon} className="image-icon" alt="error" />
          {selectedOption}
          <img src={dropdownIcon} className="downarrow" alt="error" />
        </div>
        <div className="options-area">
          {options.map((option) => {
            return (
              <div
                key={option}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelected(option);
                  setAnchorEl(null);
                }}
                className="options"
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustomSelect;
