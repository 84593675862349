import React, { useState } from "react";
import "./../signupstyle.css";
import { otpverifyAPI, sendOtpAPI } from "../../../apis/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GlobalAlert from "../../../components/Common/GlobalAlert";
const index = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const users = useSelector((state) => state.main?.store?.users);
  const enteredemail = useSelector((state) => state.main?.store?.enteredemail);
  const [message, setMessage] = useState();
  const [fieldalertmessage, setFliedalertmessage] = useState("");
  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    if (value !== "" && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    setOtp(newOtp);
  };
  const handleOtp = () => {
    if (otp.length > 5) {
      const concatenatedString = otp.join("");

      let payload = {
        user_id: users?.id,
        otp: concatenatedString,
      };
      otpverifyAPI(false, payload)
        .then((res) => {
          if (res.status) {
            localStorage.setItem("token", res.token);
            setMessage(res.message);
          }
        })
        .catch((rej) => {
          console.log(rej);
          setFliedalertmessage(rej.response?.data?.detail);
        });
    }
  };
  const handleClose = () => {
    if (fieldalertmessage) {
      setFliedalertmessage("");
    } else {
      setMessage("");
      if (localStorage.getItem("token")) {
        navigate("/social-media-connect");
      }
    }
  };
  const resendOtp = () => {
    sendOtpAPI({
      email_or_phone_number: enteredemail,
    })
      .then((res) => {
        if (res.status) {
          setFliedalertmessage(
            "We’ve sent you an email with a verification Code"
          );
        }
      })
      .catch((rej) => {
        setFliedalertmessage(rej?.response?.data?.detail);
      });
  };
  return (
    <>
      <div className="full-width headerpadding signup-page">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-center h3-title-while-whynk">
                  Verify Your Account
                </h1>
                <p className="text-center">
                  Enter the digits we’ve sent you via SMS
                </p>
              </div>
            </div>
            <div className="row position-relative pt-5">
              <div className="col-md-8 offset-md-2">
                <div className="content-padding-both">
                  <div className="otp-input-container">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-3 pt-5">
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="byteready-btn byteready-primary signupbtn"
                  onClick={handleOtp}
                >
                  Verify
                </button>
                <p className="py-2">
                  Didn’t receive the verification OTP?{" "}
                  <span onClick={resendOtp} className="signinbtn-span">
                    Resend
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(message || fieldalertmessage) && (
        <GlobalAlert
          message={message || fieldalertmessage}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default index;
