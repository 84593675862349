import React from "react";

const Verticleline = ({ rightarrow, bgcode, handleNextAd }) => {
  return (
    <>
      <div className={`circle-top ${bgcode}`}></div>
      <div className={`circle-buttom ${bgcode}`}></div>
      <div className={`vertile-line ${bgcode}`}></div>
      <img
        src={rightarrow}
        alt="right-arrow"
        onClick={handleNextAd}
        className="right-arrow"
      />
    </>
  );
};

export default Verticleline;
