import React from "react";
import Socialmediapost from "./Socialmediapost";
import Trialended from "../Reviewsmain/Trialended";
import { useSelector } from "react-redux";

import moment from "moment";
import Starttrial from "../Reviewsmain/Starttrial";

const Socialmediamain = () => {
  const subscribestatus = useSelector(
    (state) => state.main?.store?.users.subscribe_status
  );
  //console.log(subscribestatus);
  return (
    <>
      <Socialmediapost />
      {/* {subscribestatus == "free" || subscribestatus == "paid" ? (
        <>
          <Socialmediapost />
        </>
      ) : (
        <>
          {subscribestatus == "expire" ? (
            <Trialended price={199} product={"scribe"} />
          ) : (
            <>
              <Starttrial />
            </>
          )}
        </>
      )} */}
    </>
  );
};

export default Socialmediamain;
