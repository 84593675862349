import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import style from "./HorizontalSlider.js";
import { Box, Stack, Typography } from "@mui/material";


const HorizontalSlider = ({ images, onClick }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={true}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
          }
        }}
        rtl={true}
        // rewind={true}
        // rewindWithAnimation={true}
        // rtl={true}
        // shouldResetAutoplay
        // showDots={false}
        // sliderClass=""
        // slidesToSlide={1}
        // swipeable
      >
        {images.map((element, i) => {
          return (
            <Box
              key={i}
              className="crowserl-item"
              style={{
                backgroundImage: `url(${element.imgUrl})`,
              }}
              onClick={() => {
                onClick(element);
              }}
            >
              <Typography sx={style.text}>{element.text}</Typography>
            </Box>
          );
        })}
      </Carousel>
    </>
  );
};

export default HorizontalSlider;
