import React, { useState } from "react";
import logo from "./../../../../assets/img/logo.png";
import dropdownIcon from "../../../../assets/SVG/dropdown-arrow.svg";
import profileImg from "../../../../assets/images/vertical_container.png";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const Headerwhite = ({ menus, user, pathName, profile_name, handlemenu }) => {
  const navigate = useNavigate();
  const [myprofileurl, setMyprofileurl] = useState(Clerk?.user?.imageUrl || "");
  return (
    <div className="header-common header-white">
      <div className="container">
        <div className="header-container">
          <div className="logo">
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="menu-container">
            <ul className="nav-menu">
              {menus.map((item, index) => (
                <li key={index} className="nav-link">
                  <span className="nav-item">
                    {item.name} <img src={dropdownIcon} alt="error" />
                  </span>
                  {user?.id && (
                    <>
                      {item.submenu && (
                        <ul className="submenu">
                          {item.submenu.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              onClick={() => {
                                handlemenu(subItem);
                              }}
                            >
                              <span>{subItem.name}</span>{" "}
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  )}
                </li>
              ))}
              {user?.id ? (
                <>
                  <div className="admin-area">
                    <Dropdown
                      onClick={() => {
                        navigate("/my-profile");
                      }}
                    >
                      <Dropdown.Toggle className="profile">
                        <img
                          src={myprofileurl || profileImg}
                          alt="error"
                          onError={(e) => {
                            e.target.src = profileImg;
                          }}
                          style={{
                            width: 44,
                            height: 44,
                            borderRadius: "50%",
                          }}
                        />
                        <span
                          className={pathName == "/my-profile" && "bold-inner"}
                        >
                          {profile_name || "Restaurant Name"}
                        </span>
                        <img src={dropdownIcon} alt="error" />
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </>
              ) : (
                <>
                  <li className="nav-link">
                    <Link to={"#"} className="btn-one">
                      TRY FOR FREE
                    </Link>
                  </li>
                  <li className="nav-link">
                    <Link to={"/login"} className="btn-one-outline">
                      LOG IN
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headerwhite;
