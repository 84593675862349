import { Box, Grid, Stack, Typography } from "@mui/material";
import SocialMediaSwitch from "../Common/MyMediaSwitch";
import MediaTextSwitch from "../Common/MediaTextSwitch";
import CustomButton from "../Common/CustomButton/CustomButton";
import SwitchButton from "../Common/SwitchButton/SwitchButton.jsx";
import StartDate from "./StartDate";
import { useState } from "react";

const style = {
  title: {
    padding: "0px",
    color: "#9E9E9E",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  titleBox: {
    margin: "35px 0 10px 0",
  },
  headText: {
    color: "#FCFCFC",
    fontSize: "25px",
    fontFamily: "WhyteInktrap-Black",
    padding: "0px 3px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
};

const MediaHeader = ({
  handleActiveSocialMedia,
  isActive,
  designSwitch,
  switchButton,
  operation,
}) => {
  const [created, setCreated] = useState(false);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          {operation === "edit" && (
            <Typography sx={style.headText}>
              Select The Post You Want To Edit
            </Typography>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={style.titleBox}>
                <Typography sx={style.title}>Choose The Platform</Typography>
              </Box>
              {switchButton ? (
                <SocialMediaSwitch
                  handleActiveSocialMedia={handleActiveSocialMedia}
                  isActive={isActive}
                  customStyle={{ width: "100%" }}
                />
              ) : (
                <SwitchButton
                  designSwitch={designSwitch}
                  customStyle={{ width: "50%" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Box sx={style.titleBox}>
                <Typography sx={style.title}>
                  Choose From Your Campaigns
                </Typography>
              </Box>
              <MediaTextSwitch />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          {operation === "view" ? (
            <StartDate />
          ) : (
            <Box align="right" justify="bottom">
              <CustomButton
                onClick={() => setCreated(true)}
                sx={{
                  width: "40%",
                  marginTop: "20%",
                }}
                label="Confirm Calendar"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MediaHeader;
