import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import style from "./TodaysPostsStyle";
import InstaCard from "../Common/InstaCard";
import FacebookCard from "../Common/FacebookCard";
import instCardImg from "../../assets/images/insta-card-img.png";
import fbCardImg from "../../assets/images/fb-card-img.png";

const data = ["insta", "fb", "fb", "insta", "insta", "fb", "fb", "insta"];

const TodaysPosts = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleNext = () => {
    if (currentImageIndex + 5 < data.length) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <Box sx={style.container}>
        <Typography sx={style.headerText}>
          Today’s <br /> posts
        </Typography>
        <Stack
          sx={{ width: "100%", height: "100%" }}
          direction="row"
          spacing={4}
        >
          {data
            .slice(currentImageIndex, currentImageIndex + 5)
            .map((card, index) => {
              const style =
                index === 1 || index === 3 ? "8%" : index === 2 ? "15%" : "0%";
              return card === "insta" ? (
                <InstaCard
                  key={index + 1}
                  customStyle={{
                    marginTop: style,
                  }}
                  image={instCardImg}
                />
              ) : (
                <FacebookCard
                  key={index + 1}
                  customStyle={{
                    marginTop: style,
                  }}
                  image={fbCardImg}
                />
              );
            })}
        </Stack>
        {/* <button onClick={handlePrev}>Previous</button>
        <button onClick={handleNext}>Next</button> */}
      </Box>
    </>
  );
};

export default TodaysPosts;
