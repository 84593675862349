import React from "react";
import activeinsta from "../../assets/images/create-calender/switch/instagram-active.png";
import insta from "../../assets/images/create-calender/switch/instagram.png";
import facebook from "../../assets/images/create-calender/switch/facebook.png";
import activefacebook from "../../assets/images/create-calender/switch/active-facebook.png";

const Calenderdatepicker = ({handletime}) => {
  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i <= 23; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      options.push(
        <option key={hour} value={`${hour}:00`}>{`${hour}:00`}</option>
      );
    }
    return options;
  };
  return (
    <>
      <div className="calender-datepicker">
        <p className="chhoose-platform-text">Start Time</p>
        <select className="form-select custom-input datepicker-custom" onChange={handletime}>
          <option defaultValue={""}>Time</option>
          {generateTimeOptions()}
        </select>
      </div>
    </>
  );
};

export default Calenderdatepicker;
