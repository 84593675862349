import React from "react";
import WrapperComp from "../../../components/WrapperComp/index.jsx";
import { Box, Button, Stack, Typography } from "@mui/material";
import style from "./index.js";

const NewPassword = () => {
  return (
    <>
      <WrapperComp
        firstComp={
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography sx={style.boldText} variant="h4">
              Enter New <br /> Password
            </Typography>
            <Typography sx={style.text} variant="p">
              Enter your email address or phone number. You will receive <br />
              a message with verification link or 4 code digits to your <br />
              email or phone number.
            </Typography>
          </Stack>
        }
        secondComp={
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <label style={style.inputLabel}>New Password</label>
            <input type="text" style={style.inputStyles} />
            <label style={{ ...style.inputLabel, top: "190px" }}>
              Confirm New Password
            </label>
            <input type="password" style={style.inputStyles} />
            <Button sx={style.saveButton} variant="contained">
              Save
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default NewPassword;
