import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import avatarIMg from "../../assets/images/vertical_container.png";
import OptionIcon from "./OptionIcon";
import globeIcon from "../../assets/SVG/gridicons_globe.svg";
import pointIcon from "../../assets/SVG/gray-sm-point.svg";
import fbIcon from "../../assets/images/fbLike.png";
import fbLike from "../../assets/images/instaLike.png";
import emojiIcon from "../../assets/images/Facebook-emoji.png";
import likeBtn from "../../assets/images/Like-button.png";
import commentBtn from "../../assets/images/Comment-button.png";
import shareBtn from "../../assets/images/Share-button.png";

const style = {
  container: {
    width: "20%",
    height: "58vh",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4% 4% 1% 4%",
  },
  headerText: {
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "8px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  alignLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: "10px",
    fontWeight: 400,
    color: "#65676B",
  },
  line: {
    width: "92%",
    marginLeft: "4%",
    display: "flex",
    padding: "1.5px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "9px",
    alignSelf: "stretch",
    borderBottom: "0.533px solid #D8DADD",
  },
  btn: {
    cursor: "pointer",
  },
};

const FacebookCard = ({ image, customStyle }) => {
  return (
    <>
      <div style={{ ...style.container, ...customStyle }}>
        <Box sx={style.header}>
          <Stack direction="row" spacing={1}>
            <img width="25px" height="25px" src={avatarIMg} alt="error" />
            <Stack sx={{ paddingTop: "2%" }} direction="column" spacing={0.3}>
              <Typography sx={{ ...style.headerText }}>iL Melograno</Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography
                  sx={{
                    ...style.headerText,
                    color: "#65676B",
                    fontWeight: 400,
                  }}
                >
                  Sponsored
                </Typography>
                <Box sx={style.alignLayout}>
                  <img width="5px" height="5px" src={pointIcon} alt="error" />
                </Box>
                <img width="8px" height="8px" src={globeIcon} alt="error" />
              </Stack>
            </Stack>
          </Stack>
          <OptionIcon style={{ paddingLeft: "45%" }} />
        </Box>
        <Typography
          sx={{
            ...style.headerText,
            fontWeight: 400,
            padding: "0% 0% 1% 4%",
          }}
        >
          Some default post text here... <br /> I have nothing to say but you
          need to buy it.
        </Typography>
        <img width="100%" src={image} alt="error" />
        <Stack
          sx={{ padding: "4%" }}
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} sx={{ position: "relative" }}>
            <img
              style={{ zIndex: 2 }}
              width="13px"
              height="13px"
              src={fbIcon}
              alt="fbIconError"
            />
            <img
              style={{ position: "absolute", left: "-15%", zIndex: 1 }}
              width="13px"
              height="13px"
              src={fbLike}
              alt="fbLikeError"
            />
            <img
              style={{ position: "absolute", left: "-1%", bottom: "-20%" }}
              width="18px"
              height="18px"
              src={emojiIcon}
              alt="fbLikeError"
            />
            <Typography
              sx={{
                ...style.headerText,
                fontSize: "10px",
                fontWeight: 400,
                color: "#65676B",
                padding: "2% 10%",
              }}
            >
              177
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <Typography
              sx={{
                ...style.headerText,
                ...style.text,
              }}
            >
              42 Comments
            </Typography>
            <Typography
              sx={{
                ...style.headerText,
                ...style.text,
              }}
            >
              5 Shares
            </Typography>
          </Stack>
        </Stack>
        <Box sx={style.line}></Box>
        <Stack direction="row" spacing={0}>
          <img style={style.btn} width="33%" src={likeBtn} alt="likeBtnError" />
          <img
            style={style.btn}
            width="33%"
            src={commentBtn}
            alt="commentBtnError"
          />
          <img
            style={style.btn}
            width="33%"
            src={shareBtn}
            alt="shareBtnError"
          />
        </Stack>
        <Box sx={style.line}></Box>
      </div>
    </>
  );
};

export default FacebookCard;
