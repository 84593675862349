import React from 'react'
import Tabview from './Tabview'
import { CampaignContext } from '../../Context/CampaignContext'

function NewEvents() {
  return (
    <CampaignContext>
        <Tabview/>
    </CampaignContext>
  )
}

export default NewEvents