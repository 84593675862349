import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./Stories.js";
import IMG1 from "../../../assets/images/Slide1.png";
import IMG2 from "../../../assets/images/Slide2.png";
import IMG3 from "../../../assets/images/Slide3.png";
import HorizontalSlider from "../../Common/HorizontalSlider/HorizontalSlider.jsx";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getmainTemplateAPI } from "../../../apis/auth/index.jsx";
import Customslider from "../../Common/HorizontalSlider/Customslider.jsx";

const images = [
  { imgUrl: IMG3, text: "" },
  { imgUrl: IMG1, text: "Summer Season" },
  { imgUrl: IMG2, text: "Our chef" },
  { imgUrl: IMG3, text: "Farm to table" },
  { imgUrl: IMG1, text: "Chefs creation" },
  { imgUrl: IMG2, text: "Our chef" },
  { imgUrl: IMG3, text: "Farm to table" },
];

const Stories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [templateAll, setTemplateAll] = useState(images);

  const handleClick = (data) => {
    // console.log(data);
    // dispatch(storeInLocalStore({ eventTitle: data }));
    navigate("/new-events");
  };
  const getMainTemplatedata = () => {
    getmainTemplateAPI().then((res) => {
      console.log(res);
      let tempData = res.template.map((item) => ({
        id: item._id,
        imgUrl: item.image_url_for_card,
        text: item.title,
      }));
      setTemplateAll(tempData);
    });
  };
  useEffect(() => {
    getMainTemplatedata();
    dispatch(storeInLocalStore({ eventTitle: "" }));
  }, []);
  return (
    <>
      <Box sx={style.mainContainer}>
        <Box className="customcontainerglobal">
          <Box sx={{ position: "relative" }}>
            <Typography sx={style.title} variant="h3">
              Stories
            </Typography>
            <Typography sx={style.titleShadowStyle} variant="h3">
              Stories
            </Typography>
          </Box>
          <HorizontalSlider images={templateAll} onClick={handleClick} />
          <Box sx={style.button}>
            <Typography variant="h8">Explore</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Stories;
