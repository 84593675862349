const style = {
  mainContainer: {
    padding: "2% 10% 0% 13%",
    background: "#000000",
  },
  title: {
    padding: "0px",
    color: "#9E9E9E",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  titleBox: {
    margin: "35px 0 10px 0",
  },
  headText: {
    color: "#FCFCFC",
    fontSize: "25px",
    fontFamily: "WhyteInktrap-Black",
    padding: "0px 3px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  tableContainer: { padding: "2% 8% 5% 12%", background: "#000000" },
  headerText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  platformText: {
    color: "#9E9E9E",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    paddingBottom: "5px",
  },
  confirmBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  dayText: {
    color: "#eb2426",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  startDateContainer: {
    margin: "10%",
  },
  startDataText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  dateText: {
    color: "#FCFCFC",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    padding: "5px 5px 0px 10px",
  },
  boldText: {
    color: "#FCFCFC",
    fontSize: "40px",
    textAlign: "center",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 600,
    lineHeight: "1.2",
    marginTop: "50px",
    textTransform: "uppercase",
  },
  
};

export default style;
