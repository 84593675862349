import React, { useState } from "react";
import "./instastyle.css";
import rightarrow from "./../../../assets/SVG/RightArrow.svg";
import circleIcon from "../../../assets/images/circleInstaImg.png";
import instaLike from "../../../assets/SVG/black-insta-like.svg";
import instaComment from "../../../assets/SVG/black-insta-comment.svg";
import instaShare from "../../../assets/SVG/black-insta-share.svg";
import instaBookmark from "../../../assets/SVG/black-insta-bookmark.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditButton from "../EditButton";
import {
  selectedPlatform,
  storeInLocalStore,
} from "../../../redux/reducers/mainSlice";

const InstaView = ({
  restaurantName,
  data,
  loader,
  operation,
  handleEditData,
  hide,
  iconurl,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = (data) => {
    let tempdata = { ...data?.alldata, selectedplatform: "instagram" };
    dispatch(storeInLocalStore({ ediatablepost: tempdata }));
    dispatch(selectedPlatform("insta"));
    navigate("/edit-posts");
  };
  return (
    <>
      <div className="instapostcontainer">
        <div
          className="post-image"
          style={{ backgroundImage: `url(${data?.imgUrl})` }}
        >
          <h1 className={`eventtitle ${hide}`}>{data?.event}</h1>
          {!data?.alldata?.is_posted && (
            <div className={`editbtn ${hide}`}>
              <EditButton
                onClick={() => {
                  handleEdit(data);
                }}
              />
            </div>
          )}

          {/* <img
            src={iconurl || circleIcon}
            className={`logoicon ${hide}`}
            alt="circleError"
          /> */}
        </div>
      </div>
      <div className={`shop-now ${hide}`}>
        <div className="text">
          <span>Shop Now</span>
        </div>
        <div className="arrow">
          <img src={rightarrow} />
        </div>
      </div>
      <div
        className="shop-now"
        style={{
          paddingBottom: hide ? "20px" : "",
        }}
      >
        <div className="left-btn">
          <img src={instaLike} className="insta-icon" />
          <img src={instaComment} className="insta-icon" />
          <img src={instaShare} className="insta-icon" />
        </div>
        <div className="right-btn">
          <img src={instaBookmark} className="insta-icon" />
        </div>
      </div>
      <div className={`shop-now ${hide}`}>
        <span className="description">{data.description}</span>
      </div>
    </>
  );
};

export default InstaView;
