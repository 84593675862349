const style = {
  mainContainer: {
    padding: "6% 8% 0% 8%",
    background: "#00000",
  },
  tableContainer: { padding: "2% 8% 5% 13%", background: "#000000" },
  headerText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  platformText: {
    color: "#9E9E9E",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    paddingBottom: "5px",
  },
  confirmBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  dayText: {
    color: "#eb2426",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  startDateContainer: {
    margin: "10%",
  },
  startDataText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  dateText: {
    color: "#FCFCFC",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    padding: "5px 5px 0px 10px",
  },
};

export default style;
