import React, { useEffect, useState } from "react";
import Monthlydata from "./monthlydata/Monthlydata";
import Editpost from "./Editpost/Editpost";
import { useLocation, useNavigate } from "react-router-dom";
import {
  confirmsmpostcalenderAPI,
  fetchProfileAPI,
  getCaptionsAPI,
  imageUpload,
  postagreeAPI,
  updateCaptionAPI,
} from "../../../apis/auth";
import GlobalAlert from "../../Common/GlobalAlert";
import { storeInLocalStore } from "../../../redux/reducers/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchprofile } from "../../../CustomHook/addressUtills";

const Monthlycalender = ({ allData, fetchData }) => {
  const [editdata, setEditdata] = useState({
    id: "",
    cuisine_story: "",
    output_image: "",
  });
  const profileData = useSelector((state) => state?.main?.store?.profile_data);
  const users = useSelector((state) => state?.main?.store?.users);
  const [createcalenderFlag, setCreatingCalender] = useState();
  const [selecttime, setselectTime] = useState("");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [confirmbuttonFlag, setConfirmClanderFlag] = useState();
  const [loader, setLoader] = useState(false);
  const [savemessage, setSaveMessage] = useState("");
  const navigate = useNavigate();
  const handlePopup = (data) => {
    setEditdata({
      ...data,
      output_image: data?.prompt_output_img_url,
      cuisine_story: data?.prompt_output_text,
    });
  };
  const blankdata = () => {
    setEditdata({
      id: "",
      cuisine_story: "",
      output_image: "",
    });
  };
  const handleConfirmcalendar = () => {
    if (selecttime) {
      if (localStorage.getItem("token")) {
        if (users?.fb_connected) {
          setCreatingCalender(true);
          let payload = {
            profile_id: profileData?.profile_id,
            start_time: selecttime,
          };
          confirmsmpostcalenderAPI(payload)
            .then((res) => {
              if (res.status) {
                let payload = {
                  user_id: users?.id,
                  profile_id: profileData?.profile_id,
                  is_trial: true,
                };
                postagreeAPI(payload)
                  .then((res) => {
                    if (res.status) {
                      fetchprofile({
                        profile_id: profileData?.profile_id,
                      })
                        .then((result) => {
                          if (result) {
                            setCreatingCalender(false);
                            dispatch(
                              storeInLocalStore({
                                profile_data:
                                  result?.dispachabledata?.profile_data,
                              })
                            );
                            dispatch(
                              storeInLocalStore({
                                users: result?.dispachabledata?.users,
                              })
                            );
                            setTimeout(() => {
                              navigate("/my-social-media");
                              dispatch(storeInLocalStore({ moveto: "" }));
                            }, 500);
                          }
                        })
                        .catch((rej) => {
                          console.log(rej);
                        });
                    }
                  })
                  .catch((rej) => {
                    console.log(rej);
                  });
              }
            })
            .catch((rej) => {
              console.log(rej);
            });
        } else {
          dispatch(storeInLocalStore({ useravailable: false }));
          dispatch(storeInLocalStore({ moveto: pathname }));
          dispatch(storeInLocalStore({ currentcomponentid: 4 }));
          dispatch(storeInLocalStore({ calendertime: selecttime }));
          navigate("/social-media-connect");
        }
      } else {
        dispatch(storeInLocalStore({ useravailable: false }));
        dispatch(storeInLocalStore({ moveto: pathname }));
        dispatch(storeInLocalStore({ currentcomponentid: 4 }));
        dispatch(storeInLocalStore({ calendertime: selecttime }));
        navigate("/signup");
      }
    } else {
      setSaveMessage("Please select time.");
    }
  };
  const handleChanges = (data) => {
    setEditdata({
      ...editdata,
      cuisine_story: data,
    });
  };
  const handlesave = () => {
    if (
      editdata?.cuisine_story != editdata?.prompt_output_text ||
      editdata?.output_image != editdata?.prompt_output_img_url
    ) {
      setLoader(true);
      let data = {
        profile_id: editdata?.profile_id,
        prompt_output_text: editdata?.cuisine_story,
        prompt_output_img_url: editdata?.output_image,
        date: editdata?.date,
      };

      updateCaptionAPI(data)
        .then((res) => {
          if (res?.status) {
            setLoader(false);
            setSaveMessage("Post is Updated Successfully.");
          }
        })
        .catch((err) => {
          console.log("Error ", err);
          setLoader(false);
        });
    } else {
      blankdata();
    }
  };
  const handleImageupload = (e) => {
    if (editdata.date) {
      const file = e.target.files[0];
      if (file) {
        const newFileName = e.target.files[0].name.replace(/\([^()]*\)/g, "");
        const modifiedFile = new File([file], newFileName, { type: file.type });
        setLoader(true);
        imageUpload({
          id: editdata.profile_id,
          date: editdata.date,
          image: modifiedFile,
        })
          .then((res) => {
            if (res?.status) {
              setLoader(false);
              setEditdata({
                ...editdata,
                output_image: res?.image_url,
              });
            } else {
              setLoader(false);
              setSaveMessage(res.message);
            }
          })
          .catch((rej) => {
            console.log(rej);
            setLoader(false);
          });
      }
    } else {
      setSaveMessage("Please save the post before upload.");
    }
  };
  const generatecaption = (caption) => {
    let payload = {
      profile_id: editdata?.profile_id,
      date: editdata?.date || "",
      refresh: true,
      caption_type: "N/A",
      caption_response: caption,
    };
    setLoader(true);
    getCaptionsAPI(payload)
      .then((res) => {
        if (res.status) {
          if (caption == "image") {
            setEditdata({
              ...editdata,
              output_image: res?.captions?.prompt_output_img_url,
            });
          }
        }
        setLoader(false);
      })
      .catch((res) => {
        console.log(res);
        setLoader(false);
      });
  };
  const handlePopupclode = () => {
    setSaveMessage("");
    fetchData();
    blankdata();
  };

  return (
    <>
      {editdata?.id != "" ? (
        <Editpost
          data={editdata}
          handleback={blankdata}
          handleChanges={handleChanges}
          handlesave={handlesave}
          handleImageupload={handleImageupload}
          generatecaption={generatecaption}
          loader={loader}
        />
      ) : (
        <Monthlydata
          allData={allData}
          handlePopup={handlePopup}
          handleConfirmcalendar={handleConfirmcalendar}
          setselectTime={setselectTime}
        />
      )}
      {savemessage && (
        <GlobalAlert message={savemessage} handleClose={handlePopupclode} />
      )}
    </>
  );
};

export default Monthlycalender;
