import { configureStore } from "@reduxjs/toolkit";
import restaurantSlice from "./reducers/restaurantSlice";
import locationSlice from "./reducers/locationSlice";
import cuisineSlice from "./reducers/cuisineSlice";
import mainReducer from "./reducers/mainSlice";

const store = configureStore({
  reducer: {
    resturant: restaurantSlice,
    location: locationSlice,
    cuisine: cuisineSlice,
    main: mainReducer,
  },
});

export default store;
