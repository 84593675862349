import React from "react";
import decisionimage from "../../../assets/images/home/decisions.png";
import { useNavigate } from "react-router-dom";

const Decisions = ({ firstfunction, secondfunct }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="full-width py-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="content-padding-left">
              <h1 className="title">
                Data-Driven
                <br /> <span className="bold-inner">Decisions</span>
              </h1>
            </div>
            <div className="custom-bg overflow-shadow">
              <div className="actual-content-area">
                <div className="content-padding-left">
                  <p className="text-design">
                    Our platform provides clear metrics on the cost of acquiring
                    customers and their lifetime value, allowing you to make
                    informed decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-padding-right">
              <img src={decisionimage} className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="full-width py-5 custom-bg">
        <div className="">
          <div className="row">
            <div className="content-padding-both">
              <div className="col-md-12 text-center">
                <h1 className="title">
                  Unified{" "}
                  <span className="bold-inner">Digital Engagement Program</span>
                </h1>
              </div>
              <div className="d-flex justify-content-center">
                <div className="banner-btn-area ">
                  <button
                    //onClick={btnonefunction}
                    className="byteready-btn byteready-outlinne"
                    onClick={firstfunction}
                  >
                    Free Trial 30 Days
                  </button>
                  <button
                    //   onClick={btntwofunction}
                    onClick={secondfunct}
                    className="byteready-btn byteready-primary"
                  >
                    Start $399/Month
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Decisions;
