const style = {
  container: {
    padding: "88px 0 50px 0",
    backgroundColor: "#000000",
    color: "#FCFCFC",
    width: "100%",
    // height: "100%"
  },
  mainContainer: {
    padding: 0,
    maxWidth: "100%",
    position: "relative",
  },
  textContainer: {
    paddingLeft: "135px",
  },
  text: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  circleContainer: {
    position: "relative",
    margin: "20px",
    width: "95%",
    height: "650px",
    borderRadius: "0 0 650px 650px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: "blur(8px)",
  },
  ipadBox: {
    position: "absolute",
    width: "50%",
    height: "80%",
    top: "10%",
    left: "25%",
  },
  btnBox: {
    position: "absolute",
    // width: "100%",
    height: "80%",
    top: "10%",
    right: "5%",
  },
  browseBtn: {
    width: "100%",
    color: "#eb2426",
    backgroundColor: "#FFF",
    fontFamily: "SF Pro Display",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "normal",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#eb2426",
    },
  },
  createText: {
    color: "#fff",
    fontFamily: "SF Pro Display",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "normal",
  },
  ipadFrame: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  imgStyle: {
    width: "91.5%",
    height: "88%",
    marginBottom: "15px",
    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textCover: {
    position: "absolute",
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "60px",
    fontWeight: 400,
    lineHeight: "1.2",
    textAlign: "center",
    textTransform: "uppercase",
    top: "40%",
    left: "-26%",
  },
};

export default style;
