import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import googleContainer from "../../../assets/images/postview/google.png";
import style from "./GoogleViewStyle";
import profileImg from "../../../assets/images/circleInstaImg.png";
import post from "../../../assets/images/events-3.png";
import PreviousBtn from "../../Common/PreviousBtn";
import NextBtn from "../../Common/NextBtn";
import { useSelector } from "react-redux";
import UpdateEventPopUp from "../../NewEvents/EventPopOver/UpdateEventPopUp";

function GoogleView({
  data,
  profile,
  showCustomHandleButton,
  functionOnModalClose,
}) {
  const itemsPerPage = 3;
  const [startIndex, setStartIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState();
  const [popUpData, setPopUpData] = useState();
  const [campaignData, setCampaignData] = useState([]);
  const visibleItems = data.slice(startIndex, startIndex + itemsPerPage);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const getCaption = (data) => {
    let caption = data?.filter((x) => x.platform == "facebook");
    console.log(caption?.[0]?.caption, "CAPTION");
    return caption?.[0]?.caption;
  };
  const handleNext = (items) => {
    console.log("CAMPAIGN DATAAAAAAAAA");
    if (startIndex + itemsPerPage < data.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  const handlePrevious = (items) => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    if (showCustomHandleButton) {
      setCampaignData(visibleItems);
    } else {
      setCampaignData(data);
    }
  }, [data, showCustomHandleButton, visibleItems]);
  return (
    <>
      {showCustomHandleButton && data?.length > 3 && (
        <PreviousBtn width={"50px"} handlePrevious={() => handlePrevious()} />
      )}
      {campaignData?.map((campaign, index) => {
        return (
          <>
            {open && (
              <UpdateEventPopUp
                platform={"google ad"}
                functionOnModalClose={functionOnModalClose}
                profile_id={profile_id}
                data={popUpData}
                tab={tab}
                open={open}
                handleClose={() => setOpen(false)}
              />
            )}
            <Box
              key={index}
              onClick={() => {
                !campaign?.is_posted && setOpen(true);
                setTab(campaign?.type);
                setPopUpData({
                  ...campaign,
                  desc:
                    campaign?.other_platforms?.[0]?.caption ||
                    campaign?.description,
                });
              }}
              sx={{
                ...style.mainWrapper,
                backgroundImage: `url(${googleContainer})`,
                cursor: "pointer",
              }}
            >
              <Box sx={style.googleHeading}>
                <img
                  src={profileImg}
                  width={"40px"}
                  style={{ marginLeft: "15px" }}
                />
                <Typography ml={1} color={"#000"} fontWeight={"600"}>
                  {profile?.resturant_name}
                </Typography>
              </Box>
              <Box
                style={{
                  ...style.post,
                  backgroundImage: `url(${campaign?.image_url})`,
                }}
              >
                {/* <Typography style={style.postHeadingText}>
                  {campaign?.title}
                </Typography> */}
              </Box>
            </Box>
          </>
        );
      })}
      {showCustomHandleButton && data?.length > 3 && (
        <NextBtn width={"50px"} handleNext={() => handleNext()} />
      )}
    </>
  );
}

export default GoogleView;
