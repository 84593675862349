import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { yelpsettingAPI } from "../../apis/auth";
import { useSelector } from "react-redux";

const Yelpconnect = ({ yelpconnect, handleClose }) => {
  const [url, setUrl] = useState("");
  const userid = useSelector((state) => state.main?.store?.users?.id);
  const profile_id = useSelector(
    (state) => state.main?.store?.profile_data?.profile_id
  );
  const [alertmessage, setAlertmessage] = useState("");
  const refineyelpurl = (input) => {
    const yelpUrlPattern = /^https:\/\/www\.yelp\.com\/biz\/(.+)$/;
    const businessIdPattern = /^([a-zA-Z0-9-]+)$/;
    let businessId = "";
    const urlMatch = input.match(yelpUrlPattern);
    const idMatch = input.match(businessIdPattern);
    if (urlMatch) {
      businessId = urlMatch[1];
    } else if (idMatch) {
      businessId = idMatch[1];
    } else {
      return;
    }
    return businessId;
  };

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };
  const handleConnectClick = () => {
    let yelpurl = refineyelpurl(url);
    if (yelpurl) {
      console.log("yelpurl", yelpurl);
      yelpsettingAPI({
        user_id: userid,
        profile_id: profile_id,
        business_id: yelpurl,
      })
        .then((res) => {
          if (res.status) {
            handleClose();
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    } else {
      setAlertmessage("ID Not Found");
    }
    setTimeout(() => {
      setAlertmessage("");
    }, 3000);
  };
  return (
    <>
      <Modal
        show={yelpconnect}
        size=" lg"
        className="yelp-modal"
        centered
        onHide={handleClose}
      >
        <Modal.Body>
          <p>Yelp Url or Business Id</p>
          <div className="form-group my-3">
            <input
              type="text"
              className="form-control custom-input"
              value={url}
              onChange={handleInputChange}
            />
            {alertmessage && (
              <span className="py-2 alert-message">{alertmessage}</span>
            )}
          </div>

          <button
            className="byteready-btn byteready-primary"
            onClick={handleConnectClick}
          >
            Connect
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Yelpconnect;
