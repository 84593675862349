const style = {
  container: {
    margin: "10% 2%"
  },
  headerText: {
    color: "#FCFCFC",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "65px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
    textAlign: "center",
  },
};

export default style;
