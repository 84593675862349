import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import {
  VerifyComp,
  NewPassword,
  CongrsScreen,
} from "../pages/AuthPages/index.jsx";
import Auth from "../components/Authentication/Auth";
import Calendar from "../pages/Calendar/Calendar.jsx";
import allurls from "./allUrls";
import SocialmediaConnect from "../pages/AuthPages/Socialconnect/SocialmediaConnect.jsx";
import Agree from "../pages/SocialMedia/Agree";
import Congratulation from "../pages/SocialMedia/Congratulation";
import EventPage from "../pages/EventPage/EventPage";
import Reviews from "../pages/Reviews/Reviews";
import EditPosts from "../pages/EditPosts/EditPosts";
import SavedCampaign from "../pages/SavedCampaign/SavedCampaign";
import MediaPage from "../pages/MediaPage/MediaPage";
import Media from "../pages/Media/Media";
import PaymentSummary from "../pages/PaymentSummary/PaymentSummary";
import StoriesPage from "../pages/Stories/StoriesPage";
import Session from "../components/Authentication/Session";
import Dashboard from "../pages/Dashboard/Dashboard";
import Thankyou from "../pages/Thankyou/Thankyou";
import ConfirmUser from "../pages/UserConfirm/ConfirmUser";
import Newhome from "../pages/Home.jsx";
import Scribeaipage from "../pages/Scribeaipage";
import Createcalender from "../pages/Createcalender";
import Unifiedgenerate from "../pages/Unifiedgenerate";
import Mysavedsocialmedia from "../pages/Mysavedsocialmedia";
import Mycustomer from "../pages/MyCustomer/Mycustomer.jsx";
import Insightpage from "../pages/Insightpage/Insightpage.jsx";
import CreatecalenderTab from "../pages/CreatecalenderTab.jsx";
import NewEvents from "../components/NewEvents/NewEvents.jsx";
import FacebookMobileView from "../components/commoncomponents/FacebookMobileView/FacebookMobileView.jsx";
import PaidMedia from "../pages/PaidMedia/PaidMedia.jsx";
import Myprofile from "../pages/Myprofile/Myprofile.jsx";
import Campaigs from "../components/mysocialmedia/customerjourney/Campaigs.jsx";
import AddPaymentMethodPage from "../pages/PaymentPage/AddPaymentMethodPage.jsx";
import GetStartedPaymentPage from "../pages/PaymentPage/GetStartedPaymentPage.jsx";
import PaymentForm from "../pages/PaymentPage/PaymentForm.jsx";
import PaymentHistoryPage from "../pages/PaymentPage/PaymentHistoryPage.jsx";
import Bootscongratulation from "../pages/PaymentSummary/Bootscongratulation.jsx";
import PaymentFail from "../pages/PaymentSummary/PaymentFail.jsx";
import Paymentdone from "../pages/PaymentSummary/Paymentdone.jsx";
import Newlogin from "../pages/AuthPages/Login/Newlogin.jsx";
import Newsignup from "../pages/AuthPages/SignUp/Newsignup.jsx";
import Profilesetup from "../pages/AuthPages/SignUp/Profilesetup.jsx";
import Authenticate from "../pages/Authenticate.jsx";
import Addlocations from "../pages/Addlocations.jsx";
import Thoughtspot from "../pages/Thoughtspot";
import Customerbehaviour from "../pages/Customerbehaviour";
import Customerdata from "../pages/Customerdata.jsx";

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <Session>
            <Newhome />
          </Session>
        ),
      },

      {
        path: `${allurls.login}`,
        element: (
          <Session>
            <Newlogin />
          </Session>
        ),
      },
      {
        path: `${allurls.locations}`,
        element: (
          // <Session>
          <Addlocations />
          // </Session>
        ),
      },
      {
        path: `${allurls.signUp}`,
        element: (
          <Session>
            <Newsignup />
          </Session>
        ),
      },
      {
        path: `${allurls.profileseting}`,
        element: (
          <Session>
            <Profilesetup />
          </Session>
        ),
      },
      {
        path: `${allurls.verify}`,
        element: (
          <Session>
            <VerifyComp />
          </Session>
        ),
      },

      {
        path: `${allurls.newpassword}`,
        element: (
          <Session>
            <NewPassword />
          </Session>
        ),
      },
      {
        path: `${allurls.congratulations}`,
        element: (
          <Session>
            <CongrsScreen />
          </Session>
        ),
      },
      {
        path: `${allurls.calendar}`,
        element: (
          <Session>
            <Calendar />
          </Session>
        ),
      },
      {
        path: `${allurls.socialmedia}`,
        element: (
          <Session>
            <SocialmediaConnect />
          </Session>
        ),
      },
      {
        path: `${allurls.agree}`,
        element: (
          <Session>
            <Agree />
          </Session>
        ),
      },
      {
        path: `${allurls.congratulation}`,
        element: (
          <Session>
            <Congratulation />
          </Session>
        ),
      },
      {
        path: `${allurls.events}`,
        element: (
          <Session>
            <EventPage />
          </Session>
        ),
      },
      {
        path: `${allurls.reviews}`,
        element: (
          <Session>
            <Reviews />
          </Session>
        ),
      },
      {
        path: `${allurls.editMedia}`,
        element: (
          <Session>
            <MediaPage />
          </Session>
        ),
      },
      {
        path: `${allurls.editPosts}`,
        element: (
          <Session>
            <EditPosts />
          </Session>
        ),
      },
      {
        path: `${allurls.viewMedia}`,
        element: (
          <Session>
            <MediaPage />
          </Session>
        ),
      },
      {
        path: `${allurls.media}`,
        element: (
          <Session>
            <Auth>
              <Media />
            </Auth>
          </Session>
        ),
      },
      {
        path: `${allurls.savedcampaign}`,
        element: (
          <Session>
            <SavedCampaign />
          </Session>
        ),
      },
      {
        path: `${allurls.paymentSummary}`,
        element: (
          <Session>
            <PaymentSummary />
          </Session>
        ),
      },
      {
        path: `${allurls.stories}`,
        element: (
          <Session>
            <StoriesPage />
          </Session>
        ),
      },
      {
        path: `${allurls.dashboard}`,
        element: (
          <Session>
            <Dashboard />
          </Session>
        ),
      },
      {
        path: `${allurls.thankyou}`,
        element: <Thankyou />,
      },
      {
        path: `${allurls.emailverify}`,
        element: <ConfirmUser />,
      },
      {
        path: `${allurls.scribeai}`,
        element: (
          <Session>
            <Scribeaipage />
          </Session>
        ),
      },
      {
        path: `${allurls.createcalender}`,
        element: (
          <Session>
            <Createcalender />
          </Session>
        ),
      },
      {
        path: `${allurls.createcalendertab}`,
        element: (
          <Session>
            <CreatecalenderTab />
          </Session>
        ),
      },
      {
        path: `${allurls.unifyingdigital}`,
        element: (
          <Session>
            <Unifiedgenerate />
          </Session>
        ),
      },
      {
        path: `${allurls.mysavedsocialmedia}`,
        element: (
          <Session>
            <Auth>
              <Mysavedsocialmedia />
            </Auth>
          </Session>
        ),
      },
      {
        path: `${allurls.mycustomer}`,
        element: (
          <Session>
            <Auth>
              <Mycustomer />
            </Auth>
          </Session>
        ),
      },
      {
        path: `${allurls.insight360}`,
        element: (
          <Session>
            <Auth>
              <Insightpage />
            </Auth>
          </Session>
        ),
      },
      {
        path: `${allurls.myprofile}`,
        element: (
          <Session>
            <Auth>
              <Myprofile />
            </Auth>
          </Session>
        ),
      },
      {
        path: `${allurls.newevents}`,
        element: (
          <Session>
            <NewEvents />
          </Session>
        ),
      },
      {
        path: `${allurls.mypaidmedia}`,
        element: (
          <Session>
            <PaidMedia />
          </Session>
        ),
      },
      {
        path: `${allurls.customerjourney}`,
        element: (
          <Session>
            <Campaigs />
          </Session>
        ),
      },
      {
        path: `${allurls.payment}`,
        element: (
          <Session>
            <GetStartedPaymentPage />
          </Session>
        ),
      },
      {
        path: `${allurls.addPayment}`,
        element: (
          <Session>
            <AddPaymentMethodPage />
          </Session>
        ),
      },
      {
        path: `${allurls.paymentForm}`,
        element: (
          <Session>
            <PaymentForm />
          </Session>
        ),
      },
      {
        path: `${allurls.paymentHistory}`,
        element: (
          <Session>
            <PaymentHistoryPage />
          </Session>
        ),
      },
      {
        path: `${allurls.boostsuccessfullurl}`,
        element: <Bootscongratulation />,
      },
      {
        path: `${allurls.paymentfail}`,
        element: <PaymentFail />,
      },
      {
        path: `${allurls.paymentdone}`,
        element: <Paymentdone />,
      },
      {
        path: `${allurls.authenticate}`,
        element: <Authenticate />,
      },
      {
        path: `${allurls.testhought}`,
        element: (
          <Session>
            <Thoughtspot />
          </Session>
        ),
      },
      {
        path: `${allurls.customerbehaviour}`,
        element: (
          <Session>
            <Customerbehaviour />
          </Session>
        ),
      },
      {
        path: `${allurls.customerdata}`,
        element: (
          <Session>
            <Customerdata />
          </Session>
        ),
      },
    ],
  },
]);
