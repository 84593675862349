import React from "react";
import unifyingbanner from "../../../assets/images/home/unifying-banne.png";
import Chaticon from "../../Common/Chaticon";
import "./Banner.css";
import { useNavigate } from "react-router-dom";

const Banner = ({ firstfunction, secondfunct }) => {
  return (
    <div className="full-width headerpadding unifying-banner mb-5">
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <h1 className="title">
                <span className="bold-inner">Unifying</span> Digital
                <br /> Engagement Program
              </h1>
              <p className="text-design">
                AI-Driven Content Creation, targeted Distribution, and Strategic
              </p>
              <p className="text-design">Optimization for Maximized ROI.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="banner-area">
              <img src={unifyingbanner} className="w-100" />
              <div className="banner-btn-area">
                <button
                  onClick={firstfunction}
                  className="byteready-btn byteready-outlinne"
                >
                  Free Trial 30 Days
                </button>
                <button
                  onClick={secondfunct}
                  className="byteready-btn byteready-primary"
                >
                  Start $399/Month
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Chaticon /> */}
    </div>
  );
};

export default Banner;
