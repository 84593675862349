import React, { useEffect, useState } from "react";
import Socialmediachoose from "../../commoncomponents/Socialmediachoose";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";
import InstaMobileView from "../../commoncomponents/InstaMobileView/InstaMobileView";
import FacebookMobileView from "../../commoncomponents/FacebookMobileView/FacebookMobileView";
import GoogleView from "../../commoncomponents/GoogleView/GoogleView";
import GmailMobileView from "../../commoncomponents/GmailMobileView/GmailMobileView";
import SmsView from "../../commoncomponents/SMSView/SmsView";
import { useSelector } from "react-redux";
import PreviousBtn from "../../Common/PreviousBtn";
import NextBtn from "../../Common/NextBtn";
import { getallcampaignAPI } from "../../../apis/auth";
const tabStyles = {
  labelStyle: {
    color: "black",
  },
  selectedLabelStyle: {
    color: "blue", // Change label color when active
  },
  iconStyle: {
    fill: "black",
  },
  selectedIconStyle: {
    fill: "green", // Change icon color when active
  },
  indicator: {
    display: "none", // Remove the indicator (border)
  },
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, handleNext, handlePrevious, ...other } =
    props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className="d-flex justify-content-center align-items-center"
          sx={{ p: 3 }}
        >
          <Box className="d-flex align-items-center">{children}</Box>
        </Box>
      )}
    </div>
  );
}
const Mypaidmedia = () => {
  const [value, setValue] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [campaignData, setCampaignData] = useState();
  const [campId, setCampId] = useState();
  const [mobileViewData, setMobileViewData] = useState();
  const profileData = useSelector((state) => state.main.store?.profile_data);
  const profile_id = useSelector(
    (state) => state.main.store?.profile_data?.profile_id
  );
  const itemsPerPage = 3;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNext = (items) => {
    if (startIndex + itemsPerPage < items.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const handlePrevious = (items) => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  const getAllCampaign = () => {
    let data = {
      profile_id: profile_id,
      unit_id: 1,
    };
    getallcampaignAPI(data)
      .then((res) => {
        if (res.status == "success") {
          setCampaignData(res?.campaign);
          let tempCamp = [];
          for (let i = 0; i < res?.campaign?.length; i++) {
            const visibleItems = res?.campaign?.[i]?.add_unit_ids;
            tempCamp?.push({
              name: res?.campaign?.[i]?.name,
              visibleItems: visibleItems,
              totalItems: res?.campaign?.[i]?.add_unit_ids,
            });
          }
          setCampaignData(tempCamp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCampaign();
  }, []);
  const functionOnModalClose = async () => {
    getAllCampaign();
  };
  return (
    <>
      <div className="mypaid-media">
        <div className="row  py-3 heading-background">
          <div className="col-md-12">
            <div className="content-padding-both">
              <div className="position-relative">
                <h1 className="title bold-inner">My Paid Media</h1>
              </div>
            </div>
          </div>
        </div>
        {campaignData?.map((x, index) => {
          return (
            <div key={index} className="row campaign-background mb-5">
              <div className="col-md-12">
                <div className="content-padding-both">
                  <div className="d-flex align-items-center justify-content-between py-3">
                    <h1 className="white-subtitle">{x?.name}</h1>
                    <div>
                      <p className="chhoose-platform-text">
                        Choose The Platform
                      </p>
                      <div>
                        <Tabs
                          style={{}}
                          TabIndicatorProps={{ style: tabStyles.indicator }}
                          className="socialmediatabs"
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            style={{ color: "#fff" }}
                            icon={<InstagramIcon />}
                            {...a11yProps(0)}
                          />
                          <Tab
                            style={{ color: "#fff" }}
                            label="f"
                            {...a11yProps(1)}
                          />
                          <Tab
                            style={{ color: "#fff" }}
                            icon={<GoogleIcon />}
                            {...a11yProps(2)}
                          />
                          <Tab
                            style={{ color: "#fff" }}
                            label="@"
                            {...a11yProps(3)}
                          />
                          <Tab
                            style={{ color: "#fff" }}
                            label="SMS"
                            {...a11yProps(4)}
                          />
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-md-12">
                      <div className="content-padding-both">
                        <Box sx={{ maxWidth: "2560px" }}>
                          <CustomTabPanel
                            value={value}
                            index={0}
                            handlePrevious={() => handlePrevious(x.totalItems)}
                            handleNext={() => handleNext(x.totalItems)}
                          >
                            <InstaMobileView
                              data={x.visibleItems}
                              profile={profileData}
                              showCustomHandleButton={true}
                              functionOnModalClose={functionOnModalClose}
                            />
                          </CustomTabPanel>
                          <CustomTabPanel
                            value={value}
                            index={1}
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                          >
                            <FacebookMobileView
                              data={x.visibleItems}
                              profile={profileData}
                              showCustomHandleButton={true}
                              functionOnModalClose={functionOnModalClose}
                            />
                          </CustomTabPanel>
                          <CustomTabPanel
                            value={value}
                            index={2}
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                          >
                            <GoogleView
                              data={x.visibleItems}
                              profile={profileData}
                              showCustomHandleButton={true}
                              functionOnModalClose={functionOnModalClose}
                            />
                          </CustomTabPanel>
                          <CustomTabPanel
                            value={value}
                            index={3}
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                          >
                            <GmailMobileView
                              data={x.visibleItems}
                              profile={profileData}
                              showCustomHandleButton={true}
                              functionOnModalClose={functionOnModalClose}
                            />
                          </CustomTabPanel>
                          <CustomTabPanel
                            value={value}
                            index={4}
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                          >
                            <SmsView
                              data={x.visibleItems}
                              profile={profileData}
                              showCustomHandleButton={true}
                              functionOnModalClose={functionOnModalClose}
                            />
                          </CustomTabPanel>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Mypaidmedia;
