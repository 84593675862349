const style = {
  container: {
    maxWidth: "100%",
    padding: "2% 0% 0% 0%",
    backgroundColor: "#111",
    color: "#FCFCFC",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cards: {
    marginTop: "-2%",
    width: "20%",
    height: "25vh",
    borderRadius: "0px 0px 20px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    background: "#000000"
  },
  label: {
    margin: "5% 0% 2% 0%",
    color: "#9E9E9E",
    fontFamily: "WhyteInktrap-Black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "center",
  },
  mobViewContainer: {
    width: "70%",
    padding: "5% 0% 0% 0%",
    display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
    justifyContent: "center",
  },
  mobViewStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  fabIconStyle: {
    position: "fixed",
    right: "2%",
    bottom: "25%",
    background: "#eb2426",
    "&:hover": {
      background: "#eb2426",
    },
  },
};

export default style;
