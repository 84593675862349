const style = {
  mainContainer: {
    backgroundColor: "#000000",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: "100px",
  },
  text: {
    display: "inline-flex",
    alignItems: "flex-end",
    gap: "16px",
    padding: "5px",
    color: "#FCFCFC",
    fontSize: "13px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
  },
  switchBox: {
    width: "100px",
    height: "50px",
    borderRadius: "50%",
    border: "2px solid #FCFCFC",
  },
  text2: {
    color: "#FCFCFC",
    fontSize: "45px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    lineHeight: "45px",
    textTransform: "uppercase",
    padding: "100px 0px",
  },
  cardBox: {
    padding: "20px",
  },
  dayText: {
    color: "#eb2426",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  dateText: {
    color: "#FCFCFC",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "WhyteInktrap-Black",
    fontWeight: 400,
    padding: "5px 5px 0px 10px"
  },
  saveButton: {
    marginTop: "10px",
    backgroundColor: "#eb2426",
    width: "300px",
    borderRadius: "50px",
    display: "flex",
    padding: "16px 50px",
    justifyContent: "center",
    alignItems: "center",
    background: "#eb2426",
    textTransform: "none",
    "&:hover": {
      background: "#eb2426",
    },
  },
};

export default style;
