const style = {
  container: {
    padding: "88px 0 50px 0",
    backgroundColor: "#000000",
    color: "#FCFCFC",
    width: "100%",
    // height: "100%"
  },
};

export default style;
