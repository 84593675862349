import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import style from "./EditMyMediaStyle";
import MediaHeader from "../MediaHeader";
import InstaView from "../../Common/InstaView/InstaView";
import FacebookView from "../../Common/FacebookView/FacebookView";
import GoogleView from "../../Common/GoogleView/GoogleView";
import SmsView from "../../Common/SmsView/SmsView";
import EmailView from "../../Common/EmailView/EmailView";
import img1 from "../../../assets/images/mediaInstaimg1.png";
import img2 from "../../../assets/images/mediaInstaimg2.png";
import img3 from "../../../assets/images/mediaInstaimg3.png";
import img4 from "../../../assets/images/mediaInstaimg4.png";

const instaViewData = [
  {
    imgUrl: img2,
    event: "Summer Season",
    offers: "$50 for 2",
  },
  {
    imgUrl: img3,
    event: "Special dishes",
    offers: "$50 for 2",
  },
  {
    imgUrl: img4,
    event: "Sunday brunch",
    offers: "$50 for 2",
  },
  {
    imgUrl: img1,
    event: "dinner for 2/1",
    offers: "$50 for 2",
  },
];

const smsViewData = [
  {
    imgUrl: img2,
    event: "Summer Season",
    offers: "$50 for 2",
  },
  {
    imgUrl: img3,
    event: "Special dishes",
    offers: "$50 for 2",
  },
];

const EditMyMedia = () => {
  const [isSocialActive, setIsSocialActive] = useState("insta");
  const [loader, setLoader] = useState(false);

  const handleActiveSocialMedia = (params) => {
    setIsSocialActive(params);
  };
  return (
    <>
      <Box sx={style.container}>
        <MediaHeader
          handleActiveSocialMedia={handleActiveSocialMedia}
          isActive={isSocialActive}
          switchButton="true"
          operation="edit"
        />
        {isSocialActive === "insta" && (
          <Grid sx={{ margin: "20px 0" }} container spacing={2}>
            {instaViewData?.map((data, index) => {
              return (
                <Grid key={index} item xs={12} md={3}>
                  <InstaView operation="edit" data={data} loader={loader} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {isSocialActive === "fb" && (
          <Grid sx={{ margin: "20px 0" }} container spacing={2}>
            {instaViewData?.map((data, index) => {
              return (
                <Grid key={index} item xs={12} md={3}>
                  <FacebookView operation="edit" data={data} loader={loader} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {isSocialActive === "google" && (
          <Grid sx={{ margin: "20px 0" }} container spacing={2}>
            {instaViewData?.map((data, index) => {
              return (
                <Grid key={index} item xs={12} md={3}>
                  <GoogleView data={data} loader={loader} />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Stack sx={{ margin: "2% 0% 0% 10%" }} direction="row" spacing={2}>
          {isSocialActive === "email" && (
            <>
              <EmailView data={smsViewData} loader={loader} />
              <EmailView data={smsViewData} loader={loader} />
            </>
          )}
        </Stack>
        <Stack sx={{ margin: "2% 0% 0% 30%" }} direction="row" spacing={2}>
          {isSocialActive === "sms" && (
            <>
              <SmsView data={smsViewData} loader={loader} />
              <SmsView data={smsViewData} loader={loader} />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default EditMyMedia;
