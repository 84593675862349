import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import instaicon from "../../../../assets/images/instaicon.svg";
import faceicon from "../../../../assets/images/facebookIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import logo from "./../../../../assets/img/logo.png";
import mystoryicon from "../../../../assets/images/header/mystory.png";
import mymedia from "../../../../assets/images/header/my-media.png";
import mycomputer from "../../../../assets/images/header/my-computer.png";
import insight360 from "../../../../assets/images/header/insight.png";
import { storeInLocalStore } from "../../../../redux/reducers/mainSlice";
import menus from "../../menu";

const Footerinner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentsubmenu = useSelector(
    (state) => state.main.store?.currentsubmenu
  );
  const users = useSelector((state) => state.main.store?.users);
  const handlemenu = (submenu) => {
    navigate(submenu.link);
    dispatch(storeInLocalStore({ currentsubmenu: submenu.key }));
  };
  return (
    <>
      {users?.id ? (
        <>
          <div className="full-width">
            <div className="">
              <div className="footer-second">
                <div className="content-padding-both">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="logoarea">
                        <Link to={"/"} className="navbar-brand">
                          <img src={logo} alt="" className="logo" />
                        </Link>
                        <ul className="menu">
                          <li className="item">
                            <Link to={"/"}>Privacy Policy</Link>
                          </li>
                          <li className="item">
                            <Link to={"/"}>Terms & Conditions</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        {menus.map((menu, index) => (
                          <div key={index} className="col-md-3">
                            <div
                              className={`menu-head ${
                                menu.link == location.pathname ? "active" : ""
                              }`}
                            >
                              <span>{menu.name}</span>
                            </div>
                            <ul className="menu">
                              {menu.submenu.map((item, subIndex) => {
                                return (
                                  <li
                                    key={subIndex}
                                    className="item"
                                    onClick={() => {
                                      handlemenu(item);
                                    }}
                                  >
                                    <span
                                      className={`${
                                        currentsubmenu == item.key
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {item.name}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="full-width">
            <div className="">
              <div className="footer">
                <div className="logoarea content-padding-left">
                  <Link to={"/"} className="navbar-brand">
                    <img src={logo} alt="" className="logo" />
                  </Link>
                </div>

                <div className="menu-area">
                  <ul className="menu">
                    <li className="item">
                      <Link to={"/"} className={`dashboad-btn`}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="item">
                      <Link to={"/"} className={`dashboad-btn `}>
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="social-media content-padding-right">
                  <img src={faceicon} className="facebook" />
                  <img src={instaicon} className="instagram" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footerinner;
