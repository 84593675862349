import { Box } from "@mui/material";
import React from "react";
import resetImg from "../../assets/SVG/ic_round-refresh.svg";

const style = {
  btnBox: {
    width: "40px",
    height: "40px",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    margin: "10px 10px 10px 0px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};

const ResetBtn = ({ resetText }) => {
  return (
    <>
      <Box onClick={resetText} sx={style.btnBox}>
        <img src={resetImg} alt="ResetBtn" />
      </Box>
    </>
  );
};

export default ResetBtn;
